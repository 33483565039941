import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationActions } from "../reducers/notificationsSlice";

interface IShowSnackbarWithAutoHide {
    msgText: string;
}

export const showSnackbarWithAutoHide = createAsyncThunk<
    void,
    IShowSnackbarWithAutoHide
>(
    "notifications/showSnackbarWithAutoHide",
    async ({ msgText }, { dispatch }) => {
        const { showSnackbar, hideSnackbar } = notificationActions;

        dispatch(
            showSnackbar({
                msgText,
            })
        );
        setTimeout(() => {
            dispatch(hideSnackbar());
        }, 6000);
    }
);
