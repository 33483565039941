import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
    NewMessageToPartyInput: INewMessageToPartyInput[];
}

export interface INewMessageToPartyInput {
    id: number;
    msgText: string;
}

const initialState: IState = {
    NewMessageToPartyInput: [],
};

const dragDropSlice = createSlice({
    name: "drag_drop",
    initialState,
    reducers: {
        changeNewMessageToPartyInput: (
            state,
            action: PayloadAction<INewMessageToPartyInput>
        ) => {
            const { id, msgText } = action.payload;
            const existingMessageIndex = state.NewMessageToPartyInput.findIndex(
                (message) => message.id === id
            );
            if (existingMessageIndex !== -1) {
                state.NewMessageToPartyInput[existingMessageIndex].msgText =
                    msgText;
            } else {
                state.NewMessageToPartyInput.push({ id, msgText });
            }
        },
        resetNewMessageToPartyInput: (state) => {
            state.NewMessageToPartyInput.forEach((message) => {
                message.msgText = "";
            });
        },
        deleteMessageByIdNewMessageToPartyInput: (
            state,
            action: PayloadAction<number>
        ) => {
            const idToDelete = action.payload;
            state.NewMessageToPartyInput = state.NewMessageToPartyInput.filter(
                (message) => message.id !== idToDelete
            );
        },
    },
});

export const dragDropActions = dragDropSlice.actions;

const DragDropReducer = dragDropSlice.reducer;

export default DragDropReducer;
