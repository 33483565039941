import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { FormAction } from "../../types/Common";
import AddGuestFromContactForm from "../forms/AddGuestFromContactForm";
import NewPartyForm from "../forms/NewPartyForm";
import UpdatePartyForm from "../forms/UpdatePartyForm";

const PartyFormModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { closePartyModal } = modalActions;
    const action = useTypedSelector((state) => state.modals.partyModal.action);
    const show = useTypedSelector((state) => state.modals.partyModal.isOpen);

    const handleClose = useCallback(() => {
        dispatch(closePartyModal());
    }, []);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header
                closeButton
                className="d-flex justify-content-end custom-modal-header"
            ></Modal.Header>
            <Modal.Body>
                {action === FormAction.CREATE ? (
                    <NewPartyForm />
                ) : action === FormAction.MAP ? (
                    <AddGuestFromContactForm />
                ) : (
                    <UpdatePartyForm />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default PartyFormModal;
