import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import {
    fetchAnnouncements,
    fetchAnnouncementsLoadMore,
} from "../../store/asyncActions/announcement";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions as announcementActions } from "../../store/reducers/announcementSlice";
import { actions } from "../../store/reducers/contactSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { AnnouncementField, ContactField } from "../../types/MsgThread";
import Loading from "../common/Loading";
import FormErrors from "./FormErrors";

// const validationSchema = Yup.object().shape({
//     name: Yup.string()
//         .required("announcement name is required")
//         .max(150, "announcement name can't be that long"),
// });
interface Contact {
    label: string;
    value: number;
    // other properties...
}
const AnnouncementContactForm: React.FC = () => {
    // const [initialValues, setInitialValues] =
    //     useState<NewAnnouncementFromValues>({
    //         name: "",
    //     });
    const dispatch = useTypedDispatch();

    const formErrors = useTypedSelector(
        (state) => state.announcement.createAnnouncementForm.error
    );
    const { createAddInAnnouncement } = actions;
    const { closeAnncounmentModal } = modalActions;
    const { fetchAnnouncementsContacts } = announcementActions;

    const announcement = useTypedSelector(
        (state) => state.modals.announcementModal.currentAnnouncement
    );
    const { contactId, announcement_type } = useTypedSelector(
        (state) => state.modals?.announcementModal
    );
    const { activeList: activeContactList } = useTypedSelector(
        (state) => state.contact.contactList
    );
    const { isLoading: announcementIsLoading } = useTypedSelector(
        (state) => state.contact.createAddInContactForm
    );

    const [contactList, setContactList] = useState<ContactField[]>([]);

    useEffect(() => {
        if ((activeContactList as any)?.items) {
            setContactList((activeContactList as any)?.items);
        }
    }, [activeContactList]);

    const { listMore, isLoading, page, limit, search, limitMore } =
        useTypedSelector((state) => state.announcement.announcementList);

    const [announcementApiList, setAnnouncementApiList] = useState<
        AnnouncementField[]
    >([]);
    useEffect(() => {
        if ((listMore as any)?.items) {
            setAnnouncementApiList((listMore as any)?.items);
        }
    }, [listMore]);
    const totalAnnouncment = (listMore as any)?.meta?.totalItems;
    const itemAnnouncmentLoaded = (listMore as any)?.meta?.itemCount;

    const [announcementSelect, setAnnouncementSelect] = useState(
        announcement?.id || 0
    );

    useEffect(() => {
        if (announcement) {
            // setInitialValues(announcement);
        }
        dispatch(
            fetchAnnouncementsLoadMore({
                _page: 1,
                _limit: limitMore,
                search: "",
            })
        );
    }, [announcement]);
    const handleAnnouncementChange = (selectedOption: any) => {
        setAnnouncementSelect(selectedOption ? selectedOption.value : null); // Use null if you want to allow clearing the selection
    };
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

    const contactListOptions = contactList.map((contact) => ({
        label: contact.name,
        value: contact.id,
    }));

    const handleCustomSubmit = () => {
        let contactIds = [];
        if (!contactId) {
            contactIds = selectedContacts.map((contact) => contact.value);
        } else {
            contactIds = [contactId];
        }
        const payload = {
            announcement_id: announcementSelect,
            contact_id: contactIds,
            announcement_type: announcement_type,
        };

        return dispatch(createAddInAnnouncement(payload))
            .unwrap()
            .then((data: any) => {
                dispatch(
                    fetchAnnouncements({ _page: page, _limit: limit, search })
                );
                dispatch(
                    fetchAnnouncementsContacts(announcement?.id || 0)
                ).unwrap();
                setAnnouncementSelect(0);
                setSelectedContacts([]);
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: "Added!",
                    })
                );
                return data;
            })
            .then(() => {
                dispatch(closeAnncounmentModal());
            });
    };
    // const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const debouncedLoadOptions = debounce(loadOptions, 500);
    async function loadOptions() {
        if (loading) {
            return;
        }

        setLoading(true);

        try {
            if (itemAnnouncmentLoaded < totalAnnouncment) {
                // setHasMore(false);
                dispatch(
                    fetchAnnouncementsLoadMore({
                        _page: 1,
                        _limit: (limitMore ?? 0) + limit,
                        search,
                    })
                );
            }
        } finally {
            setLoading(false);
        }
    }
    const handleMenuScrollToBottom = () => {
        debouncedLoadOptions();
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                handleCustomSubmit();
            }}
            className="d-flex flex-column justify-content-between h-100"
        >
            <div>
                {!announcement && (
                    <Form.Row>
                        <Form.Group className="col-md">
                            <Select
                                placeholder="Select Announcement"
                                // defaultValue={[{ value: announcement?.id, label: announcement !=undefined ? announcement?.name?.toUpperCase() : "Select Announcement"}]}
                                options={announcementApiList.map(
                                    (announcementItem) => ({
                                        value: announcementItem?.id,
                                        label: announcementItem?.name?.toUpperCase(),
                                    })
                                )}
                                isLoading={isLoading}
                                isSearchable={false}
                                onMenuScrollToBottom={handleMenuScrollToBottom}
                                onChange={handleAnnouncementChange}
                            />
                        </Form.Group>
                    </Form.Row>
                )}
                {!contactId && (
                    <Form.Row>
                        <Form.Group className="col-md">
                            <div id="multisection" className="control-section">
                                <div id="multidefault">
                                    <div className="control-styles">
                                        <p className="text-black-50 mb-1">
                                            Select Contacts
                                        </p>
                                        <div>
                                            <MultiSelect
                                                options={contactListOptions}
                                                value={selectedContacts}
                                                onChange={setSelectedContacts}
                                                labelledBy="Select Contact"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Form.Row>
                )}
            </div>

            <FormErrors errors={formErrors} />
            <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                <Button
                    variant="primary"
                    type="submit"
                    disabled={
                        (!announcementSelect && !announcement) ||
                        announcementIsLoading
                    }
                    className="mx-4"
                >
                    {announcement
                        ? "Update Announcement"
                        : "Add Announcement's Contacts"}
                </Button>
            </Form.Row>
            {announcementIsLoading && <Loading />}
        </Form>
    );
};

export default AnnouncementContactForm;
