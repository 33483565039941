import * as React from "react";
import { Message } from "../../types/Message";
import MessageMediaPreviews from "../script/MessageMediaPreviews";
import "./messages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble, faCircleExclamation, faHourglass } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    message: Message;
    justify: "left" | "right";
    link?: string;
}

const ChatMessage: React.FC<IProps> = ({ message, justify }) => {
    const align = justify === "right" ? "align-self-end" : "align-self-start";

    return (
        <div style={{ display: "flex", overflowWrap: "anywhere" }}>
            {message.type === "Outgoing" ? (
                <div
                    className={`msg-left msg-incoming bg-primary px-3 py-2 mb-2 rounded-lg text-white ${align}`}
                >
                    {message?.messageText.replace(/https?:\/\/\S+/g, "")}
                    <a
                        href={
                            message?.messageText.match(/https?:\/\/\S+/g)?.[0]
                        }
                        className="MessagePreview-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {message?.messageText.match(/https?:\/\/\S+/g)?.[0]}
                    </a>

                    {message.mediaItems && message.mediaItems.length > 0 && (
                        <MessageMediaPreviews mediaItems={message.mediaItems} />
                    )}
                    {message.status === "sent" && (
                        <FontAwesomeIcon
                            style={{ marginBottom: "-7px", marginLeft: "5px" }}
                            icon={faCheck}
                        />
                    )}
                    {message.status === "delivered" && (
                        <FontAwesomeIcon
                            style={{ marginBottom: "-7px", marginLeft: "5px" }}
                            icon={faCheckDouble}
                        />
                    )}
                    {message.status === "failed" && (
                        <FontAwesomeIcon
                            style={{ marginBottom: "-7px", marginLeft: "5px" }}
                            icon={faCircleExclamation}
                        />
                    )}
                     {message.status === "queued" && (
                        <FontAwesomeIcon
                            style={{ marginBottom: "-7px", marginLeft: "5px" }}
                            icon={faHourglass}
                        />
                    )} 
                </div>
            ) : message.type === "Incoming" ? (
                <div
                    className="msg-right bg-secondary  px-3 py-2 mb-2 rounded-lg text-black align-self-start"
                    style={{ marginLeft: "auto" }}
                >
                    <span className="inline-block">{message.messageText}</span>
                    <div
                        className="inline-block ml-2 "
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <span style={{ color: "grey", fontSize: "10px" }}>
                            {message?.texter?.guest
                                ? `${message?.texter?.guest.nameFirst} ${message?.texter?.guest.nameLast}`
                                : null}
                        </span>
                    </div>

                    {message.mediaItems && message.mediaItems.length > 0 && (
                        <MessageMediaPreviews mediaItems={message.mediaItems} />
                    )}
                </div>
            ) : (
                <div
                    className={`msg-left msg-incoming bg-primary px-3 py-2 mb-2 rounded-lg text-white ${align}`}
                >
                    ...
                </div>
            )}
        </div>
    );
};

export default ChatMessage;
