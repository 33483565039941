import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatureTypeRendering from "../components/common/FeatureTypeRendering";
import useQueryParams from "../hooks/useQueryParams";
import DefaultLayout from "../layouts/DefaultLayout";
import LayoutCard from "../layouts/LayoutCard";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { authActions } from "../store/reducers/authSlice";
import { Feature } from "../types/Feature";
import { Mymembership } from "../types/Mymembership";
import { Plantype } from "../types/PlanType";

const MyPlans: React.FC = () => {
    const globalUser = useTypedSelector((state) => state.auth.currentUser);
    const dispatch = useTypedDispatch();
    const { restoreUser } = authActions;
    const params = useQueryParams();
    const success = params.get("success");
    const [isLoading, setIsLoading] = useState(false);
    const [myPlans, setMyPlans] = useState<Array<Plantype> | undefined>([]);
    useEffect(() => {
        const myplans = globalUser?.membershipPlan?.map(
            (plan: Mymembership) => plan?.planType
        );
        setMyPlans(myplans);
    }, []);

    useEffect(() => {
        if (success) {
            setIsLoading(true);
            dispatch(restoreUser())
                .unwrap()
                .then((user) => { 
                    if (!user.isActive) setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    }, [success]);

    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={2} xl={3} className="mt-15">
                <Col xl={12}>
                    <LayoutCard>
                        <div className="modal-body pt-0 pb-15 px-5 px-xl-20 mt-10">
                            <div className="mb-13 text-center">
                                <h1 className="mb-3 text-center">My Plans</h1>

                                <div className="text-muted fw-bold fs-5"></div>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="mt-10">
                                    <div className="row mb-10 mb-lg-0">
                                        {!isLoading &&
                                            myPlans?.map((plan: Plantype) => {
                                                return (
                                                    <div
                                                        className="col-md-6 col-sm-12 col-xl-4 mb-3"
                                                        key={plan?.id}
                                                    >
                                                        <div className="d-flex h-100 align-items-center">
                                                            {/*begin::Option*/}
                                                            <div className="w-100 h-100 d-flex flex-column flex-start align-items-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
                                                                {/*begin::Heading*/}
                                                                <div className="mb-15 text-center">
                                                                    {/*begin::Title*/}
                                                                    <h1 className="text-dark text-uppercase h1 mb-5 font-weight-bolder">
                                                                        {
                                                                            plan?.name
                                                                        }
                                                                    </h1>

                                                                    {/*end::Description*/}
                                                                    {/*begin::Price*/}
                                                                    <div className="text-center">
                                                                        <span className="fs-3x fw-bolder h1 text-primary">
                                                                            $
                                                                        </span>
                                                                        <span className="fs-3x fw-bolder h1 text-primary">
                                                                            {
                                                                                plan?.price
                                                                            }
                                                                        </span>

                                                                        <span className="fs-7 fw-semibold opacity-50">
                                                                            /
                                                                            <span data-kt-element="period">
                                                                                {plan?.recurrence ===
                                                                                "monthly"
                                                                                    ? "Mon"
                                                                                    : "Year"}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    {/*end::Price*/}
                                                                </div>
                                                                {/*end::Heading*/}
                                                                {/*begin::Features*/}
                                                                <div className="w-100 mb-5">
                                                                    {plan
                                                                        ?.features
                                                                        ?.length &&
                                                                        plan?.features?.map(
                                                                            (
                                                                                feature: Feature,
                                                                                index: number
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        key={
                                                                                            feature?.key ||
                                                                                            index
                                                                                        }
                                                                                        className="d-flex align-items-center mb-6"
                                                                                    >
                                                                                        <span className="font-weight-bold h6 text-gray-700 flex-grow-1">
                                                                                            {
                                                                                                feature?.fname
                                                                                            }
                                                                                        </span>
                                                                                        {
                                                                                            <FeatureTypeRendering
                                                                                                featureProp={
                                                                                                    feature
                                                                                                }
                                                                                            ></FeatureTypeRendering>
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-center flex-row-fluid pt-12">
                                <Link to="/upgrade">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Upgrade Plan
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </LayoutCard>
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default MyPlans;
