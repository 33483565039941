import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

interface IProps {
    to: string;
    title: string;
}

const SimpleLink: React.FC<IProps> = ({ to, title }) => {
    return (
        <Nav.Link
            className="nav-bar__link pt-6"
            as={NavLink}
            to={to}
            activeClassName="nav-bar__link-active"
        >
            <h6 className="fw-bold">{title}</h6>
        </Nav.Link>
    );
};

export default SimpleLink;
