import React, { useEffect } from "react";
import Loading from "../components/common/Loading";
import { Spinner } from "react-bootstrap";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { actions } from "../store/reducers/automationsSlice";
import { useHistory } from "react-router-dom";

const TextFlowCopy: React.FC = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token") || "";
    const history = useHistory();

    const dispatch = useTypedDispatch();
    const { copyScriptByInvite } = actions;

    useEffect(() => {
        if (token) {
            dispatch(copyScriptByInvite({ history, token }));
        } else {
            history.push("/");
            return;
        }
    }, [dispatch, token]);

    return (
        <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
            <Spinner
                animation="border"
                role="status"
                className="p1 p-5 mb-10"
            />
            <div className="mt-2">
                <p className="p3">
                    Please wait while we copy textflow to your account!...
                </p>
            </div>
        </div>
    );
};

export default TextFlowCopy;
