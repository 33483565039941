import React, { useEffect, useState } from "react";
import MessagesList from "./MessagesList";

import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/directMessagesSlice";
import { MsgThreadWithLastMsg } from "../../types/MsgThread";
// import { modalActions } from "../../store/reducers/modalsSlice";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";

// This component is used on the Event Hub and Manage Events page
const GuestsDirectMessages: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { list, isLoading, page, limit } = useTypedSelector(
        (state) => state.directMessages.threads
    );
    const [messagesList, setMessagesList] = useState<MsgThreadWithLastMsg[]>(
        []
    );

    const { fetchSellerDirectMessageThreads } = actions;
    // const { toggleNewDirectMessageModalOpen } = modalActions;

    // const showModal = useCallback(() => {
    //     dispatch(toggleNewDirectMessageModalOpen(true));
    // }, []);

    const SearchField = useTypedSearchField<MsgThreadWithLastMsg>({
        inputPlaceholder: "Search Messages...",
        isLoading,
        // btnOnClick: () => showModal(),
        hideBtn: true,
        setList: setMessagesList,
        list,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
    });

    useEffect(() => {
        dispatch(
            fetchSellerDirectMessageThreads({
                _page: page,
                _limit: limit,
            })
        );
    }, []);

    return (
        <>
            {SearchField}
            <MessagesList messagesList={messagesList} isLoading={isLoading} />
        </>
    );
};

export default GuestsDirectMessages;
