import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RedirectUrlState {
    url: string | undefined;
}
const initialState: RedirectUrlState = {
    url: undefined,
};

const redirectUrlSlice = createSlice({
    name: "redirectUrl",
    initialState,
    reducers: {
        setRedirectUrl: (
            state,
            action: PayloadAction<{ url: string | undefined }>
        ) => {
            state.url = action.payload.url;
        },
        clearRedirectUrl: (state) => {
            state.url = undefined;
        },
    },
});

export const redirectUrlActions = {
    ...redirectUrlSlice.actions,
};

const redirectUrlReducer = redirectUrlSlice.reducer;

export default redirectUrlReducer;
