import * as React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import { ChangePasswordFormValues } from "../../types/Forms";
import { Button, Form } from "react-bootstrap";
import CustomInput from "./fields/CustomInput";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { authActions } from "../../store/reducers/authSlice";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { useState } from "react";
import { ServerError } from "../../types/Error";
import FormErrors from "./FormErrors";

const initialValues: ChangePasswordFormValues = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
};

const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required("New password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/, // Modify this regex as needed
            "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
        ),
    newPasswordRepeat: Yup.string()
        .required("Repeated password is required")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
    oldPassword: Yup.string().when("isChangePassword", {
        is: false,
        then: Yup.string().required("Old password is required"),
        otherwise: Yup.string(),
    }),
});

const ChangePasswordForm: React.FC = () => {
    const email = useTypedSelector((state) => state.auth.currentUser?.email);
    const isChangePassword = useTypedSelector(
        (state) => state.auth.currentUser?.changePassword
    );
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );
    if (!isChangePassword) {
        validationSchema.fields.oldPassword = Yup.string().required(
            "Old password is required"
        );
    }

    const dispatch = useTypedDispatch();
    const { changePassword, restoreUser } = authActions;
    const { showSnackbarWithAutoHide } = notificationActions;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (
                values,
                { setSubmitting, setErrors, resetForm }
            ) => {
                if (!email) return;
                setFormErrors(undefined);
                setSubmitting(true);
                try {
                    const payload: any = isChangePassword
                        ? {
                              email,
                              newPassword: values.newPassword,
                              isChangePassword,
                          }
                        : {
                              email,
                              oldPassword: values.oldPassword,
                              newPassword: values.newPassword,
                              isChangePassword,
                          };
                    await dispatch(changePassword(payload)).unwrap();
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Updated!",
                        })
                    );
                    setSubmitting(false);
                    resetForm();
                    dispatch(restoreUser());
                } catch (err: any) {
                    setFormErrors(err);
                    setSubmitting(false);
                    setErrors(transformToFormikErrors(err));
                }
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column"
                    style={{ margin: 0, padding: 0 }}
                >
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        {!isChangePassword && (
                            <Form.Group className="w-100 w-md-30 m-2">
                                <CustomInput
                                    placeholder="Old Password"
                                    type="password"
                                    label="Old Password"
                                    name="oldPassword"
                                    required={true}
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="w-100 w-md-30 m-2">
                            <CustomInput
                                placeholder="New Password"
                                type="password"
                                label="New Password"
                                name="newPassword"
                                required={true}
                            />
                        </Form.Group>
                        <Form.Group className="w-100 w-md-30 m-2">
                            <CustomInput
                                placeholder="Repeat New Password"
                                type="password"
                                label="Repeat New Password"
                                name="newPasswordRepeat"
                                required={true}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={
                                !dirty ||
                                !isValid ||
                                isSubmitting ||
                                (isChangePassword && !dirty)
                            }
                            className="mx-4"
                        >
                            Update Password
                        </Button>
                    </div>
                    <div>
                        <FormErrors errors={formErrors} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ChangePasswordForm;
