import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message } from "../../types/Message";
import {
    DirectMessagesState,
    MsgThreadWithLastMsg,
} from "../../types/MsgThread";
import {
    fetchMessagesForThread,
    fetchSellerDirectMessageThreads,
} from "../asyncActions/directMessages";

const initialState: DirectMessagesState = {
    messages: {
        isLoading: false,
        error: undefined,
        list: [],
    },
    threads: {
        list: [],
        isLoading: false,
        error: undefined,
        page: 1,
        limit: 20,
        hasMore: true,
        search: "",
    },
};

const directMessagesSlice = createSlice({
    name: "direct-messages",
    initialState,
    reducers: {},
    extraReducers: {
        // Fetch direct messages threads
        [fetchSellerDirectMessageThreads.pending.toString()]: (state) => {
            state.threads.isLoading = state.threads.list.length === 0;
            state.threads.error = undefined;
        },
        [fetchSellerDirectMessageThreads.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.threads.isLoading = false;
            state.threads.error = action.payload;
        },
        [fetchSellerDirectMessageThreads.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThreadWithLastMsg[]>
        ) => {
            state.threads.isLoading = false;
            state.threads.list = action.payload;
            state.threads.hasMore =
                action.payload.length === state.threads.limit;
        },
        // Fetch list of messages for the thread
        [fetchMessagesForThread.pending.toString()]: (state) => {
            state.messages.isLoading = true;
            state.messages.error = undefined;
        },
        [fetchMessagesForThread.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messages.isLoading = false;
            state.messages.error = action.payload;
        },
        [fetchMessagesForThread.fulfilled.toString()]: (
            state,
            action: PayloadAction<{ threadId: number; list: Message[] }>
        ) => {
            state.messages.isLoading = false;
            state.messages.list = action.payload.list;
        },
    },
});

export const actions = {
    ...directMessagesSlice.actions,
    fetchSellerDirectMessageThreads,
    fetchMessagesForThread,
};

const directMessagesReducer = directMessagesSlice.reducer;

export default directMessagesReducer;
