import React from "react";
import { Guest } from "../../types/Guest";
import ManageGuestPopover from "./ManageGuestPopover";

interface IProps {
    guests: Guest[];
    // guests: any;
    isLoading?: boolean;
    name?: string;
}

const GuestList: React.FC<IProps> = ({ name, guests, isLoading }) => {
    if (isLoading && (!guests || guests.length === 0))
        return (
            <div>
                {new Array(5).fill(0).map((_, index) => (
                    <div
                        key={index}
                        className="d-flex align-items-center mb-1 px-2 py-1 list-view__item rounded"
                    >
                        <div className="rounded-circle shadow-sm skeleton bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4" />
                        <div className="flex-grow-1 d-flex flex-column">
                            <div className="skeleton w-50 mb-3 shadow-sm" />
                            <div className="skeleton w-20 shadow-sm" />
                        </div>
                    </div>
                ))}
            </div>
        );

    if (guests.length === 0)
        return (
            <div>
                <p className="p3 text-center mt-3">
                    {name ? name : "No guests"}
                </p>
            </div>
        );

    return (
        <div> 
            {guests.map((guest: any) => (
                <div
                    key={guest.id}
                    className="d-flex align-items-center mb-1 px-2 py-1 list-view__item rounded"
                >
                    <div className="rounded-circle shadow-sm bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4 user-select-none">
                        <p className="mb-0">
                            {guest?.nameFirst[0]?.toUpperCase()}
                            {guest?.nameLast[0]?.toUpperCase()}
                        </p>
                    </div>
                    <div className="flex-grow-1"> 
                            <span className="h6 mb-0 pr-1">
                                {guest.nameFirst} {guest.nameLast}
                            </span>
                            {!guest.texters.isActive && (
                                <span className="badge badge-danger">
                                    Waiting for approval
                                </span>
                            )} 
                        <p className="p5 mb-0">{guest?.texters?.phoneCell}</p>
                    </div>
                    <ManageGuestPopover guest={guest} />
                </div>
            ))}
        </div>
    );
};

export default GuestList;
