import React, { useEffect } from "react";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/contactSlice";
import { ContactLists } from "../../types/ContactList";
import ManageContactPopover from "./ManageContactPopover";

interface IProps {
    contact: ContactLists[];
    isLoading?: boolean;
    name?: string;
}
const ContactList: React.FC<IProps> = ({ name, contact, isLoading }) => {
    const dispatch = useTypedDispatch();
    const selectedContact = useTypedSelector(
        (state) => state.contact.selectedContact
    );
    const { page, limit, search } = useTypedSelector(
        (state) => state.contact.contactList
    );
    useEffect(() => {
        selectedContact;
    }, [selectedContact]);

    if (isLoading && (!contact || contact.length === 0))
        return (
            <div>
                {new Array(5).fill(0).map((_, index) => (
                    <div
                        key={index}
                        className="d-flex align-items-center mb-1 px-2 py-1 list-view__item rounded"
                    >
                        <div className="rounded-circle shadow-sm skeleton bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4" />
                        <div className="flex-grow-1 d-flex flex-column">
                            <div className="skeleton w-50 mb-3 shadow-sm" />
                            <div className="skeleton w-20 shadow-sm" />
                        </div>
                    </div>
                ))}
            </div>
        );

    if (contact.length === 0)
        return (
            <div>
                <p className="p3 text-center mt-3">
                    {name ? name : "No Contacts"}
                </p>
            </div>
        );
    return (
        <div>
            {contact.map((contact: any) => (
                <div
                    onClick={() => {
                        dispatch(actions.selectOne(contact));
                    }}
                    style={{
                        cursor: "pointer",
                        backgroundColor:
                            selectedContact?.id &&
                            selectedContact?.id === contact?.id
                                ? "#E0E2EF"
                                : "transparent",
                    }}
                    key={contact.id}
                    className="d-flex align-items-center mb-0 mt-2 px-2 py-1 list-view__item rounded"
                >
                    <div className="rounded-circle shadow-sm bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4 user-select-none">
                        <p className="mb-0">
                            {contact?.name[0]?.toUpperCase()}
                            {contact?.name[0]?.toUpperCase()}
                        </p>
                    </div>
                    <div className="flex-grow-1">
                        <h6 className="mb-0">{contact.name}</h6>
                        <p className="p5 mb-0">{contact.phoneCell}</p>
                    </div>
                    <ManageContactPopover
                        contact={contact}
                        _page={page}
                        _limit={limit}
                        search={search}
                    />
                </div>
            ))}
        </div>
    );
};

export default ContactList;
