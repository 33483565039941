import * as React from "react";
import { ChangeEvent } from "react";

interface IProps {
    title?: string;
    value: number | string;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    isLoading?: boolean;
    disabled?: boolean;
    white?: boolean;
}

const CustomSelect: React.FC<IProps> = ({
    title,
    isLoading,
    onChange,
    value,
    children,
    disabled,
    white,
}) => {
    const additionalStyles = white ? "bg-white" : "";

    return (
        <>
            {title && <p className="text-black-50 mb-1">{title}</p>}
            {isLoading ? (
                <div className="h-50px w-100 skeleton rounded shadow-sm" />
            ) : (
                <select
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    className={`form-control h-auto py-5 px-6 mb-2 mt-2 ${additionalStyles}`}
                >
                    {children}
                </select>
            )}
        </>
    );
};

export default CustomSelect;
