import * as React from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import ScriptForm from "../forms/ScriptForm";

const ScriptModal: React.FC = () => {
    const dispatch = useTypedDispatch();

    const show = useTypedSelector((state) => state.modals.scriptModal.isOpen);

    const { closeScriptModal } = modalActions;

    const onHide = useCallback(() => {
        dispatch(closeScriptModal());
        
    }, []);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton className="d-flex justify-content-end custom-modal-header">
        </Modal.Header>
            <Modal.Body>
                <ScriptForm />
            </Modal.Body>
        </Modal>
    );
};

export default ScriptModal;
