import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import * as Yup from "yup";

import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useQueryParams from "../../hooks/useQueryParams";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/directMessagesSlice";
import { MessageFormValues } from "../../types/Forms";
import CustomInput from "../forms/fields/CustomInput";
import { InfoCircle, PaperPlaneRegular } from "../icons";
import PrepopulatedMessages from "../prepopulatedMessages/PrepopulatedMessages";
import "./messages.scss";

const initialValues: MessageFormValues = {
    msgText: "",
};

const validationSchema = Yup.object().shape({
    msgText: Yup.string().required("Message text is required!").min(1),
});

const MessagingThread: React.FC = () => {
    const searchParams = useQueryParams();
    const bottomRef = useRef<HTMLDivElement>(null);
    const dispatch = useTypedDispatch();
    const threadId = Number(searchParams.get("thread"));
    const list = useTypedSelector(
        (state) => state.directMessages.messages.list
    );
    const { fetchMessagesForThread } = actions;

    useEffect(() => {
        bottomRef.current?.scrollIntoView();
    });

    useEffect(() => {
        if (threadId)
            dispatch(
                fetchMessagesForThread({
                    threadId,
                })
            );
    }, [threadId]);

    if (!threadId) {
        return (
            <div className="d-flex justify-content-center align-items-center h-700px">
                <Badge pill variant="primary px-4 py-2 shadow-sm">
                    <span className="p4 user-select-none">
                        Select a chat to start messaging
                    </span>
                </Badge>
            </div>
        );
    }

    return (
        <div className="d-flex h-100 max-h-700px">
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex justify-content-between align-items-center h-70px bg-white top-0 left-0">
                    <div className="d-flex align-items-center">
                        <div className="bg-gray-500 min-h-65px min-w-65px rounded-circle d-flex justify-content-center align-items-center shadow-sm">
                            HD
                        </div>
                        <div className="ml-5 d-flex flex-column justify-content-center pt-2">
                            <h3>
                                Haylie Donin{" "}
                                <InfoCircle className="h-20px w-20px cursor-pointer ml-1" />
                            </h3>
                            <p className="p4">801-888-8888</p>
                        </div>
                    </div>
                    <div>
                        <FontAwesomeIcon
                            icon={faEllipsisH}
                            className="cursor-pointer h-20px w-20px"
                        />
                    </div>
                </div>
                <div className="bg-white flex-grow-1 overflow-auto pt-3 d-flex flex-column">
                    {list.length === 0 && (
                        <Badge pill variant="primary px-4 py-2 shadow-sm">
                            <span className="p4 user-select-none">
                                Select a chat to start messaging
                            </span>
                        </Badge>
                    )}
                    {list.map((msg) => {
                        const isMyMsg = Math.random() * 2 >= 1;
                        const msgClass = isMyMsg ? "msg-right" : "msg-left";
                        return (
                            <div
                                className={`${msgClass} text-white px-4 py-3 mb-2 rounded-lg max-w-sm-500px max-w-90`}
                                key={msg.id}
                            >
                                {msg.messageText}
                            </div>
                        );
                    })}

                    <div ref={bottomRef} />
                </div>
                <div className="h-65px bg-white d-flex align-items-center justify-content-center">
                    <Formik
                        onSubmit={() => console.info("Send message!")}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {({ dirty, handleSubmit, isSubmitting, isValid }) => (
                            <Form
                                onSubmit={handleSubmit}
                                className="d-flex w-100 pt-7"
                            >
                                <CustomInput
                                    hideError={true}
                                    placeholder="Type Message Here..."
                                    type="text"
                                    noLabel
                                    className={
                                        "w-100 bg-gray-300 placeholder-dark-75"
                                    }
                                    name="msgText"
                                />
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={
                                        !dirty || !isValid || isSubmitting
                                    }
                                    className="min-w-50px h-50px rounded-circle ml-4"
                                    title="Send Message"
                                >
                                    <PaperPlaneRegular className="h-15px w-15px mx-auto" />
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className="w-200px border-left d-none d-md-block d-lg-none d-xl-block ml-2">
                <p className="w-100 text-center p5 text-uppercase mb-1">
                    QUICK TEXTS
                </p>
                <PrepopulatedMessages />
            </div>
        </div>
    );
};

export default MessagingThread;
