import React, { useCallback, useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import CustomPagination from "../../layouts/CustomPagination";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions as automationActions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { Script, ScriptType } from "../../types/Script";
import ScriptsList from "./ScriptsList";

interface IProps {
    type: ScriptType;
}

const ScriptsListView: React.FC<IProps> = ({ type }) => {
    const dispatch = useTypedDispatch();

    const { fetchScripts, fetchScriptsPaginate } = automationActions;
    const { openCreateScriptModal } = modalActions;

    const { page, limit } = useTypedSelector(
        (state) => state.automations.scripts
    );
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState<number>(page);
    const [searchValue, setSearchValue] = useState<string>("");
    const [dataPerPage] = useState(limit);
    useEffect(() => {
        const fetchData = () => {
            dispatch(
                fetchScriptsPaginate({
                    _page: currentPage,
                    _limit: dataPerPage,
                    search: searchValue,
                })
            );
            history.push("/automations/workflows");
        };
        fetchData();
        dispatch(fetchScripts({ _page: 1, _limit: 1000, search: "" }));
    }, [currentPage, dataPerPage]);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    const { listPaginate, isLoading } = useTypedSelector(
        (state) => state?.automations?.scripts
    );

    const ScriptList = useTypedSelector(
        (state) => state?.automations?.scripts?.list
    );

    const totalItems = (listPaginate as any)?.meta?.totalItems;
    const [apiList, setApiList] = useState<Script[]>([]);

    const [filteredScriptsList, setScriptsList] = useState<Script[]>([]);

    useEffect(() => {
        if (ScriptList && "items" in ScriptList) {
            setScriptsList((ScriptList as any).items || []);
        } else {
            setScriptsList([]);
        }
    }, [ScriptList]);

    const openModal = useCallback(() => {
        dispatch(
            openCreateScriptModal({
                type,
            })
        );
    }, []);
    useEffect(() => {
        if ((listPaginate as any)?.items) {
            setApiList((listPaginate as any)?.items);
        }
    }, [listPaginate]);
    const singleTypeScriptsList = useMemo(
        () =>
            apiList?.filter((script: any) => script?.scripttype?.name === type),
        [apiList]
    );
    const SearchField = useTypedSearchField<Script>({
        inputPlaceholder: "Search Here...",
        isLoading,
        btnOnClick: openModal,
        list: singleTypeScriptsList,
        setList: setScriptsList,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
        searching: "serverSide",
        searchOnChange: (value) => {
            dispatch(
                fetchScriptsPaginate({
                    _page: 1,
                    _limit: dataPerPage,
                    search: value,
                })
            );
            setSearchValue(value);
            return value;
        }, // Set the value here
    });
    return (
        <div className="max-h-700px">
            {SearchField}
            <PerfectScrollbar
               className="pr-3 mt-2 max-h-600px"
            >
                <ScriptsList
                    items={filteredScriptsList}
                    isLoading={isLoading}
                    type={type}
                />
            </PerfectScrollbar>
            <div className="centerPagination">
                {totalItems > dataPerPage && (
                    <CustomPagination
                        dataPerPage={dataPerPage}
                        totalData={totalItems}
                        paginate={(page) => paginate(page)} // Pass the paginate function here
                        currentPage={currentPage}
                    />
                )}
            </div>
        </div>
    );
};

export default ScriptsListView;
