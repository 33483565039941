import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ManageContactActionsPopover from "../contact/ManageContactActionsPopover";
import ManageAnnouncementActionsPopover from "../contactList/ManageAnnouncementActionPopover";
import { Search } from "../icons";
import ManagePartyGuestPopover from "../patrySchedule/ManageGuestPopover";

interface ISearchFieldControlProps {
    value: string;
    setValue: (value: string) => void;
    disabled?: boolean;
}

export interface ISearchFieldOptions {
    btnOnClick?: () => void;
    hideBtn?: boolean;
    isLoading?: boolean;
    btnType?: string;
    searching?: string;
    searchOnChange?: (newValue: string) => void;
    btnPlaceholder?: boolean;
    inputPlaceholder: string;
    inputAutoFocus?: boolean;
    white?: boolean;
}

const SearchField: React.FC<ISearchFieldControlProps & ISearchFieldOptions> = ({
    value,
    setValue,
    hideBtn,
    isLoading,
    inputPlaceholder,
    btnOnClick,
    btnPlaceholder,
    inputAutoFocus,
    disabled,
    white,
    btnType,
    searchOnChange,
}) => {
    const bgColor = white ? "bg-white" : "bg-secondary";

    const buttonPlaceholder = btnPlaceholder ? (
        <div className="search-btn-container ml-3" />
    ) : (
        <></>
    );

    if (isLoading)
        return (
            <div className="d-flex">
                <div className="shadow-sm skeleton search-btn-container rounded-lg h-50px w-100" />
                {hideBtn ? (
                    <>{buttonPlaceholder}</>
                ) : (
                    <div className="shadow-sm search-btn-container ml-3 skeleton rounded-lg" />
                )}
            </div>
        );
    let btn;
    const handleBtnClick =
        !hideBtn && btnOnClick
            ? btnOnClick
            : () => {
                  return;
              };
    if (btnType === "bulk") {
        btn = hideBtn ? (
            buttonPlaceholder
        ) : (
            <ManageAnnouncementActionsPopover btnOnClick={btnOnClick} />
        );
    } else if (btnType === "bulkContact") {
        btn = hideBtn ? (
            buttonPlaceholder
        ) : (
            <ManageContactActionsPopover btnOnClick={btnOnClick} />
        );
    } else if (btnType === "bulkGuest") {
        btn = hideBtn ? (
            buttonPlaceholder
        ) : (
            <ManagePartyGuestPopover btnOnClick={btnOnClick} />
        );
    } else {
        btn = hideBtn ? (
            buttonPlaceholder
        ) : (
            <Button
                className="ml-3 rounded-lg shadow-sm search-btn-container"
                onClick={() => handleBtnClick()}
            >
                <span className="svg-icon d-flex justify-content-center align-items-center mx-1">
                    <FontAwesomeIcon icon={faPlus} />
                </span>
            </Button>
        );
    }
    // Declare a debounceTimeout variable outside the function
    let debounceTimeout: NodeJS.Timeout;
    const [inputValue, setInputValue] = useState(value);

    const handleSearchOnChange = (event: any) => {
        const newValue = event.target.value;

        // Clear the previous timeout (if any) to prevent rapid execution
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            if (searchOnChange) {
                searchOnChange(newValue); // Pass the newValue as an argument
            }
        }, 500); // Adjust the delay (in milliseconds) as needed
        setInputValue(newValue);
    };

    return (
        <div className="d-flex">
            <div className="input-group shadow-sm">
                <div className="input-group-prepend">
                    <span
                        className={`input-group-text border-0 search-btn ${bgColor}`}
                    >
                        <Search className="text-primary search-icon" />
                    </span>
                </div>
                <input
                    type="text"
                    autoFocus={inputAutoFocus}
                    placeholder={inputPlaceholder}
                    value={inputValue}
                    disabled={disabled}
                    onChange={(e) => {
                        setValue(e.target.value);
                        handleSearchOnChange(e);
                    }}
                    className={`form-control form-control-solid h-auto border-0 py-5 pl-0 pr-6 search-input ${bgColor}`}
                />
            </div>
            {btn}
        </div>
    );
};

export default SearchField;
