import * as React from "react";
import LoginForm from "../../components/forms/LoginForm";
import AuthLayout from "../../layouts/AuthLayout";

const Login: React.FC = () => {
    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    );
};

export default Login;
