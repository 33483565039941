import React, { useCallback, useEffect, useState } from "react";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/partySlice";
import "./partyList.scss";

import useTypedSearchField from "../../hooks/useTypedSearchField";
import { modalActions } from "../../store/reducers/modalsSlice";
import { MsgThread } from "../../types/MsgThread";
import PartiesList from "./PartiesList";

const TodayParties: React.FC = () => {
    const { list, isLoading, page, limit } = useTypedSelector(
        (state) => state.party.parties.today
    );
    const dispatch = useTypedDispatch();
    const { fetchTodayPartiesList } = actions;
    const [partyList, setPartyList] = useState<MsgThread[]>([]);

    const { openCreatePartyModal } = modalActions;

    const showModal = useCallback(() => {
        dispatch(openCreatePartyModal());
    }, []);

    const SearchField = useTypedSearchField<MsgThread>({
        btnOnClick: () => {
            showModal();
        },
        inputPlaceholder: "Search Events...",
        isLoading,
        list,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
        setList: setPartyList,
    });

    useEffect(() => {
        dispatch(
            fetchTodayPartiesList({
                _limit: limit,
                _page: page,
            })
        );
    }, []);

    // useEffect(() => {
    //     setPartyList(
    //         list.filter((item) =>
    //             item.name.toLowerCase().includes(searchValue.toLowerCase())
    //         )
    //     );
    // }, [searchValue, list]);

    return (
        <>
            {SearchField}
            <PartiesList partiesList={partyList} isLoading={isLoading} />
        </>
    );
};

export default TodayParties;
