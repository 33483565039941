import * as React from "react";
import { Script, ScriptType } from "../../types/Script"; 
import { Accordion } from "react-bootstrap";
import useQueryParams from "../../hooks/useQueryParams";
import PerfectScrollbar from "react-perfect-scrollbar"; 
import PrepopulatedMsgScriptsListEntry from './PrepopulatedMsgScriptsListEntry';

interface IProps {
    items: Script[];
    isLoading?: boolean;
    type: ScriptType;
}

// Note: This was based off ScriptsList
const PrepopulatedMsgScriptsList: React.FC<IProps> = ({ items, isLoading }) => {
    const searchParams = useQueryParams();
    const scriptIt = Number(searchParams.get("message"));

    if (isLoading)
        return (
            <div className="mt-2">
                {new Array(6).fill(0).map((item, index) => (
                    <div
                        key={index}
                        className={"mb-1 px-3 py-3 rounded d-flex flex-column"}
                    >
                        <div className="skeleton h-20px w-75" />
                        <div className="h-15px w-50 skeleton mt-3" />
                    </div>
                ))}
            </div>
        );

    if (items.length === 0)
        return <p className="p3 text-center mt-3">No messages yet</p>;

    return (
        <PerfectScrollbar className="h-650px">
            <Accordion
                className="menu-ver-item-base mt-2 accordion"
                defaultActiveKey={scriptIt.toString()}
            >
                {items.map((item) => ( 
                    <PrepopulatedMsgScriptsListEntry
                        script={item}
                        key={item.id}
                    /> 
                ))}
            </Accordion>
        </PerfectScrollbar>
    );
};

export default PrepopulatedMsgScriptsList;
