import React from "react";
import SellerCompanyForm from "../components/forms/SellerCompanyForm";
import MyProfileHeader from "../components/myProfile/MyProfileHeader";
import DefaultLayout from "../layouts/DefaultLayout";

const MyCompany: React.FC = () => {
    return (
        <DefaultLayout>
            <MyProfileHeader />
            <div className="card mb-5 mb-xl-10 mt-4">
                <div className="card-header cursor-pointer">
                    <div className="card-title m-0">
                        <div className="d-flex justify-content-between">
                            <SellerCompanyForm />
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default MyCompany;
