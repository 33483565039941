import { ContactLists } from "./ContactList";
import { ServerError } from "./Error";
import { Guest } from "./Guest";
import { AssetCollection, ScriptAsset } from "./Media";
import { Message } from "./Message";
import {
    AddInAnnouncementField,
    AnnouncementField,
    ContactField,
    ContactListMessageField,
    MessageAnalytics,
    MsgThread,
} from "./MsgThread";
import { MsgTemplate, Script, ScriptType } from "./Script";
import { ScriptRunnerMsgTemplate } from "./scriptRunnerTypes";
import { Seller } from "./Seller";
//import { number, string } from "yup/lib/locale";

export enum FormAction {
    CREATE,
    UPDATE,
    MAP,
    IMPORT,
    IMPORTCSV,
}

export type PaginationState = {
    page: number;
    limit: number;
    hasMore: boolean;
    search: string;
};

export type AuthState = {
    isAuthenticated: boolean;
    currentUser: Seller | null;
    isLoading: boolean;
    isLoadingLogo: boolean;
    errors?: ServerError;
    isRestoreTried: boolean;
};

export type ModalsState = {
    partyModal: {
        isOpen: boolean;
        currentParty?: MsgThread;
        action: FormAction;
    };
    newDirectMessageModal: {
        isOpen: boolean;
    };
    scriptModal: {
        isOpen: boolean;
        type: ScriptType;
        currentScript?: Script;
        action: FormAction;
    };
    msgTemplateModal: {
        isOpen: boolean;
        type: ScriptType;
        currentMessageTemplate?: MsgTemplate;
        currentScriptId: number;
        action: FormAction;
        data?: {
            msg_name: string;
            msg_text: string;
        };
    };
    guestProfileModal: {
        isOpen: boolean;
        currentGuest?: Guest;
    };
    guestContactSaveModal: {
        isOpen: boolean;
        currentGuest?: Guest;
    };
    mediaCollectionModal: {
        isOpen: boolean;
    };
    contactModal: {
        isOpen: boolean;
        currentContact?: any;
        action: FormAction;
    };
    announcementModal: {
        isOpen: boolean;
        currentAnnouncement?: AnnouncementField;
        action: FormAction;
        contactId?: any;
        announcement_type?: boolean;
    };
    // guestContactModal: {
    //     isOpen: boolean;
    //     currentContact?:ContactLists;
    // };
    contactProfileModal: {
        isOpen: boolean;
        currentContact?: ContactLists;
    };
    addInAnnouncementModal: {
        isOpen: boolean;
        currentAddInAnnouncement?: AddInAnnouncementField;
        action: FormAction;
    };
    SellerProfileInfo: {
        isOpen: boolean;
        currentParty?: Seller;
    };
    legalModal: {
        isOpen: boolean;
        slug: string;
    };
    emailModal: {
        isOpen: boolean;
        email: string | undefined;
        isEmail: boolean | undefined;
    };
    emailScriptInviteModal: {
        isOpen: boolean;
        scriptId: number | undefined;
        type: ScriptType | undefined;
    };
};

export type PartyState = {
    parties: {
        today: {
            list: MsgThread[];
            isLoading: boolean;
            error?: string;
        } & PaginationState;
        completed: {
            list: MsgThread[];
            isLoading: boolean;
            error?: string;
        } & PaginationState;
        future: {
            list: MsgThread[];
            isLoading: boolean;
            error?: string;
        } & PaginationState;
        all: {
            list: MsgThread[];
            isLoading: boolean;
            error?: string;
        };
        selected: {
            party?: MsgThread;
            isLoading: boolean;
            isRunning: boolean;
            error?: string;

            guests: {
                list: Guest[];
                listPaginate: Guest[];
                isLoading: boolean;
                error?: string;
                guestId?: any;
                partyId?: number;
            } & PaginationState;
            messages: {
                list: Message[];
                isLoading: boolean;
                error?: string;
            };
            requiresRefresh: boolean;
        };
        current: {
            party?: MsgThread;
            isLoading: boolean;
            error?: string;
            guests: {
                list: Guest[];
                isLoading: boolean;
                error?: string;
            };
            messages: {
                list: Message[];
                isLoading: boolean;
                error?: string;
            };
            requiresRefresh: boolean;
        };
        addGuestFromContact: {
            isLoading: boolean;
            error?: ServerError;
        };
    };
    createNewPartyForm: {
        isLoading: boolean;
        error?: ServerError;
    };
    selectedPartyGuestList: {
        list: Guest[];
        isLoading: boolean;
        error?: string;
    };
    messageAnalytics: {
        list: MessageAnalytics[];
        isLoading: boolean;
        error?: string;
    };
};

export type AutomationState = {
    createNewScriptForm: {
        isLoading: boolean;
        error?: string;
    };
    editScriptForm: {
        isLoading: boolean;
        error?: string;
    };
    scripts: {
        isLoading: boolean;
        error?: string;
        list: { items: Script[] };
        listPaginate: Script[];
    } & PaginationState;
    messageTemplates: {
        data?: any;
        isLoading: boolean;
        error?: string;
        list: MsgTemplate[];
        currentScriptId?: number;
        currentMessage?: MsgTemplate;
        favorites?: MsgTemplate[];
        topUsed?: MsgTemplate[];
        searchResults?: MsgTemplate[];
    };
    messageTemplatesDragnDrop: {
        isLoading: boolean;
        error?: string;
        list: MsgTemplate[];
        currentScriptId?: number;
        currentMessage?: MsgTemplate;
        favorites?: MsgTemplate[];
        topUsed?: MsgTemplate[];
        searchResults?: MsgTemplate[];
    };
};

export type scriptRunnerState = {
    createNewScriptForm: {
        isLoading: boolean;
        error?: string;
    };
    editScriptForm: {
        isLoading: boolean;
        error?: string;
    };
    scripts: {
        isLoading: boolean;
        error?: string;
        list: Script[];
    };
    messageTemplates: {
        isLoading: boolean;
        error?: string;
        list: ScriptRunnerMsgTemplate[];
        currentScriptId?: number;
        currentMessage?: MsgTemplate;
        favorites?: MsgTemplate[];
        topUsed?: MsgTemplate[];
        searchResults?: MsgTemplate[];
    };
    intervalId: NodeJS.Timeout | null;
};

export type NotificationsState = {
    snackbar: {
        isOpen: boolean;
        msgText: string;
    };
};

export type MediaState = {
    collections: {
        selectedCollection: number;
        list: AssetCollection[];
        isLoading: boolean;
        error?: string;
    };
    assets: {
        list: ScriptAsset["items"][];
        isLoading: boolean;
        error?: string;
    };
};

export type FileWithPreview = File & {
    preview: string;
};

export type UploadedFileResponse = {
    url: string;
    newName: string;
    size: number;
    type: string;
};

export type myMemberShipPlan = {
    id: number;
    sellerId: number;
    planTypeId: number;
    startDate: string;
    endDate: string;
    paymentAuth: string;
    renewPlanTypeId: number;
    isMembershipActive: boolean;
    name: string;
    price: number;
    recurrence: string;
    workspaces: number;
    personalGateway: number;
    partiesPerMonth: number;
    guestsPerParty: number;
    announcements: number;
    defaultRenewWithId: number;
};
export type timeZonne = {
    id: string;
    name: string;
};

export const timeZonneList: timeZonne[] = [
    {
        id: "0",
        name: "Select Default Timezone",
    },
    {
        id: "1",
        name: "America/New_York",
    },
    {
        id: "2",
        name: "America/Chicago",
    },
    {
        id: "3",
        name: "America/Denver",
    },
    {
        id: "4",
        name: "America/Phoenix",
    },
    {
        id: "5",
        name: "America/Los_Angeles",
    },
    {
        id: "6",
        name: "America/Anchorage",
    },
    {
        id: "7",
        name: "Pacific/Honolulu",
    },
];

export type SubscriptionState = {
    plans: {
        list: [];
        isLoading: boolean;
        error?: string;
    };
    MembershipCancel: {
        successMsg?: string;
        isLoading: boolean;
        error?: string;
    };
};

export type staticPageState = {
    staticPage: {
        data: any;
        isLoading: boolean;
        error?: string;
    };
};

export type topUsedState = {
    topUsed: {
        isLoading: boolean;
        error?: string;
        list: any;
        favorites?: any;
        topUsed?: any;
        searchResults?: any;
        listPaginate: any;
        page: number;
        search: string;
        limit: number;
    };
    singleTopUsed: {
        data: any;
        isLoading?: boolean;
        error?: string;
    };
};

export type ContactState = {
    createContactForm: {
        isLoading: boolean;
        error?: ServerError;
    };
    contactList: {
        list: ContactField[];
        activeList: ContactField[];
        isLoading: boolean;
        error?: string;
    } & PaginationState;
    selectedContact: ContactField | null | any;

    createAddInContactForm: {
        isLoading: boolean;
        error?: ServerError;
    };
    contactListMessage: {
        list: ContactListMessageField[];
        isLoading: boolean;
        error?: string;
    };
    selected: {
        type: string | null;
        entity: null | ContactField | AnnouncementField;
    };
};

export type AnnouncementState = {
    createAnnouncementForm: {
        isLoading: boolean;
        error?: ServerError;
    };
    announcementList: {
        list: AnnouncementField[];
        listMore: AnnouncementField[];
        isLoading: boolean;
        error?: string;
        limitMore?: number;
    } & PaginationState;
    announcementContactList: {
        list: ContactField[];
        isLoading: boolean;
        error?: string;
        selectedAnnouncement: number;
    };
    selectedAnnouncement: AnnouncementField | null;
};

export type IPartySelectorTab = {
    selectedTab: number | string;
    tabName?: number | string;
};
