/* eslint-disable react/react-in-jsx-scope */
import clsx from "clsx";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import * as Yup from "yup";
import AuthLayout from "../../layouts/AuthLayout";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";

const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
};

const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, "Minimum 8 characters")
        .max(50, "Maximum 50 characters")
        .required("New Password is required"),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm New Password is required"),
});

export function ResetPassword() {
    const dispatch = useTypedDispatch();
    const loading = useTypedSelector((state) => state.auth.isLoading);
    const hasErrors = useTypedSelector((state) => state.auth.errors);
    const [redirect, setRedirect] = useState(false);
    const { resetPassword_ } = authActions;

    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token") || "";

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            try {
                await dispatch(
                    resetPassword_({
                        newPassword: values.newPassword,
                        token
                    })
                ).unwrap().then(() => {
                    dispatch(notificationActions.showSnackbarWithAutoHide({
                    msgText: "Your password has been changes successfully."
                }));
                setRedirect(true);
            });
            } catch (err) {
                setStatus("Password reset has been failed.");
            } finally {
                setSubmitting(false);
            }
        },
    });
    if(redirect) {
        return <Redirect to={"/auth/login"}/>;
    }
    return (
        <AuthLayout>
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                    noValidate
                    id="kt_login_password_reset_form"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="text-center mb-10">
                        <h1 className="text-dark fw-bolder mb-3">
                            Reset Password
                        </h1>
                    </div>

                    {hasErrors ? (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                                It looks like your Link has been expired or Invalid.
                            </div>
                        </div>
                    ): ""}

                    <div className="fv-row mb-8">
                        <label className="form-label fw-bolder text-gray-900 fs-6">
                            New Password<span className="text-danger">*</span>
                        </label>
                        <input
                            type="password"
                            placeholder=""
                            autoComplete="off"
                            {...formik.getFieldProps("newPassword")}
                            className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                    formik.touched.newPassword &&
                                    formik.errors.newPassword,
                            })}
                        />
                        {formik.touched.newPassword &&
                            formik.errors.newPassword && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">
                                            {formik.errors.newPassword}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="fv-row mb-8">
                        <label className="form-label fw-bolder text-gray-900 fs-6">
                            Confirm New Password<span className="text-danger"></span>
                        </label>
                        <input
                            type="password"
                            placeholder=""
                            autoComplete="off"
                            {...formik.getFieldProps("confirmNewPassword")}
                            className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                    formik.touched.confirmNewPassword &&
                                    formik.errors.confirmNewPassword,
                            })}
                        />
                        {formik.touched.confirmNewPassword &&
                            formik.errors.confirmNewPassword && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">
                                            {formik.errors.confirmNewPassword}
                                        </span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                        <button
                            type="submit"
                            id="kt_password_reset_submit"
                            className="btn btn-primary me-4"
                            disabled={loading}
                        >
                            <span className="indicator-label">
                                Reset Password
                            </span>
                            {loading && (
                                <span className="indicator-progress">
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                        <Link to="/auth/login">
                            <button
                                type="button"
                                id="kt_login_password_reset_form_cancel_button"
                                className="btn btn-light"
                                disabled={
                                    formik.isSubmitting || !formik.isValid
                                }
                            >
                                Cancel
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </AuthLayout>
    );
}
