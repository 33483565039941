import React from "react";
import NewPartyForm from "../forms/NewPartyForm";
import LayoutCard from "../../layouts/LayoutCard";

const NewPartyFormCard: React.FC = () => {
    return (
        <LayoutCard title="Create New Event">
            <NewPartyForm />
        </LayoutCard>
    );
};

export default NewPartyFormCard;
