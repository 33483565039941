import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BillingCard } from "./_model";
import BillingPaymentCardForm from "./BillingPaymentCardForm";

type Props = {
    billingModel: BillingCard;
    handleModelCloseCallback: CallableFunction;
};

const BillingPaymentCardModal: React.FC<Props> = ({
    billingModel,
    handleModelCloseCallback,
}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        handleModelCloseCallback();
        setShow(false);
    };
    const handleShow = () => setShow(true);
    return (
        <>
            {billingModel.id ? (
                <button
                    className="btn btn-sm btn-light btn-active-light-primary"
                    onClick={handleShow}
                >
                    Edit
                </button>
            ) : (
                <a
                    href={undefined}
                    className="btn btn-primary px-6 align-self-center text-nowrap ml-auto"
                    onClick={handleShow}
                >
                    New Card
                </a>
            )}
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BillingPaymentCardForm
                        checkbox={true}
                        billingModel={billingModel}
                        handleClose={handleClose}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BillingPaymentCardModal;
