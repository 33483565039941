import React, { useEffect, useState } from "react";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { actions } from "../../store/reducers/contactSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { actions as partyActions } from "../../store/reducers/partySlice";

import { Button, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { ContactField } from "../../types/MsgThread";
import FormErrors from "./FormErrors";

// const validationSchema = Yup.object().shape({
//     name: Yup.string()
//         .required("announcement name is required")
//         .max(150, "announcement name can't be that long"),
// });
interface Contact {
    label: string;
    value: number;
    // other properties...
}
const AddGuestFromContactForm: React.FC = () => {
    // const [initialValues, setInitialValues] = useState<NewAnnouncementFromValues>({
    //     name: "",
    // });
    const { closePartyModal } = modalActions;
    const { fetchContacts, fetchActiveContacts } = actions;
    const { party } = useTypedSelector((state) => state.party.parties.selected);
    const {
        addGuestFromContact,
        fetchSelectedPartyGuests,
        fetchSelectedPartyGuestsPaginate,
    } = partyActions;

    const dispatch = useTypedDispatch();

    const formErrors = useTypedSelector(
        (state) => state.announcement.createAnnouncementForm.error
    );

    const { activeList: activeContactList } = useTypedSelector(
        (state) => state.contact.contactList
    );
    const { page, limit } = useTypedSelector(
        (state) => state.contact.contactList
    );
    const [contactList, setContactList] = useState<ContactField[]>([]);
    useEffect(() => {
        if ((activeContactList as any)?.items) {
            setContactList((activeContactList as any)?.items);
        }
    }, [activeContactList]);

    useEffect(() => {
        dispatch(fetchContacts({ _page: page, _limit: limit }));
        dispatch(fetchActiveContacts({ _page: 1, _limit: 5000, search: "" }));
    }, []);

    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

    const contactListOptions = contactList.map((contact) => ({
        label: contact.name,
        value: contact.id,
    }));
    const {
        page: guestPage,
        limit: guestLimit,
        search: guestSearch,
    } = useTypedSelector((state) => state?.party?.parties?.selected?.guests);
    const handleCustomSubmit = () => {
        let contactIds = [];
        contactIds = selectedContacts.map((contact) => contact.value);
        const payload: {
            msgThreadId: number | undefined;
            contactId: number[];
            isHost: boolean;
        } = {
            msgThreadId: party?.id, // or some number value if available
            contactId: contactIds, // Replace with the actual value
            isHost: false, // Replace with the actual value
        };
        return dispatch(addGuestFromContact(payload))
            .unwrap()
            .then((data: any) => {
                if (party?.id) {
                    dispatch(fetchSelectedPartyGuests({ partyId: party?.id })); // Ensure party.id is defined
                    dispatch(
                        fetchSelectedPartyGuestsPaginate({
                            partyId: party?.id,
                            _page: guestPage,
                            _limit: guestLimit,
                            search: guestSearch,
                        })
                    );
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: data?.msg,
                        })
                    );
                }
                setSelectedContacts([]);
                return data;
            })
            .then(() => {
                dispatch(closePartyModal());
            });
    };
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                handleCustomSubmit();
            }}
            className="d-flex flex-column justify-content-between h-100"
        >
            <div>
                <Form.Row>
                    <Form.Group className="col-md">
                        <div id="multisection" className="control-section">
                            <div id="multidefault">
                                <div className="control-styles">
                                    <p className="text-black-50 mb-1">
                                        Select Contacts
                                    </p>
                                    <div>
                                        <MultiSelect
                                            options={contactListOptions}
                                            value={selectedContacts}
                                            onChange={setSelectedContacts}
                                            labelledBy="Select Contact"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form.Row>
            </div>

            <FormErrors errors={formErrors} />

            <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                <Button
                    variant="primary"
                    type="submit"
                    disabled={selectedContacts.length == 0}
                    className="mx-4"
                >
                    Add Guests
                </Button>
            </Form.Row>
        </Form>
    );
};

export default AddGuestFromContactForm;
