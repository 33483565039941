import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useCallback } from "react";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { MsgTemplate, ScriptType } from "../../types/Script";


interface IProps {
    msgTemplate: MsgTemplate;
    scriptId: number;
    isDragging?: boolean;
}

const ScriptEditorItem: React.FC<IProps> = ({
    msgTemplate,
    scriptId,
    isDragging,
}) => {
    const dispatch = useTypedDispatch();
    const { openEditMsgTemplateModal } = modalActions;

    const time = msgTemplate?.quietWaitSeconds;
    const title = msgTemplate?.name;
    const timeLimited = time && Math.min(3599, time);
    const timeStr =
        timeLimited &&
        `${Math.trunc(timeLimited / 60)}:${timeLimited % 60 || "00"}`;

    const onDoubleClick = useCallback(() => {
        dispatch(
            openEditMsgTemplateModal({
                msgTemplate,
                type: ScriptType.PARTY,
                scriptId,
            })
        );
    }, [msgTemplate, scriptId]);

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            draggable
            // title="Double click to edit"
            // onDoubleClick={onDoubleClick}
        >
            <div className="d-flex px-6">
                <div
                    className={`${
                        isDragging ? "bg-gray-900" : "bg-gray-600 ml-14"
                    } px-3 py-2 rounded d-flex flex-column align-items-center`}
                >
                    <p className="p4 text-center mb-0 text-white user-select-none">
                        {msgTemplate.link === null ? (
                            title
                        ) : (
                            <a
                                href={msgTemplate.link || undefined}
                                className="underline text-white"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <u>{title}</u>
                            </a>
                        )}
                    </p>
                </div>

                {!isDragging && ( 
                        <button
                            onClick={onDoubleClick}
                           className="btn btn-link cursor-pointer d-flex justify-content-center align-items-center"
                        >
                            <FontAwesomeIcon
                                icon={faPen}
                                className="text-gray-[900]"
                            />
                        </button> 
                )}
            </div>

            <div className="bg-gray-500 w-1px h-15px" />
            {time ? (
                <div
                    className={`${
                        isDragging ? "bg-gray-700" : "bg-gray-500"
                    } rounded-lg px-3 text-black w-60px`}
                >
                    <p className="p5 font-weight-bolder text-white text-center mb-0">
                        {timeStr}
                    </p>
                </div>
            ) : (
                <div className="bg-gray-500 rounded-circle h-25px w-25px" />
            )}

            <div className="bg-gray-500 w-1px h-15px" />
        </div>
    );
};

export default ScriptEditorItem;
