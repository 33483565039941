import * as React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faEllipsisH,
    faPlus,
    // faEnvelope,
    faTrashAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../store/reducers/announcementSlice";
import { confirmAlert } from "react-confirm-alert";
import { fetchAnnouncements } from "../../store/asyncActions/announcement";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
interface IProps {
    announcement: any;
}

const ManageAnnouncementPopover: React.FC<IProps> = ({ announcement }: any) => {
    const dispatch = useTypedDispatch();
    const { page, limit, search } = useTypedSelector(
        (state) => state.announcement.announcementList
    );

    const items = [
        {
            title: "Add Contacts",
            action: () =>
                dispatch(
                    modalActions.openCreateAnncouncementContactModal(
                        announcement
                    )
                ),
            icon: (
                <FontAwesomeIcon
                    icon={faPlus}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Edit Announcement",
            action: () =>
                dispatch(
                    modalActions.openCreateAnncouncementModal(announcement)
                ),
            icon: (
                <FontAwesomeIcon
                    icon={faUser}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Delete Announcement",
            action: () =>
                confirmAlert({
                    title: "Confirm to delete",
                    message:
                        "Are you sure you want to delete this item and all objects associated?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                dispatch(
                                    actions.deleteAnnouncement(announcement.id)
                                )
                                    .unwrap()
                                    .then(() => {
                                        dispatch(
                                            fetchAnnouncements({
                                                _page: page,
                                                _limit: limit,
                                                search,
                                            })
                                        );
                                        dispatch(
                                            showSnackbarWithAutoHide({
                                                msgText: announcement
                                                    ? "Announcement Deleted!"
                                                    : "Announcement Added!",
                                            })
                                        );
                                        // history.push(
                                        //     "/manage_parties/dashboard"
                                        // );
                                    })
                                    .catch(() => {
                                        // dispatch(
                                        //     showSnackbarWithAutoHide({
                                        //         msgText: err,
                                        //     })
                                        // );
                                    });
                            },
                        },
                        {
                            label: "No",
                            onClick: () => console.info("No."),
                        },
                    ],
                }),
            icon: (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger w-15px h-15px mr-2"
                />
            ),
        },
    ];
    const renderPopover = (props: any) => (
        <Popover id="manage-script-popover" {...props}>
            <Popover.Content className="p-3">
                <ul className="p-0 m-0">
                    {items.map((item) => (
                        <li
                            key={item.title}
                            onClick={() => item.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );
    const handleButtonClick = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
    };

    return (
        <OverlayTrigger
            rootClose
            trigger="focus"
            overlay={renderPopover}
            delay={{ show: 250, hide: 400 }}
            placement="right"
        >
            <Button
                variant="link"
                className="p-0 text-dark"
                onClick={handleButtonClick}
            >
                <FontAwesomeIcon
                    icon={faEllipsisH}
                    className="w-25px h-20px cursor-pointer"
                />
            </Button>
        </OverlayTrigger>

        // <OverlayTrigger placement="right" overlay={renderPopover}>
        //     {({ ref, ...triggerHandler }) => (
        //         <Button
        //
        //             variant="link"
        //             className="p-0 text-dark"
        //             onClick={handleButtonClick}
        //         >
        //             <FontAwesomeIcon
        //                 icon={faEllipsisH}
        //                 className="w-25px h-20px cursor-pointer"
        //             />
        //         </Button>
        //     )}
        // </OverlayTrigger>
    );
};

export default ManageAnnouncementPopover;
