// src/Modal.tsx

import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Loading from "../../../components/common/Loading";
import { useTypedDispatch } from "../../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../../store/hooks/useTypedSelector";
import { modalActions } from "../../../store/reducers/modalsSlice";

type Props = {
    slug?: string;
    content?: HTMLElement;
    updateAt?: string;
    isLoading?: boolean;
};

const CustomModal: React.FC<Props> = ({
    slug,
    content,
    updateAt: updatedAt, 
}) => {
    const dispatch = useTypedDispatch();
    const { closeLegalModal } = modalActions;
    const { isOpen: show } = useTypedSelector(
        (state) => state.modals.legalModal
    );

    const handleClose = useCallback(() => {
        dispatch(closeLegalModal());
    }, []);

    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton className="text-muted small border-0">
                <Modal.Title id="d-flex justify-content-end custom-modal-header">
                    {slug ? (
                        <p className="h1">
                            Sparty{" "}
                            {slug &&
                                slug
                                    .split("-")
                                    .map(
                                        (word: any) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                    )
                                    .join(" ")}
                        </p>
                    ) : (
                        "Sparty Policies"
                    )}
                    <p className="h2">
                        Last Updated: &#160;
                        {updatedAt
                            ? new Date(updatedAt).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                              })
                            : "Jan 1, 1970"}
                    </p>
                </Modal.Title>
            </Modal.Header>
            <PerfectScrollbar>
                <Modal.Body style={{ maxHeight: "70vh" }}>
                    {content ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: content,
                            }}
                        />
                    ) : (
                        <Loading />
                    )}
                </Modal.Body>
            </PerfectScrollbar>
            <Modal.Footer className="text-muted small border-0"></Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
