import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./components/routes/Routes";

import PerfectScrollbar from "react-perfect-scrollbar";
import Loading from "./components/common/Loading";
import AppWrapper from "./layouts/AppWrapper";
import { useTypedDispatch } from "./store/hooks/useTypedDispatch";
import { useTypedSelector } from "./store/hooks/useTypedSelector";
import { authActions } from "./store/reducers/authSlice";

const App: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { isRestoreTried } = useTypedSelector((state) => state.auth);
    const { restoreUser } = authActions;

    useEffect(() => {
        dispatch(restoreUser());
    }, []);
    return (
        <PerfectScrollbar>
            <Router>
                <AppWrapper>
                    {isRestoreTried ? <Routes /> : <Loading />}
                </AppWrapper>
            </Router>
        </PerfectScrollbar>
    );
};

export default App;
