import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnnouncementState } from "../../types/Common";
import {
    createAnnouncement,
    deleteAnnouncement,
    fetchAnnouncements,
    fetchAnnouncementsContacts,
    fetchAnnouncementsLoadMore,
    UpdateAnnouncement,
    deleteContact,
} from "../asyncActions/announcement";

const initialState: AnnouncementState = {
    announcementList: {
        list: [],
        listMore: [],
        isLoading: false,
        error: undefined,
        page: 1,
        hasMore: true,
        search: "",
        limit: 100,
        limitMore: 100,
    },
    createAnnouncementForm: {
        error: undefined,
        isLoading: false,
    },
    announcementContactList: {
        list: [],
        isLoading: false,
        error: undefined,
        selectedAnnouncement: 0,
    },
    selectedAnnouncement: null,
};

const announcementSlice = createSlice({
    name: "announcement",
    initialState,
    reducers: {
        selectOne: (state, action) => {
            state.selectedAnnouncement = action.payload;
            return state;
        },
        resetState: () => initialState, // Reset the state to its initial values
    },
    extraReducers: {
        [fetchAnnouncements.pending.toString()]: (state) => {
            state.announcementList.isLoading = true;
            state.announcementList.list = [];
            state.announcementList.error = undefined;
        },
        [fetchAnnouncements.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.announcementList.isLoading = false;
            state.announcementList.error = action.payload;
        },
        [fetchAnnouncements.fulfilled.toString()]: (state, action) => {
            state.announcementList.isLoading = false;
            const { _page, _limit, search } = action?.meta.arg;
            state.announcementList.page = _page;
            state.announcementList.limit = _limit;
            state.announcementList.search = search;
            state.announcementList.list = action.payload;
        },
        [fetchAnnouncementsLoadMore.pending.toString()]: (state) => {
            state.announcementList.isLoading = true;
            state.announcementList.listMore = [];
            state.announcementList.error = undefined;
        },
        [fetchAnnouncementsLoadMore.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.announcementList.isLoading = false;
            state.announcementList.error = action.payload;
        },
        [fetchAnnouncementsLoadMore.fulfilled.toString()]: (state, action) => {
            state.announcementList.isLoading = false;
            const { _limit } = action?.meta.arg;
            state.announcementList.limitMore = _limit;
            state.announcementList.listMore = action.payload;
        },
        // [selectOne.pending.toString()]: (state) => {
        //     state.selectedAnnouncement = null;
        // },
        // [selectOne.rejected.toString()]: (state, action: PayloadAction<string>) => {
        //     state.selectedAnnouncement = null;
        // },
        // [selectOne.fulfilled.toString()]: ( state, action: PayloadAction<[]>) => {
        //     state.selectedAnnouncement = action.payload;
        // },
        // Create new announcement
        [createAnnouncement.pending.toString()]: (state) => {
            state.createAnnouncementForm.isLoading = true;
            state.createAnnouncementForm.error = undefined;
        },
        [createAnnouncement.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createAnnouncementForm.isLoading = false;
            state.createAnnouncementForm.error = action.payload;
        },
        [createAnnouncement.fulfilled.toString()]: (state) => {
            state.createAnnouncementForm.isLoading = false;
            state.createAnnouncementForm.error = undefined;
            state.announcementList.page = 1;
            state.announcementList.limit = 100;
        },
        [fetchAnnouncementsContacts.pending.toString()]: (state) => {
            state.announcementContactList.isLoading = true;
            state.announcementContactList.list = [];
            state.announcementContactList.error = undefined;
        },
        [fetchAnnouncementsContacts.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.announcementContactList.isLoading = false;
            state.announcementContactList.error = action.payload;
        },
        [fetchAnnouncementsContacts.fulfilled.toString()]: (state, action) => {
            // const announcementId = action.meta.arg; // 'meta.arg' contains the value passed to the action creator
            state.announcementContactList.isLoading = false;
            state.announcementContactList.list = action.payload;
            // state.announcementContactList.selectedAnnouncement = announcementId;
        },
        //delete contact inside announcement
        [deleteContact.pending.toString()]: (state) => {
            state.announcementContactList.error = undefined;
        },
        [deleteContact.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.announcementContactList.error = action.payload;
        },
        [deleteContact.fulfilled.toString()]: (
            state,
            action: PayloadAction<any>
        ) => {
            state.announcementContactList.list = action.payload;
        },
    },
});

export const actions = {
    ...announcementSlice.actions,
    createAnnouncement,
    fetchAnnouncements,
    fetchAnnouncementsLoadMore,
    // selectOne,
    deleteAnnouncement,
    UpdateAnnouncement,
    fetchAnnouncementsContacts,
    deleteContact,
};
export const { resetState } = announcementSlice.actions;
const announcementReducer = announcementSlice.reducer;

export default announcementReducer;
