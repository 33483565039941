import * as React from "react";

function SvgUploadFiles(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 37 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 17.069c0 2.397.828 4.457 2.485 6.18C4.142 24.97 6.164 25.886 8.551 26c.201 0 .302-.101.302-.304V23.3c0-.225-.1-.338-.302-.338-1.528-.079-2.828-.69-3.899-1.832-1.07-1.142-1.606-2.496-1.606-4.06 0-1.486.502-2.78 1.506-3.883C5.556 12.083 6.805 11.44 8.3 11.26h.937c.235 0 .352-.107.352-.32l.1-1.03c.19-1.948 1.01-3.58 2.46-4.897 1.45-1.317 3.152-1.975 5.104-1.975 1.93 0 3.623.658 5.08 1.975 1.455 1.317 2.284 2.95 2.484 4.896l.134 1.03c0 .214.106.321.318.321h2.878c1.585 0 2.957.577 4.117 1.73 1.16 1.154 1.74 2.513 1.74 4.078 0 1.553-.538 2.904-1.614 4.052-1.077 1.148-2.374 1.761-3.891 1.84-.234 0-.352.113-.352.338v2.397c0 .203.118.304.352.304a8.446 8.446 0 004.284-1.283 8.773 8.773 0 003.087-3.25A8.913 8.913 0 0037 17.069a8.641 8.641 0 00-.703-3.444 8.852 8.852 0 00-1.89-2.82 8.945 8.945 0 00-2.829-1.882 8.643 8.643 0 00-3.43-.7h-.57c-.59-2.41-1.846-4.382-3.765-5.918C21.894.768 19.708 0 17.253 0c-2.521 0-4.758.79-6.71 2.372C8.59 3.953 7.34 5.971 6.794 8.425c-1.974.461-3.6 1.5-4.878 3.115C.64 13.155 0 14.998 0 17.069zM12.4 18.2c0 .45.145.833.435 1.148.268.27.63.405 1.088.405.435 0 .803-.135 1.105-.405l1.974-2.06v7.159c0 .428.14.793.419 1.097.279.304.63.456 1.054.456.413 0 .77-.152 1.07-.456.302-.304.453-.67.453-1.097v-7.091l2.008 1.992c.714.552 1.439.552 2.175 0 .29-.27.435-.642.435-1.114 0-.428-.145-.8-.435-1.115l-4.618-4.626a1.449 1.449 0 00-1.071-.438c-.436 0-.787.146-1.055.438l-4.602 4.626c-.29.293-.435.653-.435 1.081z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgUploadFiles;
