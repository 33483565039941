import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { scriptRunnerState } from "../../types/Common";
import { ScriptRunnerMsgTemplate } from "../../types/scriptRunnerTypes";
import { fetchMsgTemplates, sendAutomationMessage, sendWelcomeMessage } from "../asyncActions/scriptRunner.actions";

const initialState: scriptRunnerState = {
    scripts: {
        list: [],
        isLoading: false,
        error: undefined,
    },
    createNewScriptForm: {
        isLoading: false,
        error: undefined,
    },
    editScriptForm: {
        isLoading: false,
        error: undefined,
    },
    messageTemplates: {
        list: [],
        isLoading: false,
        error: undefined,
        currentScriptId: undefined,
        currentMessage: undefined,
        favorites: [],
        topUsed: [],
        searchResults: [],
    },
    intervalId: null,
};

const scriptRunnerSlice = createSlice({
    name: "scriptRunner",
    initialState,
    reducers: {
        startFirst: (
            state,
        ) => {
            const item = state.messageTemplates.list[0];
            if (item) {
                if(item.isRunning) {
                    try {
                        item.intervalId &&
                            clearInterval(
                                item.intervalId as unknown as NodeJS.Timeout
                            );
                        // eslint-disable-next-line no-empty
                    } catch (err) {}
                }
                item.startTimer = true;
            }
            return state;
        },
        playPauseToggle: (
            state,
            action: PayloadAction<ScriptRunnerMsgTemplate>
        ) => {
            const item = state.messageTemplates.list.find(
                (x) => x.id === action.payload.id
            );
            if (item) {
                if(item.isRunning) {
                    try {
                        item.intervalId &&
                            clearInterval(
                                item.intervalId as unknown as NodeJS.Timeout
                            );
                        // eslint-disable-next-line no-empty
                    } catch (err) {}
                }
                item.startTimer = false;
                item.isRunning = !item.isRunning;
            }
            return state;
        },
        clearIntervalAction: (
            state,
            action: PayloadAction<ScriptRunnerMsgTemplate>
        ) => {
            const item = state.messageTemplates.list.find(
                (x) => x.id === action.payload.id
            );
            if (item) {
                try {
                    item.intervalId &&
                        clearInterval(
                            item.intervalId as unknown as NodeJS.Timeout
                        );
                    // eslint-disable-next-line no-empty
                } catch (err) {}
            }
            return state;
        },
        resetTimer: (state, action: PayloadAction<ScriptRunnerMsgTemplate>) => {
            const item = state.messageTemplates.list.find(
                (x) => x.id === action.payload.id
            );
            if (item) {
                item.quietSeconds = item.quietWaitSeconds;
                item.quietProgress = 100;
            }
            return state;
        },
        resetActiveMessageTimer: (state) => {
            const item = state.messageTemplates.list.find(
                (x) => x.isRunning === true
            );
            if (item) {
                item.quietSeconds = item.quietWaitSeconds;
                item.quietProgress = 100;
            }
            return state;
        },
        decrement: (state, action: PayloadAction<ScriptRunnerMsgTemplate>) => {
            const item = state.messageTemplates.list.find(
                (x) => x.id === action.payload.id
            );
            if (
                item &&
                item.maxWaitSeconds !== 0 &&
                item.quietWaitSeconds !== 0
            ) {
                item.quietSeconds = item.quietSeconds - 1;
                item.quietProgress =
                    (100 / item.quietWaitSeconds) * item.quietSeconds;
                item.maxSeconds = item.maxSeconds - 1;
                item.maxProgress =
                    (100 / item.maxWaitSeconds) * item.maxSeconds;
            }
            return state;
        },
        setIntervalId: (
            state,
            action: PayloadAction<{
                id: WritableDraft<NodeJS.Timeout> | null;
                payload: ScriptRunnerMsgTemplate;
            }>
        ) => {
            const item = state.messageTemplates.list.find(
                (x) => x.id === action.payload.payload.id
            );
            // state.intervalId = action.payload.id;
            if (item) {
                item.intervalId = action.payload.id;
            }
            return state;
        },
        moveToNext: (state, action: PayloadAction<ScriptRunnerMsgTemplate>) => {
            state.messageTemplates.list.map((item) => {
                item.isRunning = false;
                item.startTimer = false;
                try {
                    item.intervalId &&
                        clearInterval(
                            item.intervalId as unknown as NodeJS.Timeout
                        );
                    // eslint-disable-next-line no-empty
                } catch (err) {}
                item.intervalId = null;
            });
            const item = state.messageTemplates.list.find(
                (x) => x.scriptIndex === action.payload.scriptIndex + 1
            );
            if (item) {
                item.startTimer = true;
            }
        },       
        startThis: (state, action: PayloadAction<ScriptRunnerMsgTemplate>) => {
            state.messageTemplates.list.map((item) => {
                item.isRunning = false;
                item.startTimer = false;
                try {
                    item.intervalId &&
                        clearInterval(
                            item.intervalId as unknown as NodeJS.Timeout
                        );
                    // eslint-disable-next-line no-empty
                } catch (err) {}
                item.intervalId = null;
            });
            const item = state.messageTemplates.list.find(
                (x) => x.scriptIndex === action.payload.scriptIndex
            );
            if (item) {
                item.startTimer = true;
            }
        },
        pauseAll: (state) => {
            state.messageTemplates.list.map((item) => {
                item.isRunning = false;
                item.startTimer = false;
                try {
                    item.intervalId &&
                        clearInterval(
                            item.intervalId as unknown as NodeJS.Timeout
                        );
                    // eslint-disable-next-line no-empty
                } catch (err) {}
                item.intervalId = null;
            });
        },
    },
    extraReducers: {
        // Message templates fetching
        [fetchMsgTemplates.pending.toString()]: (state) => {
            state.messageTemplates.isLoading = true;
            state.messageTemplates.error = undefined;
        },
        [fetchMsgTemplates.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplates.isLoading = false;
            state.messageTemplates.error = action.payload;
        },
        [fetchMsgTemplates.fulfilled.toString()]: (
            state,
            action: PayloadAction<ScriptRunnerMsgTemplate[]>
        ) => {
            state.messageTemplates.isLoading = false;
            state.messageTemplates.list = action.payload;
        },
    },
});

export const scriptRunnerActions = {
    ...scriptRunnerSlice.actions,
    fetchMsgTemplates,
    sendAutomationMessage,
    sendWelcomeMessage
};

const scriptRunnerReducer = scriptRunnerSlice.reducer;

export default scriptRunnerReducer;
