import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

const LegalInformationLayout: React.FC = ({ children }) => {


    const history = useHistory();

    const handleBackClick = () => { 
        history.goBack();
    };

   const backButtonStyle = {
       cssFloat: "right",
       color: "#000000",
       padding: "5px 10px",
       fontSize: "15px", 
       transition: "color 0.3s", 
   };


    return (
        <>
            <div className="position-relative py-20 d-flex justify-content-center bg-light">
                {/*<div className="bg-primary w-500px h-100 position-absolute top-0 left-0 zindex-0" />*/}
                <div className="max-w-1000px zindex-5 px-4">
                    {" "}
                    <Link
                        to=""
                        style={backButtonStyle}
                        onMouseOver={(e) =>
                            (e.currentTarget.style.color = "#007bfe")
                        }
                        onMouseOut={(e) =>
                            (e.currentTarget.style.color = "#000000")
                        }
                        onClick={handleBackClick}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            style={{ marginRight: "5px" }}
                            size="lg"
                        />
                    </Link>
                    {children}
                </div>
            </div>
        </>
    );
};

export default LegalInformationLayout;
