import React, { useState } from "react";
import SearchField, {
    ISearchFieldOptions,
} from "../components/common/SearchField";

interface IUseSearchFieldProps<T> {
    /**
     * Selects value from each list item to search through
     * @param item - each item of the list
     */
    itemValueSelector: (item: T) => string;
    /**
     * List of items to search through
     */
    list: T[];
    /**
     * Local useState setter for filtered list
     * @param items
     */
    setList: (items: T[]) => void;
    /**
     * Allows to transform search field value, for example make it lowercase
     * @param value
     */
    searchValueTransform: (value: string) => string;
    additionalFilters?: ((item: T) => boolean)[];
    white?: boolean;
}

function useTypedSearchField<T>({
    itemValueSelector,
    list,
    setList,
    searchValueTransform,
    additionalFilters,
    ...props
}: ISearchFieldOptions & IUseSearchFieldProps<T>): JSX.Element {
   
    const mediaItems = (list as any)?.items;

    const [searchValue, setSearchValue] = useState("");

    React.useEffect(() => {
        if ((list as any)?.items) {
            if (mediaItems?.length !== 0) {
                const filteredList = !additionalFilters
                    ? mediaItems?.items
                    : additionalFilters.reduce(
                          (currentList, filter) => currentList?.filter(filter),
                          mediaItems
                      );

                if (props?.searching == "serverSide") {
                    setList(
                        filteredList?.filter((item: any) =>
                            itemValueSelector(item).includes(
                                searchValueTransform(searchValue)
                            )
                        )
                    );
                } else {
                    setList(
                        filteredList?.filter((item: any) =>
                            itemValueSelector(item).includes(
                                searchValueTransform(searchValue)
                            )
                        )
                    );
                }
            } else {
                setList([]);
            }
        } else {
            if (list.length !== 0) {
                const filteredList = !additionalFilters
                    ? list
                    : additionalFilters.reduce(
                          (currentList, filter) => currentList?.filter(filter),
                          list
                      );

                if (props?.searching === "serverSide") {
                    setList(filteredList);
                } else {
                    setList(
                        filteredList?.filter((item: any) =>
                            itemValueSelector(item).includes(
                                searchValueTransform(searchValue)
                            )
                        )
                    );
                }
            } else {
                setList([]);
            }
        }
    }, [searchValue, additionalFilters, mediaItems, list]);

    return (
        <SearchField
            value={searchValue}
            setValue={setSearchValue}
            disabled={list.length === 0 && !searchValue}
            {...props}
        />
    );
}

export default useTypedSearchField;
