import { Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { restoreUser } from "../../store/asyncActions/auth";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import { ServerError } from "../../types/Error";
import { SellerCompanyFormValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import InputForImageUploading from "./fields/InputForImageUploading";
import FormErrors from "./FormErrors";
const default_logo_company = "../assets/media/images/default_logo_company.png";
const validationSchema = Yup.object().shape({
    companyUrl: Yup.string().url("Should be a valid URL"),
});

const SellerCompanyForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    // const [selectedFile, setSelectedFile] = useState<
    //     FileWithPreview | undefined
    // >(undefined);
    const user = useTypedSelector((state) => state.auth.currentUser);
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );

    const { updateUserProfile } = authActions;
    // const { uploadFile } = mediaActions;
    const { showSnackbarWithAutoHide } = notificationActions; 
    const isLoadingLogo = useTypedSelector((state) => state.auth.isLoadingLogo);

    const initialValues: SellerCompanyFormValues = {
        companyLogo: user?.companyLogo || default_logo_company,
        companyUrl: user?.companyUrl || "",
    };
    const handleCompanyLogoChange = async (companyLogo: any) => {
        try {
            if (companyLogo !== undefined) {
                const response = await fetch(companyLogo); // Assuming `preview` contains the image URL
                const contentType: any = response.headers.get("content-type");
                const companyLogoArrayBuffer = await response.arrayBuffer();
                const companyLogoData = new Blob([companyLogoArrayBuffer], {
                    type: contentType,
                });

                // Hit the API with the profilePicData payload
                const apiPayload = {
                    file: companyLogoData,
                    companyUrl: user?.companyUrl || "",
                    type: "company",
                };

                const result = await dispatch(
                    updateUserProfile(apiPayload)
                ).unwrap();
                if (result)
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Updated!",
                        })
                    );
                dispatch(restoreUser());
            }
        } catch (error) {
            console.error("Error uploading profile picture:", error);
        }
    };

    const handleCompanyLogoDelete = async () => {
        try { 
                const apiPayload = { 
                    companyUrl: user?.companyUrl || "",
                    companyLogo: null,
                    type: "company",
                }; 
                const result = await dispatch(
                    updateUserProfile(apiPayload)
                ).unwrap();
                if (result)
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Deleted!",
                        })
                    );
                dispatch(restoreUser());
            
        } catch (error) {
            console.error("Error uploading profile picture:", error);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    setFormErrors(undefined);

                    let profilePicData = null;
                    let companyLogo = user?.companyLogo;

                    const initialProfilePic = companyLogo;
                    const uploadedProfilePic = values.companyLogo;
                    if (uploadedProfilePic !== initialProfilePic) {
                        const response = await fetch(values.companyLogo);
                        const profilePicArrayBuffer =
                            await response.arrayBuffer();
                        profilePicData = new Blob([profilePicArrayBuffer], {
                            type: "image/jpeg",
                        });
                        companyLogo = values.companyLogo;
                    }

                    const payload = {
                        file: profilePicData,
                        companyUrl: values.companyUrl,
                        type: "company",
                    };

                    await dispatch(updateUserProfile(payload)).unwrap();

                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Updated!",
                        })
                    );
                    dispatch(restoreUser());
                    setSubmitting(false);
                } catch (err: any) {
                    setFormErrors(err);
                    setSubmitting(false);
                    setErrors(transformToFormikErrors(err));
                }
            }}
        >
            {({
                handleSubmit,
                dirty,
                isSubmitting,
                isValid,
                values,
                setValues,
            }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column"
                    style={{ margin: 0, padding: 0 }} // Remove margin and padding
                >
                    <div>
                        <Form.Row>
                            <Form.Group className="col-md-12">
                                <CustomInput
                                    placeholder="Company URL"
                                    type="text"
                                    label="Your Unique Company URL"
                                    name="companyUrl"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <InputForImageUploading
                                    btnText="Upload New Logo"
                                    initialValue={values.companyLogo}
                                    valueSetter={(companyLogo: any) => {
                                        setValues({ ...values, companyLogo });
                                        handleCompanyLogoChange(companyLogo); // Call the API when profile pic changes
                                    }}
                                    isLoading={false}
                                    isLoadingLogo={isLoadingLogo}
                                    hideButton={false}
                                    customStyles="h-100px w-175px h-xxl-100px w-xxl-175px w-xl-150px h-xl-90px rounded-lg"
                                    fileSetter={() => {
                                        return null;
                                    }}
                                    imgDeleted={handleCompanyLogoDelete}
                                    isDefaultLogo={!user?.companyLogo}
                                    defaultLogo={default_logo_company}
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div>
                        <FormErrors errors={formErrors} />
                        <div className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!dirty || !isValid || isSubmitting}
                                className="mx-4"
                            >
                                Update Information
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SellerCompanyForm;