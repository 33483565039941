import { Formik } from "formik";
import * as React from "react";
import { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { ServerError } from "../../types/Error";
import { EmailScriptInviteFormValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required!")
        .email("Email is not valid!"),
});

const EmailScriptInviteForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );
    const { closeEmailScriptInviteModal } = modalActions;

    const { sendEmailScriptInvite } = actions;

    const { scriptId } = useTypedSelector(
        (state) => state.modals.emailScriptInviteModal
    );

    const initialValues: EmailScriptInviteFormValues = {
        email: "",
        scriptId: scriptId,
    };

    const closeModal = useCallback(() => {
        dispatch(closeEmailScriptInviteModal());
    }, []);

    const submitForm = useCallback((values: EmailScriptInviteFormValues) => {
        return dispatch(
            sendEmailScriptInvite({
                email: values.email,
                scriptId: values.scriptId,
            })
        );
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setFormErrors(undefined);
                submitForm(values)
                    .unwrap()
                    .then(() => {
                        setSubmitting(false);
                        closeModal();
                    })
                    .catch((err: any) => {
                        setFormErrors(err);
                        setSubmitting(false);
                        setErrors(transformToFormikErrors(err));
                    });
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                placeholder="example@example.com"
                                type="text"
                                label="Seller Email"
                                name="email"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>

                    <FormErrors errors={formErrors} />
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="primary"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                            type="submit"
                        >
                            Send TextFlow
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EmailScriptInviteForm;
