import { Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatureTypeRendering from "../components/common/FeatureTypeRendering";
import { subscribePlan } from "../components/myProfile/billing/payment/_requests";
import useQueryParams from "../hooks/useQueryParams";
import DefaultLayout from "../layouts/DefaultLayout";
import LayoutCard from "../layouts/LayoutCard";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { authActions } from "../store/reducers/authSlice";
import { notificationActions } from "../store/reducers/notificationsSlice";
import { actions } from "../store/reducers/subscriptionSlice";
import { Feature } from "../types/Feature";
import { Plantype } from "../types/PlanType";

const Billing: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { restoreUser } = authActions;
    const [isLoading, setIsLoading] = useState(false);
    const [btnloading, setbtnloading] = useState(false);
    const params = useQueryParams();
    const success = params.get("success");
    const { showSnackbarWithAutoHide } = notificationActions;

    const [currentState, setCurrentState] = useState<"monthly" | "annually">(
        "monthly"
    );
    const [selected, setSelected] = useState<string | number | undefined>("");
    const { fetchPlans } = actions;
    const { list } = useTypedSelector((state) => state.subscription.plans);

    useEffect(() => {
        dispatch(
            fetchPlans({
                fetchActive: "true",
            })
        );
    }, []);

    useEffect(() => {
        if (success) {
            setIsLoading(true);
            dispatch(restoreUser())
                .unwrap()
                .then((user) => {
                    if (!user.isActive) setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    }, [success]);

    const initialValues = {};

    const postSubscribePlan = async () => {
        setbtnloading(true);
        await subscribePlan(selected, "")
            .then((e) => {
                if (typeof e === "object" && e.err) {
                    dispatch(showSnackbarWithAutoHide({ msgText: e.errMsg }));
                } else {
                    window.location.href = "/";
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => { })
            .finally(() => {
                setbtnloading(false);
            });
    };

    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={1} xl={3} className="mt-15">
                <Col xl={12}>
                    <LayoutCard>
                        <div className="modal-body pt-0 pb-15 mt-10">
                            <div className="mb-13 text-center">
                                <h1 className="mb-3 text-center">
                                    Upgrade a Plan
                                </h1>

                                <div className="text-muted fw-bold fs-5">
                                    If you need more info, please check{" "}
                                    <a
                                        href="#"
                                        className="link-primary fw-bolder"
                                    >
                                        Pricing Guidelines
                                    </a>
                                    .
                                </div>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={async (values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    try {
                                        postSubscribePlan();
                                        // eslint-disable-next-line no-empty
                                    } catch (err) { }
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        {/*begin::Card body*/}
                                        <div className="card-body">
                                            {/*begin::Plans*/}
                                            <div className="d-flex flex-column">
                                                {/*begin::Nav group*/}
                                                <div
                                                    className="nav-group nav-group-outline mx-auto mb-15"
                                                    data-kt-buttons="true"
                                                >
                                                    <a
                                                        href="#"
                                                        className={
                                                            "btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 " +
                                                            (currentState ===
                                                                "monthly" &&
                                                                "active btn-bg-light")
                                                        }
                                                        onClick={() => {
                                                            setCurrentState(
                                                                "monthly"
                                                            );
                                                        }}
                                                        data-kt-plan="monthly"
                                                    >
                                                        Monthly
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className={
                                                            "btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 " +
                                                            (currentState ===
                                                                "annually" &&
                                                                "active btn-bg-light")
                                                        }
                                                        onClick={() => {
                                                            setCurrentState(
                                                                "annually"
                                                            );
                                                        }}
                                                        data-kt-plan="annually"
                                                    >
                                                        Annual
                                                    </a>
                                                </div>
                                                <div className="row g-10 justify-content-center w-full" style={{ gap: '1rem' }}>
                                                    {!isLoading &&
                                                        list
                                                            ?.filter(
                                                                (x: Plantype) =>
                                                                    x?.recurrence ==
                                                                    currentState
                                                            )
                                                            ?.map(
                                                                (
                                                                    plan: Plantype
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3"
                                                                            key={
                                                                                plan.id
                                                                            }
                                                                        >
                                                                            <div className="d-flex h-100 align-items-center">
                                                                                <div
                                                                                    className="w-100 h-100 d-flex flex-column flex-start align-items-center rounded-3 bg-light bg-opacity-75 py-15 px-10"
                                                                                    style={
                                                                                        selected ==
                                                                                            plan?.id
                                                                                            ? {
                                                                                                border: "1px solid #3699FF",
                                                                                                cursor: "pointer",
                                                                                            }
                                                                                            : {
                                                                                                border: "1px solid transparent",
                                                                                                cursor: "pointer",
                                                                                            }
                                                                                    }
                                                                                    onClick={() =>
                                                                                        setSelected(
                                                                                            plan.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <div className="text-center">
                                                                                        <h1 className="text-dark text-uppercase h1 font-weight-bolder">
                                                                                            {
                                                                                                plan?.name
                                                                                            }
                                                                                        </h1>
                                                                                        <div  className="fw-bolder h5 text-primary text-center">
                                                                                             {plan?.subTitle}
                                                                                        </div>
                                                                                        <div
                                                                                            className="fw-bolder h5 bg-primary text-center"
                                                                                            style={{
                                                                                                color: '#fff',
                                                                                                width: '100%',
                                                                                                position: 'absolute',
                                                                                                left: 0,
                                                                                                display: 'inline-block',
                                                                                                padding: '1.5em',
                                                                                                clipPath: 'polygon(0 0.8em, calc(100% - 0.8em) 0.8em, calc(100% - 0.8em) 0, 100% 0.8em, 100% calc(100% - 0.8em), 0.8em calc(100% - 0.8em), 0.8em 100%, 0 calc(100% - 0.8em))',
                                                                                                borderImage: 'conic-gradient(#00000085 0 0) 50% / 0.8em',
                                                                                            }}
                                                                                        >
                                                                                            <span className="fs-3x fw-bolder h1">
                                                                                                $
                                                                                            </span>
                                                                                            <span className="fs-3x fw-bolder h1">
                                                                                                {currentState === "annually"
                                                                                                    ? (plan?.price && (plan.price / 12).toFixed(2))
                                                                                                    : plan?.price}
                                                                                            </span>
                                                                                            <span className="fs-7 fw-semibold opacity-70">
                                                                                                /
                                                                                                <span data-kt-element="period"> 
                                                                                                    Mon
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="fw-bolder h5 text-center hidden"
                                                                                            style={{
                                                                                                color: '#fff',
                                                                                                width: "100%",
                                                                                                visibility: "hidden",
                                                                                                position: 'relative',
                                                                                                display: 'inline-block',
                                                                                                padding: '1.5em',
                                                                                                clipPath: 'polygon(0 0.8em, calc(100% - 0.8em) 0.8em, calc(100% - 0.8em) 0, 100% 0.8em, 100% calc(100% - 0.8em), 0.8em calc(100% - 0.8em), 0.8em 100%, 0 calc(100% - 0.8em))',
                                                                                                borderImage: 'conic-gradient(#F3F6F9 0 0) 50% / 0.8em',
                                                                                            }}
                                                                                        >
                                                                                             <span className="fs-3x fw-bolder h1">
                                                                                                $
                                                                                            </span>
                                                                                            <span className="fs-3x fw-bolder h1">
                                                                                                {currentState === "annually"
                                                                                                    ? (plan?.price && (plan.price / 12).toFixed(2))
                                                                                                    : plan?.price}
                                                                                            </span>
                                                                                            <span className="fs-7 fw-semibold opacity-70">
                                                                                                /
                                                                                                <span data-kt-element="period"> 
                                                                                                    Mon
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="w-100 mb-5">
                                                                                        {plan
                                                                                            ?.features
                                                                                            ?.length &&
                                                                                            plan?.features?.map(
                                                                                                (
                                                                                                    feature: Feature,
                                                                                                    index: number
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <div
                                                                                                            key={
                                                                                                                feature?.key ||
                                                                                                                index
                                                                                                            }
                                                                                                            className="d-flex align-items-center mb-6"
                                                                                                        >
                                                                                                            <span className="font-weight-bold h6 text-gray-700 flex-grow-1">
                                                                                                                {
                                                                                                                    feature?.fname
                                                                                                                }
                                                                                                            </span>
                                                                                                            {
                                                                                                                <FeatureTypeRendering
                                                                                                                    featureProp={
                                                                                                                        feature
                                                                                                                    }
                                                                                                                ></FeatureTypeRendering>
                                                                                                            }
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </div>
                                                                                    {/*end::Features*/}
                                                                                    {/*begin::Select*/}
                                                                                    {/* <a
                                                                            href="#"
                                                                            className="btn btn-sm btn-primary"
                                                                            style={{
                                                                                marginTop:
                                                                                    "auto",
                                                                                minWidth:
                                                                                    "200px",
                                                                            }}
                                                                            onClick={() =>
                                                                                setSelected(
                                                                                    plan?.stripe_price_id
                                                                                )
                                                                            }
                                                                        >
                                                                            Select
                                                                        </a> */}
                                                                                    {/*end::Select*/}
                                                                                </div>
                                                                                {/*end::Option*/}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                </div>
                                                {/*end::Row*/}
                                                <div className="mt-5">
                                                    <Form.Row className="d-flex justify-content-center">
                                                        <Button
                                                            size="lg"
                                                            variant="primary"
                                                            type="submit"
                                                            className="mr-5"
                                                            disabled={
                                                                btnloading ||
                                                                (selected
                                                                    ? false
                                                                    : true)
                                                            }
                                                        >
                                                            {btnloading
                                                                ? "Loading..."
                                                                : "Upgrade Plan"}
                                                        </Button>
                                                        <Link to="/plans">
                                                            <Button
                                                                size="lg"
                                                                type="button"
                                                                className="btn btn-info"
                                                                disabled={
                                                                    btnloading
                                                                }
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Link>
                                                    </Form.Row>
                                                </div>
                                            </div>
                                            {/*end::Plans*/}
                                        </div>
                                        {/*end::Card body*/}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </LayoutCard>
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default Billing;
