import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import Loading from "../common/Loading";
import GuestProfileForm from "../forms/GuestProfileForm";

const GuestProfileModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { closeGuestProfileModal } = modalActions;
    const show = useTypedSelector(
        (state) => state.modals.guestProfileModal.isOpen
    );
    const guest = useTypedSelector(
        (state) => state.modals.guestProfileModal.currentGuest
    );

    const handleClose = () => {
        dispatch(closeGuestProfileModal());
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header
                closeButton
                className="d-flex justify-content-between align-items-center"
            >
                <Modal.Title>Guest Profile</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                ></button>
            </Modal.Header>
            <Modal.Body>
                {guest ? <GuestProfileForm guest={guest} /> : <Loading />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GuestProfileModal;
