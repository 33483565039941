import * as React from "react";
import { Col, Row } from "react-bootstrap";
import PartyScheduleCard from "../components/cardViews/PartyScheduleCard";
import DefaultLayout from "../layouts/DefaultLayout";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { useHistory } from "react-router-dom";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { redirectUrlActions } from "../store/reducers/redirectUrlSlice";

const PartyDashboard: React.FC = () => {
    const { url } = useTypedSelector((state) => state.urlReducer);
    const history = useHistory();
    const dispatch = useTypedDispatch();
    const { clearRedirectUrl } = redirectUrlActions;
    const redirectUrl = url;

    React.useEffect(() => {
        if (redirectUrl) {
            history.push(redirectUrl);
            dispatch(clearRedirectUrl());
        }
    }, [url]);

    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={2} xl={1}>
                <Col>
                    <PartyScheduleCard />
                </Col>
                {/* <Col>
                    <MessageCard />
                </Col> */}
            </Row>
        </DefaultLayout>
    );
};

export default PartyDashboard;
