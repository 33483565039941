import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FieldProps } from "formik";
import * as React from "react";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { mediaActions } from "../../store/reducers/medaiSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { AssetTypesSelect, ScriptAsset } from "../../types/Media";
import CustomSelect from "../forms/fields/CustomSelect";
import "./media.scss";
import MediaCollectionsModal from "./MediaCollectionsModal";
import MediaGrid from "./MediaGrid";
import MediaUploadModal from "./MediaUploadModal";
interface IProps extends FieldProps {
    currentAssets?: ScriptAsset["items"][];
    isSubmitted?: boolean;
    scrollToTopIfNoOverflow?: () => void;
}
const MediaGridViewSelect: React.FC<IProps> = ({
    field,
    form,
    currentAssets,
    isSubmitted,
    scrollToTopIfNoOverflow,
}) => {
    let AddMediaButtonRef: HTMLButtonElement;

    const dispatch = useTypedDispatch();
    const [filteredList, setFilteredList] = useState<ScriptAsset["items"][]>(
        []
    );
    const { openNewMediaCollectionModal } = modalActions;
    function addMappingToList(
        inputAssets?: ScriptAsset["items"][]
    ): ScriptAsset["items"][] {
        return (
            inputAssets?.map((i) => {
                const clone = { ...i };
                clone.isSelected = true;
                return clone;
            }) ?? []
        );
    }
    const [selectedList, setSelectedList] = useState<ScriptAsset["items"][]>(
        addMappingToList(currentAssets)
    );
    const [currentMediaType, setCurrentMediaType] = useState<AssetTypesSelect>(
        AssetTypesSelect.any
    );

    const collectionsList = useTypedSelector(
        (state) => state.media.collections.list
    );
    const collectionsLoading = useTypedSelector(
        (state) => state.media.collections.isLoading
    );
    const assetsLoading = useTypedSelector(
        (state) => state.media.assets.isLoading
    );
    const assetsList = useTypedSelector((state) => state.media.assets.list);
    const currentCollection = useTypedSelector(
        (state) => state.media.collections.selectedCollection
    );
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const {
        fetchUserAssetCollections,
        setSelectedCollection,
        fetchScriptAssetsByCollection,
    } = mediaActions;

    const handleCurrentCollectionChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        dispatch(setSelectedCollection(Number(e.target.value)));
    };

    const handleCurrentMediaTypeChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        setCurrentMediaType(Number(e.target.value));
    };
    const setFilteredListWithSelections = useCallback(
        (items: ScriptAsset["items"][]) => {
            let records = items;
            if (Array.isArray(items)) {
                records = items;
            } else {
                records = items["items"];
            }

            const itemsCloneWithSeletion = records?.map((i) => {
                const clone = { ...i };
                clone.isSelected = !!selectedList.find((si) => si.id === i.id);
                return clone;
            });

            setFilteredList(itemsCloneWithSeletion);
        },
        [selectedList]
    );
    useTypedSearchField<ScriptAsset["items"]>({
        inputPlaceholder: "Search here",
        list: assetsList,
        isLoading: collectionsLoading,
        setList: setFilteredListWithSelections,
        itemValueSelector: (item) => item.name.toLowerCase(),
        // btnOnClick: () => console.info(""),
        searchValueTransform: (value) => value.toLowerCase(),
        hideBtn: true,
        white: true,
        btnPlaceholder: true,
        additionalFilters: useMemo(
            () => [
                (item) => {
                    if (currentMediaType === AssetTypesSelect.any) return true;
                    return (
                        AssetTypesSelect[currentMediaType] ===
                        item.assetType.name.valueOf()
                    );
                },
            ],
            [currentMediaType]
        ),
    });

    useEffect(() => {
        dispatch(fetchUserAssetCollections());
    }, []);

    useEffect(() => {
        dispatch(
            fetchScriptAssetsByCollection({
                collectionId: currentCollection,
            })
        );
    }, [currentCollection]);

    useEffect(() => {
        if (!assetsLoading) {
            setFilteredListWithSelections(assetsList);
        }
    }, [assetsList, assetsLoading]);

    useEffect(() => {
        form.setFieldValue(field.name, selectedList);
    }, [selectedList]);

    useEffect(() => {
        if (isSubmitted) {
            const handleResetSelectedMedia = () => {
                const items: ScriptAsset["items"][] = assetsList;
                let records = items;
                if (Array.isArray(items)) {
                    records = items;
                } else {
                    records = items["items"];
                }
                setSelectedList([]); 
                const filteredListClone = records.map((asset) => ({
                    ...asset,
                    isSelected: false,
                }));
                setFilteredList(filteredListClone);
            };
            handleResetSelectedMedia();
        }
    }, [isSubmitted]);

    const onItemClick = useCallback(
        (item: ScriptAsset["items"]) => {
            const existingSelected = selectedList.find(
                (sa) => sa.id === item.id
            );
            const itemClone = { ...item };

            if (existingSelected) {
                itemClone.isSelected = false;

                setSelectedList(
                    selectedList.filter((asset) => asset.id !== item.id)
                );
            } else {
                itemClone.isSelected = true;
                setSelectedList([...selectedList, itemClone]);
            }

            const filteredListClone =
                filteredList.length > 0 ? [...filteredList] : [...assetsList];
            const index = filteredListClone.findIndex(
                (asset) => asset.id === itemClone.id
            );
            filteredListClone[index] = itemClone;

            setFilteredList(filteredListClone);
        },
        [selectedList, filteredList, assetsList]
    );

    const onDeselect = useCallback(
        (item: ScriptAsset["items"]) => {
            const itemClone = { ...item };
            itemClone.isSelected = false;
            setSelectedList(
                selectedList.filter((asset) => asset.id !== item.id)
            );

            const filteredListClone =
                filteredList.length > 0 ? [...filteredList] : [...assetsList];
            const index = filteredListClone.findIndex(
                (asset) => asset.id === itemClone.id
            );
            filteredListClone[index] = itemClone;
            setFilteredList(filteredListClone);
        },
        [selectedList, filteredList, assetsList]
    );
    const openModal = useCallback(() => {
        dispatch(openNewMediaCollectionModal());
    }, []);
    const [addMedia, setAddMedia] = useState(false);
    useEffect(() => {
        if (addMedia) {
            AddMediaButtonRef.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [addMedia]);
    const onClickEdit = useCallback(() => {
        setUploadModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setUploadModalOpen(false);
    }, []);
    return (
        <div className="form-control form-control-solid d-flex flex-column align-items-center h-auto">
            <MediaGrid
                mediaItems={selectedList}
                // isLoading={assetsLoading}
                noCollection={false}
                onIconClick={onDeselect}
                selectedIcon={faTimesCircle}
                iconColor={"grey"}
            />
            {addMedia && (
                <div>
                    <div className="d-flex align-items-end flex-wrap">
                        <div className="max-w-200px min-w-200px w-100 mr-4">
                            <CustomSelect
                                title="Media Type"
                                value={currentMediaType}
                                onChange={handleCurrentMediaTypeChange}
                                isLoading={collectionsLoading}
                                disabled={assetsLoading || collectionsLoading}
                                white
                            >
                                <option value={0}>All types</option>
                                <option value={1}>Images</option>
                                <option value={2}>Videos</option>
                                <option value={3}>Audio</option>
                            </CustomSelect>
                        </div>
                        <div className="d-flex align-items-end flex-grow-1">
                            <div className="max-w-200px min-w-200px w-100 mr-4">
                                <CustomSelect
                                    title="Collection"
                                    value={currentCollection}
                                    onChange={handleCurrentCollectionChange}
                                    isLoading={collectionsLoading}
                                    disabled={collectionsList.length === 0}
                                    white
                                >
                                    <option value={0}>
                                        {collectionsList.length === 0
                                            ? "No collections"
                                            : "Show All"}
                                    </option>
                                    {collectionsList.map((collection) => (
                                        <option
                                            key={collection.id}
                                            value={collection.id}
                                        >
                                            {collection.name}
                                        </option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </div>
                        <div
                            className="max-w-400px min-w-150px w-100 mb-2 mt-4"
                            style={{
                                justifyContent: "space-between",
                                display: "flex",
                            }}
                        >
                            <MediaUploadModal
                                show={uploadModalOpen}
                                handleClose={closeModal}
                            />
                            <Button
                                variant="primary mb-4"
                                onClick={() => onClickEdit()}
                            >
                                Add New
                            </Button>
                            <MediaCollectionsModal />
                            <Button
                                variant="primary mb-4 mr-17"
                                title="Add new collection"
                                onClick={openModal}
                            >
                                <span className="svg-icon d-flex justify-content-center align-items-center mx-1">
                                    Add Collection
                                </span>
                            </Button>
                            {/* {searchField} */}
                        </div>
                    </div>
                    <MediaGrid
                        mediaItems={filteredList}
                        isLoading={assetsLoading}
                        noCollection={currentCollection === 0}
                        onItemClick={onItemClick}
                    />
                </div>
            )}
            <div className="mr-4 position-relative right-0">
                <Button
                    variant="primary mb-2 svg-icon shadow-sm"
                    title="Add media"
                    onClick={() => {
                        if (scrollToTopIfNoOverflow) {
                            scrollToTopIfNoOverflow();
                        }
                        setAddMedia(!addMedia);
                    }}
                    ref={(el: HTMLButtonElement) => {
                        AddMediaButtonRef = el;
                    }}
                >
                    {addMedia ? "Close Media Selector" : "Open Media Selector"}
                </Button>
            </div>
        </div>
    );
};

export default MediaGridViewSelect;
