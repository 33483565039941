import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";

interface IFetchPaginationParams {
    _page?: number;
    _limit?: number;
    search?: string;
}

export const FetchTopUsed = createAsyncThunk<any, IFetchPaginationParams>(
    "topUsed/fetch",
    async (_, thunkApi) => {
        try {
            const result = await axiosClient.get<any>("/top-used", {
                params: {
                    page: _._page,
                    limit: _._limit,
                    search: _.search,
                },
            });
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IFetchTopUsedById {
    id: number;
}

export const FetchTopUsedById = createAsyncThunk<any, IFetchTopUsedById>(
    "topUsed/fetchById",
    async ({ id }, thunkApi) => {
        try {
            console.info(id);
            const result = await axiosClient.get<any>(`/top-used/${id}`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IPostTopUsedById {
    msg_text: string;
    msg_name: string;
}

export const PostTopUsedById = createAsyncThunk<any, IPostTopUsedById>(
    "topUsed/fetchById",
    async ({ msg_name, msg_text }, thunkApi) => {
        try {
            const result = await axiosClient.post<any>("/top-used/", {
                msg_name,
                msg_text,
            });
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IPre_Pop_msg_TopUsed {
    prePopId: number;
    scriptId: number;
    istopused: boolean;
}

export const Pre_Pop_msg_TopUsed = createAsyncThunk<any, IPre_Pop_msg_TopUsed>(
    "topUsed/fetchById",
    async ({ prePopId, scriptId, istopused }, thunkApi) => {
        try {
            const result = await axiosClient.post<any>(
                `/top-used/pre-populate-to-msg-template?prePopId=${prePopId}&scriptId=${scriptId}&istopused=${istopused}`,
                {
                    params: {
                        prePopId,
                        scriptId,
                        istopused,
                    },
                }
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IUpdateTopUsedById {
    id: number;
    msg_text?: string;
    msg_name?: string;
    frequency?: number;
}

export const UpdateTopUsedById = createAsyncThunk<any, IUpdateTopUsedById>(
    "topUsed/fetchById",
    async ({ id, msg_name, msg_text, frequency }, thunkApi) => {
        try {
            const postData: Record<string, any> = {
                ...(msg_name && msg_name.trim() !== "" && { msg_name }),
                ...(msg_text && msg_text.trim() !== "" && { msg_text }),
                ...(frequency !== undefined && { frequency }),
            };
            const result = await axiosClient.post<any>(
                `/top-used?update=true&id=${id}`,
                postData
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const DeleteTopUsedById = createAsyncThunk<any, { id: number }>(
    "topUsed/fetchById",
    async ({ id }, thunkApi) => {
        try {
            const result = await axiosClient.delete<any>(`/top-used?id=${id}`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
