import * as React from "react";

function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.382 12.858a4.241 4.241 0 01-2.999-7.24l.474-.473 1.212 1.212-.473.474a2.527 2.527 0 000 3.573 2.589 2.589 0 003.574 0l4.376-4.376a2.53 2.53 0 000-3.572 2.588 2.588 0 00-3.574 0L16.76 1.243a4.241 4.241 0 115.998 5.998l-4.375 4.374a4.217 4.217 0 01-3 1.243zm-6.857 1.714a4.24 4.24 0 01-2.999-7.24l4.377-4.375A4.241 4.241 0 1115.9 8.955l-.474.473-1.213-1.21.477-.478a2.528 2.528 0 00-.003-3.57 2.589 2.589 0 00-3.574 0L6.739 8.543a2.526 2.526 0 000 3.574 2.588 2.588 0 003.574 0l1.212 1.212a4.215 4.215 0 01-3 1.242zM1.715 24h17.141a1.716 1.716 0 001.714-1.714v-8.571h-1.714v8.57H1.714V5.145h3.429V3.429H1.714A1.716 1.716 0 000 5.144v17.142A1.716 1.716 0 001.714 24z"
                fill="#828282"
            />
        </svg>
    );
}

export default SvgCopy;
