import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import { DateClickArg } from "@fullcalendar/interaction";
import CalendarView from "../calendar/CalendarView";
import LayoutCard from "../../layouts/LayoutCard";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/partySlice";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import PartiesList from "../patrySchedule/PartiesList";
import PerfectScrollbar from 'react-perfect-scrollbar';

export interface ICalendarCardProps {
    calendarRef: React.Ref<FullCalendar>;
    dateClick: (args: DateClickArg) => void;
}

const SmallCalendarCard: React.FC<ICalendarCardProps> = ({
    calendarRef,
    dateClick,
}) => {
    const { list, hasMore, page, limit } = useTypedSelector(
        (state) => state.party.parties.future
    );
    const dispatch = useTypedDispatch();
    const { fetchAllUserParties, fetchFuturePartiesList } = actions;

    useEffect(() => {
        if (list.length === 0 && hasMore)
            dispatch(
                fetchFuturePartiesList({
                    _page: page,
                    _limit: limit,
                })
            );
    }, [list, hasMore]);

    useEffect(() => {
        dispatch(
            fetchAllUserParties({
                fetchInactive: false,
            })
        );
    }, []);

    return (
        <LayoutCard> 
            <CalendarView
                variant="small"
                calendarRef={calendarRef}
                dateClick={dateClick}
            />
            <p className="mt-4 p5">Upcoming Events</p> 
            <PerfectScrollbar className="px-3 h-235px">
                <PartiesList partiesList={list.slice(0, 3)} />
                </PerfectScrollbar> 
        </LayoutCard>
    );
};

export default SmallCalendarCard;
