import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ModalWapper from "../../pages/auth/model-pages/ModalWapper";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { SellerProfileInfoFormModal } from "../cardViews/SellerProfileInfoCard";
import AnnouncementFormModal from "../contact/AnnouncementFormModal";
import ContactFormModal from "../contact/ContactFormModal";
import NewDirectMessageModal from "../messages/NewDirectMessageModal";
import Snackbar from "../notifications/Snackbar";
import PartyFormModal from "../patrySchedule/PartyFormModal";
import ScriptModal from "../script/ScriptModal";
import VerificationsWrapper from "../verification/VerificationsWrapper";
import EmailScriptInviteModal from "../script/EmailScriptInviteModal";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import redirectUrlReducer, {
    redirectUrlActions,
} from "../../store/reducers/redirectUrlSlice";

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const isAuthenticated = useTypedSelector(
        (state) => state.auth.isAuthenticated
    );
    const dispatch = useTypedDispatch();
    const { setRedirectUrl } = redirectUrlActions;
    const hasValidPlan = useTypedSelector(
        (state) => state.auth.currentUser?.hasValidPlan
    );
    const location = useLocation();

    if (location.pathname == "/textflow/invite" && !isAuthenticated) {
        const params = new URLSearchParams(location.search);
        const redirectUrl = params.get("token") || undefined;
        dispatch(
            setRedirectUrl({ url: `/textflow/invite?token=${redirectUrl}` })
        );
    } 

    if (!hasValidPlan) return <Redirect to={"/billing"} />;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    <>
                        <NewDirectMessageModal />
                        <PartyFormModal />
                        <ScriptModal />
                        <EmailScriptInviteModal />
                        <Snackbar />
                        <ContactFormModal />
                        <AnnouncementFormModal />
                        <SellerProfileInfoFormModal />
                        <VerificationsWrapper />
                        <ModalWapper />
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                        {children}
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default ProtectedRoute;
