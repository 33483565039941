import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { mediaActions } from "../../store/reducers/medaiSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { AssetTypesSelect, ScriptAsset } from "../../types/Media";
import CustomSelect from "../forms/fields/CustomSelect";
import "./media.scss";
import MediaAssetViewModal from "./MediaAssetViewModal";
import MediaGrid from "./MediaGrid";

const MediaGridView: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [selectedScriptAsset, setSelectedScriptAsset] = useState<
        ScriptAsset["items"] | undefined
    >(undefined);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [filteredList, setFilteredList] = useState<ScriptAsset["items"][]>(
        []
    );
    const [currentMediaType, setCurrentMediaType] = useState<AssetTypesSelect>(
        AssetTypesSelect.any
    );

    const collectionsList = useTypedSelector(
        (state) => state.media.collections.list
    );
    const collectionsLoading = useTypedSelector(
        (state) => state.media.collections.isLoading
    );
    const assetsLoading = useTypedSelector(
        (state) => state.media.assets.isLoading
    );
    const assetsList = useTypedSelector((state) => state.media.assets.list);
    const currentCollection = useTypedSelector(
        (state) => state.media.collections.selectedCollection
    );

    const { openNewMediaCollectionModal } = modalActions;
    const {
        fetchUserAssetCollections,
        setSelectedCollection,
        fetchScriptAssetsByCollection,
    } = mediaActions;

    const handleCurrentCollectionChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        dispatch(setSelectedCollection(Number(e.target.value)));
    };

    const handleCurrentMediaTypeChange = (
        e: ChangeEvent<HTMLSelectElement>
    ) => {
        setCurrentMediaType(Number(e.target.value));
    };

    useTypedSearchField<ScriptAsset["items"]>({
        inputPlaceholder: "Search here",
        list: assetsList,
        isLoading: collectionsLoading,
        setList: setFilteredList,
        itemValueSelector: (item) => item.name.toLowerCase(),
        btnOnClick: () => console.info(""),
        searchValueTransform: (value) => value.toLowerCase(),
        hideBtn: true,
        white: true,
        btnPlaceholder: true,
        additionalFilters: useMemo(
            () => [
                (item) => {
                    if (currentMediaType === AssetTypesSelect.any) return true;
                    return (
                        AssetTypesSelect[currentMediaType] ===
                        item.assetType.name.valueOf()
                    );
                },
            ],
            [currentMediaType]
        ),
    });

    const openModal = useCallback(() => {
        dispatch(openNewMediaCollectionModal());
    }, []);

    useEffect(() => {
        dispatch(fetchUserAssetCollections());
    }, []);

    useEffect(() => {
        dispatch(
            fetchScriptAssetsByCollection({
                collectionId: currentCollection,
            })
        );
    }, [currentCollection]);

    const onItemClick = useCallback((item: ScriptAsset["items"]) => {
        setSelectedScriptAsset(item);
        setViewModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setViewModalOpen(false);
    }, []);

    return (
        <div>
            <MediaAssetViewModal
                item={selectedScriptAsset}
                show={viewModalOpen}
                handleClose={closeModal}
                collectionsList={collectionsList}
            />
            <div className="d-flex align-items-end flex-wrap">
                <div className="max-w-200px min-w-200px w-100 mr-4">
                    <CustomSelect
                        title="Media Type"
                        value={currentMediaType}
                        onChange={handleCurrentMediaTypeChange}
                        isLoading={collectionsLoading}
                        disabled={assetsLoading || collectionsLoading}
                        white
                    >
                        <option value={0}>All types</option>
                        <option value={1}>Images</option>
                        <option value={2}>Videos</option>
                        <option value={3}>Audio</option>
                    </CustomSelect>
                </div>
                <div className="d-flex align-items-end flex-grow-1">
                    <div className="max-w-200px min-w-200px w-100 mr-4">
                        <CustomSelect
                            title="Collection"
                            value={currentCollection}
                            onChange={handleCurrentCollectionChange}
                            isLoading={collectionsLoading}
                            disabled={collectionsList.length === 0}
                            white
                        >
                            <option value={0}>
                                {collectionsList.length === 0
                                    ? "No collections"
                                    : "Show All"}
                            </option>
                            {collectionsList.map((collection) => (
                                <option
                                    key={collection.id}
                                    value={collection.id}
                                >
                                    {collection.name}
                                </option>
                            ))}
                        </CustomSelect>
                    </div>
                    <div className="mr-4">
                        <Button
                            variant="primary h-50px w-50px mb-2 svg-icon shadow-sm"
                            title="Add new collection"
                            onClick={openModal}
                        >
                            <span className="svg-icon d-flex justify-content-center align-items-center mx-1">
                                <FontAwesomeIcon icon={faPlus} />
                            </span>
                        </Button>
                    </div>
                </div>
                {/* <div className="max-w-200px min-w-150px w-100 mb-2 mt-4">
                    {searchField}
                </div> */}
            </div>
            <MediaGrid
                mediaItems={filteredList}
                isLoading={assetsLoading}
                noCollection={currentCollection === 0}
                onItemClick={onItemClick}
            />
        </div>
    );
};

export default MediaGridView;
