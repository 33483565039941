import React, { useCallback } from "react";
import SellerProfileForm from "../forms/SellerProfileForm";
import LayoutCard from "../../layouts/LayoutCard";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { Modal } from "react-bootstrap";

const SellerProfileInfoCard: React.FC = () => {
    return (
        <LayoutCard title={"Edit Your Profile"}>
            <SellerProfileForm />
        </LayoutCard>
    );
};

const SellerProfileInfoFormModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { closeSellerProfileInfoFormModal } = modalActions;
    
    const show = useTypedSelector(
        (state) => state.modals.SellerProfileInfo.isOpen
    ); 
    const handleClose = useCallback(() => {
        dispatch(closeSellerProfileInfoFormModal());
    }, []);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header
                closeButton
                className="d-flex justify-content-end custom-modal-header"
            ></Modal.Header>
            <Modal.Body>
                <div className="pb-6">
                    <h3 className="pb-6">Edit Profile Details</h3>
                    <SellerProfileForm />
                </div>
            </Modal.Body>
        </Modal>
    );
};
export { SellerProfileInfoFormModal };
export default SellerProfileInfoCard;
