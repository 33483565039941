import React, { useEffect, useState } from "react";
import PartiesList from "./PartiesList";
import "./partyList.scss";

import useTypedSearchField from "../../hooks/useTypedSearchField";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/partySlice";
import { MsgThread } from "../../types/MsgThread";

const UpcomingParties: React.FC = () => {
    const { list, isLoading, page, limit } = useTypedSelector(
        (state) => state.party.parties.future
    );
    const dispatch = useTypedDispatch();
    const { fetchFuturePartiesList } = actions;
    const [partyList, setPartyList] = useState<MsgThread[]>([]);
    const SearchField = useTypedSearchField<MsgThread>({
        hideBtn: true,
        btnPlaceholder: true,
        inputPlaceholder: "Search Events...",
        isLoading,
        list,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
        setList: setPartyList,
    });

    useEffect(() => {
        dispatch(
            fetchFuturePartiesList({
                _page: page,
                _limit: limit,
            })
        );
    }, []);

    return (
        <>
            {SearchField}
            <PartiesList partiesList={partyList} isLoading={isLoading} />
        </>
    );
};

export default UpcomingParties;
