import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
    BillingCard,
    initialBillingModel,
} from "../components/myProfile/billing/payment/_model";
import {
    createBillingCard,
    deleteBillingCard,
    getBillingCard,
    checkCouponisValid,
    subscribePlan,
} from "../components/myProfile/billing/payment/_requests";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { notificationActions } from "../store/reducers/notificationsSlice";
import { AxiosError } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

interface SelectedParty {
    planId: string;
}
const billingCardSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(20, "Maximum 20 symbols")
        .required("CardbName is required"),
    number: Yup.string()
        .max(16)
        .matches(/^[0-9]+$/, "Only Number is valid")
        .required("Number is required"),
    exp_month: Yup.string()
        .min(2)
        .max(2)
        .required("Expiration month is required"),
    exp_year: Yup.string().max(4).required("Expiration year is required"),
    card_type: Yup.string().required("CardType is required"),
    is_active: Yup.boolean().required("Card is active/inactive required"),
    cvc: Yup.string()
        .min(3)
        .max(4)
        .matches(/^[0-9]+$/, "Only Number is valid")
        .required("CVC is required"),
    saveCard: Yup.boolean(),
    coupon: Yup.string(),
});

const AddCardStep: React.FC = () => {
    const { planId } = useParams<SelectedParty>();
    const dispatch = useTypedDispatch();
    const { showSnackbarWithAutoHide } = notificationActions;
    const [billingCards, setBillingCards] = useState<Array<BillingCard>>([]);
    const [selectCard, setSelectedCard] = useState<
        string | number | undefined
    >();
    const [verificationMessage, setVerificationMessage] = useState<{
        msg: string | undefined;
        status: number;
    }>({
        msg: undefined,
        status: -1,
    });

    useEffect(() => {
        getBillingCardData();
    }, []);

    const postSubscribePlan = async (
        cardId?: any,
        promoCode?: string | number | undefined
    ) => {
        await subscribePlan(+planId, "", promoCode)
            .then(async () => {
                cardId && (await deleteBillingCard(cardId));
            })
            .then(() => {
                window.location.href = "/";
            })
            .catch((err) => {
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: err?.response?.data?.message,
                    })
                );
            });
    };
    if (+planId === 1) {
        postSubscribePlan();
        return <div>Please wait...</div>;
    }
    async function getBillingCardData() {
        const data: Array<BillingCard> | undefined = await getBillingCard();
        setBillingCards(Array.isArray(data) ? data : []);
    }

    const formik = useFormik({
        initialValues: { ...initialBillingModel, coupon: "" },
        validationSchema: billingCardSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            // const IsActive=initialBillingModel?.is_active;
            try {
                if (values.coupon) {
                    await checkCouponisValid(values.coupon)
                        .then(async (data: any) => {
                            if (!data.err) {
                                await checkoutPlan(
                                    values,
                                    data.promoCode.promoCode
                                );
                            } else {
                                dispatch(
                                    showSnackbarWithAutoHide({
                                        msgText: data?.errMsg,
                                    })
                                );
                            }
                        })
                        .catch((err) => {
                            dispatch(
                                showSnackbarWithAutoHide({
                                    msgText: err?.response?.data?.message,
                                })
                            );
                        });
                } else {
                    await checkoutPlan(values);
                }

                setSubmitting(false);
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(false);
            }
        },
    });

    const checkoutPlan = async (
        values: any,
        promoCode?: string | number | undefined
    ) => {
        await createBillingCard(values).then((data: any) => {
            values.save_card &&
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: data?.message,
                    })
                );
            postSubscribePlan(
                !values.save_card ? data?.data?.card?.id : undefined,
                promoCode
            );
        });
    };

    const handleVerify = async (e: any) => {
        if (e.target.value) {
            await checkCouponisValid(e.target.value)
                .then(async (data: any) => {
                    if (!data.err) {
                        dispatch(
                            showSnackbarWithAutoHide({
                                msgText: data?.message,
                            })
                        );
                        setVerificationMessage({
                            msg: data?.message,
                            status: 1,
                        });
                    } else {
                        dispatch(
                            showSnackbarWithAutoHide({
                                msgText: data?.errMsg,
                            })
                        );

                        setVerificationMessage({
                            msg: data?.errMsg,
                            status: 0,
                        });
                    }
                })
                .catch((err) => {
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: err?.response?.data?.message,
                        })
                    );
                    setVerificationMessage({
                        msg: err?.response?.data?.message,
                        status: 0,
                    });
                });
        }
    };
    return (
        <div className="container-fluid">
            <Row className="justify-content-md-center mt-4">
                <Col xl={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <h3>Your Cards</h3>
                            <ListGroup>
                                {billingCards.length === 0 ? (
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            No card added please add new and
                                            complete your subsciption
                                        </div>
                                    </ListGroup.Item>
                                ) : (
                                    <>
                                        {billingCards.map(
                                            (billingCard: BillingCard) => {
                                                return (
                                                    <>
                                                        <ListGroup.Item
                                                            key={billingCard.id}
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                            variant={
                                                                selectCard ===
                                                                billingCard.id
                                                                    ? "success"
                                                                    : "light"
                                                            }
                                                            onClick={() =>
                                                                setSelectedCard(
                                                                    billingCard.id
                                                                )
                                                            }
                                                        >
                                                            <div className="ms-2 me-auto">
                                                                <div className="fw-bold">
                                                                    {billingCard?.name ||
                                                                        "No Name"}
                                                                </div>
                                                                {
                                                                    billingCard?.brand
                                                                }{" "}
                                                                ****{" "}
                                                            </div>
                                                            <Badge
                                                                variant="primary"
                                                                pill
                                                            >
                                                                ****{" "}
                                                                {String(
                                                                    billingCard?.last4
                                                                ).slice(-4)}
                                                            </Badge>
                                                        </ListGroup.Item>
                                                    </>
                                                );
                                            }
                                        )}
                                    </>
                                )}
                            </ListGroup>
                            <p className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary mt-4"
                                    onClick={() => {
                                        selectCard
                                            ? postSubscribePlan()
                                            : dispatch(
                                                  showSnackbarWithAutoHide({
                                                      msgText:
                                                          "Please select a card",
                                                  })
                                              );
                                    }}
                                >
                                    Proceed
                                </button>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <form
                                className="form"
                                onSubmit={formik.handleSubmit}
                                noValidate
                            >
                                <div className="d-flex flex-column mb-7 fv-row">
                                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                        Name On Card
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            formik.touched.name &&
                                            formik.errors.name
                                                ? "is-invalid"
                                                : ""
                                        } ${
                                            formik.touched.name &&
                                            !formik.errors.name
                                                ? "is-valid"
                                                : ""
                                        }`}
                                        placeholder="Card Name"
                                        {...formik.getFieldProps("name")}
                                        autoComplete="off"
                                        disabled={formik.isSubmitting}
                                    />
                                    {formik.touched.name &&
                                        formik.errors.name && (
                                            <div className="invalid-feedback">
                                                {formik.errors.name}
                                            </div>
                                        )}
                                </div>
                                <div className="d-flex flex-column mb-7 fv-row">
                                    <label className="required fs-6 fw-semibold form-label mb-2">
                                        Card Number
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            placeholder="Enter card number"
                                            {...formik.getFieldProps("number")}
                                            className={`form-control ${
                                                formik.touched.number &&
                                                formik.errors.number
                                                    ? "is-invalid"
                                                    : ""
                                            } ${
                                                formik.touched.number &&
                                                !formik.errors.number
                                                    ? "is-valid"
                                                    : ""
                                            }`}
                                        />
                                        {formik.touched.number &&
                                            formik.errors.number && (
                                                <div className="invalid-feedback">
                                                    {formik.errors.number}
                                                </div>
                                            )}
                                        <div className="position-absolute translate-middle-y top-1 end-0 me-5 pr-4 d-flex">
                                            <img
                                                src="../assets/media/svg/card-logos/visa.svg"
                                                alt=""
                                                className="h-25px top-1"
                                            />
                                            <img
                                                src="../assets/media/svg/card-logos/mastercard.svg"
                                                alt=""
                                                className="h-25px"
                                            />
                                            <img
                                                src="../assets/media/svg/card-logos/american-express.svg"
                                                alt=""
                                                className="h-25px"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-md-12 fv-row">
                                        <label className="required fs-6 fw-semibold form-label mb-2">
                                            Card Type
                                        </label>
                                        <select
                                            {...formik.getFieldProps(
                                                "card_type"
                                            )}
                                            className={`form-control w-100 ${
                                                formik.touched.card_type &&
                                                formik.errors.card_type
                                                    ? "is-invalid"
                                                    : ""
                                            } ${
                                                formik.touched.card_type &&
                                                !formik.errors.card_type
                                                    ? "is-valid"
                                                    : ""
                                            }`}
                                        >
                                            <option>Select Card Type</option>
                                            <option value="visa">Visa</option>
                                            <option value="mastercard">
                                                Master Card
                                            </option>
                                            <option value="american-express">
                                                American Express
                                            </option>
                                        </select>
                                        {formik.touched.card_type &&
                                            formik.errors.card_type && (
                                                <div className="invalid-feedback">
                                                    {formik.errors.card_type}
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <div className="col-md-8 fv-row">
                                        <label className="required fs-6 fw-semibold form-label mb-2">
                                            Expiration Month
                                        </label>
                                        <div className="row fv-row">
                                            <div className="col-6">
                                                <select
                                                    {...formik.getFieldProps(
                                                        "exp_month"
                                                    )}
                                                    className={`form-control ${
                                                        formik.touched
                                                            .exp_month &&
                                                        formik.errors.exp_month
                                                            ? "is-invalid"
                                                            : ""
                                                    } ${
                                                        formik.touched
                                                            .exp_month &&
                                                        !formik.errors.exp_month
                                                            ? "is-valid"
                                                            : ""
                                                    }`}
                                                >
                                                    <option>
                                                        Select Month
                                                    </option>
                                                    <option value="01">
                                                        01
                                                    </option>
                                                    <option value="02">
                                                        02
                                                    </option>
                                                    <option value="03">
                                                        03
                                                    </option>
                                                    <option value="04">
                                                        04
                                                    </option>
                                                    <option value="05">
                                                        05
                                                    </option>
                                                    <option value="06">
                                                        06
                                                    </option>
                                                    <option value="07">
                                                        07
                                                    </option>
                                                    <option value="08">
                                                        08
                                                    </option>
                                                    <option value="09">
                                                        09
                                                    </option>
                                                    <option value="10">
                                                        10
                                                    </option>
                                                    <option value="11">
                                                        11
                                                    </option>
                                                    <option value="12">
                                                        12
                                                    </option>
                                                </select>
                                                {formik.touched.exp_month &&
                                                    formik.errors.exp_month && (
                                                        <div className="invalid-feedback">
                                                            {
                                                                formik.errors
                                                                    .exp_month
                                                            }
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="col-6">
                                                <select
                                                    {...formik.getFieldProps(
                                                        "exp_year"
                                                    )}
                                                    className={`form-control ${
                                                        formik.touched
                                                            .exp_year &&
                                                        formik.errors.exp_year
                                                            ? "is-invalid"
                                                            : ""
                                                    } ${
                                                        formik.touched
                                                            .exp_year &&
                                                        !formik.errors.exp_year
                                                            ? "is-valid"
                                                            : ""
                                                    }`}
                                                >
                                                    <option>Select Year</option>
                                                    <option value="2023">
                                                        2023
                                                    </option>
                                                    <option value="2024">
                                                        2024
                                                    </option>
                                                    <option value="2025">
                                                        2025
                                                    </option>
                                                    <option value="2026">
                                                        2026
                                                    </option>
                                                    <option value="2027">
                                                        2027
                                                    </option>
                                                    <option value="2028">
                                                        2028
                                                    </option>
                                                    <option value="2029">
                                                        2029
                                                    </option>
                                                    <option value="2030">
                                                        2030
                                                    </option>
                                                    <option value="2031">
                                                        2031
                                                    </option>
                                                    <option value="2032">
                                                        2032
                                                    </option>
                                                    <option value="2033">
                                                        2033
                                                    </option>
                                                </select>
                                                {formik.touched.exp_year &&
                                                    formik.errors.exp_year && (
                                                        <div className="invalid-feedback">
                                                            {
                                                                formik.errors
                                                                    .exp_year
                                                            }
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 fv-row">
                                        <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                            CVV/CVC
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                type="text"
                                                {...formik.getFieldProps("cvc")}
                                                className={`form-control ${
                                                    formik.touched.cvc &&
                                                    formik.errors.cvc
                                                        ? "is-invalid"
                                                        : ""
                                                } ${
                                                    formik.touched.cvc &&
                                                    !formik.errors.cvc
                                                        ? "is-valid"
                                                        : ""
                                                }`}
                                                placeholder="CVC"
                                            />
                                            {formik.touched.cvc &&
                                                formik.errors.cvc && (
                                                    <div className="invalid-feedback">
                                                        {formik.errors.cvc}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mb-7 fv-row">
                                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                        Coupon Code
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            formik.touched.coupon &&
                                            formik.errors.coupon
                                                ? "is-invalid"
                                                : ""
                                        } ${
                                            formik.touched.coupon &&
                                            !formik.errors.coupon
                                                ? "is-valid"
                                                : ""
                                        }`}
                                        placeholder="Coupon Code"
                                        {...formik.getFieldProps("coupon")}
                                        autoComplete="off"
                                        disabled={formik.isSubmitting}
                                        onBlur={(e) => handleVerify(e)}
                                    />
                                    {formik.touched.coupon &&
                                        formik.errors.coupon && (
                                            <div className="invalid-feedback">
                                                {formik.errors.coupon}
                                            </div>
                                        )}
                                    {verificationMessage.msg != undefined && (
                                        <Alert
                                            variant={
                                                verificationMessage?.status ===
                                                1
                                                    ? "success"
                                                    : "danger"
                                            }
                                            className="mt-3 d-flex align-items-center"
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    verificationMessage?.status ===
                                                    1
                                                        ? faCheckCircle
                                                        : faTimesCircle
                                                }
                                                className="me-2"
                                            />
                                            {verificationMessage.msg}
                                        </Alert>
                                    )}
                                </div>
                                <div className="d-flex flex-stack">
                                    <div className="me-5">
                                        <label className="fs-6 fw-semibold form-label">
                                            Save Card for further billing?
                                        </label>
                                        <div className="fs-7 fw-semibold text-muted">
                                            If you need more info, please check
                                            budget planning
                                        </div>
                                    </div>
                                    <label className="form-check form-switch form-check-custom form-check-solid ml-auto">
                                        <input
                                            type="checkbox"
                                            {...formik.getFieldProps(
                                                "save_card"
                                            )}
                                            className={`form-check-input ${
                                                formik.touched.save_card &&
                                                formik.errors.save_card
                                                    ? "is-invalid"
                                                    : ""
                                            } ${
                                                formik.touched.save_card &&
                                                !formik.errors.save_card
                                                    ? "is-valid"
                                                    : ""
                                            }`}
                                            checked={formik.values.save_card}
                                        />
                                        <span className="form-check-label fw-semibold text-muted ml-5">
                                            Save Card
                                        </span>
                                    </label>
                                </div>
                                <div className="text-center pt-15">
                                    <button
                                        type="button"
                                        className="btn btn-light me-3"
                                        onClick={() =>
                                            (window.location.href = "/Billing")
                                        }
                                    >
                                        Back
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        <span className="indicator-label">
                                            Submit
                                        </span>
                                        {formik.isSubmitting && (
                                            <span className="indicator-progress">
                                                Please wait...{" "}
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AddCardStep;
