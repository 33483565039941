import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/Overlay";
import { useLocation } from "react-router-dom";

interface IProps {
    commonPath: string;
    title: string;
    popover: React.FC<OverlayInjectedProps>;
}

const LinkWithPopover: React.FC<IProps> = ({ commonPath, title, popover }) => {
    const location = useLocation();

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom"
            overlay={popover}
        >
            <h6
                className={`nav-bar__link-popover cursor-pointer pt-6 fw-bold ${
                    location.pathname.includes(commonPath)
                        ? "nav-bar__link-active"
                        : ""
                }`}
            >
                {title}
            </h6>
        </OverlayTrigger>
    );
};

export default LinkWithPopover;
