import * as React from "react";
import { Guest } from "../../types/Guest";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { actions } from "../../store/reducers/partySlice";
// import { useHistory } from "react-router-dom";
import {
    faEllipsisH,
    faPaperPlane,
    // faEnvelope,
    faTrashAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { resetCreateContactFormError } from "../../store/reducers/contactSlice";

import { useParams } from "react-router-dom";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { fetchSelectedPartyGuestsPaginate } from '../../store/asyncActions/party';
import { confirmAlert } from "react-confirm-alert";

interface IProps {
    guest: Guest;
}

const ManageGuestPopover: React.FC<IProps> = ({ guest }) => {
    // const [showSuccessToast, setShowSuccessToast] = React.useState(false);
    // const [showErrorToast, setShowErrorToast] = React.useState(false);
    // const [errorMessage, setErrorMessage] = React.useState("");
    const dispatch = useTypedDispatch();

    const param: { id?: string } = useParams();

    const { removeGuestFromParty, fetchSelectedPartyGuests, resendOptInMessage } = actions;
    const { openGuestProfileModal, openSaveGuestContactModal } = modalActions; 
    const handleOpenSaveGuestContactModal = (guest: any) => {
        dispatch(resetCreateContactFormError());
        dispatch(openSaveGuestContactModal(guest));
    };

    const handleReSendOptInMessage = async (guestId: number) => {
        await dispatch(resendOptInMessage({ guestId }));
    };

    const handleRemoveFunction = async (id: any) => {
        try {
            const promiseResult = await dispatch(
                removeGuestFromParty({ guestId: id })
            );

            await unwrapResult(promiseResult);
            if (promiseResult.payload.success) {
                dispatch(
                    fetchSelectedPartyGuestsPaginate({
                        partyId: param?.id ? Number(param.id) : 0,
                    })
                );
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: "Guest succcessfully deleted from the party",
                    })
                );
            }  else {
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText:
                            "Please delete associated data to this guest then try again",
                    })
                );
            }
        } catch (err) {
            dispatch(
                showSnackbarWithAutoHide({
                    msgText:
                        "Please delete associated data to this guest then try again",
                })
            );
        }
    }; 
    // const { removeGuestFromParty } = actions;
    const items: {
        title: string;
        action:
        | (() => { payload: Guest; type: "modals/openGuestProfileModal" })
        | (() => void)
        | (() => Promise<void>);
        icon: JSX.Element;
    }[] = [];
    // {
    //     title: "Message Guest",
    //     action: () =>
    //         history.push(`/direct_messages?thread=${guest.msgThreadId}`),
    //     icon: (
    //         <FontAwesomeIcon
    //             icon={faEnvelope}
    //             className="text-success w-15px h-15px mr-2"
    //         />
    //     ),
    // },
    items.push({
        title: "View Guest Profile",
        action: () => dispatch(openGuestProfileModal(guest)),
        icon: (
            <FontAwesomeIcon
                icon={faUser}
                className="text-primary w-15px h-15px mr-2"
            />
        ),
    });
    !guest.isHost &&
        items.push({
            title: "Save Guest as Contact",
            action: () => handleOpenSaveGuestContactModal(guest),
            icon: (
                <FontAwesomeIcon
                    icon={faUser}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        });
    !guest.isHost && !guest.texters.isActive &&
        items.push({
            title: "Resend opt-in",
            action: () => confirmAlert({
                title: "Confirm to sent opt-in notification",
                message: "Are you sure you want to send notification to the guest?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: async () => await handleReSendOptInMessage(guest.id)
                    },
                    {
                        label: "No",
                        onClick: () => console.info("No."),
                    },
                ],
            }),
            icon: (
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        });
    !guest.isHost  &&
        items.push({
            title: "Remove from Event", 
            action: () => confirmAlert({
                title: "Confirm to delete",
                message: "Are you sure you want to delete this guest?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: async () => await handleRemoveFunction(guest.id)
                    },
                    {
                        label: "No",
                        onClick: () => console.info("No."),
                    },
                ],
            }),
            icon: (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger w-15px h-15px mr-2"
                />
            ),
        });
    const renderPopover = (props: any) => (
        <>
            <Popover id="manage-script-popover" {...props}>
                <Popover.Content className="p-3">
                    <ul className="p-0 m-0">
                        {items.map((item) => (
                            <li
                                key={item.title}
                                onClick={() => item.action()}
                                className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                            >
                                {item.icon}
                                <p className="p3 mb-0">{item.title}</p>
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover>
        </>
    );

    return (
        <>
            <OverlayTrigger
                rootClose
                trigger="click"
                overlay={renderPopover}
                placement="right"
            >
                <FontAwesomeIcon
                    icon={faEllipsisH}
                    className="w-25px h-20px cursor-pointer"
                />
            </OverlayTrigger>
        </>
    );
};

export default ManageGuestPopover;
