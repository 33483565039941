import React, { useEffect, useState } from "react";
import { getBillingHistory } from "./_requests";
import dayjs from "dayjs";
import { TransactionModel } from "../../../../types/TransactionModel";
type Props = {
    historyFilter: string;
};

const BillingHistoryList: React.FC<Props> = ({ historyFilter }) => {
    const [historyData, setHistoryData] = useState<
        Array<TransactionModel> | undefined
    >([]);
    useEffect(() => {
        gethistoryData();
    }, [historyFilter]);

    async function gethistoryData() {
        await getBillingHistory(historyFilter)
            .then((data) => {
                setHistoryData(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <div className="table-responsive">
            <table className="table table-row-bordered align-middle gy-4 gs-9">
                <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light bg-opacity-75">
                    <tr>
                        <td className="min-w-150px">Date</td>
                        <td className="min-w-250px">Description</td>
                        <td className="min-w-150px">Amount</td>
                        <td className="min-w-150px">Invoice</td>
                    </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                    {historyData?.map((history: TransactionModel) => (
                        <tr key={history.id}>
                            <td>
                                {dayjs(
                                    new Date(history?.created * 1000)
                                ).format("MMM D, YYYY")}
                            </td>
                            <td>
                                <a href="#">{history?.description}</a>
                            </td>
                            <td>${(history.amount / 100).toFixed(2)}</td>
                            <td>
                                <a
                                    href={history?.receipt_url}
                                    target={"_blank"}
                                    className="btn btn-sm btn-light btn-active-light-primary"
                                    rel="noreferrer"
                                >
                                    PDF
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BillingHistoryList;
