import * as React from "react";
import { FormikConfig, FormikValues } from "formik";

export type FormStepProps = Pick<
    FormikConfig<FormikValues>,
    "children" | "validationSchema"
>;

const FormStep: React.FC<FormStepProps> = ({ children }) => {
    return <>{children}</>;
};

export default FormStep;
