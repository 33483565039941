import { FormikErrors } from "formik";
import { ServerError } from "../types/Error";

const transformToFormikErrors = (
    errors: ServerError
): FormikErrors<unknown> => {
    if (Array.isArray(errors))
        return errors.reduce(
            (acc, error) => ({
                ...acc,
                [error.property]: Object.values(error.constrains)[0],
            }),
            {}
        );
    else return {};
};

export default transformToFormikErrors;
