import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { FormAction } from "../../types/Common";
import AnnouncementContactForm from "../forms/AnnouncementContactForm";
import AnnouncementForm from "../forms/AnnouncementForm";
import UpdatePartyForm from "../forms/UpdatePartyForm";

const AnnouncementFormModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { closeAnncounmentModal } = modalActions;
    const action = useTypedSelector(
        (state) => state.modals.announcementModal.action
    );
    const show = useTypedSelector(
        (state) => state.modals.announcementModal.isOpen
    );

    const handleClose = useCallback(() => {
        dispatch(closeAnncounmentModal());
    }, []);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                {action === FormAction.CREATE ? (
                    <AnnouncementForm />
                ) : action === FormAction.MAP ? (
                    <AnnouncementContactForm />
                ) : (
                    <UpdatePartyForm />
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AnnouncementFormModal;
