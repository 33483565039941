import dayjs from "dayjs";
import { Formik } from "formik";
import React, { ChangeEvent } from "react";
import * as Yup from "yup";

import phone from "phone";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { isStartDateBeforeEndDate } from "../../helpers/isStartDateBeforeEndDate";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { actions } from "../../store/reducers/partySlice";
import { NewPartyFormValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";
const phoneRegExpUS = /^\+1\d{10}$/;
const phoneRegExpIndia = /^\+91\d{10}$/;
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Event name is required")
        .max(150, "Event name can't be that long"),
    host: Yup.object()
        .shape({
            nameFirst: Yup.string()
                .required("Host first name is required")
                .max(50, "Host name is too long"),
            nameLast: Yup.string()
                .required("Host last name is required")
                .max(50, "Host name is too long"),
            email: Yup.string()
                .required("Host email is required")
                .email("Email is not valid"),
            phoneCell: Yup.string()
                .required("Cell Number is required")
                .test(
                    "is-valid-phone-number",
                    "Cell number is invalid",
                    (val) => {
                        if (!val) return false;
                        return (
                            phoneRegExpUS.test(val) ||
                            phoneRegExpIndia.test(val)
                        );
                    }
                )
                .nullable(),
        })
        .nullable(),
    partyStartDate: Yup.date()
        .required("Start date is required")
        .min(
            dayjs().subtract(1, "day").toDate(),
            "You can't create events in the past"
        )
        .max(dayjs().add(2, "years").toDate(), "Too far into the future"),
    partyStartTime: Yup.string().required("Event time is required"),
    partyEndDate: Yup.date()
        .required("End date is required")
        .min(Yup.ref("partyStartDate"), "End date should be after start date")
        .max(dayjs().add(2, "years").toDate(), "Too far into the future"),
    partyEndTime: Yup.string()
        .required("Event time is required")
        .test(
            "is-not-in-the-past",
            "End time should be after start time",
            (val, testContext) =>
                isStartDateBeforeEndDate({
                    ...testContext.parent,
                    partyEndTime: val || "",
                })
        ),
});

const initialValues: NewPartyFormValues = {
    name: "",
    host: {
        nameLast: "",
        nameFirst: "",
        email: "",
        phoneCell: "",
    },
    partyStartDate: dayjs().format("YYYY-MM-DD"),
    partyStartTime: dayjs()
        .set("hour", dayjs().add(1, "hour").hour())
        .set("minutes", 0)
        .format("HH:mm"),
    partyEndDate: dayjs().format("YYYY-MM-DD"),
    partyEndTime: dayjs()
        .set("hour", dayjs().add(2, "hour").hour())
        .set("minutes", 0)
        .format("HH:mm"),
};

const NewPartyForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const gatewayId = useTypedSelector(
        (state) => state.auth.currentUser?.personalGatewayId
    );
    const formErrors = useTypedSelector(
        (state) => state.party.createNewPartyForm.error
    );

    const { createParty, createGuestForPartySellerOnly } = actions;
    const { closePartyModal } = modalActions;

    const submitForm = (values: NewPartyFormValues) => {
        return dispatch(createParty(values))
            .unwrap()
            .then((data) => {
                dispatch(
                    createGuestForPartySellerOnly({
                        ...values.host,
                        isHost: true,
                        nameAlias: `${values.host.nameFirst} ${values.host.nameLast}`,
                        gatewayId: Number(gatewayId || 0),
                        msgThreadId: Number(data.id),
                        phoneCountryCode:
                            phone(values.host.phoneCell).countryIso3 || "",
                    })
                );
                return data;
            })
            .then((data) => {
                history.push(`/manage_parties/party/${data.id}`);
                dispatch(closePartyModal());
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitForm(values).finally(() => {
                    resetForm();
                    setSubmitting(false);
                });
            }}
        >
            {({
                handleSubmit,
                dirty,
                isSubmitting,
                isValid,
                setFieldValue,
            }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column justify-content-between h-100"
                >
                    <div>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Unique Event Name..."
                                    type="text"
                                    label="Event Name"
                                    name="name"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="Host First Name"
                                    type="text"
                                    label="Host First Name"
                                    name="host.nameFirst"
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="Host Last Name"
                                    type="text"
                                    label="Host Last Name"
                                    name="host.nameLast"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="(888) 888-8888"
                                    type="tel"
                                    className="py-4"
                                    maxLength={16}
                                    label="Host Cell Phone"
                                    name="host.phoneCell"
                                    required={true}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "host.phoneCell",
                                            phone(e.target.value).phoneNumber,
                                            true
                                        )
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="email@email.com"
                                    type="text"
                                    label="Host Email"
                                    name="host.email"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder=""
                                    type="date"
                                    label="Event Start Date"
                                    name="partyStartDate"
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group
                                id="customInputContainerTime"
                                className="col-md-6"
                            >
                                <CustomInput
                                    placeholder="10:00 PM"
                                    type="time"
                                    label="Start Time"
                                    className="timepicker"
                                    name="partyStartTime"
                                    required={true}
                                    onInput={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const startTime = e.target.value;
                                        const partyStartTime = dayjs(
                                            initialValues.partyStartDate +
                                                " " +
                                                startTime
                                        );
                                        const partyEndTime = partyStartTime.add(
                                            1,
                                            "hour"
                                        );
                                        setFieldValue(
                                            "partyEndTime",
                                            partyEndTime.format("HH:mm")
                                        );
                                    }}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder=""
                                    type="date"
                                    label="Event End Date"
                                    name="partyEndDate"
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group
                                id="customInputContainerTime"
                                className="col-md-6"
                            >
                                <CustomInput
                                    placeholder="10:00 PM"
                                    type="time"
                                    label="End Time"
                                    className="timepicker"
                                    name="partyEndTime"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>

                    <FormErrors errors={formErrors} />

                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                        >
                            Create New Event
                        </Button>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default NewPartyForm;
