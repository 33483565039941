import React from "react";
import { ICalendarCardProps } from "./SmallCalendarCard";
import CalendarView from "../calendar/CalendarView";
import LayoutCard from "../../layouts/LayoutCard";

const BigCalendarCard: React.FC<ICalendarCardProps> = ({
    calendarRef,
    dateClick,
}) => {
    return (
        <LayoutCard>
            <CalendarView
                variant="big"
                calendarRef={calendarRef}
                dateClick={dateClick}
            />
        </LayoutCard>
    );
};

export default BigCalendarCard;
