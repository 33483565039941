import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "../components/navBar/NavBar";

const DefaultLayout: React.FC = ({ children }) => {
    return (
        <>
            <NavBar />
            <Container className="bg-transparent h-min-100 pb-5" fluid="lg">
                {children}
            </Container>
        </>
    );
};

export default DefaultLayout;
