import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Script, ScriptType } from "../../types/Script";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { actions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import PrepopulatedMsgScriptsList from "./PrepopulatedMsgScriptsList";

interface IProps {
    type: ScriptType;
}

const PrepopulatedMsgScriptsListView: React.FC<IProps> = ({ type }) => {
    const dispatch = useTypedDispatch();
    const { fetchScripts } = actions;
    const { openCreateScriptModal } = modalActions;
    const [filteredScriptsList, setScriptsList] = useState<Script[]>([]);
    const { list, isLoading ,page,limit,search} = useTypedSelector(
        (state) => state.automations.scripts
    );

    useEffect(() => {
        dispatch(fetchScripts({_page: page, _limit: limit,search}));
    }, []);

    const openModal = useCallback(() => {
        dispatch(
            openCreateScriptModal({
                type,
            })
        );
    }, []);

    const singleTypeScriptsList = useMemo(
        () => list.items.filter((script: any) => script.scripttype.name === type),
        [list]
    );

    const SearchField = useTypedSearchField<Script>({
        inputPlaceholder: "Search Here...",
        isLoading,
        btnOnClick: openModal,
        list: singleTypeScriptsList,
        setList: setScriptsList,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
    });

    return (
        <div>
            {SearchField}
            <PrepopulatedMsgScriptsList
                items={filteredScriptsList}
                isLoading={isLoading}
                type={type}
            />
        </div>
    );
};

export default PrepopulatedMsgScriptsListView;