import React from "react";
import GuestsDirectMessages from "../messages/GuestsDirectMessages";
import TabbedCard from "../../layouts/TabbedCard";

const MessageCard: React.FC = () => {
    return (
        <TabbedCard
            items={[
                {
                    component: <GuestsDirectMessages />,
                    tabTitle: "Messages",
                },
            ]}
            title="Direct Message Guest"
        />
    );
};

export default MessageCard;
