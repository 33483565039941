import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import Snackbar from "../notifications/Snackbar";

const InactiveSellerRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const isAuthenticated = useTypedSelector(
        (state) => state.auth.isAuthenticated
    );
    const isActive = useTypedSelector(
        (state) => state.auth.currentUser?.isActive
    ); 
    if (isActive) return <Redirect to={"/"} />;
    
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    <>
                        <Snackbar />
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                        {children}
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default InactiveSellerRoute;
