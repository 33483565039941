import React, { useEffect, useState } from "react";
import LayoutCard from "../../layouts/LayoutCard";
import {
    Accordion,
    Card,
    OverlayTrigger,
    Table,
    Tooltip,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretRight,
    faCaretDown,
    faCircleInfo,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import { MessageAnalytics } from "../../types/MsgThread";
import { Socket } from "socket.io-client";
import Loading from "../common/Loading";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { actions } from "../../store/reducers/partySlice";
import { useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isArray } from "lodash";
interface Props {
    eventKey: string;
    imageSrc: string;
    title: string;
    componentId: number;
    description?: string;
    counter: string;
    content: any;
    totalCountMsg?: number;
}
interface IProps {
    messageAnalytics: MessageAnalytics[];
    isLoading?: boolean;
    partyId: number;
    socket?: Socket;
}

interface ManageSinglePartyParams {
    id: string;
}

const AccordionItem: React.FC<Props> = ({
    eventKey,
    imageSrc,
    title,
    componentId,
    description,
    counter,
    content,
    totalCountMsg,
}) => {
    const [openAccordion, setOpenAccordion] = useState();
    const dispatch = useTypedDispatch();
    const { fetchSelectedPartyMessageAnalytics } = actions;
    const toggleAccordion = (msgEventKey: any) => {
        setOpenAccordion((prevState) =>
            prevState === msgEventKey ? "" : msgEventKey
        );
    };

    const isAccordionOpen = (msgEventKey: any) => {
        return openAccordion === msgEventKey;
    };

    const { id } = useParams<ManageSinglePartyParams>();
    const numberId = Number(id);
    useEffect(() => {
        dispatch(
            fetchSelectedPartyMessageAnalytics({
                id: numberId,
            })
        );
    }, []);

    const accordionMessageItems = content?.map((item: any) => { 
        if (componentId === 1) {
            return {
                msgEventKey: `1_${item?.id}`,
                title: `${
                    item?.guest?.nameFirst ||
                    item?.guest?.nameLast ||
                    item?.guest?.nameAlias
                        ? `${item?.guest?.nameFirst || ""} ${
                              item?.guest?.nameLast || ""
                          } [${item?.guest?.nameAlias || ""}]`
                        : "Not found"
                }`,
                msgcontent: item?.messages || "No messages",
                counter: item?.messages?.length,
            };
        } else if (componentId === 2 || componentId === 3) {
            return {
                msgEventKey: `1_${item?.id}`,
                title: `${
                    item?.guest?.nameFirst ||
                    item?.guest?.nameLast ||
                    item?.guest?.nameAlias
                        ? `${item?.guest?.nameFirst || ""} ${
                              item?.guest?.nameLast || ""
                          } [${item?.guest?.nameAlias || ""}]`
                        : "Not found"
                }`,
                msgcontent: item?.assetShares,
                counter: item?.assetShares?.length,
            };
        } else {
            return {
                msgEventKey: `1_${item?.id}`,
                title: item?.nameFirst + " (" + item?.email + ")",
                counter: undefined,
            };
        }
    });

    const popupInfo = (clickCount: number) => {
        return (
            <OverlayTrigger
                key="bottom"
                placement="bottom"
                delay={{
                    show: 0,
                    hide: 400,
                }}
                overlay={
                    <Tooltip id="bottom">
                        <strong>Numbers of click :</strong> {clickCount}
                    </Tooltip>
                }
            >
                <FontAwesomeIcon className="w-15px ml-4" icon={faCircleInfo} />
            </OverlayTrigger>
        );
    };

    const popupInfoImage = (clickCount: number, assetUrl: string) => { 
        return (
            <>
                <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    delay={{
                        show: 0,
                        hide: 400,
                    }}
                    overlay={
                        <Tooltip id="bottom">
                            <img src={assetUrl} alt="" />
                            <FontAwesomeIcon
                                icon={faEye}
                                style={{
                                    cursor: "pointer",
                                    marginLeft: 10,
                                }}
                                className="mx-1"
                            />
                            {clickCount}
                        </Tooltip>
                    }
                >
                    <FontAwesomeIcon
                        className="w-15px ml-4"
                        icon={faCircleInfo}
                    />
                </OverlayTrigger>
            </>
        );
    };

    return (
        <Card className="py-2">
            <Card.Header>
                <Accordion.Toggle
                    as={Button}
                    variant="secondary"
                    eventKey={eventKey}
                    className="accordion-header"
                >
                    <div className="image-container">
                        <img
                            src={imageSrc}
                            alt="Image"
                            className="image-icon"
                        />
                    </div>
                    <div className="accordion-content mb-4 d-flex justify-content-between">
                        <div>
                            <p className="accordionHead">{title}</p>
                            {description && (
                                <p className="faded">{description}</p>
                            )}
                        </div>
                        <div className="counter">
                            <p> 
                                {counter}
                            </p>
                        </div>
                    </div>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>
                    <Accordion
                        defaultActiveKey={openAccordion}
                        className="partyMessageTree"
                    >
                        {!(accordionMessageItems[0]?.counter === 0) ? (
                            <>
                                {accordionMessageItems?.map((item: any) => {
                                    const counterNumber = parseInt(
                                        item?.counter,
                                        10
                                    );

                                    if (componentId === 4) {
                                        return (
                                            <>
                                                <div className="accordion-content mb-4 d-flex justify-content-between">
                                                    <p
                                                        key={componentId}
                                                        className="accordionSubHead"
                                                    >
                                                        {item?.title}
                                                    </p>
                                                </div>
                                            </>
                                        );
                                    } else {
                                        return (
                                            ((counterNumber > 0 &&
                                                componentId === 1) ||
                                                item?.counter === undefined ||
                                                (counterNumber > 0 &&
                                                    componentId === 2) ||
                                                (counterNumber > 0 &&
                                                    componentId === 3)) && (
                                                <Card key={item?.msgEventKey}>
                                                    <Card.Header>
                                                        <Accordion.Toggle
                                                            as={Button}
                                                            variant="secondary"
                                                            eventKey={
                                                                item?.msgEventKey
                                                            }
                                                            className="accordion-header"
                                                            onClick={() =>
                                                                toggleAccordion(
                                                                    item?.msgEventKey
                                                                )
                                                            }
                                                        >
                                                            <div className="accordion-content mb-4 d-flex justify-content-between">
                                                                <div>
                                                                    <p className="accordionSubHead">
                                                                        {
                                                                            item?.title
                                                                        }
                                                                    </p>
                                                                </div>
                                                                {item?.counter !=
                                                                    null && (
                                                                    <div className="counter d-flex justify-content-between">
                                                                        <p>
                                                                            {
                                                                                item?.counter
                                                                            }
                                                                        </p>
                                                                        <FontAwesomeIcon
                                                                            style={{
                                                                                fontSize:
                                                                                    "12px",
                                                                            }}
                                                                            icon={
                                                                                isAccordionOpen(
                                                                                    item?.msgEventKey
                                                                                )
                                                                                    ? faCaretDown
                                                                                    : faCaretRight
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse
                                                        eventKey={
                                                            item?.msgEventKey
                                                        }
                                                    >
                                                        <Card.Body
                                                            style={{
                                                                overflowY:
                                                                    "auto",
                                                                height: "200px",
                                                            }}
                                                        >
                                                            <PerfectScrollbar>
                                                                <MessageTable
                                                                    messages={
                                                                        item?.msgcontent
                                                                    }
                                                                    componentId={
                                                                        componentId
                                                                    }
                                                                />
                                                            </PerfectScrollbar>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        );
                                    }
                                })}
                            </>
                        ) : (
                            <span>No data</span>
                        )}
                    </Accordion>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

interface IMessageTable {
    messages: any[];
    componentId: number;
}

const MessageTable: React.FC<IMessageTable> = ({ messages, componentId }) => {
    return (
        <Table bordered hover>
            <thead>
                <tr>
                    {/* {componentId === 1 && <th>Received Message</th>} */}
                    {(componentId === 2 || componentId === 3) && (
                        <th>Message</th>
                    )}
                    {(componentId === 2 || componentId === 3) && (
                        <th>
                            {componentId === 2
                                ? "No. of Clicks"
                                : "No. of Views"}
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {isArray(messages) &&
                    messages.map((message) => (
                        <tr key={message.id}>
                            <td>
                                <div className="text-dark">
                                    {componentId === 1 && (
                                        <span>{message?.messageText}</span>
                                    )}
                                    {componentId === 3 ? (
                                        <img
                                            src={message?.assetUrl}
                                            alt=""
                                            width={200}
                                        />
                                    ) : componentId === 2 ? (
                                        <span>{message?.messageText}</span>
                                    ) : null}
                                </div>
                            </td>
                            {(componentId === 2 || componentId === 3) && (
                                <td>
                                    <div className="text-dark">
                                        {message?.clickCount}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
};

const PartyTreeCard: React.FC<IProps> = ({
    messageAnalytics,
    isLoading,
    partyId,
    socket,
}) => { 
    const accordionItems = messageAnalytics.map((item) => {
        let description = "";
        let counter = 0;

        if (item?.data?.length > 0) {
            description = item?.label;
            counter = item?.id == 1 ? item.totalCountMsg : item?.data?.length;
        }

        return {
            eventKey: item?.id.toString(),
            imageSrc: `/media/${item?.name
                .toLowerCase()
                .replace(" ", "-")}-removebg-preview.png`,
            title: item?.name,
            componentId: item?.id,
            description: description,
            counter: counter.toString(),
            content: item?.data,
            totalCountMsg: item?.totalCountMsg,
        };
    });

    return (
        <LayoutCard>
            <h3>Results</h3>
            {isLoading ? (
                <Loading />
            ) : (
                <Accordion defaultActiveKey="1" className="partyTree">
                    {accordionItems.map((item) => (
                        <AccordionItem
                            key={item?.eventKey}
                            eventKey={item?.eventKey}
                            imageSrc={item?.imageSrc}
                            title={item?.title}
                            componentId={item?.componentId}
                            description={item?.description}
                            counter={item?.counter}
                            content={item?.content}
                            totalCountMsg={item?.totalCountMsg}
                        />
                    ))}
                </Accordion>
            )}
        </LayoutCard>
    );
};

export default PartyTreeCard;
