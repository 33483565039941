import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { getFileUrl } from "../../../helpers/getFileUrl";
import { FileWithPreview } from "../../../types/Common";
import Loading from "../../common/Loading";
const default_logo_company = "../assets/media/images/default_logo_company.png";

interface IProps {
    initialValue: string;
    valueSetter: (link: string) => void;
    btnText: string;
    customStyles?: string;
    isLoading?: boolean;
    isLoadingLogo?: boolean;
    hideButton: boolean;
    fileSetter: (file: FileWithPreview | undefined) => void;
    imgDeleted?: () => void;
    isDefaultLogo?: boolean;
    defaultLogo?: string;
}

const InputForImageUploading: React.FC<IProps> = ({
    btnText,
    customStyles,
    valueSetter,
    initialValue,
    isLoading,
    isLoadingLogo,
    fileSetter,
    hideButton,
    imgDeleted,
    isDefaultLogo,
    defaultLogo,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [picUrl, setPicUrl] = useState(
        initialValue ? getFileUrl(initialValue) : ""
    );

    React.useEffect(() => {
        setPicUrl(initialValue ? initialValue : "");
    }, [initialValue]);

    const openFileSelect = () => {
        fileInputRef.current?.click();
    };

    const setNewFileLink = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileToSelect = e.target.files?.[0] as FileWithPreview;
        if (!fileToSelect) return;
        fileToSelect.preview = URL.createObjectURL(fileToSelect);
        setPicUrl(fileToSelect.preview);
        valueSetter(fileToSelect.preview);
        fileSetter(fileToSelect);
    };

    const deleteImage = async () => {
        fileSetter(undefined);
        if (imgDeleted && defaultLogo) {
            imgDeleted();
            setPicUrl(defaultLogo);
        }
    };

    const editImage = () => {
        openFileSelect();
    };
    const shimmerStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: '#f6f7f8',
        backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        animation: 'shimmer 2s infinite',
        zIndex: 2,
    };
    
    const shimmerKeyframes = `
    @keyframes shimmer {
        0% {
            background-position: -100% 0;
        }
        100% {
            background-position: 100% 0;
        }
    }`;

    return (
        <div className="d-flex">
           <div className="position-relative overflow-hidden d-flex align-items-center">
                <style>{shimmerKeyframes}</style>
                {(isLoading || isLoadingLogo) && (
                    <div style={shimmerStyle} />
                )}     
                 
                <div className="position-relative">
                    <img
                        className={`d-flex justify-content-center align-items-center text-center overflow-hidden bg-gray-300 ${customStyles}`}
                        src={picUrl}
                        alt="No image"
                    />
                    {hideButton ? (
                        <div
                            className="position-absolute top-0 end-0 translate-middle bg-primary rounded-circle p-2"
                            onClick={editImage}
                            style={{ cursor: "pointer", marginLeft: "125px" }}
                        >
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-white"
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="ml-4 d-flex flex-column justify-content-center">
                <input
                    className="d-none"
                    ref={fileInputRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={setNewFileLink}
                />
                {!hideButton ? (
                    <>
                        <Button
                            variant="primary"
                            onClick={() => openFileSelect()}
                            className="my-2"
                            disabled={isLoading}
                        >
                            {btnText}
                        </Button>
                        <Button
                            disabled={isLoading || isDefaultLogo}
                            variant="secondary"
                            onClick={() => deleteImage()}
                            className="my-2"
                        >
                            Delete Image
                        </Button>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default InputForImageUploading;
