import * as React from "react";

function SvgSpartyLogo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 108.3 122.21"
            width="1em"
            height="1em"
            {...props}
        >
            <g data-name="Layer 2">
                <path
                    d="M108.3 36.3v-4.59A36.14 36.14 0 0072.44 0H36.13A36.14 36.14 0 000 36.13v3.7l.19.17a36.13 36.13 0 0035.75 32.44h.07H0v4.59a36.15 36.15 0 0029.4 31.13v14l20.13-13.42h22.64a36.12 36.12 0 0036.13-36.08V69l-.19-.17a36.13 36.13 0 00-35.75-32.47l-.06-.06h36zM42.63 93.74a4 4 0 114-4 4 4 0 01-4 4zm13.36 0a4 4 0 114-4 4 4 0 01-3.99 4zm17.42-4a4 4 0 11-4-4 4 4 0 014 3.96z"
                    fill="currentColor"
                    data-name="Layer 1"
                />
            </g>
        </svg>
    );
}

export default SvgSpartyLogo;
