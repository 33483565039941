import * as React from "react";
import useQueryParams from "../../hooks/useQueryParams";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import MessagePreview from "./MessagePreview";

const ScriptPreview: React.FC = () => {
    const searchParams = useQueryParams();
    const workflowId = Number(searchParams.get("workflow"));
    const messageId = Number(searchParams.get("message"));

    const msgTemplatesList = useTypedSelector(
        (state) => state.automations.messageTemplates.list
    );
    // const isLoading = useTypedSelector(
    //     (state) => state.automations.messageTemplates.isLoading
    // );

    const scriptId = workflowId || messageId;

    if (!scriptId)
        return <p className="w-100 text-center">Select to preview</p>;

    return (
        <div className="d-flex flex-column align-items-end">
            {/* {isLoading ? (
                <Loading />
            ) : ( */}
                <>
                    {msgTemplatesList.map((msg) => (
                        <MessagePreview key={msg.id} msg={msg} link={msg.link} />
                    ))}
                </>
            {/* )} */}
        </div>
    );
};

export default ScriptPreview;
