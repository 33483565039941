import React from "react";

const Log: React.FC = () => {
    return (
        <>
            <div className="card mb-5 mb-xl-10 mt-4">
                <div className="card-header cursor-pointer">
                    <div className="d-flex justify-content-between">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">Log Details</h3>
                        </div>
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-between">
                                <div className="my-1 me-4">
                                    <select
                                        className="form-select form-select-sm form-select-solid w-125px select2-hidden-accessible p-3"
                                        data-control="select2"
                                        data-placeholder="Select Hours"
                                        data-hide-search="true"
                                        data-select2-id="select2-data-10-3deb"
                                        tabIndex={-1}
                                        aria-hidden="true"
                                        data-kt-initialized="1"
                                    >
                                        <option
                                            value="1"
                                            selected={false}
                                            data-select2-id="select2-data-12-wv9t"
                                        >
                                            1 Hours
                                        </option>
                                        <option value="2">6 Hours</option>
                                        <option value="3">12 Hours</option>
                                        <option value="4">24 Hours</option>
                                    </select>
                                </div>
                                <a
                                    href="#"
                                    className="btn btn-sm btn-primary my-1"
                                >
                                    View All
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body p-9">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                                <tr>
                                    <th className="min-w-250px">Location</th>
                                    <th className="min-w-100px">Status</th>
                                    <th className="min-w-150px">Device</th>
                                    <th className="min-w-150px">IP Address</th>
                                    <th className="min-w-150px">Time</th>
                                </tr>
                            </thead>

                            <tbody className="fw-6 fw-semibold text-gray-600">
                                <tr>
                                    <td>
                                        <a
                                            href="#"
                                            className="text-hover-primary text-gray-600"
                                        >
                                            USA(5)
                                        </a>
                                    </td>

                                    <td>
                                        <span className="badge badge-success fs-7 fw-bold">
                                            OK
                                        </span>
                                    </td>

                                    <td>Chrome - Windows</td>

                                    <td>236.125.56.78</td>

                                    <td>2 mins ago</td>
                                </tr>
                                <tr>
                                    <td>
                                        <a
                                            href="#"
                                            className="text-hover-primary text-gray-600"
                                        >
                                            United Kingdom(10)
                                        </a>
                                    </td>

                                    <td>
                                        <span className="badge badge-success fs-7 fw-bold">
                                            OK
                                        </span>
                                    </td>

                                    <td>Safari - Mac OS</td>

                                    <td>236.125.56.78</td>

                                    <td>10 mins ago</td>
                                </tr>
                                <tr>
                                    <td>
                                        <a
                                            href="#"
                                            className="text-hover-primary text-gray-600"
                                        >
                                            Norway(-)
                                        </a>
                                    </td>

                                    <td>
                                        <span className="badge badge-danger fs-7 fw-bold">
                                            ERR
                                        </span>
                                    </td>

                                    <td>Firefox - Windows</td>

                                    <td>236.125.56.10</td>

                                    <td>20 mins ago</td>
                                </tr>
                                <tr>
                                    <td>
                                        <a
                                            href="#"
                                            className="text-hover-primary text-gray-600"
                                        >
                                            Japan(112)
                                        </a>
                                    </td>

                                    <td>
                                        <span className="badge badge-success fs-7 fw-bold">
                                            OK
                                        </span>
                                    </td>

                                    <td>iOS - iPhone Pro</td>

                                    <td>236.125.56.54</td>

                                    <td>30 mins ago</td>
                                </tr>
                                <tr>
                                    <td>
                                        <a
                                            href="#"
                                            className="text-hover-primary text-gray-600"
                                        >
                                            Italy(5)
                                        </a>
                                    </td>

                                    <td>
                                        <span className="badge badge-warning fs-7 fw-bold">
                                            WRN
                                        </span>
                                    </td>

                                    <td>Samsung Noted 5- Android</td>

                                    <td>236.100.56.50</td>

                                    <td>40 mins ago</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Log;
