import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LegalInformationLayout from "../../layouts/LegalInformationLayout";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/staticPage";

const AllLegalPage: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { fetchStaticPage } = actions;
    const { data, isLoading } = useTypedSelector(
        (state) => state.staticPage.staticPage
    );
    const param = useParams();

    useEffect(() => {
        dispatch(
            fetchStaticPage({
                slug__: (param as { pageType?: string })?.pageType,
            })
        );
    }, [dispatch, param]);

    return (
        <LegalInformationLayout>
            <div style={{ overflow: "auto", fontSize:"14px"}}>
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <p className="h1">
                            Sparty{" "}
                            {data?.slug &&
                                data.slug
                                    .split("-")
                                    .map(
                                        (word: any) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                    )
                                    .join(" ")}
                        </p>

                        <p className="h2">
                            Last Updated:{" "}
                            {data?.updatedAt
                                ? new Date(data.updatedAt).toLocaleDateString(
                                      "en-US",
                                      {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                      }
                                  )
                                : "N/A"}
                        </p>

                        {data && (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: data?.content,
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </LegalInformationLayout>
    );
};

export default AllLegalPage;
