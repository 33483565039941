import * as React from "react";

function SvgImageicon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill="#3699FF" d="M0 0h24v24H0z" />
            <path
                d="M19.429 6H4.57A.58.58 0 004 6.59v11.82c0 .326.255.59.571.59H19.43a.58.58 0 00.571-.59V6.59a.58.58 0 00-.571-.59zM8.893 8.66c.63 0 1.143.53 1.143 1.18 0 .653-.513 1.183-1.143 1.183S7.75 10.493 7.75 9.84c0-.652.512-1.182 1.143-1.182zm9.177 8.07a.141.141 0 01-.093.036H6.02a.146.146 0 01-.142-.148c0-.035.012-.068.034-.096l3.04-3.73a.14.14 0 01.202-.019c.006.006.013.011.018.019l1.775 2.179 2.823-3.463a.14.14 0 01.202-.018c.006.005.013.011.018.018l4.1 5.016a.154.154 0 01-.021.206z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgImageicon;
