import * as React from "react";
import { MsgThreadSummary } from "../../types/MsgThread";
import dayjs from "dayjs";
import "./joinParty.scss";
import { getFileUrl } from "../../helpers/getFileUrl";

interface IProps {
    party?: MsgThreadSummary;
}

const JoinPartyDescription: React.FC<IProps> = ({ party }) => {
    return (
        <div>
            {/*  Image somewhere here  */}
            <p className="text-center text-primary font-weight-bold h3">
                You&apos;re invited
            </p>
            <p className="text-center text-primary h5">
                to {party?.seller?.nameFirst} {party?.seller.nameLast}`s Active Online
                Event
            </p>
            <div className="bg-light rounded-lg d-flex justify-content-center mx-4 py-4 px-5 px-sm-2 mb-4 mt-6 flex-column flex-sm-row">
                <div className="mx-1 px-1">
                    <p className="mb-0 p4 text-center">When:</p>
                    <p className="mb-0 h4 text-center text-primary font-weight-boldest">
                        {dayjs(party?.timeSchedStart)
                            .tz(party?.timezone)
                            .format("M/D/YY")}
                    </p>
                    <p className="mb-0 p4 text-center text-primary">
                        {dayjs(party?.timeSchedStart)
                            .tz(party?.timezone)
                            .format("hA - z")}
                    </p>
                </div>
                <div className="px-2 my-4 py-3 py-sm-0 my-sm-0 sm-border-0 middle-element">
                    <p className="mb-0 p4 text-center">Where:</p>
                    <p className="mb-0 h4 text-center text-primary font-weight-boldest">
                        Virtually
                    </p>
                    <p className="mb-0 p4 text-center text-primary">
                        On Your Phone
                    </p>
                </div>
                <div className="mx-1 px-1">
                    <p className="mb-0 p4 text-center">Why:</p>
                    <p className="mb-0 h4 text-center text-primary font-weight-boldest">
                        Shop
                    </p>
                    <p className="mb-0 p4 text-center text-primary">
                        Click & Save
                    </p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <img
                    src={getFileUrl(
                        party?.seller?.companyLogo ||
                            party?.seller?.profilePic ||
                            ""
                    )}
                    alt={""}
                    title={`${party?.sellerNameFirst} ${party?.sellerNameLast}`}
                    className="mb-3 w-55px h-55px rounded-circle bg-gray-300 img-circle-border"
                />
            </div>
            <p className="text-center">Welcome to my Event!</p>
            <div className="d-flex justify-content-center mb-2">

            <p className="text-center text-muted w-80">
                You have been invited to join a text event. Please enter your
                details below and RSVP to this event. You will receive text
                messages during this time.
            </p>
            </div>
            <p className="text-black-50 text-wrap text-center mx-auto max-w-325px">
                {party?.description}
            </p>
        </div>
    );
};

export default JoinPartyDescription;
