import React, { useState } from "react";
import TabbedCard from "../../layouts/TabbedCard";
import NewMessageToContact from "../messages/NewMessageToContact";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";

const ContactMessageCard: React.FC = () => {
    const [msgText, setMsgText] = useState("");
    return (
            <TabbedCard
                items={[
                    {
                        component: (
                            <NewMessageToContact
                                prePopulatedMessage={msgText}
                                onReset={() => setMsgText("")}
                            />
                        ),
                        tabTitle: "Messages",
                    },
                ]}
            />
    );
};

export default ContactMessageCard;
