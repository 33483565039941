import { Formik } from "formik";
import * as React from "react";
import { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import {
    fetchScripts,
    fetchScriptsPaginate,
} from "../../store/asyncActions/automations";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { FormAction } from "../../types/Common";
import { ServerError } from "../../types/Error";
import { ScriptFormValues } from "../../types/Forms";
import { ScriptType } from "../../types/Script";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});

const ScriptForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );
    const { type, currentScript, action } = useTypedSelector(
        (state) => state.modals.scriptModal
    );
    const { createScript, updateScript } = actions;
    const { closeScriptModal } = modalActions;

    const initialValues: ScriptFormValues = {
        name: currentScript?.name || "",
    };
    const { page, limit, search } = useTypedSelector(
        (state) => state.automations.scripts
    );

    const closeModal = useCallback(() => {
        dispatch(closeScriptModal());
    }, []);

    const submitForm = useCallback(
        (values: ScriptFormValues) => {
            if (action === FormAction.CREATE)
                return dispatch(
                    createScript({
                        ...values,
                        scriptType: type,
                    })
                );
            else
                return dispatch(
                    updateScript({
                        scriptId: currentScript?.id,
                        scriptType: type,
                        starred: currentScript?.starred,
                        ...values,
                    })
                );
        },
        [currentScript, type]
    );

    const currentLabel = type === ScriptType.PARTY ? "Textflow" : "Message";
    const callToAction = action === FormAction.CREATE ? "Create" : "Update";

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setFormErrors(undefined);
                submitForm(values)
                    .unwrap()
                    .then(() => {
                        setSubmitting(false);
                        closeModal();
                        dispatch(
                            fetchScripts({ _page: 1, _limit: 1000, search: "" })
                        );
                        dispatch(
                            fetchScriptsPaginate({
                                _page: page,
                                _limit: limit,
                                search,
                            })
                        );
                        // history.push(
                        //     "/automations/workflows?workflow=" + resp?.id
                        // );
                    })
                    .catch((err) => {
                        setFormErrors(err);
                        setSubmitting(false);
                        setErrors(transformToFormikErrors(err));
                    });
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                placeholder={`${currentLabel} ...`}
                                type="text"
                                label={`${currentLabel} Name`}
                                name="name"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>

                    <FormErrors errors={formErrors} />
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="primary"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                            type="submit"
                        >
                            {callToAction} {currentLabel}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ScriptForm;
