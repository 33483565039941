import * as React from "react";
import { MsgTemplate, Script } from "../../types/Script";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { useContext, useEffect, useState } from "react";
import {
    fetchMsgTemplates,
    setFavoriteMsgTemplate,
} from "../../store/asyncActions/automations";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import {
    Accordion,
    AccordionContext,
    Button,
    Card,
    ListGroup,
} from "react-bootstrap";
import { actions } from "../../store/reducers/automationsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faSolid from "@fortawesome/free-solid-svg-icons";
import * as faRegular from "@fortawesome/free-regular-svg-icons";
import {
    faAngleDown,
    faAngleUp,
    faPlus,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { faObjectGroup } from "@fortawesome/free-regular-svg-icons";
interface IProps {
    script: Script;
}

// Note: This was based off ScriptsListEntry
const PrepopulatedMsgScriptsListEntry: React.FC<IProps> = ({ script }) => {
    const currentEventKey = useContext(AccordionContext);
    const history = useHistory();
    const searchParams = useQueryParams();

    const urlParamsKey = "message";
    const urlBase = `/automations/${urlParamsKey}s`;
    const scriptIt = Number(searchParams.get(urlParamsKey));
    const selectedMessageId = Number(searchParams.get("template"));
    const dispatch = useTypedDispatch();
    const { openEditMsgTemplate } = actions;

    const messagesList = useTypedSelector(
        (state) => state.automations.messageTemplates
    );


    useEffect(() => {
        if (
            currentEventKey === script.id.toString() &&
            scriptIt === script.id
        ) {
            dispatch(fetchMsgTemplates({ scriptId: script.id }));
        }
    }, [currentEventKey, script]);

    const toggleItemAsFavorite = (template: MsgTemplate) => {
        dispatch(
            setFavoriteMsgTemplate({
                id: template.id,
                isFavorite: !template.isFavorite,
            })
        );
    };

    return (
        <div className="menu-item accordion-item">
            <Card>
                <span
                    onClick={(e) => {
                        e.stopPropagation()
                        history.push(`${urlBase}`);
                    }}
                >
                    <Droppable
                        droppableId={`massageTemplateDropEditors-scriptId-${script.id}`}
                        type="massageTemplateType"
                        key={script.id}
                    >
                        {(provided, snapshot) => (
                            <span
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div
                                    key={script.id}
                                    className={`mb-1 px-3 py-3 list-view__item rounded cursor-pointer ${currentEventKey === script.id.toString()
                                        ? "bg-gray-400 shadow-sm"
                                        : ""
                                        }`}
                                >
                                    <span className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 className="mb-0 text-truncate max-w-225px">
                                                {script.name}
                                            </h5>
                                            <p className="p4 mb-0 text-black-50">
                                                {script.messageCount} {"Messages"}
                                            </p>
                                        </div>
                                        <span className="text-primary">
                                            {/* <Button
                                                variant="link"
                                                className="p1 cursor-pointer mb-0"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                    transition: "background 0.3s ease",
                                                    borderRadius: "50%",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    history.push(
                                                        `${urlBase}?${urlParamsKey}=${script.id}&action=drop`
                                                    );
                                                }}
                                            >
                                                <span className="text-primary">
                                                    <FontAwesomeIcon icon={faObjectGroup} />
                                                </span>
                                            </Button> */}
                                            <Button
                                                variant="link"
                                                className="p1 cursor-pointer mb-0"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                    transition: "background 0.3s ease",
                                                    borderRadius: "50%",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    history.push(
                                                        `${urlBase}?${urlParamsKey}=${script.id}&action=create`
                                                    );
                                                }}
                                            >
                                                <span className="text-primary">
                                                    <FontAwesomeIcon size="sm" icon={faPlus} />
                                                </span>
                                            </Button>
                                            <Accordion.Toggle
                                                as={Button}
                                                defaultActiveKey={scriptIt.toString()}
                                                eventKey={script.id.toString()}
                                                onClick={(e) => {
                                                    // history.push(
                                                    //     `${urlBase}?${urlParamsKey}=${script.id}`
                                                    // );
                                                    e.stopPropagation()
                                                    dispatch(
                                                        fetchMsgTemplates({
                                                            scriptId: script.id,
                                                        })
                                                    );
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                    transition: "background 0.3s ease",
                                                    borderRadius: "50%",
                                                }}
                                                variant="link"
                                                className="p1 cursor-pointer mb-0"
                                            >
                                                <span className="text-primary">
                                                    {currentEventKey ===
                                                        script.id.toString() ? (
                                                        <FontAwesomeIcon icon={faAngleUp} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faAngleDown} />
                                                    )}
                                                </span>
                                            </Accordion.Toggle>
                                        </span>
                                    </span>
                                    {snapshot.isDraggingOver &&
                                        <>{provided.placeholder}</>
                                    }
                                </div>
                            </span>
                        )}
                    </Droppable>
                </span>
                <Accordion.Collapse eventKey={script.id.toString()}>
                    <Card.Body id="body-card-quick-text">
                        {currentEventKey === script.id.toString() && (
                            <Droppable
                                droppableId="massageTemplate"
                                type="massageTemplateType"
                            >
                                {(provided) => (
                                    <span  {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <ListGroup className="w-100">
                                            {messagesList.list.map((item, index) => {
                                                return (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={`draggableId-${item.id.toString()}-currentScript-${script.id}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <ListGroup.Item
                                                                onDragStart={() => {
                                                                    console.log(snapshot.isDragging)
                                                                }}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                key={item.id}
                                                                className={`list-view__item d-flex justify-content-between ${selectedMessageId ===
                                                                    item.id
                                                                    ? "bg-gray-400 shadow-sm"
                                                                    : ""
                                                                    }`}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        openEditMsgTemplate(
                                                                            {
                                                                                msgTemplate:
                                                                                    item,
                                                                                scriptId:
                                                                                    script.id,
                                                                                list: messagesList.list as MsgTemplate[],
                                                                            }
                                                                        )
                                                                    );
                                                                    dispatch(
                                                                        fetchMsgTemplates({
                                                                            scriptId:
                                                                                script.id,
                                                                        })
                                                                    );

                                                                    history.push(
                                                                        `${urlBase}?${urlParamsKey}=${script.id}&template=${item.id}`
                                                                    );
                                                                }}
                                                            >
                                                                <div>{item.name}</div>
                                                                <span
                                                                    className="p-1 text-primary cursor-pointer"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            item.isFavorite
                                                                                ? faSolid.faStar
                                                                                : faRegular.faStar
                                                                        }
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            toggleItemAsFavorite(
                                                                                item
                                                                            );
                                                                        }}
                                                                    />
                                                                </span>
                                                            </ListGroup.Item>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        </ListGroup>
                                    </span>
                                )}
                            </Droppable>
                        )}
                    </Card.Body>
                </Accordion.Collapse>

            </Card>
        </div>
    );
};

export default PrepopulatedMsgScriptsListEntry;
