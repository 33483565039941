import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPartySelectorTab } from "../../types/Common";

const initialState: IPartySelectorTab = {
    selectedTab: 0,
    tabName: "",
};

const selectedPartyTabSlice = createSlice({
    name: "selected_party_tab",
    initialState,
    reducers: {
        changeSelectPartyTab: (
            state,
            action: PayloadAction<IPartySelectorTab>
        ) => {
            if (action.payload) {
                state.selectedTab = action.payload.selectedTab;
            }
        },
    },
});


export const SelectedPartyTabActions = {
    ...selectedPartyTabSlice.actions,
};

const TabReducer = selectedPartyTabSlice.reducer;

export default TabReducer;
