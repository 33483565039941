import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionState } from "../../types/Common";
import { fetchPlans, MembershipCancel } from "../asyncActions/subscription";

const initialState: SubscriptionState = {
    plans: {
        list: [],
        isLoading: false,
        error: undefined,
    },
    MembershipCancel: {
        successMsg: "",
        isLoading: false,
        error: undefined,
    },
};

const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {},
    extraReducers: {
        // Fetch guests for specific party (For the party details page)
        [fetchPlans.pending.toString()]: (state) => {
            state.plans.isLoading = true;
            state.plans.error = undefined;
        },
        [fetchPlans.fulfilled.toString()]: (
            state,
            action: PayloadAction<[]>
        ) => {
            state.plans.isLoading = false;
            state.plans.list = action.payload;
        },
        [fetchPlans.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.plans.isLoading = false;
            state.plans.error = action.payload;
            state.plans.list = [];
        },
        [MembershipCancel.pending.toString()]: (state) => {
            state.MembershipCancel.isLoading = true;
            state.MembershipCancel.error = undefined;
        },
        [MembershipCancel.fulfilled.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.MembershipCancel.isLoading = false;
            state.MembershipCancel.successMsg = action.payload;
        },
        [MembershipCancel.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.MembershipCancel.isLoading = false;
            state.MembershipCancel.error = action.payload;
            state.MembershipCancel.successMsg = "";
        },
    },
});

export const actions = {
    ...subscriptionSlice.actions,
    fetchPlans,
    MembershipCancel,
};

const subscriptionReducer = subscriptionSlice.reducer;

export default subscriptionReducer;
