import { combineReducers } from "@reduxjs/toolkit";
import announcementReducer from "./announcementSlice";
import authReducer from "./authSlice";
import automationsReducer from "./automationsSlice";
import contactReducer from "./contactSlice";
import directMessagesReducer from "./directMessagesSlice";
import DragDropReducer from "./dragDropSlice";
import loaderReducer from "./loader";
import mediaReducer from "./medaiSlice";
import modalsReducer from "./modalsSlice";
import notificationsReducer from "./notificationsSlice";
import partyReducer from "./partySlice";
import scriptRunnerReducer from "./scriptRunnerSlice";
import TabReducer from "./selectedPartyTabSlice";
import staticPageReducer from "./staticPage";
import subscriptionReducer from "./subscriptionSlice";
import topUsedReducer from "./topUsed";
import redirectUrlReducer from "./redirectUrlSlice";
const rootReducer = combineReducers({
    party: partyReducer,
    auth: authReducer,
    directMessages: directMessagesReducer,
    modals: modalsReducer,
    automations: automationsReducer,
    notifications: notificationsReducer,
    media: mediaReducer,
    subscription: subscriptionReducer,
    contact: contactReducer,
    announcement: announcementReducer,
    scriptRunner: scriptRunnerReducer,
    staticPage: staticPageReducer,
    loader: loaderReducer,
    topUsed: topUsedReducer,
    tabReducer: TabReducer,
    dragDrop: DragDropReducer,
    urlReducer: redirectUrlReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
