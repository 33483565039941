import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";


interface IProps {
    title?: string;
    icon?: JSX.Element;
}

const LayoutCard: React.FC<IProps> = ({ children, title, icon }) => {

    return (
        <>
            {title && (
                <div className="my-4">
                    {icon && <div>{icon}</div>}
                    <h5>{title}</h5>
                </div>
            )}
            <PerfectScrollbar>
                <div className="rounded-lg bg-light border parties-schedule min-h-750px bg-white p-6 mb-1 shadow overflow-auto">
                    {children}
                </div>
            </PerfectScrollbar> 
    
        </>
    );
};

export default LayoutCard;
