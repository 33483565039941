import {
    faEdit,
    faEllipsisH,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
    fetchScripts,
    fetchScriptsPaginate,
} from "../../store/asyncActions/automations";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { Script, ScriptType } from "../../types/Script";

interface IProps {
    script: Script;
}

const ManageScriptPopover: React.FC<IProps> = ({ script }) => {
    const dispatch = useTypedDispatch();
    const { openEditScriptModal } = modalActions;
    const { deactivateScript } = actions;
    const { page, limit, search } = useTypedSelector(
        (state) => state.automations.scripts
    );
    const handleDeleteClick = async () => {
        try {
            const resultData = await dispatch(
                deactivateScript({ id: script.id })
            );
            if (resultData.type === deactivateScript.fulfilled.toString()) {
                // Deactivation was successful, handle success scenario
                dispatch(fetchScripts({ _page: 1, _limit: 1000, search: "" }));
                dispatch(
                    fetchScriptsPaginate({ _page: page, _limit: limit, search })
                );
                // history.push(`/automations/workflows`);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const items = [
        {
            title: "Edit",
            action: () =>
                dispatch(
                    openEditScriptModal({
                        currentScript: script,
                        type: script?.scripttype?.name as ScriptType,
                    })
                ),
            icon: (
                <FontAwesomeIcon
                    icon={faEdit}
                    className="text-warning w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Delete",
            action: () => {
                confirmAlert({
                    title: "Confirm to delete",
                    message:
                        "Are you sure you want to delete this item and all objects associated?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: handleDeleteClick,
                        },
                        {
                            label: "No",
                            onClick: () => console.info("No."),
                        },
                    ],
                });
            },
            icon: (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger w-15px h-15px mr-2"
                />
            ),
        },
    ];

    const renderPopover = (props: any) => (
        <Popover id="manage-script-popover" {...props}>
            <Popover.Content className="p-3">
                <ul className="p-0 m-0">
                    {items.map((item) => (
                        <li
                            key={item.title}
                            onClick={() => item.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            overlay={renderPopover}
            placement="right"
        >
            <FontAwesomeIcon
                icon={faEllipsisH}
                className="position-absolute right-0 top-50 mr-5 w-25px h-20px cursor-pointer"
            />
        </OverlayTrigger>
    );
};

export default ManageScriptPopover;
