import * as React from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import MediaCollectionForm from "../forms/MediaCollectionForm";

const MediaCollectionsModal: React.FC = () => {
    const show = useTypedSelector(
        (state) => state.modals.mediaCollectionModal.isOpen
    );
    const dispatch = useTypedDispatch();
    const { closeMediaCollectionModal } = modalActions;

    const handleClose = useCallback(() => {
        dispatch(closeMediaCollectionModal());
    }, []);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header
                closeButton
                className="d-flex justify-content-end custom-modal-header"
            ></Modal.Header>
            <Modal.Body>
                <MediaCollectionForm />
            </Modal.Body>
        </Modal>
    );
};

export default MediaCollectionsModal;
