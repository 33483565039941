import * as React from "react";
import { Col, Row } from "react-bootstrap";
import PrepopulatedMessagesEditorCard from "../../components/cardViews/PrepopulatedMessagesEditorCard";
import PrepopulatedMessagesListCard from "../../components/cardViews/PrepopulatedMessagesListCard";
import MsgTemplateModal from "../../components/script/msg-templates/MsgTemplateModal";
import DefaultLayout from "../../layouts/DefaultLayout";

const ManagePrepopulatedMessages: React.FC = () => {
    return (
        <DefaultLayout>
             <MsgTemplateModal />
            <Row sm={1} xs={1} lg={2} xl={2}>
                <Col className="pt-15">
                    <PrepopulatedMessagesListCard />
                </Col>
                <Col className="pt-15">
                    <PrepopulatedMessagesEditorCard />
                </Col>
            </Row> 
        </DefaultLayout>
    );
};

export default ManagePrepopulatedMessages;
