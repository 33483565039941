import React, { useEffect } from "react";
import { useTypedDispatch } from "../../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../../store/hooks/useTypedSelector";
import { actions } from "../../../store/reducers/staticPage";
import Modal from "./LegalModal";

const ModalWapper: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { fetchStaticPage } = actions;
    const { data, isLoading } = useTypedSelector(
        (state) => state.staticPage.staticPage
    );
    const { isOpen: show, slug } = useTypedSelector(
        (state) => state.modals.legalModal
    );

    useEffect(() => {
        if (show) {
            dispatch(fetchStaticPage({ slug__: slug }));
        }
    }, [show, dispatch, slug]);

    return (
        <Modal
            slug={data?.slug}
            content={data?.content}
            updateAt={data?.updatedAt}
            isLoading={isLoading}
        />
    );
};

export default ModalWapper;
