import { faSignOutAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown, Image, Popover } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/Overlay";
import { useHistory } from "react-router-dom";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { authActions } from "../../store/reducers/authSlice";
import { toAbsoluteUrl } from "../../helpers/toAbsoluteUrl";
import { getFileUrl } from "../../helpers/getFileUrl";
import { Seller } from "../../types/Seller";

interface IProps {
    user: Seller | null;
    userName: string;
}

const ProfileBtnPopover = (props: IProps) => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const { logOutToken } = authActions;

    const sellerUserName = props.user
        ? `${props.user.nameFirst || ""} ${props.user.nameLast || ""} [${
              props.user.nameAlias || ""
          }]`
        : "";

    const items = [
        // {
        //     title: "Edit profile",
        //     action: () => history.push("/profile"),
        //     icon: (
        //         <FontAwesomeIcon
        //             icon={faUserCircle}
        //             className="mr-2 w-15px h-15px text-secondary"
        //         />
        //     ),
        // },
        {
            title: "My profile",
            action: () => history.push("/my-profile/overview"),
            icon: (
                <FontAwesomeIcon
                    icon={faUserCircle}
                    className="mr-2 w-15px h-15px text-secondary"
                />
            ),
        },
        // {
        //     title: "Plan + Billing",
        //     action: () => history.push("/plans"),
        //     icon: (
        //         <FontAwesomeIcon
        //             icon={faWallet}
        //             className="mr-2 w-15px h-15px text-secondary"
        //         />
        //     ),
        // },
        {
            title: "Log out",
            action: () => dispatch(logOutToken()),
            icon: (
                <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="mr-2 w-15px h-15px text-secondary"
                />
            ),
        },
    ];

    return (
        <Dropdown.Menu
            id="popover-profile"
            {...props}
            style={{ left: "auto", right: 0 }}
        >
            <Dropdown.Item
                eventKey={0}
                className="d-flex m-auto w-90 active-bg align-items-center cursor-pointer px-2 py-2 rounded"
            >
                <div
                    className="d-flex align-items-center"
                    style={{ width: "max-content", paddingRight: "25px"}}
                >
                    {props.user?.profilePic ? (
                        <img
                            src={getFileUrl(props?.user.profilePic)}
                            alt=""
                            className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center text-center"
                        />
                    ) : (
                        <div className="bg-gray-500 h-40px w-40px rounded-circle d-flex justify-content-center align-items-center text-center">
                            <span className="user-select-none mb-0 font-weight-bold">
                                {props?.userName}
                            </span>
                        </div>
                    )}
                    <div className="d-flex flex-column ml-2">
                        <p className="d-flex align-items-center font-weight-bold">
                            {sellerUserName}
                        </p>
                        <a href="#" className="text-muted text-decoration-none">
                            {props.user?.email}
                        </a>
                    </div>
                </div>
            </Dropdown.Item>
            <hr className="mt-1 mb-1"/>
            {items.map((item) => (
                <Dropdown.Item
                    key={item.title}
                    eventKey={item.title}
                    onClick={() => item.action()}
                    className="d-flex w-75 m-auto align-items-center active-bg cursor-pointer px-2 py-1 rounded"
                    style={{ color: "black" }}
                >
                    {item.icon}
                    <p className="p3 mb-0 px-2 py-1">{item.title}</p>
                </Dropdown.Item>
            ))}
            <span className="mt-2"></span>
        </Dropdown.Menu>
    );
};

export default ProfileBtnPopover;
