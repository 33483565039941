import { faCirclePlay, faFileAudio } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { AssetType, ScriptAsset } from "../../types/Media";

const MessageMediaPreviews: React.FC<{ mediaItems: ScriptAsset["items"][]}> = ({
    mediaItems,
}) => {
    return <>{mediaItems.map((item) => previewMediaItem(item))}</>;

    function previewMediaItem(item: ScriptAsset["items"]): JSX.Element {
        let content;
        switch (item?.assetType?.name) {
            case AssetType.VIDEO:
                content = (
                    <div
                        className="w-100 h-100 d-flex justify-content-center"
                        title={item.name}
                    >
                        <FontAwesomeIcon
                            icon={faCirclePlay}
                            className="h-50px w-50px"
                        />
                    </div>
                );
                break;

            case AssetType.AUDIO:
                content = (
                    <div
                        className="w-100 h-100 d-flex justify-content-center"
                        title={item.name}
                    >
                        <FontAwesomeIcon
                            icon={faFileAudio}
                            className="h-50px w-50px"
                        />
                    </div>
                );
                break;

            case AssetType.IMAGE:
                content = (
                    <img
                        title={item.name}
                        src={item.linkUrl}
                        className="d-block w-100 p-1 preview-image"
                        alt={item.name}
                    />
                );
                break;

            default:
                content = <>{item.name}</>;
                break;
        }
        return <div key={item.id}>{content}</div>;
    }
};

export default MessageMediaPreviews;
