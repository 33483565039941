import * as React from "react";
import { fetchContactMessageList } from "../../store/asyncActions/contact";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import MessagePreview from "./MessagePreview";

const ContectMessagePreview: React.FC = () => {
    const dispatch = useTypedDispatch();
    const selectedContact = useTypedSelector(
        (state) => state.contact.selected
    );

    React.useEffect(() => {
        if (selectedContact && selectedContact.entity?.id) {
            dispatch(fetchContactMessageList(selectedContact));
        }
    }, [selectedContact]);

    const list: any = useTypedSelector(
        (state) => state.contact.contactListMessage.list
    );

    return (
            <div className="d-flex flex-column align-items-end">
                <>
                    {(list?.list?.items || []).map((msg: any) => (
                        <MessagePreview key={msg.id} msg={msg} />
                    ))}
                </>
            </div>
        );
};

export default ContectMessagePreview;
