import * as React from "react";
import LayoutCard from "../../layouts/LayoutCard";
import ScriptEditorView from "../script/ScriptEditorView";

const WorkflowEditorCard: React.FC = () => {
    return (
        <LayoutCard>
            <ScriptEditorView />
        </LayoutCard>
    );
};

export default WorkflowEditorCard;
