import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import CustomPagination from "../../layouts/CustomPagination";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/contactSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { ContactField } from "../../types/MsgThread";
import ContactList from "../contactList/ContactList";

const Contact: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { list, isLoading } = useTypedSelector(
        (state) => state.contact.contactList
    );
    const { fetchContacts, fetchActiveContacts } = actions;
    const totalContacts = (list as any)?.meta?.totalItems;
    const { page, limit } = useTypedSelector(
        (state) => state.contact.contactList
    );

    const [currentPage, setCurrentPage] = useState(page);
    const [searchValue, setSearchValue] = useState<string>("");
    const [dataPerPage] = useState(limit);
    const [apiList, setApiList] = useState<ContactField[]>([]);

    useEffect(() => {
        const fetchData = () => {
            dispatch(
                fetchContacts({
                    _page: currentPage,
                    _limit: dataPerPage,
                    search: searchValue,
                })
            );
            dispatch(
                fetchActiveContacts({ _page: 1, _limit: 5000, search: "" })
            );
        };
        fetchData();
    }, [currentPage, dataPerPage]);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    useEffect(() => {
        if ((list as any)?.items) {
            setApiList((list as any)?.items);
        }
    }, [list]);

    const [contactList, setContactList] = useState<ContactField[]>([]);

    const SearchField = useTypedSearchField<ContactField>({
        btnOnClick: () =>
            dispatch(modalActions.openCreateContactModal(undefined)),
        inputPlaceholder: "Search Contacts...",
        list: apiList,
        btnType: "bulkContact",
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
        searching: "serverSide",
        searchOnChange: (value) => {
            dispatch(
                fetchContacts({ _page: 1, _limit: dataPerPage, search: value })
            );
            setSearchValue(value);
            return value;
        }, // Set the value here
        setList: setContactList,
    });

    return (
        <>
            {SearchField}
            <PerfectScrollbar className="pr-3 mt-2 max-h-550px">
                <ContactList contact={contactList} isLoading={isLoading} />
            </PerfectScrollbar>
            <div className="centerPagination">
                {totalContacts > dataPerPage && (
                    <CustomPagination
                        dataPerPage={dataPerPage}
                        totalData={totalContacts}
                        paginate={(page) => paginate(page)} // Pass the paginate function here
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
};

export default Contact;
