import React, { useState, ChangeEvent, FormEvent } from "react";
import { Button, Form } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import {
    fetchContacts,
    importCsvContact,
} from "../../store/asyncActions/contact";
import { modalActions } from "../../store/reducers/modalsSlice";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";


const ImportCsv = () => {
    const [file, setFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>("No file chosen");
    const dispatch = useTypedDispatch();
    const { closeContactModal } = modalActions;

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFile(null);
            setFileName("No file chosen");
        }
    };

    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        if (file) {
            dispatch(importCsvContact(file))
                .then((data: any) => {
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: data?.payload.message,
                        })
                    );
                })
                .then(() => {
                    dispatch(fetchContacts({ _page: 1, _limit: 10 }));
                })
                .then(() => {
                    dispatch(closeContactModal());
                })

                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Failed to import contact from csv file",
                        })
                    );
                });
        } else {
            setIsLoading(false);
            dispatch(
                showSnackbarWithAutoHide({
                    msgText: "File not found or corrupted",
                })
            );
        }
    };

    const data = [
        { name: 'anshu', phone: '123-456-7890', email: 'anshusharma608218@gmail.com' },
        { name: 'vishal', phone: '987-654-3210', email: 'anshusharma608218@gmail.com' },
    ];

    const handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'sample-data.csv');
    };

    return (
        <span className="d-flex flex-column p-3 rounded mb-4">
            <span className="d-flex justify-content-between">
                <span className="mr-2 font-weight-bold">
                    Choose CSV file
                </span>
                <Button
                    variant="link"
                    className="p-0 text-dark mb-3"
                    onClick={handleDownload}
                >
                    <FontAwesomeIcon icon={faDownload} className="w-15px h-15px mr-2" />
                    Download Sample
                </Button>
            </span>
            <Form
                onSubmit={onFormSubmit}
                className="d-flex align-items-center rounded"
            >
                <span className="d-flex justify-content-between">
                    <Form.Group controlId="csvFile" className="mr-3 mb-0">
                        <Form.File
                            label={fileName}
                            accept=".csv"
                            onChange={onFileChange}
                            custom
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        className="ml-24"
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading.." : "Upload"}
                    </Button>
                </span>
            </Form>
        </span>
    );
};

export default ImportCsv;
