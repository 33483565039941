/* eslint-disable react/react-in-jsx-scope */
import { unwrapResult } from "@reduxjs/toolkit";
import clsx from "clsx";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AuthLayout from "../../layouts/AuthLayout";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { authActions } from "../../store/reducers/authSlice";
const initialValues = {
    email: "",
};

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
});

export function ForgotPassword() {
    const dispatch = useTypedDispatch();
    const [loading, setLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
    const { forgotPassword_ } = authActions;
    const history = useHistory();

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setHasErrors(undefined);
            setLoading(true);
            try {
                const promiseResult = await dispatch(
                    forgotPassword_({ email: values.email })
                );
                unwrapResult(promiseResult);
                history.push("login");
                toast.success(
                    "Password reset instructions have been sent. Please check your email."
                );
                setHasErrors(false);
            } catch (err) {
                setHasErrors(true);
            } finally {
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <AuthLayout>
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                    noValidate
                    id="kt_login_password_reset_form"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="text-center mb-10">
                        <h1 className="text-dark fw-bolder mb-3">
                            Forgot Password ?
                        </h1>
                        <div className="text-gray-500 fw-semibold fs-6">
                            Enter your email to reset your password.
                        </div>
                    </div>

                    {hasErrors === true && (
                        <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                                Seems, you are not registered with our
                                application.
                            </div>
                        </div>
                    )}

                    {hasErrors === false && (
                        <div className="mb-10 bg-light-info p-8 rounded">
                            <div className="text-info">
                                Password reset instructions have been sent.
                                Please check your email.
                            </div>
                        </div>
                    )}

                    <div className="fv-row mb-8">
                        <label className="form-label fw-bolder text-gray-900 fs-6">
                            Email<span className="text-danger">*</span>
                        </label>
                        <input
                            type="email"
                            placeholder=""
                            autoComplete="off"
                            {...formik.getFieldProps("email")}
                            className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                    formik.touched.email && formik.errors.email,
                            })}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">
                                        {formik.errors.email}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                        <button
                            type="submit"
                            id="kt_password_reset_submit"
                            className="btn btn-primary me-4"
                            disabled={loading}
                        >
                            {!loading ? (
                                <span className="indicator-label">Submit</span>
                            ) : (
                                <span className="indicator-progress">
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                        <Link to="/auth/login">
                            <button
                                type="button"
                                id="kt_login_password_reset_form_cancel_button"
                                className="btn btn-light"
                                disabled={
                                    formik.isSubmitting || !formik.isValid
                                }
                            >
                                Back
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </AuthLayout>
    );
}
