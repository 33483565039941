import { Field, FieldProps, Formik } from "formik";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { scriptRunnerActions } from "../../store/reducers/scriptRunnerSlice";
import { FormAction } from "../../types/Common";
import { ServerError } from "../../types/Error";
import { MsgTemplateFormValues } from "../../types/Forms";
import MediaGridViewSelect from "../media/MediaGridViewSelect";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    quietWaitSeconds: Yup.number().min(0, "Time can not be negative"),
    maxWaitSeconds: Yup.number().min(0, "Time can not be negative"),
    messageText: Yup.string().required("Message text is required"),
    link: Yup.string().matches(
        /^(https?:\/\/)?(www\.)?[^.]+(\..+)+(\?.*)?$/,
        "Enter a correct URL!"
    ),
});

const MsgTemplateForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );
    const { currentMessageTemplate, action, currentScriptId } =
        useTypedSelector((state) => state.modals.msgTemplateModal);
    const { fetchMsgTemplatesDragnDrop } = actions;
    const { closeMsgTemplateModal } = modalActions;
    const { createMsgTemplate, updateMsgTemplate, deleteMsgTemplate } = actions;
    const msgTemplatesList = useTypedSelector(
        (state) => state.automations.messageTemplates.list
    );
    const msg_name_msgTemplateModal =
        useTypedSelector(
            (state) => state?.modals?.msgTemplateModal?.data?.msg_name
        ) ?? "";
    const msg_text_msgTemplateModal =
        useTypedSelector(
            (state) => state?.modals?.msgTemplateModal?.data?.msg_text
        ) ?? "";

    const initialValues: MsgTemplateFormValues = useMemo(
        () => ({
            name: currentMessageTemplate?.name || msg_name_msgTemplateModal,
            templateLength: msgTemplatesList?.length || 0,
            maxWaitSeconds: currentMessageTemplate?.maxWaitSeconds || 60,
            quietWaitSeconds: currentMessageTemplate?.quietWaitSeconds || 15,
            link: currentMessageTemplate?.link || "",
            messageText:
                currentMessageTemplate?.messageText ||
                msg_text_msgTemplateModal,
            isFavorite: currentMessageTemplate?.isFavorite || false,
            mediaItems: currentMessageTemplate?.mediaItems || [],
        }),
        []
    );

    const closeModal = useCallback(() => {
        dispatch(closeMsgTemplateModal());
    }, []);

    const submitForm = useCallback(
        (values: any, scriptId: number) => {
            if (action === FormAction.CREATE)
                return dispatch(
                    createMsgTemplate({
                        scriptId,
                        ...values,
                    })
                );
            else
                return dispatch(
                    updateMsgTemplate({
                        ...values,
                        id: currentMessageTemplate?.id,
                    })
                );
        },
        [currentMessageTemplate]
    );

    const deleteScriptConfirm = () => {
        closeModal();
        confirmAlert({
            title: "Confirm to delete",
            message:
                "Are you sure you want to delete this item and all objects associated?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteScript(),
                },
                {
                    label: "No",
                    onClick: () => console.info("No."),
                },
            ],
        });
    };
    const deleteScript = useCallback(() => {
        if (!currentMessageTemplate) return;
        dispatch(
            deleteMsgTemplate({
                msgTemplateId: currentMessageTemplate?.id,
            })
        )
            .unwrap()
            .then(() => {
                closeModal();
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: "Successfully Massage deleted",
                    })
                );
            });
    }, [currentMessageTemplate]);

    const btnLabel = action === FormAction.CREATE ? "Create" : "Update";

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                values.templateLength = msgTemplatesList.length;
                setFormErrors(undefined);
                submitForm(values, currentScriptId)
                    .unwrap()
                    .then(() => {
                        dispatch(
                            scriptRunnerActions.fetchMsgTemplates({
                                scriptId: currentScriptId,
                            })
                        );
                        dispatch(
                            fetchMsgTemplatesDragnDrop({
                                scriptId: currentScriptId,
                            })
                        );
                        dispatch(
                            showSnackbarWithAutoHide({
                                msgText:
                                    action === FormAction.CREATE
                                        ? "Added!"
                                        : "Updated!",
                            })
                        );
                        closeModal();
                        setSubmitting(false);
                    })
                    .catch((err: any) => {
                        setFormErrors(err);
                        setErrors(transformToFormikErrors(err));
                        setSubmitting(false);
                    });
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                placeholder={"Enter name here"}
                                type="text"
                                label={"Name"}
                                name="name"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                placeholder="Enter message text here"
                                type="text"
                                label="Message text"
                                name="messageText"
                                as="textarea"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-6">
                            <CustomInput
                                placeholder="Enter quiet wait time here (in seconds)"
                                type="number"
                                label="Quiet wait time  (seconds)"
                                name="quietWaitSeconds"
                                min={0}
                                required={true}
                            />
                        </Form.Group>

                        <Form.Group className="col-6">
                            <CustomInput
                                placeholder="Enter max wait time here (in seconds)"
                                type="number"
                                label="Max wait time (seconds)"
                                name="maxWaitSeconds"
                                min={0}
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                placeholder="Enter link here"
                                type="text"
                                label="Link"
                                name="link"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <Form.Row>
                                <Field
                                    className="custom-select"
                                    name="mediaItems"
                                    placeholder="Select Images"
                                    isMulti={true}
                                >
                                    {(props: FieldProps) => (
                                        <MediaGridViewSelect
                                            currentAssets={
                                                currentMessageTemplate?.mediaItems
                                            }
                                            {...props}
                                        ></MediaGridViewSelect>
                                    )}
                                </Field>
                            </Form.Row>
                        </Form.Group>
                    </Form.Row>

                    <FormErrors errors={formErrors} />
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="primary"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                            type="submit"
                        >
                            {btnLabel}
                        </Button>
                        <Button
                            variant="secondary"
                            className="mx-4"
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                        {action === FormAction.UPDATE && (
                            <Button
                                onClick={deleteScriptConfirm}
                                variant="danger"
                                className="mx-4"
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default MsgTemplateForm;
