import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { topUsedState } from "../../types/Common";
import {
    DeleteTopUsedById,
    FetchTopUsed,
    FetchTopUsedById,
    PostTopUsedById,
    Pre_Pop_msg_TopUsed,
    UpdateTopUsedById,
} from "../asyncActions/topUsed";

const initialState: topUsedState = {
    topUsed: {
        list: { items: [] },
        listPaginate: [],
        isLoading: false,
        error: undefined,
        page: 1,
        search: "",
        limit: 100,
    },
    singleTopUsed: {
        data: undefined,
        isLoading: false,
        error: undefined,
    },
};

const topUsedSlice = createSlice({
    name: "topUsed",
    initialState,
    reducers: {},
    extraReducers: {
        [FetchTopUsed.pending.toString()]: (state) => {
            state.topUsed.isLoading = true;
            state.topUsed.error = undefined;
        },
        [FetchTopUsed.fulfilled.toString()]: (
            state,
            action: PayloadAction<[]>
        ) => {
            state.topUsed.isLoading = false;
            state.topUsed.list = action.payload;
        },
        [FetchTopUsed.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.topUsed.isLoading = false;
            state.topUsed.error = action.payload;
            state.topUsed.list = undefined;
        },
        //fetchById
        [FetchTopUsedById.pending.toString()]: (state) => {
            state.singleTopUsed.isLoading = true;
            state.singleTopUsed.error = undefined;
        },
        [FetchTopUsedById.fulfilled.toString()]: (
            state,
            action: PayloadAction<[]>
        ) => {
            state.singleTopUsed.isLoading = false;
            state.singleTopUsed.data = action.payload;
        },
        [FetchTopUsedById.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.singleTopUsed.isLoading = false;
            state.singleTopUsed.error = action.payload;
            state.singleTopUsed.data = undefined;
        },
    },
});

export const actions = {
    ...topUsedSlice.actions,
    FetchTopUsed,
    FetchTopUsedById,
    PostTopUsedById,
    UpdateTopUsedById,
    DeleteTopUsedById,
    Pre_Pop_msg_TopUsed,
};

const topUsedReducer = topUsedSlice.reducer;

export default topUsedReducer;
