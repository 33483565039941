import React from "react";
import { useTypedSelector } from "../../../../store/hooks/useTypedSelector";
import SubscriptionPlanDetails from "./SubscriptionPlanDetails";

const BillingSubscriptionComponent: React.FC = () => {
    const plans = useTypedSelector(
        (state) => state?.auth?.currentUser?.membershipPlan
    );
    return (
        <div className="card mb-5 mb-xl-10 mt-4">
            <div className="card-body p-9">
                <SubscriptionPlanDetails plans={plans || []} />
            </div>
        </div>
    );
};

export default BillingSubscriptionComponent;
