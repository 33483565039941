import React, { useMemo, useState } from "react";
import TabbedCard from "../../layouts/TabbedCard";
import NewMessageToParty from "../messages/NewMessageToParty";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { ScriptType } from "../../types/Script";
import RunTextFlow from "../textFlow/RunTextFlow";

const PartyMessageCard: React.FC = () => {

    const party = useTypedSelector(
        (state) => state.party.parties.selected.party
    ); 
    const textflowId = party?.scriptId ? party?.scriptId : -1;
    const scriptList = useTypedSelector(
        (state) => state.automations.scripts.list
    );
    const workflowsList = useMemo(
        () =>
        (scriptList as any)?.items?.filter(
                (script:any) => script?.scripttype?.name === ScriptType.PARTY
            ),
        [(scriptList as any)?.items]
    );
    const partyWorkflow = useMemo(
        () => workflowsList?.find((workflow:any) => workflow.id === textflowId),
        [workflowsList, textflowId]
    );

    const activeWorkflowComponent = partyWorkflow ? (
        <RunTextFlow
            script={partyWorkflow}
            scriptId={party?.scriptId}
            isModifiable={false}
        />
    ) : (
        <p className="w-100 text-center">No textflow</p>
    );
    const [msgText, setMsgText] = useState("");

    return (
            <TabbedCard
                items={[
                    {
                        component: (
                            <NewMessageToParty
                                id={party?.id}
                                prePopulatedMessage={msgText}
                                onReset={() => setMsgText("")}
                            />
                        ),
                        tabTitle: "Messages",
                    },
                    {
                        component: activeWorkflowComponent,
                        tabTitle: "Active Textflow",
                    },
                ]}
            />
    );
};

export default PartyMessageCard;
