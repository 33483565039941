import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import { Socket } from "socket.io-client";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { actions } from "../../store/reducers/partySlice";
import { Message } from "../../types/Message";
import Loading from "../common/Loading";
import ChatMessage from "./ChatMessage";

interface IProps {
    messages: Message[];
    isLoading?: boolean;
    partyId: number;
    socket?: Socket; 
}

const MessagesContainer: React.FC<IProps> = ({
    messages,
    isLoading,
    partyId,
    socket,
}) => { 
    const { fetchSelectedPartyMessages } = actions;
    const dispatch = useTypedDispatch();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleMsgThreadStateChange = useCallback((event) => {
        if (event === "new-message") {
           dispatch(fetchSelectedPartyMessages({ id: partyId }));
        }
    }, []);

    useEffect(() => {
        const messageEventName = `msg-thread-${partyId}`;
        // listen for incoming message
        socket?.on(messageEventName, handleMsgThreadStateChange);
        return () => {
            socket?.off(messageEventName);
        };
    }, [socket]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView();
            
            // containerRef.current.scrollIntoView({
            //     behavior: "smooth",
            // });
        }
    }, [messages]);

    if (isLoading && !messages) return <Loading />;

    return (
        <div>
            {messages.map((message) => (
                <ChatMessage
                    key={message.id}
                    message={message}
                    justify="right"
                    link={message.msgTemplate?.link}
                />
            ))} 
            <div ref={containerRef}></div>
        </div>
    );
};

export default MessagesContainer;
