import { composeDates } from "./composeDates";

interface IIsStartDateBeforeEndDate {
    partyStartDate: string;
    partyStartTime: string;
    partyEndDate: string;
    partyEndTime: string;
}

export const isStartDateBeforeEndDate = ({
    partyStartDate,
    partyStartTime,
    partyEndDate,
    partyEndTime,
}: IIsStartDateBeforeEndDate): boolean => {
    const startDateTime = composeDates(partyStartDate, partyStartTime);
    const endDateTime = composeDates(partyEndDate, partyEndTime);
    return startDateTime.isBefore(endDateTime);
};
