import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PartyState } from "../../types/Common";
import { Guest } from "../../types/Guest";
import { Message } from "../../types/Message";
import { MessageAnalytics, MsgThread, ThreadState } from "../../types/MsgThread";
import {
    addGuestFromContact,
    addGuestToParty,
    createGuestForParty,
    createGuestForPartySellerOnly,
    createParty,
    deleteAllPartyGuests,
    deleteParty,
    fetchAllUserParties,
    fetchCompletedPartiesList,
    fetchFuturePartiesList,
    fetchGuestsForParty,
    fetchPartySummary,
    fetchSelectedParty,
    fetchSelectedPartyGuests,
    fetchSelectedPartyGuestsPaginate,
    fetchSelectedPartyMessageAnalytics,
    fetchSelectedPartyMessages,
    fetchTodayPartiesList,
    pauseParty,
    removeGuestFromParty,
    resendOptInMessage,
    resetParty,
    resumeParty,
    sendMessageToTheSelectedParty,
    startParty,
    stopParty,
    updateParty,
} from "../asyncActions/party";

const initialState: PartyState = {
    selectedPartyGuestList: {
        list: [],
        isLoading: false,
        error: undefined,
    },
    createNewPartyForm: {
        error: undefined,
        isLoading: false,
    },
    parties: {
        today: {
            list: [],
            isLoading: false,
            error: undefined,
            page: 1,
            hasMore: true,
            search: "",
            limit: 20,
        },
        completed: {
            list: [],
            isLoading: false,
            error: undefined,
            page: 1,
            hasMore: true,
            search: "",
            limit: 20,
        },
        future: {
            list: [],
            isLoading: false,
            error: undefined,
            page: 1,
            hasMore: true,
            search: "",
            limit: 20,
        },
        all: {
            list: [],
            isLoading: false,
            error: undefined,
        },
        selected: {
            guests: {
                list: [],
                listPaginate: [],
                isLoading: true,
                error: undefined,
                page: 1,
                hasMore: true,
                search: "",
                limit: 100,
            },
            messages: {
                list: [],
                isLoading: true,
                error: undefined,
            },
            error: undefined,
            party: undefined,
            isRunning: false,
            isLoading: false,
            requiresRefresh: false,
        },
        current: {
            guests: {
                list: [],
                isLoading: true,
                error: undefined,
            },
            messages: {
                list: [],
                isLoading: true,
                error: undefined,
            },
            error: undefined,
            party: undefined,
            isLoading: false,
            requiresRefresh: false,
        },
        addGuestFromContact: {
            isLoading: false,
            error: undefined,
        },
    },

    messageAnalytics: {
        list: [],
        isLoading: false,
        error: undefined,
    },
};

const partySlice = createSlice({
    name: "party",
    initialState,
    reducers: {},
    extraReducers: {
        // Fetch messages for specific party (For the party details page)
        [fetchSelectedPartyMessages.pending.toString()]: (state) => {
            state.parties.selected.messages.error = undefined;
            state.parties.selected.messages.isLoading = true;
        },
        [fetchSelectedPartyMessages.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.messages.error = action.payload;
            state.parties.selected.messages.isLoading = false;
            state.parties.selected.messages.list = [];
        },
        [fetchSelectedPartyMessages.fulfilled.toString()]: (
            state,
            action: PayloadAction<Message[]>
        ) => {
            state.parties.selected.messages.isLoading = false;
            state.parties.selected.messages.list = action.payload;
        },
        // Sending message to the selected party (For the party details page)
        // [sendMessageToTheSelectedParty.pending.toString()]: (state, action: PendingActionFromAsyncThunk<typeof sendMessageToTheSelectedParty>) => {
        //     // state.parties.selected.messages.list.push({
        //     //     id: Date.now(),
        //     //     isRead: false,
        //     //     messageText: action.meta.arg.messageText,
        //     //     msgTemplateId: 0,
        //     //     msgThreadId: action.meta.arg.msgThreadId,
        //     //     nameAlias: "You"
        //     // })
        // },
        [sendMessageToTheSelectedParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<Message>
        ) => {
            state.parties.selected.messages.list.push(action.payload);
        },
        // Fetch specific party (For the party details page)
        [fetchSelectedParty.pending.toString()]: (state) => {
            state.parties.selected.isLoading = true;
            state.parties.selected.error = undefined;
            state.parties.selected.requiresRefresh = false;
        },
        [fetchSelectedParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.isLoading = false;
            state.parties.selected.error = action.payload;
        },
        [fetchSelectedParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.selected.isLoading = false;
            state.parties.selected.party = action.payload;
            if (action.payload.threadState.name === ThreadState.IN_PROGRESS) {
                state.parties.selected.isRunning = true;
            } else {
                state.parties.selected.isRunning = false;
            }
        },
        // Fetch guests for specific party (For the party details page)
        [fetchSelectedPartyGuests.pending.toString()]: (state) => {
            state.parties.selected.guests.isLoading = true;
            state.parties.selected.guests.error = undefined;
        },
        [fetchSelectedPartyGuests.fulfilled.toString()]: (state, action) => {
            state.parties.selected.guests.isLoading = false;
            state.parties.selected.guests.list = action.payload;
        },
        [fetchSelectedPartyGuests.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.guests.isLoading = false;
            state.parties.selected.guests.error = action.payload;
            state.parties.selected.guests.list = [];
        },
        // Fetch guests with pagination for specific party (For the party details page)

        [fetchSelectedPartyGuestsPaginate.pending.toString()]: (state) => {
            state.parties.selected.guests.isLoading = true;
            state.parties.selected.guests.error = undefined;
        },
        [fetchSelectedPartyGuestsPaginate.fulfilled.toString()]: (
            state,
            action
        ) => {
            state.parties.selected.guests.isLoading = false;
            state.parties.selected.guests.listPaginate = action.payload;
            const { _page, _limit, search } = action?.meta.arg;
            state.parties.selected.guests.page = _page;
            state.parties.selected.guests.limit = _limit;
            state.parties.selected.guests.search = search;
        },
        [fetchSelectedPartyGuestsPaginate.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.guests.isLoading = false;
            state.parties.selected.guests.error = action.payload;
            state.parties.selected.guests.listPaginate = [];
        },
        // Fetch guests for new guest modal, (NOT for the party details page)
        [fetchGuestsForParty.pending.toString()]: (state) => {
            state.selectedPartyGuestList.isLoading = true;
            state.selectedPartyGuestList.list = [];
            state.selectedPartyGuestList.error = undefined;
        },
        [fetchGuestsForParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.selectedPartyGuestList.isLoading = false;
            state.selectedPartyGuestList.error = action.payload;
        },
        [fetchGuestsForParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<Guest[]>
        ) => {
            state.selectedPartyGuestList.isLoading = false;
            state.selectedPartyGuestList.list = action.payload;
        },
        // Create new party
        [createParty.pending.toString()]: (state) => {
            state.createNewPartyForm.isLoading = true;
            state.createNewPartyForm.error = undefined;
        },
        [createParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createNewPartyForm.isLoading = false;
            state.createNewPartyForm.error = action.payload;
        },
        [createParty.fulfilled.toString()]: (state) => {
            state.createNewPartyForm.isLoading = false;
            state.createNewPartyForm.error = undefined;
        },
        // Fetch parties for today
        [fetchTodayPartiesList.pending.toString()]: (state) => {
            state.parties.today.error = undefined;
            state.parties.today.isLoading =
                state.parties.today.list.length === 0;
        },
        [fetchTodayPartiesList.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread[]>
        ) => {
            state.parties.today.list = action.payload;
            state.parties.today.isLoading = false;
            state.parties.today.hasMore =
                action.payload.length === state.parties.today.limit;
        },
        [fetchTodayPartiesList.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.today.error = action.payload;
            state.parties.today.isLoading = false;
        },
        // Fetch party message analytics
        [fetchSelectedPartyMessageAnalytics.pending.toString()]: (state) => {
            state.messageAnalytics.error = undefined;
            state.messageAnalytics.isLoading =
                state.messageAnalytics.list.length === 0;
        },
        [fetchSelectedPartyMessageAnalytics.fulfilled.toString()]: (
            state,
            action: PayloadAction<MessageAnalytics[]>
        ) => {
            state.messageAnalytics.list = action.payload;
            state.messageAnalytics.isLoading = false;
        },
        [fetchSelectedPartyMessageAnalytics.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageAnalytics.error = action.payload;
            state.messageAnalytics.isLoading = false;
        },
        // Fetch completed parties
        [fetchCompletedPartiesList.pending.toString()]: (state) => {
            state.parties.completed.error = undefined;
            state.parties.completed.isLoading =
                state.parties.completed.list.length === 0;
        },
        [fetchCompletedPartiesList.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread[]>
        ) => {
            state.parties.completed.list = action.payload;
            state.parties.completed.isLoading = false;
            state.parties.completed.hasMore =
                action.payload.length === state.parties.completed.limit;
        },
        [fetchCompletedPartiesList.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.completed.error = action.payload;
            state.parties.completed.isLoading = false;
        },
        // Fetch future parties
        [fetchFuturePartiesList.pending.toString()]: (state) => {
            state.parties.future.error = undefined;
            state.parties.future.isLoading =
                state.parties.future.list.length === 0;
        },
        [fetchFuturePartiesList.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread[]>
        ) => {
            state.parties.future.list = action.payload;
            state.parties.future.isLoading = false;
            state.parties.future.hasMore =
                action.payload.length === state.parties.future.limit;
        },
        [fetchFuturePartiesList.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.future.error = action.payload;
            state.parties.future.isLoading = false;
        },
        // Fetch all parties for some modals and calendar events
        [fetchAllUserParties.pending.toString()]: (state) => {
            state.parties.all.isLoading = state.parties.all.list.length === 0;
            state.parties.all.error = undefined;
        },
        [fetchAllUserParties.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.all.isLoading = false;
            state.parties.all.error = action.payload;
        },
        [fetchAllUserParties.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread[]>
        ) => {
            state.parties.all.isLoading = false;
            state.parties.all.list = action.payload;
        },
        // Update party
        [updateParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.current.party = action.payload;
            state.parties.selected.party = action.payload;
        },
        // Start party
        [startParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.party = action.payload;
            state.parties.selected.isLoading = false;
            state.parties.selected.isRunning = true;
        },
        [startParty.pending.toString()]: (state) => {
            state.parties.selected.isLoading = true;
            state.parties.selected.error = undefined;
            state.parties.selected.isRunning = false;
        },
        [startParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.requiresRefresh = true;
            state.parties.selected.isLoading = false;
            state.parties.selected.error = action.payload;
            state.parties.selected.isRunning = false;
        },
        // Stop party
        [stopParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.party = action.payload;
            state.parties.selected.isLoading = false;
        },
        [stopParty.pending.toString()]: (state) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.isLoading = true;
            state.parties.selected.error = undefined;
        },
        [stopParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.requiresRefresh = true;
            state.parties.selected.isLoading = false;
            state.parties.selected.error = action.payload;
        },
        // Reset party
        [resetParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.party = action.payload;
            state.parties.selected.isLoading = false;
        },
        [resetParty.pending.toString()]: (state) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.isLoading = true;
            state.parties.selected.error = undefined;
        },
        [resetParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.requiresRefresh = true;
            state.parties.selected.isLoading = false;
            state.parties.selected.error = action.payload;
        },
        // Resume party
        [resumeParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.party = action.payload;
            state.parties.selected.isLoading = false;
        },
        [resumeParty.pending.toString()]: (state) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.isLoading = true;
            state.parties.selected.error = undefined;
        },
        [resumeParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.requiresRefresh = true;
            state.parties.selected.isLoading = false;
            state.parties.selected.error = action.payload;
        },
        // Pause party
        [pauseParty.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgThread>
        ) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.party = action.payload;
            state.parties.selected.isLoading = false;
        },
        [pauseParty.pending.toString()]: (state) => {
            state.parties.selected.requiresRefresh = false;
            state.parties.selected.isLoading = true;
            state.parties.selected.error = undefined;
        },
        [pauseParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.selected.requiresRefresh = true;
            state.parties.selected.isLoading = false;
            state.parties.selected.error = action.payload;
        },

        [removeGuestFromParty.fulfilled.toString()]: (state, action) => {
            const responseData = action.payload;
            if (responseData.success) {
                const guestsList = state.parties.selected.guests.list;

                if (Array.isArray(guestsList)) {
                    state.parties.selected.guests.list = guestsList.filter(
                        (guest) => guest.id !== action.meta.arg.guestId
                    );
                } else {
                    state.parties.selected.guests.list = [];
                }
            }
        },

        //remove all guest
        [deleteAllPartyGuests.pending.toString()]: (state) => {
            state.parties.selected.guests.list = [];
        },
        [deleteAllPartyGuests.fulfilled.toString()]: (state) => {
            state.parties.selected.guests.list = [];
        },

        //create AddInAnnouncement
        [addGuestFromContact.pending.toString()]: (state) => {
            state.parties.addGuestFromContact.isLoading = true;
            state.parties.addGuestFromContact.error = undefined;
        },
        [addGuestFromContact.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.parties.addGuestFromContact.isLoading = false;
            state.parties.addGuestFromContact.error = action.payload;
        },
        [addGuestFromContact.fulfilled.toString()]: (state) => {
            state.parties.addGuestFromContact.isLoading = false;
            state.parties.addGuestFromContact.error = undefined;
        },
        // add guest from party
        [addGuestToParty.pending.toString()]: (state) => {
            state.createNewPartyForm.isLoading = true;
            state.createNewPartyForm.error = undefined;
        },
        [addGuestToParty.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createNewPartyForm.isLoading = false;
            state.createNewPartyForm.error = action.payload;
        },
        [addGuestToParty.fulfilled.toString()]: (state) => {
            state.createNewPartyForm.isLoading = false;
            state.createNewPartyForm.error = undefined;
        },
    },
});

export const actions = {
    ...partySlice.actions,
    fetchTodayPartiesList,
    fetchFuturePartiesList,
    fetchCompletedPartiesList,
    fetchGuestsForParty,
    createParty,
    fetchSelectedPartyGuests,
    fetchSelectedPartyGuestsPaginate,
    fetchAllUserParties,
    fetchSelectedParty,
    updateParty,
    fetchPartySummary,
    createGuestForParty,
    removeGuestFromParty,
    fetchSelectedPartyMessages,
    sendMessageToTheSelectedParty,
    fetchSelectedPartyMessageAnalytics,
    deleteParty,
    startParty,
    resetParty,
    resumeParty,
    pauseParty,
    stopParty,
    addGuestFromContact,
    addGuestToParty,
    resendOptInMessage,
    createGuestForPartySellerOnly,
};

const counterReducer = partySlice.reducer;

export default counterReducer;
