import {
    faEnvelope,
    faGift,
    faStream,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Popover } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/Overlay";
import { useHistory } from "react-router-dom";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { ScriptType } from "../../types/Script";

const PlusBtnPopover: React.FC<OverlayInjectedProps> = (props) => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const { openCreatePartyModal, openCreateScriptModal } = modalActions;

    const handleDirect = () => {
        history.push("/contact");
    };

    const items = [
        {
            title: "New Event",
            action: () => dispatch(openCreatePartyModal()),
            icon: (
                <FontAwesomeIcon
                    icon={faGift}
                    className="mr-2 w-15px h-15px text-secondary"
                />
            ),
        },
        {
            title: "New Textflow",
            action: () => {
                dispatch(
                    openCreateScriptModal({
                        type: ScriptType.PARTY,
                    })
                );
            },
            icon: (
                <FontAwesomeIcon
                    icon={faStream}
                    className="mr-2 w-15px h-15px text-secondary"
                />
            ),
        },
        {
            title: "New Direct Message",
            action: () => handleDirect(),
            icon: (
                <FontAwesomeIcon
                    icon={faEnvelope}
                    className="mr-2 w-15px h-15px text-secondary"
                />
            ),
        },
    ];

    return (
        <Popover id="popover-basic" {...props}>
            <Popover.Content>
                <ul className="p-0 m-0">
                    {items.map((item) => (
                        <li
                            key={item.title}
                            onClick={() => item.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );
};

export default PlusBtnPopover;
