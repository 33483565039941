import React, { useCallback } from "react";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { modalActions } from "../store/reducers/modalsSlice";

const AuthLayout: React.FC = ({ children }) => {
    const dispatch = useTypedDispatch();
    const { openLegalModal } = modalActions;

    const handleOpen = useCallback((id: string) => {
        dispatch(openLegalModal({ slug: id }));
    }, []);
    return (
        <>
            <div className="d-flex h-100 flex-column flex-root">
                <div className="d-flex h-100 bg-white flex-column flex-md-row">
                    <div className="w-500px d-none d-lg-block bg-primary" />
                    <div className="d-flex flex-column-fluid flex-center position-relative px-4">
                        {children}
                        <div className="position-absolute bottom-0 translate-middle d-flex mb-4">
                            <p
                                className="mx-2 font-weight-bold text-primary h6 btn-link text-decoration-none cursor-pointer"
                                onClick={() => handleOpen("terms-of-service")}
                            >
                                Terms of Service
                            </p>

                            <p
                                className="mx-2 font-weight-bold text-primary h6 btn-link text-decoration-none cursor-pointer"
                                onClick={() => handleOpen("privacy-policy")}
                            >
                                Privacy Policy
                            </p>
                            <p
                                className=" mx-2 font-weight-bold text-primary h6 btn-link text-decoration-none cursor-pointer"
                                onClick={() => handleOpen("acceptable-use-policy")}
                            >
                                Acceptable use Policy
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthLayout;
