import * as React from "react";
import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTypedDispatch } from "../../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../../store/hooks/useTypedSelector";
import { modalActions } from "../../../store/reducers/modalsSlice";
import MsgTemplateForm from "../../forms/MsgTemplateForm";

const MsgTemplateModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const isOpen = useTypedSelector(
        (state) => state.modals.msgTemplateModal.isOpen
    );
    const { closeMsgTemplateModal } = modalActions;

    const handleClose = useCallback(() => {
        dispatch(closeMsgTemplateModal());
    }, []);

    return (
        <Modal show={isOpen} onHide={handleClose} centered>
             <Modal.Header closeButton className="d-flex justify-content-end custom-modal-header">
        </Modal.Header>
            <Modal.Body>
                <MsgTemplateForm />
            </Modal.Body>
        </Modal>
    );
};

export default MsgTemplateModal;
