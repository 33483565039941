import * as React from "react";
import LayoutCard from "../../layouts/LayoutCard";
import { ScriptType } from "../../types/Script";
import PrepopulatedMsgScriptsListView from "../script/PrepopulatedMsgScriptsListView";

const PrepopulatedMessagesListCard: React.FC = () => {
    return (
        <LayoutCard title="Categories">
            <div
                data-kt-menu="true"
                className={
                    "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                }
            >
                <PrepopulatedMsgScriptsListView type={ScriptType.MESSAGE} />
            </div>
        </LayoutCard>
    );
};

export default PrepopulatedMessagesListCard;
