import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"; // Replace with the icon you want to use
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { fetchScriptAssetsByCollection } from "../../store/asyncActions/media";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { mediaActions } from "../../store/reducers/medaiSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import { FileWithPreview } from "../../types/Common";
import Loading from "../common/Loading";
import { UploadFiles } from "../icons";
import FilePreview from "./FilePreview";
import "./media.scss";

interface IProps {
    show: boolean;
    handleClose: () => void;
}

const MediaUploadModal: React.FC<IProps> = ({ handleClose, show }) => {
    const dispatch = useTypedDispatch();
    const collectionId = useTypedSelector(
        (state) => state.media.collections.selectedCollection
    );

    const { uploadFile } = mediaActions;
    const currentCollection = useTypedSelector(
        (state) => state.media.collections.selectedCollection
    );
    const { showSnackbarWithAutoHide } = notificationActions;
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<FileWithPreview[]>([]);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFileArray = acceptedFiles.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
        );
        setFiles(newFileArray);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: isLoading,
        accept: "image/*, video/*, audio/*",
    });

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    const clearFiles = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setFiles([]);
    };
    // const [showUploadError, setShowUploadError] = useState("");

    const uploadFiles = async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (collectionId === 0) {
            // setShowUploadError("Select collection to be able to upload media!");
            return dispatch(
                showSnackbarWithAutoHide({
                    msgText: "Select collection to be able to upload media!",
                })
            );
        }
        if (files.length === 0) return;
        setIsLoading(true);
        try {
            await dispatch(
                uploadFile({
                    files,
                    collectionId,
                })
            );
            dispatch(
                fetchScriptAssetsByCollection({
                    collectionId: currentCollection,
                })
            );
            handleClose();
            setFiles([]);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.error(err);
        }
    };

    const thumbs = (
        <div className="d-flex flex-row flex-wrap mt-4 justify-content-center align-items-center">
            {files.map((file, index) => (
                <div
                    key={file.name}
                    className="d-inline-flex rounded border mb-2 mx-1 p-1 bg-gray-100 text-wrap text-truncate position-relative"
                >
                    <FilePreview file={file} />
                    {isLoading && (
                        <div className="position-absolute top-0 left-0 d-flex justify-content-center align-items-center semi-transparent w-100 h-100">
                            <Loading />
                        </div>
                    )}
                    <button
                        className="close-icon"
                        onClick={(event) => removeFile(index, event)}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                </div>
            ))}
        </div>
    );

    const DragZone = (
        <div className="drag-area rounded py-4 px-2 d-flex flex-column justify-content-center align-items-center h-400px cursor-pointer">
            <span className="text-primary upload-icon mt-6">
                <UploadFiles />
            </span>
            <p className="p3 text-center">Drag & Drop File Here...</p>
            <p className="p3">OR</p>
            <Button variant="primary mb-4">Select File to Upload</Button>
        </div>
    );

    const DropZone = (
        <div className="drop-area rounded py-4 px-2 d-flex flex-column justify-content-center align-items-center h-400px">
            <span className="text-primary upload-icon mt-6">
                <UploadFiles />
            </span>
            <p className="p3 text-center">Release File to Upload...</p>
        </div>
    );
    const removeFile = (index: number, event: any) => {
        event.stopPropagation();
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const FilesPreview = (
        <div className="drag-area rounded py-4 px-2 d-flex flex-column justify-content-center align-items-center min-h-400px cursor-pointer">
            {thumbs}
            {files.length !== 0 && (
                <>
                    <Button
                        variant="primary"
                        className="mt-4"
                        onClick={uploadFiles}
                        disabled={isLoading}
                    >
                        Upload selected files
                    </Button>
                    <Button
                        variant="secondary"
                        className="mt-4"
                        onClick={clearFiles}
                        disabled={isLoading}
                    >
                        Clear selected files
                    </Button>
                </>
            )}
        </div>
    );
    const content = (
        <div className="d-flex flex-column">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    DropZone
                ) : (
                    <>{files.length !== 0 ? FilesPreview : DragZone} </>
                )}
            </div>
        </div>
    );

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="max-w-100 min-w-100 w-100"
        >
            <Modal.Header
                closeButton
                className="d-flex justify-content-between align-items-center"
            >
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                ></button>
            </Modal.Header>
            <Modal.Body>{!show ? <Loading /> : content}</Modal.Body>
        </Modal>
    );
};

export default MediaUploadModal;
