import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DirectMessageThreadsCard from "../components/cardViews/DirectMessageThreadsCard";
import MessagingThreadCard from "../components/cardViews/MessagingThreadCard";
import useQueryParams from "../hooks/useQueryParams";
import DefaultLayout from "../layouts/DefaultLayout";

const DirectMessages: React.FC = () => {
    const searchParams = useQueryParams();
    const history = useHistory();

    const threadId = Number(searchParams.get("thread"));

    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={2} xl={2}>
                <Col
                    xs={12}
                    sm={12}
                    xl={4}
                    className={`pt-15 ${threadId ? "d-lg-block d-none" : ""}`}
                >
                    <DirectMessageThreadsCard />
                </Col>
                <Col
                    xl={8}
                    className={`pt-15 ${threadId ? "" : "d-lg-block d-none"}`}
                >
                    <Button
                        variant="primary"
                        className={`mb-4 ${
                            threadId ? "d-lg-none d-block" : "d-none"
                        }`}
                        onClick={() => history.push("/direct_messages")}
                    >
                        Back to direct messages
                    </Button>
                    <MessagingThreadCard />
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default DirectMessages;
