import React, { useMemo,useEffect ,useState} from "react";
import LayoutCard from "../../layouts/LayoutCard";
import PartyDetails from "../patrySchedule/PartyDetails";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { Script, ScriptType } from "../../types/Script";
import Loading from "../common/Loading";
import { Socket } from "socket.io-client";
import { Guest } from "../../types/Guest";

interface IProps {
    socket?: Socket;
}
const PartyDetailsCard: React.FC<IProps> = ({ socket }) => {
    const { party: currentParty } = useTypedSelector(
        (state) => state.party.parties.selected
    );

    const { isLoading: isGuestListLoading, list: guestsList,  listPaginate: guestsListPaginate } =
        useTypedSelector((state) => state.party.parties.selected.guests);

    const { list: scriptList, isLoading: scriptLoading } = useTypedSelector(
        (state) => state?.automations?.scripts
    );

    const workflowsList = useMemo(
        () =>
        (scriptList as any)?.items?.filter(
                (script:any) => script?.scripttype?.name === ScriptType.PARTY
            ),
        [(scriptList as any)?.items]
    ); 
    return (
        <LayoutCard>
            {!currentParty ? (
                <Loading />
            ) : (
                <PartyDetails
                    currentParty={currentParty}
                    workflowsList={workflowsList}
                    guestsList={guestsList}
                    guestsListPaginate={guestsListPaginate}
                    guestsListLoading={isGuestListLoading}
                    workflowsListLoading={scriptLoading}
                    socket={socket} 
                />
            )}
        </LayoutCard>
    );
};

export default PartyDetailsCard;
