import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";

import {
    faEllipsisH,
    faPlus,
    // faEnvelope,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { deleteAllPartyGuests, fetchSelectedPartyGuestsPaginate } from "../../store/asyncActions/party";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import { Guest } from "../../types/Guest";
interface IProps {
    btnOnClick?: () => void;
}

const ManageContactActionsPopover: React.FC<IProps> = ({ btnOnClick }) => {
    const dispatch = useTypedDispatch();
    const { openAddGuestContactPartyModal } = modalActions;
    const { showSnackbarWithAutoHide } = notificationActions;
    const [apiList, setApiList] = useState<Guest[]>([]);
    const { list: guestsList } = useTypedSelector(
        (state) => state.party.parties.selected.guests
    );
    const party = useTypedSelector(
        (state) => state.party?.parties?.selected?.party
    );
    useEffect(() => {
        if ((guestsList as any)?.items) {
            setApiList((guestsList as any)?.items);
        }
    }, [guestsList]);

    const handleBtnClick = btnOnClick
        ? btnOnClick
        : () => {
              return;
          };
    const items = [
        {
            title: "Add Guest From Event",
            action: handleBtnClick,
            icon: (
                <FontAwesomeIcon
                    icon={faPlus}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Add Guest from Contacts",
            action: () => {
                if (party !== undefined) {
                    dispatch(
                        openAddGuestContactPartyModal({
                            currentParty: party,
                        })
                    );
                }
            },
            icon: (
                <FontAwesomeIcon
                    icon={faPlus}
                    className="text-warning w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Remove All Guests",

            action: () => {
                confirmAlert({
                    title: "Confirm to delete",
                    message: "Are you sure you want to delete all guests?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: async () => {
                                await dispatch(
                                    deleteAllPartyGuests({
                                        guests: apiList.map(
                                            (guest) => guest.id
                                        ),
                                    })
                                );
                                await dispatch(
                                    fetchSelectedPartyGuestsPaginate({
                                        partyId: party?.id || 0
                                    })
                                );
                            },
                        },
                        {
                            label: "No",
                            onClick: () => console.info("No."),
                        },
                    ],
                });
            },
            icon: (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger w-15px h-15px mr-2"
                />
            ),
        },
    ];
    const renderPopover = (props: any) => (
        <Popover id="manage-script-popover" {...props}>
            <Popover.Content className="p-3">
                <ul className="p-0 m-0">
                    {items?.map((item: any) => (
                        <li
                            key={item.title}
                            onClick={() => item?.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            overlay={renderPopover}
            placement="right"
        >
            <FontAwesomeIcon
                icon={faEllipsisH}
                className="w-25px h-30px cursor-pointer ml-2 more-actions rounded-lg shadow-sm btn btn-primary"
            />
        </OverlayTrigger>
    );
};

export default ManageContactActionsPopover;
