import * as React from "react";
import { useCallback, useState } from "react";
import * as Yup from "yup";
import { MsgTemplateFormValues } from "../../types/Forms";
import { Field, FieldProps, Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import CustomInput from "./fields/CustomInput";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { FormAction } from "../../types/Common";
import { actions } from "../../store/reducers/automationsSlice";
import { ServerError } from "../../types/Error";
import FormErrors from "./FormErrors";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { MsgTemplate } from "../../types/Script";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../script/ConfirmationModal";
import CustomCheck from "./fields/CustomCheck";
import MediaGridViewSelect from "../media/MediaGridViewSelect";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    quietWaitSeconds: Yup.number().min(0, "Time can not be negative"),
    maxWaitSeconds: Yup.number().min(0, "Time can not be negative"),
    messageText: Yup.string().required("Message text is required"),
});

interface IProps {
    currentMessageTemplate?: MsgTemplate;
    currentScriptId: number;
    action: FormAction;
}

// Note: this is really similar to MsgTemplateForm, there could be an opportunity to refactor.
const PrepopulatedMsgTemplateForm: React.FC<IProps> = ({
    currentMessageTemplate,
    action,
    currentScriptId,
}) => {
    const history = useHistory();
    const msgTemplatesList = useTypedSelector(
        (state) => state.automations.messageTemplates.list
    );
    const dispatch = useTypedDispatch();
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );

    const { createMsgTemplate, updateMsgTemplate, deleteMsgTemplate } = actions;
    const [showConfirmation, setShowConfiration] = useState(false);
    const initialValues: MsgTemplateFormValues = {
        name: currentMessageTemplate?.name || "",
        templateLength: msgTemplatesList?.length || 0,
        maxWaitSeconds: currentMessageTemplate?.maxWaitSeconds || 0,
        quietWaitSeconds: currentMessageTemplate?.quietWaitSeconds || 0,
        link: currentMessageTemplate?.link || "",
        messageText: currentMessageTemplate?.messageText || "",
        isFavorite: currentMessageTemplate?.isFavorite || false,
        mediaItems: currentMessageTemplate?.mediaItems || [],
    };

    const cancelEdit = useCallback(() => {
        setShowConfiration(false);
        const rexexp = /(&template=\d+)|(&action=create)/;
        history.push(
            history.location.pathname +
                history.location.search.replace(rexexp, "")
        );
    }, []);

    const submitForm = useCallback(
        (values: any, scriptId: number) => {
            if (action === FormAction.CREATE)
                return dispatch(
                    createMsgTemplate({
                        scriptId,
                        ...values,
                    })
                );
            else
                return dispatch(
                    updateMsgTemplate({
                        ...values,
                        id: currentMessageTemplate?.id,
                    })
                );
        },
        [currentMessageTemplate]
    );

    const deleteScript: () => void = useCallback(() => {
        if (!currentMessageTemplate) return;

        dispatch(
            deleteMsgTemplate({
                msgTemplateId: currentMessageTemplate.id,
            })
        )
            .unwrap()
            .finally(cancelEdit);
    }, [currentMessageTemplate]);

    const btnLabel = action === FormAction.CREATE ? "Create" : "Update";

    return (
        <>
            {showConfirmation && currentMessageTemplate && (
                <ConfirmationModal
                    title="Delete Confirmation"
                    onConfirm={deleteScript}
                    onHide={() => setShowConfiration(false)}
                >{`Are you sure you want to delete template '${currentMessageTemplate.name}`}</ConfirmationModal>
            )}

            <span className="w-95">

            </span>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    values.templateLength = msgTemplatesList.length + 1;
                    setFormErrors(undefined);
                    submitForm(values, currentScriptId)
                        .unwrap()
                        .then(() => {
                            cancelEdit();
                            setSubmitting(false);
                        })
                        .catch((err) => {
                            setFormErrors(err);
                            setErrors(transformToFormikErrors(err));
                            setSubmitting(false);
                        });
                }}
                enableReinitialize={true}
            >
                {({
                    handleSubmit,
                    dirty,
                    isSubmitting,
                    isValid,
                    resetForm,
                }) => (
                    <Form onSubmit={handleSubmit} className="w-95 m-auto">
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder={"Enter name here"}
                                    type="text"
                                    label={"Name"}
                                    name="name"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Enter link here"
                                    type="text"
                                    label="Link"
                                    name="link"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Enter message text here"
                                    type="text"
                                    label="Message text"
                                    name="messageText"
                                    as="textarea"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="d-flex justify-content-between">
                            <Form.Group className="col-5">
                                <CustomInput
                                    placeholder="Enter quiet wait time here (in seconds)"
                                    type="number"
                                    label="Quiet wait time  (seconds)"
                                    name="quietWaitSeconds"
                                    min={0}
                                />
                            </Form.Group>
                            <Form.Group className="col-5">
                                <CustomInput
                                    placeholder="Enter max wait time here (in seconds)"
                                    type="number"
                                    label="Max wait time (seconds)"
                                    name="maxWaitSeconds"
                                    min={0}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-6">
                                <CustomCheck
                                    type="switch"
                                    label="Favorite"
                                    name="isFavorite"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Group className="col-md">
                            <Form.Row>
                                <Field
                                    className="custom-select"
                                    name="mediaItems"
                                    placeholder="Select Images"
                                    isMulti={true}
                                >
                                    {(props: FieldProps) => (
                                        <MediaGridViewSelect
                                            currentAssets={
                                                currentMessageTemplate?.mediaItems
                                            }
                                            {...props}
                                        ></MediaGridViewSelect>
                                    )}
                                </Field>
                            </Form.Row>
                        </Form.Group>
                        <FormErrors errors={formErrors} />
                        <div className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                disabled={!dirty || !isValid || isSubmitting}
                                className="mx-4"
                                type="submit"
                            >
                                {btnLabel}
                            </Button>
                            <Button
                                variant="secondary"
                                className="mx-4"
                                onClick={() => {
                                    resetForm();
                                    cancelEdit();
                                }}
                            >
                                Cancel
                            </Button>
                            {action === FormAction.UPDATE && (
                                <Button
                                    onClick={() => setShowConfiration(true)}
                                    variant="danger"
                                    className="mx-4"
                                >
                                    Delete
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PrepopulatedMsgTemplateForm;
