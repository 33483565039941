import { faCircleDot, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React from "react";
import { Card, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { restoreUser } from "../../store/asyncActions/auth";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
// import { FileWithPreview } from "../../types/Common";
// import { ServerError } from "../../types/Error";
import InputForImageUploading from "../forms/fields/InputForImageUploading";

const default_profile = "../assets/media/images/default_profile.png";
// const default_profile =
//     "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.dreamstime.com%2Fillustration%2Fdefault.html&psig=AOvVaw30cLcBrXkvcJ7VQAbZe-NS&ust=1699605084163000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCNDA2rvAtoIDFQAAAAAdAAAAABAI";
const validationSchema = Yup.object().shape({
    profilePic: Yup.string(),
});

const MyProfileHeader = () => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector((state) => state.auth.isLoading);
    const user = useTypedSelector((state) => state.auth.currentUser);
    // const [formErrors, setFormErrors] = React.useState<undefined | ServerError>(
    //     undefined
    // );
    const { updateUserProfile } = authActions;
    const { showSnackbarWithAutoHide } = notificationActions;
    // const [selectedFile, setSelectedFile] = React.useState<
    //     FileWithPreview | undefined
    // >(undefined);
    const initialValues = {
        profilePic: user?.profilePic || default_profile,
    };

    const totalDirectMessageTexts =
        user?.planCount?.membershipPlanCount?.TotalDirectMessageTexts;
    const usedDirectMessageTexts =
        user?.planCount?.UsedMembershipPlanCount?.usedDirectMessageTexts;

    let remainingDirectMessageTexts;

    if (
        typeof totalDirectMessageTexts === "number" &&
        typeof usedDirectMessageTexts === "number"
    ) {
        if (usedDirectMessageTexts >= totalDirectMessageTexts) {
            remainingDirectMessageTexts = 0;
        } else {
            remainingDirectMessageTexts =
                totalDirectMessageTexts - usedDirectMessageTexts;
        }
    } else {
        remainingDirectMessageTexts =
            typeof totalDirectMessageTexts === "string"
                ? totalDirectMessageTexts
                : "Invalid data";
    }

    const handleProfilePicChange = async (profilePic: any) => {
        try {
            if (profilePic !== undefined) {
                const response = await fetch(profilePic);
                const contentType: any = response.headers.get("content-type");
                const profilePicArrayBuffer = await response.arrayBuffer();
                const profilePicData = new Blob([profilePicArrayBuffer], {
                    type: contentType,
                });

                const apiPayload = {
                    file: profilePicData,
                    type: "profile",
                };

                const result = await dispatch(
                    updateUserProfile(apiPayload)
                ).unwrap();
                if (result) {
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Updated!",
                        })
                    );
                }
                dispatch(restoreUser());
            }
        } catch (error) {
            console.error("Error uploading profile picture:", error);
        }
    };

    const location = useLocation();

    return (
        <div className="row">
            <div className="col-12">
                <div className="card mt-2">
                    <div className="card-body pt-4 pb-0">
                        {" "}
                        {/* Adjusted padding */}
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (
                                    values,
                                    { setSubmitting, setErrors }
                                ) => {
                                    try {
                                        setErrors({ profilePic: "" });

                                        const initialProfilePic =
                                            user?.profilePic;
                                        const uploadedProfilePic =
                                            values.profilePic;

                                        let profilePicData = null;
                                        if (
                                            uploadedProfilePic !==
                                                initialProfilePic &&
                                            values.profilePic !== null
                                        ) {
                                            const response = await fetch(
                                                values.profilePic
                                            );
                                            const contentType: any =
                                                response.headers.get(
                                                    "content-type"
                                                );
                                            const profilePicArrayBuffer =
                                                await response.arrayBuffer();
                                            profilePicData = new Blob(
                                                [profilePicArrayBuffer],
                                                {
                                                    type: contentType,
                                                }
                                            );
                                        }

                                        const payload = {
                                            file: profilePicData,
                                            type: "profile",
                                        };

                                        await dispatch(
                                            updateUserProfile(payload)
                                        ).unwrap();

                                        dispatch(
                                            showSnackbarWithAutoHide({
                                                msgText: "Updated!",
                                            })
                                        );
                                        dispatch(restoreUser());
                                        setSubmitting(false);
                                    } catch (err: any) {
                                        setErrors({ profilePic: err });
                                        setSubmitting(false);
                                        setErrors(transformToFormikErrors(err));
                                    }
                                }}
                            >
                                {({ handleSubmit, setFieldValue, values }) => (
                                    <Form
                                        onSubmit={handleSubmit}
                                        className="d-flex flex-column justify-content-between h-100"
                                    >
                                        <div>
                                            <Form.Row>
                                                <Form.Group>
                                                    <InputForImageUploading
                                                        btnText="Upload New Profile Photo"
                                                        initialValue={
                                                            values.profilePic
                                                        }
                                                        valueSetter={(
                                                            profilePic: any
                                                        ) => {
                                                            setFieldValue(
                                                                "profilePic",
                                                                profilePic
                                                            );
                                                            // setSelectedFile(
                                                            //     profilePic
                                                            // );
                                                            handleProfilePicChange(
                                                                profilePic
                                                            );
                                                        }}
                                                        isLoading={isLoading}
                                                        isLoadingLogo={false}
                                                        // fileSetter={
                                                        //     setSelectedFile
                                                        // }
                                                        hideButton={true}
                                                        customStyles="h-150px w-150px img-circle-border rounded"
                                                        fileSetter={() => {
                                                            return "";
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                            {/* <div className="me-7 mb-4">
                                <div className="symbol symbol-160px symbol-lg-160px symbol-fixed position-relative">
                                    <img
                                        style={{
                                            width: "160px",
                                            height: "160px",
                                            maxWidth: "160px",
                                        }}
                                        src={`${
                                            user?.profilePic
                                                ? getFileUrl(user.profilePic)
                                                : "https://preview.keenthemes.com/metronic8/demo2/assets/media/avatars/300-1.jpg"
                                        }`}
                                        alt={user?.nameFirst}
                                        className="bg-primary img-circle-border rounded-circle"
                                    />
                                    <div
                                        style={{ right: "-10px" }}
                                        className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px ${
                                            user?.isActive
                                                ? "bg-success"
                                                : "bg-warning"
                                        }`}
                                    >
                                        <label
                                            htmlFor="profilePicInput"
                                            className="pencil-icon text-white rounded-circle d-flex justify-content-center align-items-center"
                                            style={{ cursor: "pointer" }}
                                        ></label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            <a
                                                href="#"
                                                className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                                            >
                                                {`${user?.nameFirst} ${user?.nameLast} (${user?.email})`}
                                            </a>
                                            <a href="#">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                    className="mr-2 text-gray-900"
                                                />
                                            </a>
                                        </div>

                                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                            <a
                                                href="#"
                                                className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleDot}
                                                    className="mr-2"
                                                />
                                                {user?.timezoneDefault}
                                            </a>
                                        </div>
                                        <Card
                                            style={{
                                                width: "fit-content",
                                                height: "fit-content",
                                            }}
                                        >
                                            <Card.Body className="p-4">
                                                <Card.Title className="m-0">
                                                    {
                                                        remainingDirectMessageTexts
                                                    }
                                                    /
                                                    {
                                                        user?.planCount
                                                            .membershipPlanCount
                                                            .TotalDirectMessageTexts
                                                    }
                                                </Card.Title>
                                                <Card.Text>
                                                    Direct Message
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    {/* <div className="d-flex my-4">
                                        <div className="me-0">
                                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                                                <i className="bi bi-three-dots fs-3"></i>
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex overflow-auto h-55px">
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                                <li className="nav-item">
                                    <Link
                                        className={
                                            "nav-link text-active-primary me-6 h-100 " +
                                            (location.pathname ===
                                                "/my-profile/overview" &&
                                                "active")
                                        }
                                        to="/my-profile/overview"
                                    >
                                        Overview
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            "nav-link text-active-primary me-6 h-100 " +
                                            (location.pathname ===
                                                "/my-profile/billing" &&
                                                "active")
                                        }
                                        to="/my-profile/billing"
                                    >
                                        Billing
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            "nav-link text-active-primary me-6 h-100 " +
                                            (location.pathname ===
                                                "/my-profile/company" &&
                                                "active")
                                        }
                                        to="/my-profile/company"
                                    >
                                        Company
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            "nav-link text-active-primary me-6 h-100 " +
                                            (location.pathname ===
                                                "/my-profile/change-password" &&
                                                "active")
                                        }
                                        to="/my-profile/change-password"
                                    >
                                        Change Password
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfileHeader;
