export type BillingCard = {
    isDefault?: boolean;
    metadata: any;
    id?: string | number | undefined;
    name: string;
    number: string;
    exp_month: string;
    exp_year: string;
    cvc: number;
    is_active?: number;
    card_type?: string;
    save_card?: boolean;
    last4?: string;
    brand?: string;
};

export const initialBillingModel: BillingCard = {
    name: "demo card",
    number: "4242424242424242",
    exp_month: "10",
    exp_year: "2024",
    cvc: 0,
    is_active: 1,
    card_type: "visa",
    save_card: true,
    metadata:undefined
};
