import * as React from "react";
import { AssetType, ScriptAsset } from "../../types/Media";
import { AudioIcon, ImageIcon, VideoIcon } from "../icons";
import "./media.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faFileAudio,
    faFileVideo,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    item: ScriptAsset["items"];
    onItemClick?: (item: ScriptAsset["items"]) => void;
    onIconClick?: (item: ScriptAsset["items"]) => void;
    selectedIcon?: IconDefinition;
    iconColor?: string;
}

interface Icons {
    [key: string]: React.ReactElement;
}

const IconsForType: Icons = {
    image: <ImageIcon className="h-25px w-25px" />,
    video: <VideoIcon className="h-25px w-25px" />,
    audio: <AudioIcon className="h-25px w-25px" />,
    any: <></>,
};

const MediaGridItem: React.FC<IProps> = ({
    item,
    onItemClick,
    onIconClick,
    selectedIcon = faCheckCircle,
    iconColor = "green",
}) => {
    const assetType = item?.assetType?.name || "any"; // Fallback to 'any' if the name is not available
    let content;
    switch (item?.assetType?.name) {
        case AssetType.VIDEO:
            content = (
                <div
                    className="w-100 h-100 d-flex justify-content-center align-items-center"
                    title={item.name}
                >
                    <FontAwesomeIcon
                        icon={faFileVideo}
                        className="h-50px w-50px"
                    />
                </div>
            );
            break;

        case AssetType.AUDIO:
            content = (
                <div
                    className="w-100 h-100 d-flex justify-content-center align-items-center"
                    title={item.name}
                >
                    <FontAwesomeIcon
                        icon={faFileAudio}
                        className="h-50px w-50px"
                    />
                </div>
            );
            break;

        case AssetType.IMAGE:
            content = (
                <img
                    title={item.name}
                    src={item.linkUrl}
                    className="d-block w-100px h-100px preview-image"
                    alt={item.name}
                />
            );
            break;

        default:
            content = <>{item.name}</>;
            break;
    }

    return (
        <div
            className="rounded-lg overflow-hidden"
            title={item.name}
            onClick={() => onItemClick?.(item)}
        >
            <div
                className={
                    "bg-gray-300 m-1 media-grid__item position-relative d-flex justify-content-center align-items-center" +
                    (onItemClick ? " cursor-pointer" : "")
                }
            >
                {content}
                <div className="h-25px w-25px d-flex justify-content-center align-items-center position-absolute bottom-0 right-0 bg-white">
                    {IconsForType[assetType]}
                </div>

                {item.isSelected && (
                    <div
                        className={
                            "h-12px w-12px d-flex justify-content-center align-items-center position-absolute top-0 right-0 mt-2 mr-2 bg-white rounded-circle" +
                            (onIconClick ? " cursor-pointer" : "")
                        }
                    >
                        {/* <SvgCheckCircleRegular className="h-25px w-25px" /> */}
                        <FontAwesomeIcon
                            icon={selectedIcon}
                            color={iconColor}
                            mask={["far", "circle"]}
                            size="lg"
                            onClick={() => onIconClick?.(item)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const MediaGridItemSkeleton: React.FC = () => {
    return (
        <div className="rounded-lg" title="Loading...">
            <div className="m-1 media-grid__item">
                <div className="skeleton h-100 w-100" />
            </div>
        </div>
    );
};

export default MediaGridItem;
