import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { AxiosError } from "axios";
import { isArray, map } from "lodash";
import { notificationActions } from "../store/reducers/notificationsSlice";

export const processAsyncThunkAxiosErrors = (
    err: {
        response: {
            data: AxiosError;
        };
    },
    thunkApi: BaseThunkAPI<
        unknown,
        unknown,
        ThunkDispatch<unknown, unknown, AnyAction>,
        unknown
    >
) => {
    const { rejectWithValue, dispatch } = thunkApi;
    const { showSnackbarWithAutoHide } = notificationActions;
    const message: unknown = err.response.data.message;

    if (!message) {
        dispatch(
            showSnackbarWithAutoHide({
                msgText: "Server error",
            })
        );
        return rejectWithValue("Server error");
    } else if (typeof message === "string") {
        dispatch(
            showSnackbarWithAutoHide({
                msgText: message,
            })
        );
        return rejectWithValue(message);
    } else if (isArray(message) && message?.length !== 0) {
        dispatch(
            showSnackbarWithAutoHide({
                msgText: map(message, "constrains").join(", "),
            })
        );
        return rejectWithValue(message);
    } else {
        dispatch(
            showSnackbarWithAutoHide({
                msgText: "Server error",
            })
        );
    }

    return rejectWithValue(message);
};
