import { Feature } from "../../types/Feature";
import { KTSVG } from "../../helpers/KTSVG";
import React from "react";

interface FProps {
    featureProp: Feature;
}

const FeatureTypeRendering: React.FC<FProps> = ({ featureProp }) => {
    if (featureProp?.ftype === "boolean") {
        return featureProp?.fvalue ? (
            <KTSVG
                path="/media/gen043.svg"
                className="svg-icon-1 svg-icon-success"
            />
        ) : (
            <KTSVG path="/media/gen040.svg" className="svg-icon-1" />
        );
    } else {
        return <span>{featureProp?.fvalue || ""}</span>;
    }
};

export default FeatureTypeRendering;
