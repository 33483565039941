import * as React from "react";

function SvgInfoCircle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 64 64"
            {...props}
        >
            <path d="M22.2 1.8C0 9.8-6.8 36.2 9 53.4c12.6 13.7 33.4 13.7 46 0 6.2-6.8 8.5-12.4 8.5-21.4 0-6.2-.5-8.5-2.8-13.1C53.5 4.2 36.6-3.3 22.2 1.8zm22.7 5.9c9.7 5 16.1 16.8 14.7 27.2-.9 6.8-5.4 15-10.4 18.8C33.3 65.8 11 58.4 5.4 39.3c-2-7-1.3-13 2.3-20.2C14.7 5.5 30.9.5 44.9 7.7z" />
            <path d="M29 16.5c-2.6 3.2 1.3 8.1 4.8 5.9 2.2-1.4 2.7-4.1 1.2-5.9-1.6-1.9-4.4-1.9-6 0zM28 39v10h8V29h-8v10z" />
        </svg>
    );
}

export default SvgInfoCircle;
