import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import {
    addGuestToParty,
    fetchSelectedPartyGuestsPaginate,
} from "../../store/asyncActions/party";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { actions } from "../../store/reducers/partySlice";

interface GuestList {
    label: string;
    value: number;
}
const NewDirectMessageModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [partySelectValue, setPartySelectValue] = useState(0);
    const [guestSelectValue, setGuestSelectValue] = useState<GuestList[]>([]);
    const { party: currentParty } = useTypedSelector(
        (state) => state.party.parties.selected
    );

    const {
        isLoading,
        list: guestList,
        error,
    } = useTypedSelector((state) => state.party.selectedPartyGuestList);
    const { list: allPartiesList } = useTypedSelector(
        (state) => state.party.parties.all
    );
    const show = useTypedSelector(
        (state) => state.modals.newDirectMessageModal.isOpen
    );

    const { fetchGuestsForParty, fetchAllUserParties } = actions;
    const { toggleNewDirectMessageModalOpen } = modalActions;

    useEffect(() => {
        if (allPartiesList?.length === 0)
            dispatch(fetchAllUserParties({ fetchInactive: false }));
    }, []);

    useEffect(() => {
        if (partySelectValue !== 0)
            dispatch(
                fetchGuestsForParty({
                    partyId: partySelectValue,
                })
            );
    }, [partySelectValue]);

    const handleClose = useCallback(() => {
        dispatch(toggleNewDirectMessageModalOpen(false));
    }, []);

    const handlePartyChange = (selectedOption: any) => {
        setPartySelectValue(
            Number(selectedOption ? selectedOption.value : null)
        );
        setGuestSelectValue([]);
    };
    const guestListItems = (guestList as any)?.items;
    const isGuestSelectDisabled =
        isLoading ||
        partySelectValue === 0 ||
        guestListItems?.length === 0 ||
        !!error;

    const guestSelectInitialValue =
        !isLoading && guestListItems?.length === 0 && partySelectValue !== 0
            ? [{ label: "This event has no guests", value: "" }]
            : [{ label: "Select Guest...", value: "" }];

    const guestListOptions = guestListItems
        ? guestListItems.map((guest: any) => ({
              label: guest.nameFirst + " " + guest.nameLast,
              value: guest.id,
          }))
        : [];
    const { page, limit, search } = useTypedSelector(
        (state) => state?.party?.parties?.selected?.guests
    );

    const handleCustomSubmit = () => {
        let guestIds = [];

        guestIds = guestSelectValue.map((guest) => guest?.value);
        const payload = {
            partyId: currentParty?.id,
            guestId: guestIds,
        };

        return dispatch(addGuestToParty(payload))
            .unwrap()
            .then((data: any) => {
                setPartySelectValue(0);
                setGuestSelectValue([]);
                dispatch(
                    fetchSelectedPartyGuestsPaginate({
                        partyId: currentParty?.id ?? 0,
                        _page: page,
                        _limit: limit,
                        search,
                    })
                );
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: data,
                    })
                );
                return data;
            })
            .then(() => {
                handleClose();
            });
    };
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleCustomSubmit();
                    }}
                    className="d-flex flex-column justify-content-between h-100"
                >
                    <div className="mb-6">
                        <p className="text-black-50 mb-1">Event</p>
                        <Select
                            options={allPartiesList.map((party) => ({
                                value: party?.id,
                                label: party?.name?.toUpperCase(),
                            }))}
                            isLoading={isLoading}
                            isSearchable={true}
                            name="party"
                            onChange={handlePartyChange}
                        />
                    </div>
                    <div className="mb-6">
                        <div id="multisection" className="control-section">
                            <div id="multidefault">
                                <div className="control-styles">
                                    <p className="text-black-50 mb-1">Guest</p>
                                    <div>
                                        <MultiSelect
                                            options={guestListOptions}
                                            value={
                                                guestListOptions?.length === 0
                                                    ? guestSelectInitialValue
                                                    : guestSelectValue
                                            }
                                            onChange={setGuestSelectValue}
                                            labelledBy="Guests"
                                            disabled={isGuestSelectDisabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <Button
                            variant="primary"
                            type="submit"
                            className="mx-4"
                            disabled={
                                isGuestSelectDisabled ||
                                guestSelectValue.length == 0
                            }
                        >
                            Add Guest
                        </Button>
                    </Form.Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NewDirectMessageModal;
