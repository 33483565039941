import React, { useState } from "react";
import BillingHistoryList from "./BillingHistoryList";

const BillingHistoryComponent: React.FC = () => {
    const [historyFilter, setHistoryFilter] = useState<string>("month");
    return (
        <div className="card">
            <div className="card-header card-header-stretch border-bottom border-gray-200 d-flex justify-content-between pb-0">
                <div className="card-title">
                    <h3 className="fw-bold m-0">Billing History</h3>
                </div>
                <div className="card-toolbar m-0">
                    <ul
                        className="nav nav-stretch nav-line-tabs border-transparent h-100"
                        role="tablist"
                    >
                        <li className="nav-item" role="presentation">
                            <a
                                className={
                                    "nav-link fs-5 fw-semibold me-3 h-100 " +
                                    (historyFilter === "month" && "active")
                                }
                                role="tab"
                                href={undefined}
                                onClick={() => setHistoryFilter("month")}
                            >
                                Month
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                id="kt_billing_1year_tab"
                                className={
                                    "nav-link fs-5 fw-semibold me-3 h-100 " +
                                    (historyFilter === "year" && "active")
                                }
                                role="tab"
                                href={undefined}
                                onClick={() => setHistoryFilter("year")}
                            >
                                Year
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                id="kt_billing_alltime_tab"
                                className={
                                    "nav-link fs-5 fw-semibold me-3 h-100 " +
                                    (historyFilter === "all" && "active")
                                }
                                role="tab"
                                href={undefined}
                                onClick={() => setHistoryFilter("all")}
                            >
                                All Time
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body">
                <BillingHistoryList historyFilter={historyFilter} />
            </div>
        </div>
    );
};

export default BillingHistoryComponent;
