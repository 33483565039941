import React from "react";
import TodayParties from "../patrySchedule/TodayParties";
import UpcomingParties from "../patrySchedule/UpcomingParties";
import CompletedParties from "../patrySchedule/CompletedParties";
import TabbedCard from "../../layouts/TabbedCard";

const PartyScheduleCard: React.FC = () => {
    return (
        <TabbedCard
            items={[
                {
                    component: <TodayParties />,
                    tabTitle: "Today",
                },
                {
                    component: <UpcomingParties />,
                    tabTitle: "Upcoming",
                },
                {
                    component: <CompletedParties />,
                    tabTitle: "Completed",
                },
            ]}
            _id="your_parties_schedule"
            title="Your Event Schedule"
        />
    );
};

export default PartyScheduleCard;
