import axios from "axios";
import { serverUrl } from "./constants";

const axiosClient = axios.create({
    baseURL: `${serverUrl}/api/v1`,
});

axiosClient.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("access_token");
        if (token) config.headers.Authorization = `Bearer ${token}`;
            // Set the headers here
        const currentDate = new Date();
        const offsetMinutes = currentDate.getTimezoneOffset();
        const isoTimestamp = currentDate.toISOString();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        config.headers["X-Timezone-Offset"] = offsetMinutes;
        config.headers["X-Timestamp"] = isoTimestamp;
        config.headers["X-TZ"] = timeZone;

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default axiosClient;
