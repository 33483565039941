import * as React from "react";
import RegisterForm from "../../components/forms/RegisterForm";
import AuthLayout from "../../layouts/AuthLayout";

const Register: React.FC = () => {
    return (
        <AuthLayout>
            <RegisterForm />
        </AuthLayout>
    );
};

export default Register;
