import axios from "axios";
import {
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from "react-google-login";
import { serverUrl } from "../helpers/constants";

function useGoogleAuthentication() {
    const handleGoogleAuth = async (
        response: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => {
        if ("accessToken" in response) {
            const accessToken = response.accessToken;
            const res = await axios.post<{ access_token: string }>(
                `${serverUrl}/api/v1/auth/google-authentication`,
                {
                    token: accessToken,
                }
            );
            return res.data.access_token;
        }
    };

    return {
        handleGoogleAuth,
    };
}

export default useGoogleAuthentication;
