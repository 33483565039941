const backendDevPort = 5000; // Change this to 51000 in macOs Monterey (leave at 5000)
// const backendDevPort = 51000; // Change this to 51000 in macOs Monterey (leave at 5000)

export const serverUrl =
    process.env.NODE_ENV === "development"
        ? `http://localhost:${backendDevPort}`
        : `${window.location.protocol}//${window.location.host}`;

export const clientUrl =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : `${window.location.protocol}//${window.location.host}`;

export const clientId =
    "720427387134-418lj4vumlcb24t3abg3543s6famuvaj.apps.googleusercontent.com";
