import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useTypedDispatch } from "../../../../store/hooks/useTypedDispatch";
import { notificationActions } from "../../../../store/reducers/notificationsSlice";
import { SuccessResponse } from "../../../../types/SuccessResponse";
import { BillingCard, initialBillingModel } from "./_model";
import { createBillingCard, updateBillingCard } from "./_requests";

type Props = {
    billingModel: BillingCard;
    handleClose: CallableFunction;
    checkbox: boolean;
};

const billingCardSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(20, "Maximum 20 symbols")
        .required("CardbName is required"),
    number: Yup.string()
        .max(16)
        .matches(/^[0-9]+$/, "Only Number is valid")
        .required("Number is required"),
    exp_month: Yup.string()
        .min(2)
        .max(2)
        .required("Expiration month is required"),
    exp_year: Yup.string().max(4).required("Expiration year is required"),
    card_type: Yup.string().required("CardType is required"),
    is_active: Yup.boolean().required("Card is active/inactive required"),
    cvc: Yup.string()
        .min(3)
        .max(4)
        .matches(/^[0-9]+$/, "Only Number is valid")
        .required("CVC is required"),
    saveCard: Yup.boolean(),
});

const BillingPaymentCardForm: React.FC<Props> = ({
    billingModel,
    handleClose,
    checkbox,
}) => {
    const dispatch = useTypedDispatch();
    const { showSnackbarWithAutoHide } = notificationActions;

    const [billingCardModelEdit, setBillingCardModelEdit] =
        useState(billingModel);

    const formik = useFormik({
        initialValues: billingCardModelEdit,
        validationSchema: billingCardSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                if (values.id) {
                    await updateBillingCard(values)
                        .then(() =>
                            dispatch(
                                showSnackbarWithAutoHide({
                                    msgText: "Updated!",
                                })
                            )
                        )
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        .catch(() => {});
                } else {
                    await createBillingCard(values)
                        .then((msg: SuccessResponse) => {
                            dispatch(
                                showSnackbarWithAutoHide({
                                    msgText: msg?.message,
                                })
                            );
                        })
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        .catch(() => {});
                }
                handleReset();
                handleClose();
                setSubmitting(false);
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(false);
            }
        },
    });
    const handleReset = () => {
        setBillingCardModelEdit(initialBillingModel);
    };

    return (
        <form
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
            onReset={handleReset}
        >
            <div className="d-flex flex-column mb-7 fv-row">
                <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                    Name On Card
                </label>
                <input
                    type="text"
                    className={`form-control ${
                        formik.touched.name && formik.errors.name
                            ? "is-invalid"
                            : ""
                    } ${
                        formik.touched.name && !formik.errors.name
                            ? "is-valid"
                            : ""
                    }`}
                    placeholder="Card Name"
                    {...formik.getFieldProps("name")}
                    autoComplete="off"
                    disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                )}
            </div>
            <div className="d-flex flex-column mb-7 fv-row">
                <label className="required fs-6 fw-semibold form-label mb-2">
                    Card Number
                </label>
                <div className="position-relative">
                    <input
                        type="text"
                        placeholder="Enter card number"
                        {...formik.getFieldProps("number")}
                        className={`form-control ${
                            formik.touched.number && formik.errors.number
                                ? "is-invalid"
                                : ""
                        } ${
                            formik.touched.number && !formik.errors.number
                                ? "is-valid"
                                : ""
                        }`}
                    />
                    {formik.touched.number && formik.errors.number && (
                        <div className="invalid-feedback">
                            {formik.errors.number}
                        </div>
                    )}
                    <div className="position-absolute translate-middle-y top-1 end-0 me-5 pr-4 d-flex">
                        <img
                            src="../assets/media/svg/card-logos/visa.svg"
                            alt=""
                            className="h-25px top-1"
                        />
                        <img
                            src="../assets/media/svg/card-logos/mastercard.svg"
                            alt=""
                            className="h-25px"
                        />
                        <img
                            src="../assets/media/svg/card-logos/american-express.svg"
                            alt=""
                            className="h-25px"
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-10">
                <div className="col-md-6 fv-row">
                    <label className="required fs-6 fw-semibold form-label mb-2">
                        Card Type
                    </label>
                    <select
                        {...formik.getFieldProps("card_type")}
                        className={`form-control w-100 ${
                            formik.touched.card_type && formik.errors.card_type
                                ? "is-invalid"
                                : ""
                        } ${
                            formik.touched.card_type && !formik.errors.card_type
                                ? "is-valid"
                                : ""
                        }`}
                    >
                        <option>Select Card Type</option>
                        <option value="visa">Visa</option>
                        <option value="mastercard">Master Card</option>
                        <option value="american-express">
                            American Express
                        </option>
                    </select>
                    {formik.touched.card_type && formik.errors.card_type && (
                        <div className="invalid-feedback">
                            {formik.errors.card_type}
                        </div>
                    )}
                </div>
                <div className="col-md-6 fv-row">
                    <label className="required fs-6 fw-semibold form-label mb-2">
                        IsActive
                    </label>
                    <select
                        {...formik.getFieldProps("is_active")}
                        className={`form-control ${
                            formik.touched.is_active && formik.errors.is_active
                                ? "is-invalid"
                                : ""
                        } ${
                            formik.touched.is_active && !formik.errors.is_active
                                ? "is-valid"
                                : ""
                        }`}
                    >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    {formik.touched.is_active && formik.errors.is_active && (
                        <div className="invalid-feedback">
                            {formik.errors.is_active}
                        </div>
                    )}
                </div>
            </div>
            <div className="row mb-10">
                <div className="col-md-8 fv-row">
                    <label className="required fs-6 fw-semibold form-label mb-2">
                        Expiration Month
                    </label>
                    <div className="row fv-row">
                        <div className="col-6">
                            <select
                                {...formik.getFieldProps("exp_month")}
                                className={`form-control ${
                                    formik.touched.exp_month &&
                                    formik.errors.exp_month
                                        ? "is-invalid"
                                        : ""
                                } ${
                                    formik.touched.exp_month &&
                                    !formik.errors.exp_month
                                        ? "is-valid"
                                        : ""
                                }`}
                            >
                                <option>Select Month</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            {formik.touched.exp_month &&
                                formik.errors.exp_month && (
                                    <div className="invalid-feedback">
                                        {formik.errors.exp_month}
                                    </div>
                                )}
                        </div>
                        <div className="col-6">
                            <select
                                {...formik.getFieldProps("exp_year")}
                                className={`form-control ${
                                    formik.touched.exp_year &&
                                    formik.errors.exp_year
                                        ? "is-invalid"
                                        : ""
                                } ${
                                    formik.touched.exp_year &&
                                    !formik.errors.exp_year
                                        ? "is-valid"
                                        : ""
                                }`}
                            >
                                <option>Select Year</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                            </select>
                            {formik.touched.exp_year &&
                                formik.errors.exp_year && (
                                    <div className="invalid-feedback">
                                        {formik.errors.exp_year}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 fv-row">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                        CVV/CVC
                    </label>
                    <div className="position-relative">
                        <input
                            type="text"
                            {...formik.getFieldProps("cvc")}
                            className={`form-control ${
                                formik.touched.cvc && formik.errors.cvc
                                    ? "is-invalid"
                                    : ""
                            } ${
                                formik.touched.cvc && !formik.errors.cvc
                                    ? "is-valid"
                                    : ""
                            }`}
                            placeholder="CVC"
                        />
                        {formik.touched.cvc && formik.errors.cvc && (
                            <div className="invalid-feedback">
                                {formik.errors.cvc}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {!checkbox && (
                <div className="d-flex flex-stack">
                    <div className="me-5">
                        <label className="fs-6 fw-semibold form-label">
                            Save Card for further billing?
                        </label>
                        <div className="fs-7 fw-semibold text-muted">
                            If you need more info, please check budget planning
                        </div>
                    </div>
                    <label className="form-check form-switch form-check-custom form-check-solid ml-auto">
                        <input
                            type="checkbox"
                            {...formik.getFieldProps("save_card")}
                            className={`form-check-input ${
                                formik.touched.save_card &&
                                formik.errors.save_card
                                    ? "is-invalid"
                                    : ""
                            } ${
                                formik.touched.save_card &&
                                !formik.errors.save_card
                                    ? "is-valid"
                                    : ""
                            }`}
                            checked={formik.values.save_card}
                        />
                        <span className="form-check-label fw-semibold text-muted ml-5">
                            Save Card
                        </span>
                    </label>
                </div>
            )}
            <div className="text-center pt-15">
                <button type="reset" className="btn btn-light me-3">
                    Discard
                </button>
                <button type="submit" className="btn btn-primary">
                    <span className="indicator-label">Submit</span>
                    {formik.isSubmitting && (
                        <span className="indicator-progress">
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </div>
        </form>
    );
};

export default BillingPaymentCardForm;
