import * as React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ContactCard from "../components/cardViews/ContactCard";
import DefaultLayout from "../layouts/DefaultLayout";
import PhoneCard from "../layouts/PhoneCard";
// import ScriptPreview from "../components/script/ScriptPreview";
import ContactMessageCard from "../components/cardViews/ContactMessageCard";
import ContectMessagePreview from "../components/script/ContectMessagePreview";
import { fetchAnnouncements } from "../store/asyncActions/announcement";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";

const ContactDashboard: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { page, limit, search } = useTypedSelector(
        (state) => state.announcement.announcementList
    );

    useEffect(() => {
        if (fetchAnnouncements && dispatch) {
            dispatch(
                fetchAnnouncements({ _page: page, _limit: limit, search })
            );
        }
    }, [dispatch, fetchAnnouncements]);

    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={3} xl={3}>
                <Col>
                    <ContactCard />
                </Col>
                <Col className="pt-15">
                    <ContactMessageCard />
                </Col>
                <Col className="pt-15">
                    <PhoneCard>
                        <ContectMessagePreview />
                    </PhoneCard>
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default ContactDashboard;
