import React, { useMemo } from "react";
import FullCalendar, {
    CalendarOptions,
    EventInput,
    ToolbarInput,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import dayjs from "dayjs";

import "./calendar.scss";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { useHistory } from "react-router-dom";

interface IProps {
    variant: "big" | "small";
    calendarRef: React.Ref<FullCalendar>;
    dateClick: (args: DateClickArg) => void;
}

const smallCalendarOptions: CalendarOptions = {
    displayEventTime: false,
};

const bigCalendarOptions: CalendarOptions = {
    displayEventTime: true,
};

const calendarOptions = {
    small: smallCalendarOptions,
    big: bigCalendarOptions,
};

const smallToolbarInput: ToolbarInput = {
    left: "title",
    right: "today prev,next",
};

const bigToolbarInput: ToolbarInput = {
    left: "prev,next today",
    center: "title",
    right: "dayGridMonth,timeGridWeek,timeGridDay",
};

const toolbarViews = {
    small: smallToolbarInput,
    big: bigToolbarInput,
};

const initialView = {
    small: "dayGridMonth",
    big: "timeGridWeek",
};

const CalendarView: React.FC<IProps> = ({
    variant,
    calendarRef,
    dateClick,
}) => {
    const history = useHistory();
    const toolbar = toolbarViews[variant];
    const view = initialView[variant];
    const options = calendarOptions[variant];

    const list = useTypedSelector((state) => state.party.parties.all.list);

    const eventsList: EventInput[] = useMemo(
        () =>
            list.map((party) => ({
                id: `${party.id}`,
                title: party.name,
                start: dayjs(party.timeSchedStart).tz(party.timezone).toDate(),
                end: dayjs(party.timeSchedEnd).tz(party.timezone).toDate(),
            })),
        [list]
    );

    const navigateToParty = (partyId: string) => {
        history.push(`/manage_parties/party/${partyId}`);
    };

    return (
        <FullCalendar
            themeSystem={"bootstrap"}
            timeZone="local"
            contentHeight={variant === "small" ? 360 : 650}
            plugins={[
                dayGridPlugin,
                bootstrapPlugin,
                timeGridPlugin,
                interactionPlugin,
            ]}
            dateClick={dateClick}
            selectable={true}
            ref={calendarRef}
            displayEventTime={true}
            initialView={view}
            headerToolbar={toolbar}
            events={eventsList}
            eventClick={(info) => navigateToParty(info.event.id)}
            {...options}
        />
    );
};

export default CalendarView;
