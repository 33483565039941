import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { modalActions } from "../../store/reducers/modalsSlice";

const VerificationsModal: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { closeEmailModal } = modalActions;
    const { isOpen: show } = useTypedSelector(
        (state) => state.modals.emailModal
    );
    const isEmail = useTypedSelector(
        (state) => state.modals.emailModal.isEmail as boolean
    );
    const [loaderBtn, setLoaderBtn] = useState(false);
    const { restoreUser, verifyEmail } = authActions;

    const [otp, setOtp] = useState("");

    const handleClose = useCallback(() => {
        dispatch(closeEmailModal());
    }, [dispatch, closeEmailModal]);

    const handleVerify = () => {
        setLoaderBtn(true);
        dispatch(verifyEmail({ otp, isEmail }))
            .then((response: any) => {
                if (response?.payload.success) {
                    toast.success(response?.payload.message);
                    handleClose();
                    setOtp("");
                } else {
                    toast.error(response?.payload.message);
                }
            })
            .then(() => {
                dispatch(restoreUser());
            })
            .catch(() => {
                toast.error("Something went wrong");
            })
            .finally(() => {
                setLoaderBtn(false);
            });
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className="text-muted small border-0">
                <Modal.Title id="d-flex justify-content-end custom-modal-header">
                    OTP Verification
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OtpInput
                    placeholder="0000"
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                        <input
                            {...props}
                            style={{
                                width: "70px",
                                height: "70px",
                                fontSize: "32px",
                                margin: "10px auto",
                                border: "2px solid #ccc",
                                borderRadius: "5px",
                                textAlign: "center",
                                display: "block",
                            }}
                        />
                    )}
                />
            </Modal.Body>

            <Modal.Footer className="text-muted small border-0">
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleVerify}
                    disabled={otp.length !== 4 || loaderBtn}
                >
                    {!loaderBtn ? "Verify" : "Loading..."}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VerificationsModal;
