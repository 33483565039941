import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../types/Common";
import { ServerError } from "../../types/Error";
import { Seller } from "../../types/Seller";
import {
    changePassword,
    createUser,
    forgotPassword_,
    getMyMembership,
    loginUserWithEmailAndPassword,
    loginUsingToken,
    loginUsingTokenActive,
    logOutToken,
    resetPassword_,
    restoreUser,
    sentOtp,
    subscribeToPlan,
    updateUserProfile,
    verifyEmail
} from "../asyncActions/auth";

const initialState: AuthState = {
    isAuthenticated: false,
    currentUser: null,
    isLoading: false,
    isLoadingLogo: false,
    errors: undefined,
    isRestoreTried: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // logout: (state) => {
        //     localStorage.removeItem("access_token");
        //     state.errors = undefined;
        //     state.isLoading = false;
        //     state.currentUser = null;
        //     state.isAuthenticated = false;
        // },
        // forgotPassword: (state) => {
        //     state.isLoading = true;
        //     state.errors = undefined;
        // },
        // forgotPasswordSuccess: (state) => {
        //     state.isLoading = false;
        // },
        // forgotPasswordFailure: (state, action: PayloadAction<ServerError>) => {
        //     state.isLoading = false;
        //     state.errors = action.payload;
        // },
    },
    extraReducers: {
        // Login user
        [loginUserWithEmailAndPassword.rejected.toString()]: (
            state,
            action: PayloadAction<ServerError>
        ) => {
            state.errors = action.payload;
            state.isLoading = false;
            state.currentUser = null;
            state.isAuthenticated = false;
        },
        [loginUsingToken.rejected.toString()]: (
            state,
            action: PayloadAction<ServerError>
        ) => {
            state.errors = action.payload;
            state.isLoading = false;
            state.currentUser = null;
            state.isAuthenticated = false;
        },
        [loginUsingTokenActive.rejected.toString()]: (
            state,
            action: PayloadAction<ServerError>
        ) => {
            state.errors = action.payload;
            state.isLoading = false;
            state.currentUser = null;
            state.isAuthenticated = false;
        },
        [loginUsingToken.pending.toString()]: (state) => {
            state.isLoading = true;
            state.errors = undefined;
        },
        [loginUsingTokenActive.pending.toString()]: (state) => {
            state.isLoading = true;
            state.errors = undefined;
        },
        [loginUsingToken.fulfilled.toString()]: (
            state,
            action: PayloadAction<Seller>
        ) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.errors = undefined;
            state.currentUser = action.payload;
        },
        [loginUsingTokenActive.fulfilled.toString()]: (
            state,
            action: PayloadAction<Seller>
        ) => {
            state.isAuthenticated = true;
            state.isLoading = false;
            state.errors = undefined;
            state.currentUser = action.payload;
        },
        // Set user
        // Restore user profile using access token from local storage
        [restoreUser.pending.toString()]: (state) => {
            state.isLoading = true;
        },
        [restoreUser.rejected.toString()]: (
            state,
            action: PayloadAction<ServerError>
        ) => {
            state.isRestoreTried = true;
            state.errors = action.payload;
            state.isLoading = false;
            state.currentUser = null;
            state.isAuthenticated = false;
        },
        [restoreUser.fulfilled.toString()]: (
            state,
            action: PayloadAction<Seller>
        ) => {
            state.isRestoreTried = true;
            state.errors = undefined;
            state.isLoading = false;
            state.currentUser = action.payload;
            state.isAuthenticated = true;
        },
        // Update user profile info
        [updateUserProfile.pending.toString()]: (state, action) => {
            const type = action.meta.arg.type; // 'meta.arg' contains the value passed to the action creator
            if (type == "profile") {
                state.isLoading = true;
            } else {
                state.isLoadingLogo = true;
            }
        },
        [updateUserProfile.fulfilled.toString()]: (
            state 
        ) => {
            // state.currentUser = action.payload;
            state.isLoading = false;
            state.isLoadingLogo = false;
        },

        [logOutToken.fulfilled.toString()]: (
            state
        ) => {
            localStorage.removeItem("access_token");
            state.errors = undefined;
            state.isLoading = false;
            state.currentUser = null;
            state.isAuthenticated = false;
        },
        //sent mail of forgot password
        [forgotPassword_.pending.toString()]: (state) => {
            state.isLoading = true;
            state.errors = undefined;
        },
        [forgotPassword_.fulfilled.toString()]: (state) => {
            state.isLoading = false;
        },
        [forgotPassword_.rejected.toString()]: (
            state,
            action: PayloadAction<ServerError>
        ) => {
            state.isLoading = false;
            state.errors = action.payload;
        },

        [resetPassword_.pending.toString()]: (state) => {
            state.isLoading = true;
            state.errors = undefined;
        },
        [resetPassword_.fulfilled.toString()]: (
            state
        ) => {
            state.isLoading = false;
        },
        [resetPassword_.rejected.toString()]: (
            state,
            action: PayloadAction<ServerError>
        ) => {
            state.errors = action.payload;
            state.isLoading = false;
        },
    },
});

export const authActions = {
    ...authSlice.actions,
    loginUserWithEmailAndPassword,
    loginUsingToken,
    loginUsingTokenActive,
    restoreUser,
    getMyMembership,
    createUser,
    updateUserProfile,
    subscribeToPlan,
    changePassword,
    logOutToken,
    forgotPassword_,
    resetPassword_,
    sentOtp,
    verifyEmail
};

const authReducer = authSlice.reducer;

export default authReducer;
