import React, { useCallback, useState } from "react";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import { authActions } from "../../store/reducers/authSlice";
import { toast } from "react-toastify";

const Overview: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { openSellerProfileInfoFormModal } = modalActions;
    const { sentOtp } = authActions;
    const plans = useTypedSelector(
        (state) => state?.auth?.currentUser?.membershipPlan
    );
    const [loader, setLoader] = useState(false);
    const sortedPlans = [...(plans || [])]
        .filter((plan: any) => plan.isMembershipActive === true)
        .sort(
            (a: any, b: any) =>
                new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
        );

    const showModal = useCallback(() => {
        dispatch(openSellerProfileInfoFormModal());
    }, []);
    const userData = useTypedSelector((state) => state.auth.currentUser);

    const { openEmailModal } = modalActions;

    

    const handleVerificationOpen = async (isEmail:boolean) => {
        setLoader(true);
        await dispatch(sentOtp({isEmail}))
            .then((response: any) => {
                toast.success(response?.payload.message);
            })
            .then(() => {
                dispatch(openEmailModal({ email: userData?.email , isEmail}));
            })
            .catch((error: any) => {
                toast.error(error?.message);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <>
            <div className="card mb-5 mb-xl-10 mt-4">
                <div className="card-header cursor-pointer">
                    <div className="card-title m-0">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h3 className="fw-bolder m-0">
                                    Profile Details
                                </h3>
                            </div>
                            <div>
                                <Button
                                    className="btn btn-primary align-self-center"
                                    onClick={showModal}
                                >
                                    Edit Profile
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body p-9">
                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Full Name
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-dark">
                                {`${userData?.nameFirst} ${userData?.nameLast}`}
                            </span>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Email
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-dark">
                                {userData?.email || "..."}
                            </span>
                            <>
                                {userData?.isEmailVerified ? (
                                    <span
                                        className={"ml-2 badge badge-success"}
                                    >
                                        Verified
                                    </span>
                                ) : (
                                    <Button
                                        variant="secondary"
                                        onClick={()=>handleVerificationOpen(true)}
                                        title="Click to verify email"
                                        className="ml-2"
                                    >
                                        {!loader ? "Send OTP" : "Loading..."}
                                    </Button>
                                )}
                            </>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Company
                        </label>

                        <div className="col-lg-8 fv-row">
                            <span className="fw-bold fs-6">
                                {userData?.companyUrl || "..."}
                            </span>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Cell Phone
                            {/* <i
                                className="fas fa-exclamation-circle ms-1 fs-7"
                                data-bs-toggle="tooltip"
                                title="Phone number must be active"
                            ></i> */}
                        </label>

                        <div className="col-lg-8 d-flex align-items-center">
                            <span className="fw-bolder fs-6 me-2">
                                {userData?.phoneCell}
                            </span>

                            <>
                                {userData?.isCellPhoneVerified ? (
                                    <span
                                        className={"ml-2 badge badge-success"}
                                    >
                                        Verified
                                    </span>
                                ) : (
                                    <Button
                                        variant="secondary"
                                        onClick={()=>handleVerificationOpen(false)}
                                        title="Click to verify Cell Phone"
                                        className="ml-2"
                                    >
                                        {!loader ? "Send OTP" : "Loading..."}
                                    </Button>
                                )}
                            </>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Is Active
                        </label>

                        <div className="col-lg-8">
                            <a
                                href="#"
                                className="fw-bold fs-6 text-dark text-hover-primary"
                            >
                                {userData?.status == "Active" ? "Yes" : "No"}
                            </a>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Personal Gateway Id
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-dark">
                                {(
                                    userData?.cellPhoneAssign as {
                                        cellPhone: { cellPhone: string };
                                    }[]
                                )
                                    ?.map((item, index) => {
                                        const phoneNumber =
                                            item.cellPhone?.cellPhone.trim();
                                        return (
                                            phoneNumber &&
                                            (index === 0
                                                ? phoneNumber
                                                : `, ${phoneNumber}`)
                                        );
                                    })
                                    .join("") || "..."}
                            </span>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-bold text-muted">
                            Plan End Date
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bolder fs-6 text-dark">
                                {`${
                                    dayjs(sortedPlans[0]?.endDate).format(
                                        "MMM DD, YYYY"
                                    ) || "..."
                                }`}
                            </span>
                        </div>
                    </div>

                    <div className="row mb-10">
                        <label className="col-lg-4 fw-bold text-muted">
                            Site Id
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bold fs-6">
                                {userData?.siteId || "..."}
                            </span>
                        </div>
                    </div>
                    <div className="row mb-10">
                        <label className="col-lg-4 fw-bold text-muted">
                            Stripe Customer Id
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bold fs-6">
                                {userData?.stripeCustId || "..."}
                            </span>
                        </div>
                    </div>
                    <div className="row mb-10">
                        <label className="col-lg-4 fw-bold text-muted">
                            Timezone Default
                        </label>

                        <div className="col-lg-8">
                            <span className="fw-bold fs-6">
                                {userData?.timezoneDefault || "..."}
                            </span>
                        </div>
                    </div>

                    {/* <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                        <KTIcon
                            iconName="information-5"
                            className="fs-2tx text-warning me-4"
                        />
                        <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-bold">
                                <h4 className="text-gray-800 fw-bolder">
                                    We need your attention!
                                </h4>
                                <div className="fs-6 text-gray-600">
                                    Your payment was declined. To start using
                                    tools, please
                                    <Link
                                        className="fw-bolder"
                                        to="/crafted/account/settings"
                                    >
                                        {" "}
                                        Add Payment Method
                                    </Link>
                                    .
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Overview;
