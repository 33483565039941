import * as React from "react";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import "./notifications.scss";

const Snackbar: React.FC = () => {
    const { isOpen, msgText } = useTypedSelector(
        (state) => state.notifications.snackbar
    );
    return (
        <div id={"snackbar"} className={isOpen ? "snackbar-shown" : ""}>
            {msgText}
        </div>
    );
};

export default Snackbar;
