import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { Script, ScriptType } from "../../types/Script";
import ManageScriptPopover from "./ManageScriptPopover";
import ScriptEditorItem from "./ScriptEditorItem";
import "./script.scss";
interface IProps {
    script: Script;
    isModifiable: boolean;
}

const ScriptEditor: React.FC<IProps> = ({ script, isModifiable }) => {
    const dispatch = useTypedDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const msgTemplatesList = useTypedSelector(
        (state) => state.automations.messageTemplates.list
    );

    const { fetchMsgTemplates } = actions;
    const { openCreateMsgTemplateModal } = modalActions;

    const isLoadingDragnDrop = useTypedSelector(
        (state) => state?.loader?.DragNDropLoader?.isLoading
    );

    useEffect(() => {
        setIsLoading(isLoadingDragnDrop);
    }, [isLoadingDragnDrop]);

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchMsgTemplates({ scriptId: script.id }))
            .unwrap()
            .then(() => setIsLoading(false));
    }, [script]);

    const scriptTimeMinutes = useMemo(() => {
        const timeSum = msgTemplatesList.reduce(
            (acc, item) => acc + (item.quietWaitSeconds || 0),
            0
        );
        return Math.ceil(timeSum / 60);
    }, [msgTemplatesList]);

    const openCreateModal = useCallback(() => {
        dispatch(
            openCreateMsgTemplateModal({
                scriptId: script.id,
                type: script.scripttype.name as ScriptType,
            })
        );
    }, [script]);

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-center align-items-center position-relative">
                <h5
                    className="font-weight-bold text-center mb-0"
                    title={script.name}
                >
                    {script.name}
                </h5>
                <ManageScriptPopover script={script} />
            </div>
            {isLoading ? (
                <div className="skeleton w-25 mx-auto mb-6 mt-2 shadow-sm" />
            ) : (
                <p className="p5 text-uppercase text-center mb-0">
                    {msgTemplatesList.length} steps - {scriptTimeMinutes}{" "}
                    minutes
                </p>
            )}
            {isLoading ? (
                <>
                    <div className="mt-4 max-h-650px flex-grow-1 animate-pulse">
                        <div className="bg-gray-200 h-32 rounded w-full"></div>
                    </div>
                    <div className="h-32 bg-gray-300 px-5 pb-10 pt-5 rounded-lg w-200px mx-auto mt-5 animate-pulse"></div>
                    <div className="mt-3 h-10 bg-indigo-600 rounded w-full animate-pulse"></div>
                </>
            ) : (
                <PerfectScrollbar>
                    <div className="mt-4 max-h-650px flex-grow-1">
                        <Droppable
                            droppableId="msgTemplateId"
                            type="scriptTemp"
                        >
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {msgTemplatesList.map((item, index) => (
                                        <Draggable
                                            draggableId={`${item.id}`}
                                            index={index}
                                            key={item.id}
                                            isDragDisabled={!isModifiable}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={
                                                        provided.draggableProps
                                                            .style
                                                    }
                                                >
                                                    <ScriptEditorItem
                                                        msgTemplate={item}
                                                        scriptId={script.id}
                                                        isDragging={
                                                            snapshot.isDragging
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable
                            droppableId="createScripMessageDropZone"
                            type="scriptTempPre"
                        >
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="d-flex flex-column align-items-center bg-gray-300 px-5 pb-10 pt-5 rounded-lg script__new-template-msg-container w-200px mx-auto"
                                >
                                    <p className="p5 text-center mb-1">
                                        Drag from quick texts or...
                                    </p>
                                    {provided.placeholder}
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={openCreateModal}
                                    >
                                        Create a New Message
                                    </Button>
                                </div>
                            )}
                        </Droppable>
                    </div>
                </PerfectScrollbar>
            )}
        </div>
    );
};

export default ScriptEditor;
