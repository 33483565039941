import { ScriptAsset } from "./Media";

export type Script = {
    link: string;
    id: number;
    sellerId: number;
    scriptTypeId: number;
    name: string;
    messageCount: number;
    starred: boolean;
    isActive: boolean; 
    scripttype: { id: number; name: string }; 
};

export enum ScriptType {
    PARTY = "Party",
    MESSAGE = "Message",
}

export type MsgTemplate = {
    msg_text?: string;
    msg_name?: string;
    id: number;
    sellerId: number;
    scriptId: number;
    name: string;
    quietWaitSeconds: number;
    maxWaitSeconds: number;
    link: string;
    messageText: string;
    scriptIndex: number;
    isActive: boolean;
    isFavorite: boolean;
    mediaItems: ScriptAsset["items"][];
};
