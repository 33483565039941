import { createSlice } from "@reduxjs/toolkit";

type DragNDropLoaderState = {
    isLoading: boolean;
};

type LoaderState = {
    DragNDropLoader: DragNDropLoaderState;
};


const initialState: LoaderState = {
    DragNDropLoader: {
        isLoading: false,
    },
};

const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setActiveDragnDropLoader: (state) => {
            state.DragNDropLoader.isLoading = true;
        },
        setInactiveDragnDropLoader: (state) => {
            state.DragNDropLoader.isLoading = false;
        },
    },
});

export const { setActiveDragnDropLoader, setInactiveDragnDropLoader } =
    loaderSlice.actions;

const loaderReducer = loaderSlice.reducer;

export default loaderReducer;
