import * as React from "react";
import { Button, Modal } from "react-bootstrap";
interface IProps {
    title?: string;
    onConfirm: () => void;
    onHide: () => void;
}

const ConfirmationModal: React.FC<IProps> = ({
    children,
    title,
    onConfirm,
    onHide,
}) => {
    return (
        <Modal show={true} onHide={onHide} centered>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
