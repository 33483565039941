import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { ScriptRunnerMsgTemplate } from "../../types/scriptRunnerTypes";
import { RootState } from "../reducers";

const sortMsgTemplates = (
    a: ScriptRunnerMsgTemplate,
    b: ScriptRunnerMsgTemplate
) => {
    const firstIndex = a.scriptIndex;
    const secondIndex = b.scriptIndex;
    return firstIndex > secondIndex ? 1 : firstIndex < secondIndex ? -1 : 0;
};

export const fetchMsgTemplates = createAsyncThunk<
    ScriptRunnerMsgTemplate[],
    {
        scriptId: number;
    }
>("scriptRunner/fetchMsgTemplates", async ({ scriptId }, thunkApi) => {
    try {
        const result = await axiosClient.get<ScriptRunnerMsgTemplate[]>(
            `/msg-template/by-script/${scriptId}/`
        );
        return result.data.sort(sortMsgTemplates).map((msgTemplate) => {
            msgTemplate.isRunning = false;
            msgTemplate.areButtonsDisabled = false;
            msgTemplate.maxProgress = 100;
            msgTemplate.maxSeconds = msgTemplate.maxWaitSeconds;
            msgTemplate.quietProgress = 100;
            msgTemplate.quietSeconds = msgTemplate.quietWaitSeconds;
            msgTemplate.intervalId = null;
            msgTemplate.startTimer = false;
            return msgTemplate;
        });
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ISendAutomationMessageParams {
    msgThreadId: number;
    msgTemplateId?: number;
    msgTemplateIndex?: number;
    type?: string;
}
export const sendAutomationMessage = createAsyncThunk<
    ScriptRunnerMsgTemplate,
    ISendAutomationMessageParams
>("automation/send-message", async (data, thunkApi) => {
    const { scriptRunner } = thunkApi.getState() as RootState;
    const templates = scriptRunner.messageTemplates.list;
    let index;
    if (data.type === "scriptIndex") {
        index = 0;
    } else {
        index = templates.findIndex((x) => x.id === data.msgTemplateId);
        index = index + 1;
    }
    try {
        const result = await axiosClient.post<ScriptRunnerMsgTemplate>(
            "/automation/send-message",
            {
                msgThreadId: data.msgThreadId,
                msgTemplateId: templates[index].id,
            }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const sendWelcomeMessage = createAsyncThunk<
    ScriptRunnerMsgTemplate,
    { msgThreadId: number }
>("automation/welcome-message", async ({ msgThreadId }, thunkApi) => {
    try {
        const result = await axiosClient.post<ScriptRunnerMsgTemplate>(
            "/automation/welcome-message",
            {
                msgThreadId,
            }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
