import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import phone from "phone";
import * as React from "react";
import { ChangeEvent, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import useQueryParams from "../../hooks/useQueryParams";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { actions } from "../../store/reducers/partySlice";
import { ServerError } from "../../types/Error";
import { JoinPartyFormValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const initialValues: JoinPartyFormValues = {
    nameAlias: "",
    nameFirst: "",
    nameLast: "",
    email: "",
    phoneCell: "",
};
const phoneRegExpUS = /^\+1\d{10}$/;
const phoneRegExpIndia = /^\+91\d{10}$/;
const validationSchema = Yup.object().shape({
    nameAlias: Yup.string()
        .required("Name alias is required")
        .min(3, "At least 3 characters"),
    nameFirst: Yup.string()
        .required("First name is required")
        .min(3, "At least 3 characters"),
    nameLast: Yup.string()
        .required("Last name is required")
        .min(3, "At least 3 characters"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    phoneCell: Yup.string()
        .required("Cell Number is required")
        .test("is-valid-phone-number", "Cell number is invalid", (val) => {
            if (!val) return false;
            return phoneRegExpUS.test(val) || phoneRegExpIndia.test(val);
        })
        .nullable(),
});

interface IProps {
    msgThreadId: number;
    setFormSuccess: (value: boolean) => void;
    formSuccess: boolean;
    setNotInterestedProps: React.Dispatch<React.SetStateAction<boolean>>;
}

const JoinPartyForm: React.FC<IProps> = ({
    setNotInterestedProps,
    msgThreadId,
    formSuccess,
    setFormSuccess,
}) => {
    const dispatch = useTypedDispatch();
    const { createGuestForParty } = actions;
    const gatewayId = useQueryParams().get("gatewayId");
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );

    if (formSuccess)
        return (
            <div className="d-flex flex-column text-success justify-content-center align-items-center my-8">
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="h-50px w-50px mb-6"
                />
                <p className="h5">Thanks for participating!</p>
            </div>
        );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setFormErrors(undefined);
                const countryCode = phone(values.phoneCell).countryIso3 || "";

                dispatch(
                    createGuestForParty({
                        ...values,
                        msgThreadId: msgThreadId,
                        isHost: false,
                        gatewayId: Number(gatewayId),
                        phoneCountryCode: countryCode,
                    })
                )
                    .unwrap()
                    .then(() => {
                        setFormSuccess(true);
                    })
                    .catch((err) => {
                        setFormErrors(err);
                        setErrors(transformToFormikErrors(err));
                        setSubmitting(false);
                    });
            }}
        >
            {({
                handleSubmit,
                dirty,
                isSubmitting,
                isValid,
                setFieldValue,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <CustomInput
                            placeholder="Enter your First Name"
                            type="text"
                            label="First Name"
                            name="nameFirst"
                            labelClasses="text-center w-100"
                            className="text-center"
                            required={true}
                            small
                        />
                    </Form.Group>
                    <Form.Group>
                        <CustomInput
                            placeholder="Enter your Last Name"
                            type="text"
                            label="Last Name"
                            name="nameLast"
                            labelClasses="text-center w-100"
                            required={true}
                            className="text-center"
                            small
                        />
                    </Form.Group>
                    <Form.Group>
                        <CustomInput
                            placeholder="Enter your Name Alias"
                            type="text"
                            label="Name Alias"
                            name="nameAlias"
                            labelClasses="text-center w-100"
                            className="text-center"
                            required={true}
                            small
                            helperText={
                                "This is how your name will appear in messages you send"
                            }
                        />
                    </Form.Group>
                    <Form.Group>
                        <CustomInput
                            placeholder="youremail@getsparty.com"
                            type="email"
                            label="Email"
                            name="email"
                            required={true}
                            labelClasses="text-center w-100"
                            className="text-center"
                            small
                        />
                    </Form.Group>
                    <Form.Group>
                        <CustomInput
                            placeholder="(888) 888-8888"
                            type="tel"
                            label="Cell Number"
                            name="phoneCell"
                            required={true}
                            labelClasses="text-center w-100"
                            className="w-100 py-2"
                            small
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFieldValue(
                                    "phoneCell",
                                    phone(e.target.value).phoneNumber,
                                    true
                                )
                            }
                        />
                    </Form.Group>
                    <FormErrors errors={formErrors} />
                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                        >
                            RSVP & Sign up for SMS
                        </Button>
                    </Form.Row>
                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <a
                            className="btn btn-active-light-danger bold"
                            style={{
                                display: "inline-block",
                                padding: "10px 20px",
                                textDecoration: "none",
                                fontSize: "12px",
                                color: "black",
                                cursor: "pointer",
                                backgroundColor: "#f2f2ff",
                                transition: "background-color 0.3s, color 0.3s",
                            }}
                            onClick={() => {
                                setNotInterestedProps(true);
                            }}
                            onMouseOver={(e) => {
                                (
                                    e.target as HTMLAnchorElement
                                ).style.backgroundColor = "#e0e0e0";
                            }}
                            onMouseOut={(e) => {
                                (
                                    e.target as HTMLAnchorElement
                                ).style.backgroundColor = "#f2f2ff";
                            }}
                            onMouseDown={(e) => {
                                (
                                    e.target as HTMLAnchorElement
                                ).style.backgroundColor = "#a5a8a8";
                            }}
                            onMouseUp={(e) => {
                                (
                                    e.target as HTMLAnchorElement
                                ).style.backgroundColor = "#e0e0e0";
                            }}
                        >
                            No Thanks
                        </a>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default JoinPartyForm;
