import * as React from "react";
import LayoutCard from "../../layouts/LayoutCard";
import MediaUpload from "../media/MediaUpload";

const MediaUploadCard: React.FC = () => {
    return (
        <LayoutCard>
            <MediaUpload />
        </LayoutCard>
    );
};

export default MediaUploadCard;
