import React from "react";
import ScrollBar from "react-perfect-scrollbar";

const PhoneCard: React.FC = ({ children }) => {
    return (
        <div className="phone-card rounded-xl shadow-lg d-flex flex-column min-h-750px mb-1">
            <div className="w-100 d-flex justify-content-center align-items-center h-40px">
                <div className="rounded-xl h-10px w-100px bg-white mt-n2" />
            </div>
            <ScrollBar className="p-3 rounded-xl flex-grow-1 bg-white max-h-650px">
                {children}
            </ScrollBar>
            <div className="w-100 h-20px" />
        </div>
    );
};

export default PhoneCard;
