import dayjs from "dayjs";

/**
 * Compose date and time into a single dayjs object
 * @param date Date string
 * @param time Time in HH:mm 24h format
 */
export function composeDates(date: string, time: string) {
    if (!time) return dayjs(date);
    const [startHours, startMinutes] = time.split(":");
    return dayjs(
        `${dayjs(date)
            .set("hours", Number(startHours) || 0)
            .set("minutes", Number(startMinutes) || 0)}`
    );
}
