import React from "react";
import Billing from "../components/myProfile/Billing";
import MyProfileHeader from "../components/myProfile/MyProfileHeader";
import DefaultLayout from "../layouts/DefaultLayout";

const MyBilling: React.FC = () => {
    return (
        <DefaultLayout>
            <MyProfileHeader />
            <Billing />
        </DefaultLayout>
    );
};

export default MyBilling;
