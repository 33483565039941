import * as React from "react";
import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import useQueryParams from "../../hooks/useQueryParams";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { ScriptType } from "../../types/Script";
import Loading from "../common/Loading";
import PrepopulatedMessages from "../prepopulatedMessages/PrepopulatedMessages";
import ScriptEditor from "./ScriptEditor";

const ScriptEditorView: React.FC = () => {
    const searchParams = useQueryParams();
    // const dispatch = useTypedDispatch();
    // const { fetchMsgTemplates, swapMessageTemplates, copyMessageTemplates } =
    //     actions;
    // const msgTemplatesList = useTypedSelector(
    //     (state) => state.automations.messageTemplates.list
    // );

    // const { favorites, topUsed, searchResults } = useTypedSelector(
    //     (state) => state.automations.messageTemplates
    // );

    const scriptList = useTypedSelector(
        (state) => state.automations.scripts.list
    );
    const workflowId = Number(searchParams.get("workflow"));
    const messageId = Number(searchParams.get("message"));

    const scriptId = workflowId || messageId;

    const type = workflowId ? ScriptType.PARTY : ScriptType.MESSAGE;
    const scriptAlias = type === ScriptType.PARTY ? "workflow" : "message";

    const currentScript = useMemo(
        () =>
            (scriptList as any)?.items?.find(
                (script: any) =>
                    script?.id === scriptId && script?.scripttype?.name === type
            ),
        [scriptId, scriptList]
    );

    if (!scriptId) {
        return (
            <div className="d-flex justify-content-center align-items-center h-700px">
                <Badge pill variant="primary px-4 py-2 shadow-sm">
                    <span className="p4 user-select-none">
                        Select a {scriptAlias} to edit
                    </span>
                </Badge>
            </div>
        );
    }

    // Redirect if no script found in current user scripts list
    // if (!currentScript) history.replace(`/automations/${scriptAlias}s`);

    // Show spinner to ensure that currentScript is defined for passing it as props below
    if (!currentScript) return <Loading />;

    return (
        <div className="d-flex">
            <div className="flex-grow-1">
                <ScriptEditor script={currentScript} isModifiable={true} />
            </div>

            <div className="w-200px border-left d-block d-lg-none d-xl-block ml-2">
                <p className="w-100 text-center p5 text-uppercase mb-1">
                    QUICK TEXTS
                </p>
                <PrepopulatedMessages />
            </div>
        </div>
    );
};

export default ScriptEditorView;
