import * as React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { confirmAlert } from "react-confirm-alert";
import socketIOClient, { Socket } from "socket.io-client";
import { serverUrl } from "../helpers/constants";
import useQueryParams from "../hooks/useQueryParams";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { actions } from "../store/reducers/automationsSlice";
import { dragDropActions } from "../store/reducers/dragDropSlice";
import {
    setActiveDragnDropLoader,
    setInactiveDragnDropLoader,
} from "../store/reducers/loader";
import { actions as topUsedAction } from "../store/reducers/topUsed";
import { notificationActions } from "../store/reducers/notificationsSlice";

export const SocketContext = React.createContext<Socket | undefined>(undefined);

const AppWrapper: React.FC = ({ children }) => {
    const searchParams = useQueryParams();
    const dispatch = useTypedDispatch();

    const isAuthenticated = useTypedSelector(
        (state) => state.auth.isAuthenticated
    );
    const [socket, setSocket] = React.useState<Socket>();
    const {
        fetchMsgTemplates,
        swapMessageTemplates,
        copyMessageTemplatesForScriptList,
        fetchMsgTemplatesDragnDrop,
        fetchScripts,
    } = actions;
    const { changeNewMessageToPartyInput } = dragDropActions;
    const { showSnackbarWithAutoHide } = notificationActions;
    const msgTemplatesList = useTypedSelector(
        (state) => state.automations.messageTemplates.list
    );

    const workflowId = Number(searchParams.get("workflow"));
    const messageId = Number(searchParams.get("message"));
    const scriptId = workflowId || messageId;

    // const type = workflowId ? ScriptType.PARTY : ScriptType.MESSAGE;
    // const scriptAlias = type === ScriptType.PARTY ? "workflow" : "message";
    const { Pre_Pop_msg_TopUsed } = topUsedAction;

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId, type } = result;
        const regexScriptList =
            /Msg-Template-drag-(\d+)-ScriptId-drop-(\d+)-id=(\w+)-(\d+)-DragnDropScript/;
        const matchesScriptList = regexScriptList?.exec(draggableId);
        const idValueScriptList = matchesScriptList?.[3] || undefined;

        if (!destination) return;

        if (
            source.droppableId === "massageTemplate" &&
            type === "massageTemplateType"
        ) {
            const regex = /scriptId-(\d+)/;
            const match = destination.droppableId.match(regex);
            const dropIndex = destination?.index;
            const regexDraggableId = /draggableId-(\d+)-currentScript-(\d+)/;
            const matchDraggableId = draggableId.match(regexDraggableId);

            if (match !== null && matchDraggableId != null) {
                const scriptId = match[1];
                const tempMsgTemplateDrag = matchDraggableId[1];
                const currentScript = matchDraggableId[2];

                dispatch(
                    copyMessageTemplatesForScriptList({
                        MsgTemplateDrag: +tempMsgTemplateDrag,
                        ScriptIdDrop: +scriptId,
                        dropIndex,
                    })
                )
                    .unwrap()
                    .then(() => {
                        dispatch(
                            fetchScripts({
                                _page: 1,
                                _limit: 1000,
                                search: "",
                            })
                        );
                    }).then(() => {
                        dispatch(fetchMsgTemplates({ scriptId: +currentScript }))
                    })
                    .then(() => {
                        dispatch(
                            showSnackbarWithAutoHide({
                                msgText:
                                    "Message Moved successfully",
                            })
                        )
                    });
            }
        }

        // set to NewMessageToPartyInput
        if (
            source.droppableId.startsWith("prePopulatedMsgTemplateId") &&
            destination.droppableId === "NewMessageToPartyInput" &&
            type === "scriptTempPre"
        ) {
            const regexPattern =
                /msg_name-(.*?)-msg_text-(.*?)-partyId-(.*?)(?=-|$)/;
            const matchResult = draggableId.match(regexPattern);
            if (matchResult !== null) {
                dispatch(
                    changeNewMessageToPartyInput({
                        id: +matchResult[3],
                        msgText: matchResult[2],
                    })
                );
            }
        }

        if (
            source.droppableId === "massageTemplate" &&
            destination.droppableId === "massageTemplate"
        ) {
            // Remove the element from the source index
            const draggedItem = msgTemplatesList[source.index];

            // Create a new array with the updated order
            const updatedArray = [
                ...msgTemplatesList.slice(0, source.index),
                ...msgTemplatesList.slice(source.index + 1),
            ];

            // Insert the dragged item at the destination index
            updatedArray.splice(destination.index, 0, draggedItem);

            dispatch(
                swapMessageTemplates({
                    sourceId: messageId,
                    data: updatedArray,
                    sourceIndex: source.index,
                    destinationScriptIndex: destination.index,
                    destinationIndex: destination.index,
                })
            )
        }

        if (
            source.droppableId === "msgTemplateId" &&
            destination.droppableId === "msgTemplateId"
        ) {
            // Remove the element from the source index
            const draggedItem = msgTemplatesList[source.index];

            // Create a new array with the updated order
            const updatedArray = [
                ...msgTemplatesList.slice(0, source.index),
                ...msgTemplatesList.slice(source.index + 1),
            ];

            // Insert the dragged item at the destination index
            updatedArray.splice(destination.index, 0, draggedItem);

            dispatch(
                swapMessageTemplates({
                    sourceId: workflowId,
                    data: updatedArray,
                    sourceIndex: source.index,
                    destinationScriptIndex: destination.index,
                    destinationIndex: destination.index,
                })
            )
                .unwrap()
                .then(() => {
                    dispatch(fetchMsgTemplates({ scriptId: scriptId }));
                });
        }
        if (
            source.droppableId.startsWith("prePopulatedMsgTemplateId") &&
            (destination.droppableId === "createScripMessageDropZone" ||
                destination.droppableId === "msgTemplateId") &&
            type === "scriptTempPre"
        ) {
            // const regexPattern = /msg_name-(.*?)-msg_text-(.*?)(?=-|$)/;
            // const matchResult = draggableId.match(regexPattern);
            const match = draggableId.match(
                /\[(\d+)\]-msg_name-(.*)-msg_text-(.*)/
            );
            if (match) {
                const id = parseInt(match[1]);
                // const value = match[2];
                const type = draggableId.includes("favorites") ? false : true;
                // const formattedMessage = { id, value, type };

                confirmAlert({
                    title: "Confirm to copy message",
                    message: "Are you sure you want to copy this message",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                dispatch(setActiveDragnDropLoader());
                                dispatch(
                                    Pre_Pop_msg_TopUsed({
                                        prePopId: id,
                                        scriptId: workflowId,
                                        istopused: type,
                                    })
                                )
                                    .unwrap()
                                    .then(async () => {
                                        await dispatch(
                                            fetchMsgTemplatesDragnDrop({
                                                scriptId: workflowId,
                                            })
                                        );
                                        await dispatch(
                                            fetchScripts({
                                                _page: 1,
                                                _limit: 100,
                                                search: "",
                                            })
                                        );
                                        dispatch(setInactiveDragnDropLoader());
                                    });
                            },
                        },
                        {
                            label: "No",
                            onClick: () => console.info("No."),
                        },
                    ],
                });

            }
        }

        if (
            source.droppableId === "scriptName" &&
            destination.droppableId === "msgTemplateId" &&
            idValueScriptList === "ScriptList"
        ) {
            const dropIndex = destination?.index;
            const MsgTemplateDrag = matchesScriptList?.[1]
                ? parseInt(matchesScriptList[1], 10)
                : -1;
            const ScriptIdDrop = matchesScriptList?.[2]
                ? parseInt(matchesScriptList[2], 10)
                : -1;
            const DragnDropScript =
                matchesScriptList?.[3] !== undefined
                    ? parseInt(matchesScriptList[4], 10)
                    : -1;

            confirmAlert({
                title: "Confirm to move message",
                message: "Are you sure you want to move this message",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            dispatch(setActiveDragnDropLoader());
                            dispatch(
                                copyMessageTemplatesForScriptList({
                                    MsgTemplateDrag,
                                    ScriptIdDrop,
                                    dropIndex,
                                })
                            )
                                .unwrap()
                                .then(async () => {
                                    await dispatch(
                                        fetchMsgTemplatesDragnDrop({
                                            scriptId: DragnDropScript,
                                        })
                                    );
                                    await dispatch(
                                        fetchScripts({
                                            _page: 1,
                                            _limit: 100,
                                            search: "",
                                        })
                                    );
                                    dispatch(setInactiveDragnDropLoader());
                                });
                        },
                    },
                    {
                        label: "No",
                        onClick: () => console.info("No."),
                    },
                ],
            });
        }
    };

    React.useEffect(() => {
        // connect to socket
        const newSocket = isAuthenticated
            ? socketIOClient(serverUrl, {
                //   auth: { token: localStorage.getItem("access_token") },
                transports: ["websocket"],
            })
            : socketIOClient(serverUrl);

        newSocket.on("connect_error", (error) =>
            console.error("connect_error occurred", error)
        );

        setSocket(newSocket);

        return () => {
            newSocket.close();
            setSocket(undefined);
        };
    }, [isAuthenticated]);

    return (
        <SocketContext.Provider value={socket}>
            <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
        </SocketContext.Provider>
    );
};

export default AppWrapper;
