import * as React from "react";
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline,
} from "react-google-login";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import useGoogleAuthentication from "../../hooks/useGoogleAuthentication";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { authActions } from "../../store/reducers/authSlice";
import { clientId } from "../../helpers/constants";

interface IProps {
    type: "registration" | "login";
}

const OAuth: React.FC<IProps> = ({ type }) => {
    const { showSnackbarWithAutoHide } = notificationActions;
    const { handleGoogleAuth } = useGoogleAuthentication();
    const dispatch = useTypedDispatch();
    const { loginUsingToken } = authActions;

    const action = type === "registration" ? "Register" : "Login";

    const handleGoogleAuthLogin = async (
        response: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => {
        const access_token = await handleGoogleAuth(response);
        if (!access_token) {
            return dispatch(
                showSnackbarWithAutoHide({
                    msgText: "Something went wrong",
                })
            );
        }
        dispatch(
            loginUsingToken({
                access_token,
            })
        );
    };

    return (
        <div>
            <GoogleLogin
                clientId={clientId + ""}
                buttonText={`${action} with Google`}
                onSuccess={handleGoogleAuthLogin}
                className="w-100 rounded"
            />
        </div>
    );
};

export default OAuth;
