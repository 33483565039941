import { faPause, faPlay, faRedo, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { scriptRunnerActions } from "../../store/reducers/scriptRunnerSlice";
import { ScriptRunnerMsgTemplate } from "../../types/scriptRunnerTypes";

interface IProps {
    msgTemplate: ScriptRunnerMsgTemplate;
    scriptId?: number;
    isDragging?: boolean;
    isActive?: boolean;
    setActiveItem?: boolean;
    autoStartTimer?: boolean;
    nextMsgTemplateId?: number;
}

const ShowItem: React.FC<IProps> = ({
    msgTemplate,
}) => {
    const dispatch = useTypedDispatch();
    const handlePlayPause = useCallback((msgTemplate) => {
        if (!msgTemplate.isRunning) {
            const intervalId = setInterval(() => dispatch(scriptRunnerActions.decrement(msgTemplate)), 1000);
            dispatch(scriptRunnerActions.setIntervalId({ id: intervalId, payload: msgTemplate }));
        }
        dispatch(scriptRunnerActions.playPauseToggle(msgTemplate));

    }, []);
    const party = useTypedSelector(
        (state) => state.party.parties.selected.party
    );

    const partyCurrent = useTypedSelector(
        (state) => state.party.parties.current.party
    );

    useEffect(() => {
        if (msgTemplate.startTimer) {
            handlePlayPause(msgTemplate);
        }
    }, [msgTemplate.startTimer]);

    const handleResetQuiet = useCallback((msgTemplate) => {
        dispatch(scriptRunnerActions.resetTimer(msgTemplate));
    }, []);

    const moveToNext = useCallback((msgTemplate) => {
        dispatch(scriptRunnerActions.moveToNext(msgTemplate));
    }, []);

    if (msgTemplate.isRunning && (msgTemplate.maxSeconds <= 0 || msgTemplate.quietSeconds <= 0)) {
        // dispatch(scriptRunnerActions.clearIntervalAction(msgTemplate));
        dispatch(scriptRunnerActions.moveToNext(msgTemplate));
        dispatch(scriptRunnerActions.sendAutomationMessage({ msgThreadId: partyCurrent?.id || party?.id || 0, msgTemplateId: msgTemplate.id }));
    }

    return (
        <div className="px-3">
            <div className="d-flex flex-column justify-content-center align-items-center">

                <div className={`d-flex flex-column justify-content-center align-items-center ${msgTemplate.areButtonsDisabled ? "grayed-out" : ""}`}>
                    <div className="d-flex justify-content-between w-100">
                        <div
                            className={`bg-gray-600 px-3 py-2 rounded d-flex flex-column align-items-center cursor-move ${msgTemplate.id == 0 ? "hidden-player" : "left-title-bar"}`}
                        >
                            <p className="p4 text-center mb-0 text-white user-select-none"> 
                                {msgTemplate?.link === null ? (
                                    msgTemplate?.name
                                ) : (
                                    <a
                                        href={msgTemplate?.link || undefined}
                                        className="underline text-white"
                                        target="_blank" rel="noreferrer"
                                    >
                                        <u>{msgTemplate?.name}</u>
                                    </a>
                                )}
                            </p> 
                        </div>
                        {(<div className="d-flex justify-content-end align-items-center">
                            <div className="right-player">
                                <button onClick={() => handlePlayPause(msgTemplate)} className="btn btn-link" disabled={msgTemplate.areButtonsDisabled}>
                                    <FontAwesomeIcon icon={msgTemplate.isRunning ? faPause : faPlay} />
                                </button>
                                <button onClick={() => handleResetQuiet(msgTemplate)} className="btn btn-link" disabled={msgTemplate.areButtonsDisabled}>
                                    <FontAwesomeIcon icon={faRedo} />
                                </button>
                                <button onClick={() => moveToNext(msgTemplate)} className="btn btn-link" disabled={msgTemplate.areButtonsDisabled}>
                                    <FontAwesomeIcon icon={faStepForward} />
                                </button>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="bg-gray-500 w-1px h-15px" />
                    <div className="w-100px">
                        <div className="w-100px">
                            <div className="mb-2">
                                <ProgressBar
                                    variant="success"
                                    className="h-30px"
                                    now={msgTemplate.maxProgress}
                                    label={`${msgTemplate.maxSeconds} sec`}
                                />
                            </div>
                            <div>
                                <ProgressBar
                                    variant="info"
                                    className="h-30px"
                                    now={msgTemplate.quietProgress}
                                    label={`${msgTemplate.quietSeconds} sec`}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-500 w-1px h-15px" />
            </div>
        </div>
    );
};

export default ShowItem;
