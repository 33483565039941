import React, { useCallback, useState } from "react";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { MsgThreadWithLastMsg } from "../../types/MsgThread";
import MessagesList from "./MessagesList";

// Used only on the Direct Messages page
const ActiveMsgThreads: React.FC = () => {
    const dispatch = useTypedDispatch();
    const list: MsgThreadWithLastMsg[] = [];
    const [messagesList, setMessagesList] = useState<MsgThreadWithLastMsg[]>(
        []
    );

    const { toggleNewDirectMessageModalOpen } = modalActions;

    const showModal = useCallback(() => {
        dispatch(toggleNewDirectMessageModalOpen(true));
    }, []);

    const SearchField = useTypedSearchField<MsgThreadWithLastMsg>({
        inputPlaceholder: "Search Messages...",
        btnOnClick: () => showModal(),
        setList: setMessagesList,
        list,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
    });

    return (
        <>
            {SearchField}
            <MessagesList messagesList={messagesList} />
        </>
    );
};

export default ActiveMsgThreads;
