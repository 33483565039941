import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";

interface IFetchActivePlansParams {
    fetchActive?: string; 
}

 

export const fetchPlans = createAsyncThunk<void, IFetchActivePlansParams>(
    "subscription/fetchPlans",
    async ({ fetchActive }, thunkApi) => {
        try {
            const result = await axiosClient.get<void>("/plan-type", {
                params: {
                    is_active: fetchActive,
                },
            });
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);


export const MembershipCancel = createAsyncThunk<void, number>(
    "subscription/MembershipCancel",
    async (memberShipId, thunkApi) => {
        try {
            const result = await axiosClient.patch<void>(
                "/membership-plan/cancel-subscription/" + memberShipId
            );
            return result.data;
        } catch (error: any) {
            return processAsyncThunkAxiosErrors(error, thunkApi);
        }
    }
);
