import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store";

import "font-awesome/css/font-awesome.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./helpers/dayjsConfig";
import "./styles/index.scss";
import "./styles/tailwindout.css";


ReactDOM.render(
    <Provider store={store}>
            <App />
    </Provider>,
    document.getElementById("root")
);
