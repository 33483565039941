import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import { unwrapResult } from "@reduxjs/toolkit";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { LoginFormValues } from "../../types/Forms";
import OAuth from "../auth/OAuth";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const initialValues: LoginFormValues = {
    email: "",
    password: "",
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required!")
        .email("Email is not valid!"),
    password: Yup.string().required("Password is required!"),
});

const LoginForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    const errors = useTypedSelector((state) => state.auth.errors);
    const history = useHistory();
    const location = useLocation<{ from: { pathname: string } }>();
    const { loginUserWithEmailAndPassword, loginUsingToken } = authActions;
    const [tokenError, setTokenError] = useState<boolean>(false);
    const { from } = location.state || { from: { pathname: "/" } };
    const isAuthenticated = useTypedSelector(
        (state) => state.auth.isAuthenticated
    );

    useEffect(() => {
        const checkTokenInUrl = async (url: URL): Promise<void> => {
            try {
                const urlSearchParams = new URLSearchParams(url.search);
                const token = urlSearchParams.get("token_v2");
                if (token && !isAuthenticated) {
                    const res = await dispatch(
                        loginUsingToken({ access_token: token })
                    );
                    res.payload == "Unauthorized"
                        ? setTokenError(true)
                        : setTokenError(false);
                }

                // eslint-disable-next-line no-empty
            } catch (error) {
                setTokenError(true);
            }
        };

        const currentUrl = new URL(window.location.href);
        checkTokenInUrl(currentUrl);
    }, []);

    return (
        <div className="login-form">
            <div className="mb-lg-4">
                <h1 className="h1 font-weight-bold">Welcome back to Sparty</h1>
                <p className="text-muted font-weight-bold p2">
                    New here?{" "}
                    <Link to={"/auth/register"}>
                        <span className="text-primary font-weight-bold">
                            Create an Account
                        </span>
                    </Link>
                </p>
            </div>
            <div className="mb-6">
                <OAuth type="login" />
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (
                    values: LoginFormValues,
                    { setSubmitting }
                ) => {
                    setSubmitting(true);
                    try {
                        const promiseResult = await dispatch(
                            loginUserWithEmailAndPassword(values)
                        );
                        unwrapResult(promiseResult);
                        setSubmitting(false);
                        // history.push(from.pathname);
                    } catch (err) {
                        setSubmitting(false);
                    }
                }}
            >
                {({ dirty, handleSubmit, isSubmitting, isValid }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <CustomInput
                                placeholder="Email"
                                type="email"
                                label="Email"
                                name="email"
                                required={true}
                            />
                        </div>
                        <div className="position-relative">
                            <Link
                                to="/auth/forgot-password"
                                tabIndex={-1}
                                className="text-primary text-hover-primary-500 position-absolute top-0 right-0"
                            >
                                Forgot password?
                            </Link>
                            <div className="form-group">
                                <CustomInput
                                    placeholder="Password"
                                    label="Password"
                                    type="password"
                                    name="password"
                                    required={true}
                                />
                            </div>
                        </div>

                        <FormErrors
                            errors={tokenError ? "Token Invalid" : errors}
                        />
                        <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
                            <Button
                                type="submit"
                                disabled={!dirty || !isValid || isSubmitting}
                                className={
                                    "btn btn-primary font-weight-bold px-9 py-4 my-3"
                                }
                            >
                                Sign In
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoginForm;
