import { Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { mediaActions } from "../../store/reducers/medaiSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { ServerError } from "../../types/Error";
import { MediaCollectionFormValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Collection name is required"),
});

const initialValues: MediaCollectionFormValues = {
    name: "",
};

const MediaCollectionForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [formErrors, setFormErrors] = useState<undefined | ServerError>(
        undefined
    );
    const { closeMediaCollectionModal } = modalActions;
    const { createMediaCollection, setSelectedCollection } = mediaActions;

    const closeModal = () => dispatch(closeMediaCollectionModal());

    const submitForm = (values: MediaCollectionFormValues) => {
        return dispatch(createMediaCollection(values));
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setFormErrors(undefined);
                submitForm(values)
                    .unwrap()
                    .then((newCollection) => {
                        closeModal();
                        dispatch(setSelectedCollection(newCollection.id));
                        setSubmitting(false);
                    })
                    .catch((err) => {
                        setFormErrors(err);
                        setErrors(transformToFormikErrors(err));
                        setSubmitting(false);
                    });
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                placeholder={"Enter name here"}
                                type="text"
                                label="Collection name"
                                name="name"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>
                    <FormErrors errors={formErrors} />
                    <div className="d-flex justify-content-center">
                        <Button
                            variant="primary"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                            type="submit"
                        >
                            Create Collection
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default MediaCollectionForm;
