import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactState } from "../../types/Common";
import { ContactField } from "../../types/MsgThread";
import {
    createAddInAnnouncement,
    createContact,
    deleteContact,
    fetchActiveContacts,
    fetchContactMessageList,
    fetchContacts,
    sendMessageToTheSelectedContact,
    UpdateContact,
    importCsvContact
} from "../asyncActions/contact";

const initialState: ContactState = {
    contactList: {
        list: [],
        activeList: [],
        isLoading: false,
        error: undefined,
        page: 1,
        hasMore: true,
        search: "",
        limit: 100,
    },
    createContactForm: {
        error: undefined,
        isLoading: false,
    },
    selectedContact: null,
    createAddInContactForm: {
        error: undefined,
        isLoading: false,
    },
    contactListMessage: {
        list: [],
        isLoading: false,
        error: undefined,
    },
    selected: {
        type: null,
        entity: null,
    },
};
export const resetCreateContactFormError = createAction<void>(
    "contact/resetCreateContactFormError"
);

const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setSelected: (
            state,
            action: PayloadAction<ContactState["selected"]>
        ) => {
            state.selected = action.payload;
            return state;
        },
        selectOne: (state, action: PayloadAction<ContactField>) => {
            state.selectedContact = action.payload;
            state.selected = {
                type: "contact",
                entity: action.payload,
            };
            return state;
        },
        resetContactState: () => initialState, // Reset the state to its initial values
    },
    extraReducers: {
        [fetchContacts.pending.toString()]: (state) => {
            state.contactList.isLoading = true;
            state.contactList.list = [];
            state.contactList.error = undefined;
        },
        [fetchContacts.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.contactList.isLoading = false;
            state.contactList.error = action.payload;
        },
        [fetchContacts.fulfilled.toString()]: (state, action) => {
            state.contactList.isLoading = false;
            const { _page, _limit, search } = action?.meta.arg;
            state.contactList.page = _page;
            state.contactList.limit = _limit;
            state.contactList.search = search;
            state.contactList.list = action.payload;
        },
        [fetchActiveContacts.pending.toString()]: (state) => {
            state.contactList.isLoading = true;
            state.contactList.activeList = [];
            state.contactList.error = undefined;
        },
        [fetchActiveContacts.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.contactList.isLoading = false;
            state.contactList.error = action.payload;
        },
        [fetchActiveContacts.fulfilled.toString()]: (state, action) => {
            state.contactList.isLoading = false;
            state.contactList.activeList = action.payload;
        },
        // [selectOne.pending.toString()]: (state) => {
        //     state.selectedContact = null;
        // },
        // [selectOne.rejected.toString()]: (state,
        //     action: PayloadAction<string>
        //     ) => {
        //     state.selectedContact = null;
        // },
        // [selectOne.fulfilled.toString()]: (
        //     state,
        //     action: PayloadAction<[]>
        // ) => {
        //     state.selectedContact = action.payload;
        // },
        // Create new contact
        [createContact.pending.toString()]: (state) => {
            state.createContactForm.isLoading = true;
            state.createContactForm.error = undefined;
        },
        [createContact.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createContactForm.isLoading = false;
            state.createContactForm.error = action.payload;
        },
        [createContact.fulfilled.toString()]: (
            state,
            action: PayloadAction<any>
        ) => {
            state.createContactForm.isLoading = false;
            state.createContactForm.error = action.payload.error;
            state.contactList.page = 1;
            state.contactList.limit = 100;
        },
        // Update contact
        [UpdateContact.pending.toString()]: (state) => {
            state.createContactForm.isLoading = true;
            state.createContactForm.error = undefined;
        },
        [UpdateContact.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createContactForm.isLoading = false;
            state.createContactForm.error = action.payload;
        },
        [UpdateContact.fulfilled.toString()]: (
            state,
            action: PayloadAction<any>
        ) => {
            state.createContactForm.isLoading = false;
            state.createContactForm.error = action.payload.error;
        },
        //create AddInAnnouncement
        [createAddInAnnouncement.pending.toString()]: (state) => {
            state.createAddInContactForm.isLoading = true;
            state.createAddInContactForm.error = undefined;
        },
        [createAddInAnnouncement.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createAddInContactForm.isLoading = false;
            state.createAddInContactForm.error = action.payload;
        },
        [createAddInAnnouncement.fulfilled.toString()]: (
            state
        ) => {
            state.createAddInContactForm.isLoading = false;
            state.createAddInContactForm.error = undefined;
        },
        //list contact msg

        [fetchContactMessageList.pending.toString()]: (state) => {
            state.contactListMessage.isLoading = true;
            state.contactListMessage.error = undefined;
        },
        [fetchContactMessageList.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.contactListMessage.isLoading = false;
            state.contactListMessage.error = action.payload;
            state.contactListMessage.list = [];
        },
        [fetchContactMessageList.fulfilled.toString()]: (
            state,
            action: PayloadAction<[]>
        ) => {
            state.contactListMessage.isLoading = false;
            state.contactListMessage.list = action.payload;
        },
        // Handle the resetCreateContactFormError action
        [resetCreateContactFormError.type]: (state) => {
            state.createContactForm.error = undefined;
        },
    },
});

export const actions = {
    ...contactSlice.actions,
    createContact,
    fetchContacts,
    fetchActiveContacts,
    // selectOne,
    deleteContact,
    UpdateContact,
    createAddInAnnouncement,
    sendMessageToTheSelectedContact,
    fetchContactMessageList,
    resetCreateContactFormError, // Export the reset action
    importCsvContact
};
export const { resetContactState } = contactSlice.actions;
const contactReducer = contactSlice.reducer;

export default contactReducer;
