import { AxiosError, AxiosResponse } from "axios";
import axiosClient from "../../../../helpers/axiosClients";
import { BillingCard } from "./_model";

const BILLING_CARD_URL = "/payments/card";
const PROMOCODE_URL = "/promo-codes";
export interface BillingCardResponse {
    sources?: Array<BillingCard>;
    defaultSource?: string;
}
const getBillingCard = (): Promise<Array<BillingCard> | undefined> => {
    return axiosClient
        .get(`${BILLING_CARD_URL}`)
        .then((d: AxiosResponse) => d?.data);
};

const getSelletBillingCard = (): Promise<BillingCardResponse> | undefined => {
    return axiosClient
        .get(`${BILLING_CARD_URL}`)
        .then((response: AxiosResponse) => {
            const billingCards = response?.data;

            // Assuming billingCards is an array of cards
            const sources = billingCards?.sources?.data;
            const defaultSource = billingCards?.default_source;

            return { sources, defaultSource };
        });
};

const createBillingCard = (plantype: BillingCard): Promise<any> => {
    return axiosClient
        .post(BILLING_CARD_URL, plantype)
        .then((response: AxiosResponse<any>) => response.data);
};

const updateBillingCard = (
    billingCard:
        | BillingCard
        | { id: string | number | undefined; is_active: number }
): Promise<{ message: string } | undefined> => {
    return axiosClient
        .patch(`/payments/customers/default-card/${billingCard.id}`)
        .then((response: AxiosResponse<{ message: string }>) => response.data);
};

const deleteBillingCard = (
    billingCardId: string | number | undefined
): Promise<{ message: string }> => {
    return axiosClient
        .delete(`${BILLING_CARD_URL}/${billingCardId}`)
        .then((response: AxiosResponse<{ message: string }>) => response.data);
};

const subscribePlan = (
    planId: string | number | undefined,
    cardId: string | number | undefined,
    promoCode?: string | number | undefined
): Promise<string | { err: boolean; errMsg: string } | undefined> => {
    return axiosClient
        .post("/payments/subscribe", {
            plan_type_id: planId,
            cardId,
            promoCode,
        })
        .then((response: AxiosResponse<undefined>) => response.data)
        .catch((error: AxiosError) => {
            if (error.response) {
                return { err: true, errMsg: error.response.data.message };
            } else {
                return { err: true, errMsg: "Error in subscribePlan" };
            }
        });
};

const checkCouponisValid = (
    coupon: string | number
): Promise<string | { err: boolean; errMsg: string } | undefined> => {
    return axiosClient
        .get(`${PROMOCODE_URL}/${coupon}`)
        .then((response: AxiosResponse<undefined>) => response.data)
        .catch((error: AxiosError) => {
            if (error.response) {
                return { err: true, errMsg: error.response.data.message };
            } else {
                return { err: true, errMsg: "Error in checkCouponisValid" };
            }
        });
};

export {
    createBillingCard,
    deleteBillingCard,
    getBillingCard,
    getSelletBillingCard,
    subscribePlan,
    updateBillingCard,
    checkCouponisValid,
};
