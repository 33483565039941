import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormAction, ModalsState } from "../../types/Common";
import { ContactLists } from "../../types/ContactList";
import { Guest } from "../../types/Guest";
import { MsgThread } from "../../types/MsgThread";
import { MsgTemplate, Script, ScriptType } from "../../types/Script";

const initialState: ModalsState = {
    newDirectMessageModal: {
        isOpen: false,
    },
    partyModal: {
        isOpen: false,
        action: FormAction.CREATE,
        currentParty: undefined,
    },
    scriptModal: {
        isOpen: false,
        type: ScriptType.PARTY,
        currentScript: undefined,
        action: FormAction.CREATE,
    },
    msgTemplateModal: {
        isOpen: false,
        type: ScriptType.PARTY,
        action: FormAction.CREATE,
        currentMessageTemplate: undefined,
        currentScriptId: 0,
    },
    guestProfileModal: {
        isOpen: false,
        currentGuest: undefined,
    },
    guestContactSaveModal: {
        isOpen: false,
        currentGuest: undefined,
    },
    mediaCollectionModal: {
        isOpen: false,
    },
    contactModal: {
        isOpen: false,
        action: FormAction.CREATE,
        currentContact: undefined,
    },
    announcementModal: {
        isOpen: false,
        action: FormAction.CREATE,
        currentAnnouncement: undefined,
        contactId: undefined,
        announcement_type: false,
    },
    contactProfileModal: {
        isOpen: false,
        currentContact: undefined,
    },
    addInAnnouncementModal: {
        isOpen: false,
        action: FormAction.CREATE,
        currentAddInAnnouncement: undefined,
    },
    SellerProfileInfo: {
        isOpen: false,
        currentParty: undefined,
    },
    legalModal: {
        isOpen: false,
        slug: "",
    },
    emailModal: {
        isOpen: false,
        email: "",
        isEmail: undefined,
    },
    emailScriptInviteModal: {
        isOpen: false, 
        scriptId: undefined,
        type: undefined,
    },
};

interface IOpenEditPartyModal {
    currentParty: MsgThread;
}

interface IOpenEditMsgTemplateModalPayload {
    type: ScriptType;
    msgTemplate: MsgTemplate;
    scriptId: number;
}

interface IOpenCreateMsgTemplateModal {
    type: ScriptType;
    scriptId: number;
    data?: any;
}

interface IOpenCreateScriptModal {
    type: ScriptType;
}

interface IOpenEditScriptModal {
    type: ScriptType;
    currentScript: Script;
}

interface ILayoutModal {
    slug: string;
}
interface IEmailModal {
    email: string | undefined;
    isEmail: boolean | undefined;
}

interface IEmailScriptInviteModal { 
    type: ScriptType;
    scriptId: number;
}

// interface IOpenEditContactModal {
//     currentContact: ContactField;
// }

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        toggleNewDirectMessageModalOpen: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.newDirectMessageModal.isOpen = action.payload;
            if (action.payload) {
                state.partyModal.isOpen = false;
                state.scriptModal.isOpen = false;
                state.contactModal.isOpen = false;
            }
        },
        openCreatePartyModal: (state) => {
            state.scriptModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.partyModal.currentParty = undefined;
            state.partyModal.action = FormAction.CREATE;
            state.partyModal.isOpen = true;
        },

        openEditPartyModal: (
            state,
            action: PayloadAction<IOpenEditPartyModal>
        ) => {
            state.newDirectMessageModal.isOpen = false;
            state.scriptModal.isOpen = false;
            state.partyModal.currentParty = action.payload.currentParty;
            state.partyModal.action = FormAction.UPDATE;
            state.partyModal.isOpen = true;
        },
        openAddGuestContactPartyModal: (
            state,
            action: PayloadAction<IOpenEditPartyModal>
        ) => {
            state.newDirectMessageModal.isOpen = false;
            state.scriptModal.isOpen = false;
            state.partyModal.currentParty = action.payload.currentParty;
            state.partyModal.action = FormAction.MAP;
            state.partyModal.isOpen = true;
        },
        closePartyModal: (state) => {
            state.partyModal.isOpen = false;
        },
        openCreateScriptModal: (
            state,
            action: PayloadAction<IOpenCreateScriptModal>
        ) => {
            state.scriptModal.isOpen = true;
            state.partyModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.scriptModal.action = FormAction.CREATE;
            state.scriptModal.type = action.payload.type;
            state.scriptModal.currentScript = undefined;
        },
        openEditScriptModal: (
            state,
            action: PayloadAction<IOpenEditScriptModal>
        ) => {
            state.scriptModal.isOpen = true;
            state.partyModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.scriptModal.action = FormAction.UPDATE;
            state.scriptModal.type = action.payload.type;
            state.scriptModal.currentScript = action.payload.currentScript;
        },
        closeScriptModal: (state) => {
            state.scriptModal.isOpen = false;
        },
        openEditMsgTemplateModal: (
            state,
            action: PayloadAction<IOpenEditMsgTemplateModalPayload>
        ) => {
            state.msgTemplateModal.isOpen = true;
            state.msgTemplateModal.type = action.payload.type;
            state.msgTemplateModal.currentScriptId = action.payload.scriptId;
            state.msgTemplateModal.currentMessageTemplate =
                action.payload.msgTemplate;
            state.msgTemplateModal.action = FormAction.UPDATE;
        },
        openCreateMsgTemplateModal: (
            state,
            action: PayloadAction<IOpenCreateMsgTemplateModal>
        ) => {
            state.msgTemplateModal.isOpen = true;
            state.msgTemplateModal.type = action.payload.type;
            state.msgTemplateModal.currentScriptId = action.payload.scriptId;
            state.msgTemplateModal.currentMessageTemplate = undefined;
            state.msgTemplateModal.action = FormAction.CREATE;
            state.msgTemplateModal.data = action.payload.data;
        },
        closeMsgTemplateModal: (state) => {
            state.msgTemplateModal.isOpen = false;
            state.msgTemplateModal.currentMessageTemplate = undefined;
            state.msgTemplateModal.data = undefined;
        },
        openGuestProfileModal: (state, action: PayloadAction<Guest>) => {
            state.guestProfileModal.currentGuest = action.payload;
            state.guestProfileModal.isOpen = true;
        },
        closeGuestProfileModal: (state) => {
            state.guestProfileModal.isOpen = false;
        },
        openSaveGuestContactModal: (state, action: PayloadAction<Guest>) => {
            state.guestContactSaveModal.currentGuest = action.payload;
            state.guestContactSaveModal.isOpen = true;
        },
        closeSaveGuestContactModal: (state) => {
            state.guestContactSaveModal.isOpen = false;
        },
        openNewMediaCollectionModal: (state) => {
            state.mediaCollectionModal.isOpen = true;
        },
        closeMediaCollectionModal: (state) => {
            state.mediaCollectionModal.isOpen = false;
        },
        openCreateContactModal: (state, action: PayloadAction<any>) => {
            state.contactModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.contactModal.currentContact = action?.payload
                ? action?.payload
                : undefined;
            state.contactModal.action = FormAction.CREATE;
            state.contactModal.isOpen = true;
        },
        openImportContactModal: (state) => {
            state.contactModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.contactModal.action = FormAction.IMPORT;
            state.contactModal.isOpen = true;
        },
        closeContactModal: (state) => {
            state.contactModal.isOpen = false;
        },
        openImportCsvContactModal: (state) => {
            state.contactModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.contactModal.action = FormAction.IMPORTCSV;
            state.contactModal.isOpen = true;
        },
        openCreateAnncouncementModal: (state, action: PayloadAction<any>) => {
            state.announcementModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.announcementModal.currentAnnouncement = action?.payload
                ? action?.payload
                : undefined;
            state.announcementModal.action = FormAction.CREATE;
            state.announcementModal.isOpen = true;
        },
        closeAnncounmentModal: (state) => {
            state.announcementModal.isOpen = false;
        },
        openCreateAnncouncementContactModal: (
            state,
            action: PayloadAction<any>
        ) => {
            state.announcementModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.announcementModal.action = FormAction.MAP;
            state.announcementModal.currentAnnouncement = action?.payload
                ? action?.payload
                : undefined;
            state.announcementModal.isOpen = true;
            state.announcementModal.contactId = undefined;
            state.announcementModal.announcement_type = true;
        },
        openMoveAnncouncementContactModal: (state, action) => {
            state.announcementModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.announcementModal.action = FormAction.MAP;
            state.announcementModal.isOpen = true;
            const contactId = action.payload; // Payload should be the contactId
            state.announcementModal.contactId = contactId;
            state.announcementModal.announcement_type = false;
        },
        openContactProfileModal: (
            state,
            action: PayloadAction<ContactLists>
        ) => {
            state.contactProfileModal.currentContact = action.payload;
            state.contactProfileModal.isOpen = true;
        },
        closeContactProfileModal: (state) => {
            state.contactProfileModal.isOpen = false;
        },
        openAddInAnncouncementModal: (state, action: PayloadAction<any>) => {
            state.addInAnnouncementModal.isOpen = false;
            state.newDirectMessageModal.isOpen = false;
            state.addInAnnouncementModal.currentAddInAnnouncement =
                action?.payload ? action?.payload : undefined;
            state.addInAnnouncementModal.action = FormAction.CREATE;
            state.addInAnnouncementModal.isOpen = true;
        },
        openSellerProfileInfoFormModal: (state) => {
            state.SellerProfileInfo.isOpen = true;
        },
        closeSellerProfileInfoFormModal: (state) => {
            state.SellerProfileInfo.isOpen = false;
        },
        openLegalModal: (state, action: PayloadAction<ILayoutModal>) => {
            state.legalModal.slug = action.payload.slug;
            state.legalModal.isOpen = true;
        },
        closeLegalModal: (state) => {
            state.legalModal.slug = "";
            state.legalModal.isOpen = false;
        },
        openEmailModal: (state, action: PayloadAction<IEmailModal>) => {
            state.emailModal.email = action?.payload.email;
            state.emailModal.isOpen = true;
            state.emailModal.isEmail = action?.payload.isEmail;
        },
        closeEmailModal: (state) => {
            state.emailModal.email = "";
            state.emailModal.isOpen = false;
            state.emailModal.isEmail = undefined;
        },

        openEmailScriptInviteModal: (
            state,
            action: PayloadAction<IEmailScriptInviteModal>
        ) => {
            state.emailScriptInviteModal.isOpen = true; 
            state.emailScriptInviteModal.type = action?.payload.type;
            state.emailScriptInviteModal.scriptId = action?.payload.scriptId;
        },

        closeEmailScriptInviteModal: (state) => {
            state.emailScriptInviteModal.isOpen = false; 
            state.emailScriptInviteModal.type = undefined;
            state.emailScriptInviteModal.scriptId = undefined;
        },
    },
});

export const modalActions = {
    ...modalsSlice.actions,
};

const modalsReducer = modalsSlice.reducer;

export default modalsReducer;
