import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DefaultLayout from "../layouts/DefaultLayout";
// import ScriptPreview from "../components/script/ScriptPreview";
import axios from "axios";
import { useHistory } from "react-router";
import Loading from "../components/common/Loading";
import { serverUrl } from "../helpers/constants";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { actions } from "../store/reducers/contactSlice";

const GoogleContactsCallback: React.FC = () => {
    const dispatch = useTypedDispatch();
    const url = new URL(window.location.href);
    const [showResult, setshowResult] = useState();
    const { fetchContacts } = actions;
    const code = url?.searchParams?.get("code");
    const history = useHistory();
    // const { list } = useTypedSelector(
    //     (state) => state.contact.contactList
    // );

    // const [apiList, setApiList] = useState<ContactField[]>([]);
    // useEffect(() => {
    //     if ((list as any)?.items) {
    //         setApiList((list as any)?.items);
    //     }
    // }, [list]);
    const { page, limit } = useTypedSelector(
        (state) => state.contact.contactList
    );
    useEffect(() => {
        const fetchData = async () => {
            if (code) {
                try {
                    const response = await axios.get(
                        `${serverUrl}/api/v2/contact/callback?code=${code}`
                    );
                    // Dispatch an action with the received data if needed
                    const data = response.data;
                    setshowResult(data);
                    const windowDataString =
                        localStorage.getItem("childWindowData");

                    if (windowDataString !== null) {
                        const windowData = JSON.parse(windowDataString);

                        if (
                            windowData &&
                            windowData.uniqueId === "googleContacts"
                        ) {
                            dispatch(
                                fetchContacts({ _page: page, _limit: limit })
                            );
                            window.close();
                        }
                    }
                    localStorage.removeItem("childWindowData");
                } catch (error) {
                    console.error(
                        "Error fetching Google contact callback:",
                        error
                    );
                }
            } else {
                history.push("/contact");
            }
        };

        fetchData(); // Call the fetchData function immediately
    }, [code]); // No need to include dispatch here

    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={3} xl={3}>
                <Col>
                    <Loading />
                    {showResult}
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default GoogleContactsCallback;
