import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Form, FormCheck, FormCheckProps } from "react-bootstrap";

type IProps = FormCheckProps &
    InputHTMLAttributes<HTMLInputElement> & {
        label?: string;
        type?: string;
        noLabel?: boolean;
        hideError?: boolean;
        name: string;
        labelClasses?: string;
        small?: boolean;
        helperText?: string;
    };

const CustomCheck: React.FC<IProps> = ({
    label,
    noLabel,
    hideError,
    className,
    labelClasses,
    helperText,
    ...props
}) => {
    const [field, meta] = useField(props.name);

    return (
        <>
            <Form.Group className="d-flex" controlId={props.name}>
                <FormCheck
                    {...field}
                    {...props}
                    checked={field.value}
                    className={`${className} h-auto`}
                    isInvalid={!!meta.error && meta.touched && !hideError}
                />
                {!noLabel && (
                    <Form.Label className={`${labelClasses} pt-1`}>
                        {label}
                    </Form.Label>
                )}
                {helperText && (
                    <Form.Text className="text-muted">{helperText}</Form.Text>
                )}
                {!hideError && (
                    <Form.Control.Feedback type="invalid">
                        {meta.error}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        </>
    );
};

export default CustomCheck;
