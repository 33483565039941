import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { fetchAnnouncements } from "../../store/asyncActions/announcement";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/announcementSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { NewAnnouncementFromValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("announcement name is required")
        .max(150, "announcement name can't be that long"),
});

const AnnouncementForm: React.FC = () => {
    const [initialValues, setInitialValues] =
        useState<NewAnnouncementFromValues>({
            name: "",
        });
    const dispatch = useTypedDispatch();

    const formErrors = useTypedSelector(
        (state) => state.announcement.createAnnouncementForm.error
    );
    const { createAnnouncement, UpdateAnnouncement } = actions;
    const { closeAnncounmentModal } = modalActions;

    const announcement = useTypedSelector(
        (state) => state.modals.announcementModal.currentAnnouncement
    );
    const { page, limit, search } = useTypedSelector(
        (state) => state.announcement.announcementList
    );
    useEffect(() => {
        if (announcement) {
            setInitialValues(announcement);
        }
    }, [announcement]);

    const submitForm = async (values: any) => {
        return dispatch(
            announcement
                ? UpdateAnnouncement({ ...values, id: announcement?.id })
                : createAnnouncement(values)
        )
            .unwrap()
            .then((data: any) => {
                announcement
                    ? dispatch(
                          fetchAnnouncements({
                              _page: page,
                              _limit: limit,
                              search,
                          })
                      )
                    : dispatch(
                          fetchAnnouncements({
                              _page: 1,
                              _limit: limit,
                              search,
                          })
                      );
                dispatch(
                    showSnackbarWithAutoHide({
                        msgText: announcement
                            ? "Announcement Updated!"
                            : "Announcement Added!",
                    })
                );
                return data;
            })
            .then(() => {
                dispatch(closeAnncounmentModal());
            });
    };
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                submitForm(values).finally(() => {
                    resetForm();
                    setSubmitting(false);
                });
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column justify-content-between h-100"
                >
                    <div>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Announcement Name..."
                                    type="text"
                                    label="Announcement Name"
                                    name="name"
                                    
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>

                    <FormErrors errors={formErrors} />

                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                        >
                            {announcement
                                ? "Update Announcement"
                                : "Create New Announcement"}
                        </Button>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default AnnouncementForm;
