import * as React from "react";
import LayoutCard from "../../layouts/LayoutCard";
import SellerCompanyForm from "../forms/SellerCompanyForm";

const SellerCompanyInfoCard: React.FC = () => {
    return (
        <LayoutCard title="Your Company Information">
            <SellerCompanyForm />
        </LayoutCard>
    );
};

export default SellerCompanyInfoCard;
