import * as React from "react";
import { ScriptAsset } from "../../types/Media";
import MessageMediaPreviews from "./MessageMediaPreviews";

interface MessagePreviewProps {
    msg: {
        id: number;
        messageText: string;
        mediaItems?: ScriptAsset["items"][];
    };
    link?: string;
}

const MessagePreview: React.FC<MessagePreviewProps> = ({ msg, link }) => {
    return (
        <div
            key={msg.id}
            className="msg-preview bg-primary px-3 py-2 mb-2 rounded-lg text-white position-relative"
            style={{overflowWrap:"anywhere"}}
        >
            <span>
                {msg.messageText}
                <div>
                    {link !== null && (
                        <a
                            href={link || undefined}
                            className="MessagePreview-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <u>{link}</u>
                        </a>
                    )}
                </div>
            </span>
            {msg.mediaItems && msg.mediaItems.length > 0 && (
                <MessageMediaPreviews mediaItems={msg.mediaItems} />
            )}
        </div>
    );
};

export default MessagePreview;
