import React from "react";
import { Col, Row } from "react-bootstrap";
import MessageCard from "../../components/cardViews/MessagesCard";
import NewPartyFormCard from "../../components/cardViews/NewPartyFormCard";
import PartyScheduleCard from "../../components/cardViews/PartyScheduleCard";
import DefaultLayout from "../../layouts/DefaultLayout";

const ManagePartiesDashboard: React.FC = () => {
    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={2} xl={3}>
                <Col>
                    <PartyScheduleCard />
                </Col>
                <Col>
                    <MessageCard />
                </Col>
                <Col>
                    <NewPartyFormCard />
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default ManagePartiesDashboard;
