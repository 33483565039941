import * as React from "react";
// import { Canva, Dropbox, GoogleDrive, Url } from "../icons";
import DragAndDropArea from "./DragAndDropArea";

const MediaUpload: React.FC = () => {
    // const thirdPartyUploadOptions = [
    //     {
    //         icon: <Dropbox />,
    //         title: "Dropbox",
    //         action: () => console.info("Upload through Dropbox!"),
    //     },
    //     {
    //         icon: <GoogleDrive />,
    //         title: "Google Drive",
    //         action: () => console.info("Upload through Google Drive!"),
    //     },
    //     {
    //         icon: <Canva />,
    //         title: "Canva",
    //         action: () => console.info("Upload through Canva!"),
    //     },
    //     {
    //         icon: <Url />,
    //         title: "From URL...",
    //         action: () => console.info("Upload through URL!"),
    //     },
    // ];

    return (
        <div className="position-sticky">
            <DragAndDropArea />
            {/*<div>*/}
            {/*    <p className="mb-1 mt-3 ml-1 text-black-50">*/}
            {/*        Or Add Media From...*/}
            {/*    </p>*/}
            {/*    <div className="w-100 d-flex flex-wrap">*/}
            {/*        {thirdPartyUploadOptions.map((item) => (*/}
            {/*            <div key={item.title} className="w-50">*/}
            {/*                <div*/}
            {/*                    onClick={() => item.action()}*/}
            {/*                    className="h-100px bg-gray-200 bg-hover-gray-300 m-1 rounded-lg p-3 d-flex justify-content-center align-items-center flex-column  cursor-pointer shadow-sm"*/}
            {/*                >*/}
            {/*                    <span className="third-party-upload-icon">*/}
            {/*                        {item.icon}*/}
            {/*                    </span>*/}
            {/*                    <p className="mb-0 p4 text-center">*/}
            {/*                        {item.title}*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default MediaUpload;
