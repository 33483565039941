import React, { useCallback, useEffect, useState } from "react";
import { useTypedDispatch } from "../../../../store/hooks/useTypedDispatch";
import { modalActions } from "../../../../store/reducers/modalsSlice";
import { notificationActions } from "../../../../store/reducers/notificationsSlice";
import ShimmerLoader from "../ShimmerLoader";
import { BillingCard, initialBillingModel } from "./_model";
import {
    deleteBillingCard,
    getBillingCard,
    updateBillingCard,
} from "./_requests";
import BillingPaymentCardModal from "./BillingPaymentCardModal";

const BillingPaymentComponent: React.FC = () => {
    const handleModelCloseCallback = () => getBillingCardData();
    const [paymentMethodTab, SetPaymentMethodTab] =
        useState<string>("card-payment");
    const { openLegalModal } = modalActions;
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isActivating, setActivating] = useState<boolean>(false);
    const [billingCards, setBillingCards] = useState<Array<BillingCard>>([]);
    const dispatch = useTypedDispatch();
    const { showSnackbarWithAutoHide } = notificationActions;

    const handleDeleteBillingCard = async (
        billingCardId: string | number | undefined
    ) => {
        setIsDeleting(true);
        if (window.confirm("Delete the item?")) {
            await deleteBillingCard(billingCardId)
                .then(() => {
                    setIsDeleting(false);
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Deleted Successfully!",
                        })
                    );
                    getBillingCardData();
                })
                .catch(() => {
                    setIsDeleting(false);
                });
        } else {
            setIsDeleting(false);
        }
    };

    const handleActivateCard = async (billingCard: BillingCard) => {
        setActivating(true);
        if (window.confirm("Are you sure ?")) {
            await updateBillingCard({ id: billingCard.id, is_active: 1 })
                .then(() => {
                    setActivating(false);
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Activated Successfully!",
                        })
                    );
                    getBillingCardData();
                })
                .catch(() => {
                    setActivating(false);
                });
        } else {
            setActivating(false);
        }
    };

    useEffect(() => {
        getBillingCardData();
    }, []);

    async function getBillingCardData() {
        const data: Array<BillingCard> | undefined = await getBillingCard();
        setBillingCards(data || []);
    }

    const handleOpen = useCallback((id: string) => {
        dispatch(openLegalModal({ slug: id }));
    }, []);

    return (
        <div className="card mb-5 mb-xl-10">
            <div className="card-header card-header-stretch pb-0 d-flex justify-content-between">
                <div className="card-title mb-4">
                    <h3 className="m-0">Payment Methods</h3>
                </div>
                <div className="card-toolbar m-0">
                    <ul
                        className="nav nav-stretch nav-line-tabs border-transparent h-100"
                        role="tablist"
                    >
                        <li className="nav-item" role="presentation">
                            <a
                                className={
                                    "nav-link fs-5 fw-bold me-5 h-100 " +
                                    (paymentMethodTab === "card-payment" &&
                                        "active")
                                }
                                role="tab"
                                href={undefined}
                                onClick={() => {
                                    SetPaymentMethodTab("card-payment");
                                }}
                            >
                                Add Card
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body tab-content">
                <div
                    className={
                        "tab-pane fade show " +
                        (paymentMethodTab === "card-payment" && "active")
                    }
                    role="tabpanel"
                >
                    <h3 className="mb-5">My Cards</h3>
                    <div className="row gx-9 gy-6">
                        {billingCards.length === 0 ? (
                            <ShimmerLoader />
                        ) : (
                            <>
                                {billingCards.map(
                                    (billingCard: BillingCard) => {
                                        return (
                                            <div
                                                key={billingCard.id}
                                                className="col-xl-6 mb-2"
                                            >
                                                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                                                    <div className="d-flex flex-column py-2">
                                                        <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                                                            {billingCard?.name ||
                                                                "No Name"}
                                                            <span
                                                                className={
                                                                    "badge fs-7 ms-2 " +
                                                                    (billingCard?.isDefault
                                                                        ? "badge-success"
                                                                        : "badge-secondary")
                                                                }
                                                            >
                                                                {billingCard?.isDefault
                                                                    ? "Primary"
                                                                    : "Secondary"}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={`../assets/media/svg/card-logos/${billingCard?.brand?.toLowerCase()}.svg`}
                                                                alt=""
                                                                className="me-4"
                                                            />
                                                            <div>
                                                                <div className="fs-4 fw-bold">
                                                                    {
                                                                        billingCard?.brand
                                                                    }{" "}
                                                                    ****{" "}
                                                                    {String(
                                                                        billingCard?.last4
                                                                    ).slice(-4)}
                                                                </div>
                                                                <div className="fs-6 fw-semibold text-gray-400">
                                                                    Card expires
                                                                    at{" "}
                                                                    {`${billingCard.exp_month}/${billingCard.exp_year}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center ml-auto py-2">
                                                        <button
                                                            className="btn btn-sm btn-light btn-active-light-primary me-3"
                                                            onClick={() =>
                                                                handleDeleteBillingCard(
                                                                    billingCard?.id
                                                                )
                                                            }
                                                            disabled={
                                                                isDeleting
                                                            }
                                                        >
                                                            <span className="indicator-label">
                                                                Delete
                                                            </span>
                                                            {isDeleting && (
                                                                <span className="indicator-progress ml-2">
                                                                    Please
                                                                    wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                        <button
                                                            className={
                                                                "btn btn-sm " +
                                                                (billingCard?.isDefault
                                                                    ? "btn-light"
                                                                    : "btn-primary")
                                                            }
                                                            disabled={
                                                                Boolean(
                                                                    billingCard?.isDefault
                                                                ) ||
                                                                isActivating
                                                            }
                                                            onClick={() =>
                                                                handleActivateCard(
                                                                    billingCard
                                                                )
                                                            }
                                                        >
                                                            {!isActivating && (
                                                                <span className="indicator-label">
                                                                    {billingCard?.isDefault
                                                                        ? "Actived"
                                                                        : "Activate"}
                                                                </span>
                                                            )}
                                                            {isActivating && (
                                                                <span className="indicator-progress ml-2">
                                                                    Please
                                                                    wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </>
                        )}
                        <div className="col-xl-6">
                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
                                <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                    <div className="mb-3 mb-md-0 fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">
                                            Important Note!
                                        </h4>
                                        <div className="fs-6 text-gray-700 pe-7">
                                            Please carefully read
                                            {/* <a
                                                href="/legal/terms-of-service"
                                                className="fw-bold mx-1"
                                            >
                                                Terms of Service
                                            </a> */}
                                            <span
                                                className="mx-2 font-weight-bold text-primary h6 btn-link text-decoration-none cursor-pointer"
                                                onClick={() =>
                                                    handleOpen(
                                                        "terms-of-service"
                                                    )
                                                }
                                            >
                                                Terms of Service
                                            </span>
                                            adding
                                            <br />
                                            your new payment card
                                        </div>
                                    </div>
                                    <BillingPaymentCardModal
                                        billingModel={initialBillingModel}
                                        handleModelCloseCallback={
                                            handleModelCloseCallback
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingPaymentComponent;
