import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Loading from "../components/common/Loading";
import JoinPartyForm from "../components/forms/JoinPartyForm";
import { SpartyLogo } from "../components/icons";
import JoinPartyDescription from "../components/joinParty/JoinPartyDescription";
import useQueryParams from "../hooks/useQueryParams";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { actions } from "../store/reducers/partySlice";
import { MsgThreadSummary } from "../types/MsgThread";

const JoinParty: React.FC = () => {
    const history = useHistory();
    const partyId = useQueryParams().get("eventId");
    const dispatch = useTypedDispatch();
    const { fetchPartySummary } = actions;
    const [isLoading, setIsLoading] = useState(true);
    const [notInterested, setNotInterested] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [partySummary, setPartySummary] = useState<
        MsgThreadSummary | undefined
    >(undefined);

    useEffect(() => {
        if (partyId)
            dispatch(
                fetchPartySummary({
                    partyId,
                })
            )
                .unwrap()
                .then((data) => {
                    if (!data) return history.replace("/");
                    setPartySummary(data);
                    setIsLoading(false);
                })
                .catch(() => history.replace("/"));
    }, [partyId]);

    const mainContent =
        !isLoading && !!partySummary ? (
            <>
                {notInterested ? (
                    <div className="d-flex flex-column text-warning justify-content-center align-items-center my-8">
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="h-50px w-50px mb-6"
                        />
                        <p className="h5">
                            You have been removed from the party!
                        </p>
                    </div>
                ) : (
                    <>
                        <JoinPartyDescription party={partySummary} />
                        <div className="max-w-300px mx-auto">
                            <JoinPartyForm
                                setNotInterestedProps={setNotInterested}
                                msgThreadId={partySummary?.id}
                                formSuccess={formSuccess}
                                setFormSuccess={setFormSuccess}
                            />
                        </div>
                    </>
                )}
            </>
        ) : (
            <Loading />
        );

    return (
        <div className="h-100 bg-light position-relative">
            <div className="d-flex justify-content-center h-150px w-100 bg-primary text-white text-center  pt-2">
                <SpartyLogo className="w-55px h-65px mt-1 mt-sm-3" />
            </div>

            <div className="d-flex flex-column align-items-center">
                <div className="rounded-lg w-100 max-w-425px bg-light border parties-schedule  bg-white p-6 mt-n20 mt-sm-n15 mb-2 shadow ">
                    {mainContent}
                    <div className="d-flex justify-content-center mb-2">
                        <p className="text-center text-muted w-90">
                            By submitting this form and signing up for texts,
                            you consent to receive marketing text messages (e.g.
                            promos, cart reminders) from Sparty and the Host
                            Rep. Consent is not a condition of purchase. Msg &
                            data rates may apply. Msg frequency varies.
                            Unsubscribe at any time by replying STOP or clicking
                            the unsubscribe link (where available). See all
                            terms, conditions and policies below:
                        </p>
                    </div>
                    <Nav className="justify-content-around flex-wrap">
                        <Nav.Item>
                            <Link
                                to="/legal/terms-of-service"
                                className="nav-link text-decoration-underline"
                                style={{fontSize: "11px", color: "#388ff2"}}
                            >
                                Terms of Service
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link
                                to="/legal/privacy-policy"
                                className="nav-link small-link"
                                style={{fontSize: "11px", color: "#388ff2"}}
                            >
                                Privacy Policy
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link
                                to="/legal/acceptable-use-policy"
                                className="nav-link small-link"
                                style={{fontSize: "11px", color: "#388ff2"}}
                            >
                                Acceptable Use Policy
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link
                                to="/legal/event-guest-sms-acceptance"
                                className="nav-link small-link"
                                style={{fontSize: "11px", color: "#388ff2"}}
                            >
                                SMS Services Policy
                            </Link>
                        </Nav.Item>
                    </Nav>
                </div>{" "}
                <span className="mb-10"></span>
                {/* {!isLoading &&
                    !!partySummary &&
                    !notInterested &&
                    !formSuccess && (
                        <span
                            className="mb-10 cursor-pointer"
                            onClick={() => setNotInterested(true)}
                        >
                            I&apos;m not interested. Please remove me.
                        </span>
                    )} */}
            </div>
        </div>
    );
};

export default JoinParty;
