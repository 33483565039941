import { AssetType } from "../types/Media";

const videoTypeRegex = /video\/.+/;
const audioTypeRegex = /audio\/.+/;
const imageTypeRegex = /image\/.+/;

export const determineMediaFileType = (type: string): AssetType => {
    if (videoTypeRegex.test(type)) return AssetType.VIDEO;
    if (audioTypeRegex.test(type)) return AssetType.AUDIO;
    if (imageTypeRegex.test(type)) return AssetType.IMAGE;
    return AssetType.ANY;
};
