import { Formik } from "formik";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/contactSlice";
import { MessageFormValues } from "../../types/Forms";
import CustomInput from "../forms/fields/CustomInput";
import PrepopulatedMessages from "../prepopulatedMessages/PrepopulatedMessages";

const validationSchema = Yup.object().shape({
    msgText: Yup.string().required("Message text is required!").min(1),
});

interface IProps {
    id?: number;
    prePopulatedMessage?: string;
    onReset: () => void;
}

const NewMessageToContact: React.FC<IProps> = ({
    prePopulatedMessage,
    onReset,
}) => {
    const dispatch = useTypedDispatch();
    const selectedContact = useTypedSelector((state) => state.contact.selected);
    const initialValues = {
        msgText: "",
        ...selectedContact,
    };

    if (!selectedContact?.entity)
        return (
            <div
                style={{
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                Please Select a contact to send message
            </div>
        );
    return (
        <div>
            <div className="border-bottom border-secondary pb-4 mb-4">
                <p className="p4 text-black-50 mb-1">
                    {selectedContact?.type == "contact"
                        ? `New Message to ${selectedContact?.entity.name}`
                        : `New Announcement for ${selectedContact?.entity.name}`}
                </p>
                <Formik
                    onSubmit={(
                        values: MessageFormValues,
                        { setSubmitting, resetForm }
                    ) => {
                        setSubmitting(true);
                        dispatch(
                            actions.sendMessageToTheSelectedContact({
                                messageText: values.msgText,
                                ...selectedContact,
                            })
                        )
                            .unwrap()
                            .then(() => {
                                dispatch(
                                    actions.fetchContactMessageList(
                                        selectedContact
                                    )
                                );
                                resetForm();
                                onReset();
                                setSubmitting(false);
                            })
                            .catch((err) => {
                                setSubmitting(false);
                                console.error(err);
                            });
                    }}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                >
                    {({ dirty, handleSubmit, isSubmitting, isValid }) => (
                        <Form
                            onSubmit={handleSubmit}
                            className="d-flex flex-column align-items-end"
                        >
                            <CustomInput
                                placeholder={"Type here..."}
                                type="text"
                                className="w-100 bg-gray-300 placeholder-dark-75 h-150px"
                                name="msgText"
                                as={"textarea"}
                                valueOverride={prePopulatedMessage}
                            ></CustomInput>
                            <Droppable
                                droppableId="NewMessageToPartyInput"
                                isCombineEnabled={true}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="d-flex flex-column align-items-center  px-5 pb-1px rounded-lg w-100 mx-auto"
                                    >
                                        {snapshot.isDraggingOver && (
                                            <div className="d-flex flex-column align-items-center bg-gray-300 px-5 pb-10 pt-5 rounded-lg script__new-template-msg-container w-100 mx-auto">
                                                <p className="p5 text-center mb-1">
                                                    Drop quick texts
                                                    here.
                                                </p>
                                            </div>
                                        )}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={!dirty || !isValid || isSubmitting}
                                className="rounded mt-2"
                                title="Send Message"
                            >
                                Send New Message
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
            <PrepopulatedMessages />
        </div>
    );
};

export default NewMessageToContact;
