import axiosClient from "../../../../helpers/axiosClients";
import { AxiosResponse } from "axios";
import { TransactionModel } from "../../../../types/TransactionModel";
const BILLING_HISTORY_URL = "/payments/transaction";
const getBillingHistory = (query: string): Promise<Array<TransactionModel>> => {
    return axiosClient
        .get(`${BILLING_HISTORY_URL}?query=${query}`)
        .then((d: AxiosResponse) => d.data?.data);
};

export { getBillingHistory };
