import React from "react";
import TabbedCard from "../../layouts/TabbedCard";
import ActiveMsgThreads from "../messages/ActiveMsgThreads";
import ArchivedMsgThreads from "../messages/ArchivedMsgThreads";

const DirectMessageThreadsCard: React.FC = () => {
    return (
        <TabbedCard
            items={[
                {
                    component: <ActiveMsgThreads />,
                    tabTitle: "Active",
                },
                {
                    component: <ArchivedMsgThreads />,
                    tabTitle: "Archived",
                },
            ]}
        />
    );
};

export default DirectMessageThreadsCard;
