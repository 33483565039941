import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PendingActionFromAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { AutomationState } from "../../types/Common";
import { MsgTemplate, Script } from "../../types/Script";
import {
    copyMessageTemplates,
    copyMessageTemplatesForScriptList,
    createMsgTemplate,
    createScript,
    deactivateScript,
    deleteMsgTemplate,
    fetchFavoriteMsgTemplates,
    fetchMsgTemplates,
    fetchMsgTemplatesDragnDrop,
    fetchScripts,
    fetchScriptsPaginate,
    fetchTopUsedMsgTemplates,
    searchMsgTemplates,
    sendAutomationMessage,
    setFavoriteMsgTemplate,
    setisFav,
    swapMessageTemplates,
    updateMsgTemplate,
    updateScript,
    sendEmailScriptInvite,
    copyScriptByInvite,
} from "../asyncActions/automations";

const initialState: AutomationState = {
    scripts: {
        list: { items: [] },
        listPaginate: [],
        isLoading: false,
        error: undefined,
        page: 1,
        hasMore: true,
        search: "",
        limit: 100,
    },
    createNewScriptForm: {
        isLoading: false,
        error: undefined,
    },
    editScriptForm: {
        isLoading: false,
        error: undefined,
    },
    messageTemplates: {
        list: [],
        isLoading: false,
        error: undefined,
        currentScriptId: undefined,
        currentMessage: undefined,
        favorites: [],
        topUsed: [],
        searchResults: [],
    },
    messageTemplatesDragnDrop: {
        list: [],
        isLoading: false,
        error: undefined,
        currentScriptId: undefined,
        currentMessage: undefined,
        favorites: [],
        topUsed: [],
        searchResults: [],
    },
};

interface IOpenEditMsgTemplatePayload {
    msgTemplate: MsgTemplate;
    scriptId: number;
    list: MsgTemplate[];
}

const automationsSlice = createSlice({
    name: "automations",
    initialState,
    reducers: {
        openEditMsgTemplate: (
            state,
            action: PayloadAction<IOpenEditMsgTemplatePayload>
        ) => {
            state.messageTemplates.currentScriptId = action.payload.scriptId;
            state.messageTemplates.currentMessage = action.payload.msgTemplate;
            state.messageTemplates.list = action.payload.list;
        },
    },
    extraReducers: {
        // Scripts fetching
        [fetchScripts.pending.toString()]: (state) => {
            state.scripts.error = undefined;
            state.scripts.isLoading = state.scripts.list.items.length === 0;
        },
        [fetchScripts.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.scripts.error = action.payload;
            state.scripts.isLoading = false;
        },
        [fetchScripts.fulfilled.toString()]: (state, action) => {
            state.scripts.list = action.payload;
            state.scripts.isLoading = false;
        },
        [fetchScriptsPaginate.fulfilled.toString()]: (state, action) => {
            state.scripts.listPaginate = action.payload;
            const { _page, _limit, search } = action?.meta.arg;
            state.scripts.page = _page;
            state.scripts.limit = _limit;
            state.scripts.search = search;
            state.scripts.isLoading = false;
        },
        // Create script
        [createScript.pending.toString()]: (state) => {
            state.createNewScriptForm.isLoading = true;
            state.createNewScriptForm.error = undefined;
        },
        [createScript.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.createNewScriptForm.isLoading = false;
            state.createNewScriptForm.error = action.payload;
        },
        [createScript.fulfilled.toString()]: (
            state,
            action: PayloadAction<Script>
        ) => {
            state.createNewScriptForm.isLoading = false;
            if (!Array.isArray(state.scripts.list)) {
                state.scripts.list.items = [];
            }
            state.scripts.list.items.unshift(action.payload);
        },
        [sendAutomationMessage.fulfilled.toString()]: (state) => {
            state.createNewScriptForm.isLoading = false;
        },
        // Edit script
        [updateScript.pending.toString()]: (state) => {
            state.editScriptForm.isLoading = true;
            state.editScriptForm.error = undefined;
        },
        [updateScript.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.editScriptForm.isLoading = false;

            state.editScriptForm.error = action.payload;
        },
        [updateScript.fulfilled.toString()]: (
            state,
            action: PayloadAction<Script>
        ) => {
            state.editScriptForm.isLoading = false;
            if (!Array.isArray(state.scripts.list)) {
                state.scripts.list.items = [];
            }
            const replaceIndex = state.scripts.list.items.findIndex(
                (script) => script.id === action.payload.id
            );
            state.scripts.list.items[replaceIndex] = action.payload;
            state.scripts.listPaginate[replaceIndex] = action.payload;
        },
        // Deactivate script
        [deactivateScript.fulfilled.toString()]: (
            state,
            action: PayloadAction<number>
        ) => {
            if (!Array.isArray(state.scripts.list)) {
                state.scripts.list.items = [];
            }
            state.scripts.list.items = state.scripts.list.items.filter(
                (script) => script.id !== action.payload
            );
            state.scripts.listPaginate = state.scripts.list.items.filter(
                (script) => script.id !== action.payload
            );
        },
        // Message templates fetching
        [fetchMsgTemplates.pending.toString()]: (state) => {
            state.messageTemplates.isLoading = true;
            state.messageTemplates.error = undefined;
        },
        [fetchMsgTemplates.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplates.isLoading = false;
            state.messageTemplates.error = action.payload;
        },
        [fetchMsgTemplates.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate[]>
        ) => {
            state.messageTemplates.isLoading = false;
            state.messageTemplates.list = action.payload;
        },

        [fetchMsgTemplatesDragnDrop.pending.toString()]: (state) => {
            state.messageTemplatesDragnDrop.isLoading = true;
            state.messageTemplatesDragnDrop.error = undefined;
        },
        [fetchMsgTemplatesDragnDrop.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplatesDragnDrop.isLoading = false;
            state.messageTemplatesDragnDrop.error = action.payload;
        },
        [fetchMsgTemplatesDragnDrop.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate[]>
        ) => {
            state.messageTemplatesDragnDrop.isLoading = false;
            state.messageTemplatesDragnDrop.list = action.payload;
        },

        [fetchFavoriteMsgTemplates.pending.toString()]: (state) => {
            state.messageTemplates.error = undefined;
        },
        [fetchFavoriteMsgTemplates.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplates.error = action.payload;
        },
        [fetchFavoriteMsgTemplates.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate[]>
        ) => {
            state.messageTemplates.favorites = action.payload;
        },
        [fetchTopUsedMsgTemplates.pending.toString()]: (state) => {
            state.messageTemplates.error = undefined;
        },
        [fetchTopUsedMsgTemplates.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplates.error = action.payload;
        },
        [fetchTopUsedMsgTemplates.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate[]>
        ) => {
            state.messageTemplates.topUsed = action.payload;
        },
        [searchMsgTemplates.pending.toString()]: (state) => {
            state.messageTemplates.error = undefined;
        },
        [searchMsgTemplates.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplates.error = action.payload;
        },
        [searchMsgTemplates.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate[]>
        ) => {
            state.messageTemplates.searchResults = action.payload;
        },
        // Create message template
        [searchMsgTemplates.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.messageTemplates.error = action.payload;
        },
        [createMsgTemplate.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate>
        ) => {
            const items = (state.scripts.list as any).items;

            const scriptIndex = items.findIndex(
                (script: any) => script.id === action.payload.scriptId
            );
            items[scriptIndex].messageCount++;
            state.messageTemplates.list.push(action.payload);
        },
        // Update message template
        [updateMsgTemplate.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate>
        ) => {
            const replaceIndex = state.messageTemplates.list.findIndex(
                (template) => template.id === action.payload.id
            );
            state.messageTemplates.list[replaceIndex] = action.payload;
            state.messageTemplates.currentMessage = action.payload;
        },
        [setFavoriteMsgTemplate.fulfilled.toString()]: (
            state,
            action: PayloadAction<MsgTemplate>
        ) => {
            const replaceIndex = state.messageTemplates.list.findIndex(
                (template) => template.id === action.payload.id
            );
            state.messageTemplates.list[replaceIndex] = action.payload;
            state.messageTemplates.currentMessage = action.payload;
        },
        // Swap two message templates when drag&drop
        [swapMessageTemplates.pending.toString()]: (
            state,
            action: PendingActionFromAsyncThunk<typeof swapMessageTemplates>
        ) => {
            const { sourceIndex, destinationIndex } = action.meta.arg;
            const source = state.messageTemplates.list[sourceIndex];
            state.messageTemplates.list.splice(sourceIndex, 1);
            state.messageTemplates.list.splice(destinationIndex, 0, source);
        },
        // Delete message template
        [deleteMsgTemplate.fulfilled.toString()]: (
            state,
            action: PayloadAction<number>
        ) => {
            const msgTemplateIndex = state.messageTemplates.list.findIndex(
                (msgTemplate) => msgTemplate.id === action.payload
            );
            const scriptId =
                state.messageTemplates.list[msgTemplateIndex].scriptId;
            const items = (state.scripts.list as any).items;
            const scriptIndex = items.findIndex(
                (script: any) => script.id === scriptId
            );
            state.messageTemplates.list.splice(msgTemplateIndex, 1);
            // // Decrement message count of the corresponding script
            items[scriptIndex].messageCount--;
        },
    },
});

export const actions = {
    ...automationsSlice.actions,
    fetchScripts,
    fetchScriptsPaginate,
    createScript,
    sendAutomationMessage,
    fetchMsgTemplates,
    fetchFavoriteMsgTemplates,
    fetchTopUsedMsgTemplates,
    searchMsgTemplates,
    updateScript,
    deactivateScript,
    createMsgTemplate,
    updateMsgTemplate,
    setFavoriteMsgTemplate,
    swapMessageTemplates,
    copyMessageTemplates,
    copyMessageTemplatesForScriptList,
    fetchMsgTemplatesDragnDrop,
    deleteMsgTemplate,
    setisFav,
    sendEmailScriptInvite,
    copyScriptByInvite,
};

const automationsReducer = automationsSlice.reducer;

export default automationsReducer;
