import * as React from "react";
import { AssetType, ScriptAsset } from "../../types/Media";

interface IProps {
    file: ScriptAsset["items"];
}

const FileView: React.FC<IProps> = ({ file }) => {
    const { linkUrl: link, assetType: type, name } = file;

    switch (type?.name) {
        case AssetType.VIDEO:
            return (
                <div className="d-flex justify-content-center align-items-center w-100">
                    <video title={name} controls className="w-100" preload="auto">
                        <source src={link} type={file.type} />
                    </video>
                </div>
            );

        case AssetType.AUDIO:
            return (
                <div className="d-flex justify-content-center align-items-center w-100">
                    {/* <audio  src={link} controls className="w-100"/> */}
                    <audio title={name} controls className="w-100" preload="auto">
                        <source src={link} type={file.type} />
                    </audio>
                </div>
            );

        case AssetType.IMAGE:
            return <img title={name} src={link} className="w-100" alt={name} />;

        default:
            return <></>;
    }
};

export default FileView;
