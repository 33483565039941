import * as React from "react";
import { Guest } from "../../types/Guest";
import { GuestProfileFormValues } from "../../types/Forms";
import { Formik } from "formik";
import CustomInput from "./fields/CustomInput";
import { Form } from "react-bootstrap";

interface IProps {
    guest: Guest;
}

const GuestProfileForm: React.FC<IProps> = ({ guest }) => {
    const initialValues: GuestProfileFormValues = {
        nameFirst: guest?.nameFirst || "",
        nameAlias: guest?.nameAlias || "",
        email: guest?.email || "",
        phoneCell: guest?.texters?.phoneCell || "",
        nameLast: guest?.nameLast || "",
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => {
                // Do nothing for now
            }}
        >
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group className="col-md-6">
                            <CustomInput
                                name="nameFirst"
                                disabled
                                label="First Name"
                            />
                        </Form.Group>
                        <Form.Group className="col-md-6">
                            <CustomInput
                                name="nameLast"
                                disabled
                                label="Last Name"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                name="nameAlias"
                                disabled
                                label="Alias"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput name="email" disabled label="Email" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md">
                            <CustomInput
                                name="phoneCell"
                                disabled
                                label="Phone Cell"
                                type="tel"
                                className="py-4"
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default GuestProfileForm;
