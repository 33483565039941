import { PaginationState } from "./Common";
import { Guest } from "./Guest";
import { ScriptAsset } from "./Media";
import { Message } from "./Message";
import { MsgTemplate } from "./Script";
import { Seller } from "./Seller";

export enum ThreadState {
    SCHEDULED = "Scheduled",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    PAUSED = "Paused",
}

export enum ThreadType {
    PARTY = "Party",
    ANNOUNCEMENT = "Announcement",
    CHAT = "Chat",
}

export type MsgThreadWithLastMsg = {
    id: number;
    sellerId: number;
    scriptId: number;
    threadTypeId: number;
    threadStateId: number;
    name: string;
    description: string;
    timeSchedStart: Date;
    timeSchedEnd: Date;
    timezone: string;
    welcomeMsg: string;
    isActive: boolean;
    timeActualStart: Date;
    timeActualEnd: Date;
    lastMsgTemplateId: number;
    threadType: string;
    allowGroup: number;
    broadcastResponse: number;
    threadState: ThreadState;
    unreadCount: number;
    nameFirst: string;
    nameLast: string;
    timePosted: Date;
    messageText: string;
    isRead: boolean;
};
export type MsgThreadWithLastMsgPagination = {
    items: MsgThreadWithLastMsg[],
    links:[],
    meta:[]
}
export type MsgThread = {
    id: number;
    scriptId: number;
    partyId: string;
    threadTypeId: number;
    threadStateId: number;
    name: string;
    description: string;
    timeSchedStart: Date;
    timeSchedEnd: Date;
    timezone: string;
    welcomeMsg: string;
    isActive: boolean;
    timeActualStart: Date;
    timeActualEnd: Date;
    lastMsgTemplateId: number;
    sellerId: number;
    threadType: string;
    allowGroup: number;
    broadcastResponse: number;
    threadState: {id: number, name: string};
    sentTemplates: MsgTemplate[];
    guestCount: number;
    hostTexterId: string;
    hostNameFull: string;
};
export type MsgThreadPagination = {
    items: MsgThread[],
    links:[],
    meta:[]
}

export type MsgThreadSummary = {
    id: number;
    partyId: string;
    sellerId: number,
    scriptId: number,
    threadTypeId: number;
    threadStateId: number;
    name: string;
    description: string;
    timeSchedStart: Date;
    timeSchedEnd: Date;
    timezone: string;
    welcomeMsg: string;
    isActive: true;
    sellerNameFirst: string;
    sellerNameLast: string;
    profilePic: string;
    hostNameFirst: string;
    hostNameLast: string;
    threadType: string;
    threadState: string;
    seller: {
        id: number;
        nameFirst: string;
        nameLast: string;
        nameAlias: string;
        profilePic: string;
        companyLogo: string;
    }
};



export type DirectMessagesState = {
    threads: {
        list: MsgThreadWithLastMsg[];
        isLoading: boolean;
        error?: string;
    } & PaginationState;
    messages: {
        list: Message[];
        isLoading: boolean;
        error?: string;
    };
};

export type MessageAnalytics = {
    totalCountMsg: any;
    id: number,
    name: string,
    label: string,
    data: {
        id: number,
        msgThreadId: number,
        gatewayId: number,
        texterTypeId: number,
        isSellerId: number,
        isGuestId: number,
        phoneCell: number,
        isActive: boolean,
        guest: Guest,
        seller: Seller,
        messages: {
            id: number,
            texterId: number,
            msgThreadId: number,
            timePosted: Date,
            nameAlias: string,
            messageText: string,
            isRead: boolean,
            msgTemplateId: number,
            createdDate: Date,
            modifiedDate: Date,
            entity: string,
            entity_id: number,
            type: string
            mediaItems?: ScriptAsset["items"][];
        }[];
    }[];
    
};
export type ContactField = {
    id:number;
    name: string;
    address:string;
    phoneCell:string;
    email:string;
    profilePic:string;  
    status:boolean;
}


export type AnnouncementField = {
    id:number;
    name: string;
    address:string;
    phoneCell:string;
    email:string;
    profilePic:string;   
}

export type AddInAnnouncementField = {
    id:number;
    contact: string;
    announcement:string;
}

export type ContactListMessageField = {
    mediaItems: any[]; // You can replace 'any[]' with the actual type for 'mediaItems'
    id: number;
    texterId: number | null; // You can replace 'number' with the actual type for 'texterId'
    msgThreadId: number | null; // You can replace 'number' with the actual type for 'msgThreadId'
    timePosted: string;
    nameAlias: string | null; // You can replace 'string' with the actual type for 'nameAlias'
    messageText: string;
    isRead: boolean;
    msgTemplateId: number | null; // You can replace 'number' with the actual type for 'msgTemplateId'
    createdDate: string;
    modifiedDate: string;
    entity: string;
    entity_id: number;
    type: string;
    items:any[];
}