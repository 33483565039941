import React, { useCallback, useEffect } from "react";
import TabbedCard from "../../layouts/TabbedCard";
import Contact from "../contact/Contact";
import Announcement from "../contact/Announcement";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { actions, resetContactState } from "../../store/reducers/contactSlice";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";

const ContactCard: React.FC = () => {
    const dispatch = useTypedDispatch();
    const announcement = useTypedSelector(state => state.announcement.selectedAnnouncement);
    const contact = useTypedSelector(state => state.contact.selectedContact);
   
    const tabChanged = (payload: {
        tabTitle: string;
        component: JSX.Element;
    }) => {
        if(payload.tabTitle == "Announcement") {
            dispatch(actions.setSelected({
                type: "announcement",
                entity: announcement
            }));
        } else if (payload.tabTitle == "My Contacts") {
            dispatch(actions.setSelected({
                type: "contact",
                entity: contact
            }));
           
        }
    };
    
    return (
        <TabbedCard
            items={[
                {
                    component: <Contact />,
                    tabTitle: "My Contacts",
                },
                {
                    component: <Announcement />,
                    tabTitle: "Announcement",
                },
            ]}
            title="My Contacts"
            onChange={tabChanged}
        />
    );
};

export default ContactCard;
