import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { NewAnnouncementFromValues, UpdateAnnouncementFromValues } from "../../types/Forms";
import { AnnouncementField } from "../../types/MsgThread";
import { showSnackbarWithAutoHide } from "./notifications";

interface IFetchAnnouncementListParams {
    _page?: number;
    _limit?: number;
    search?: string;
}
export const fetchAnnouncements = createAsyncThunk<AnnouncementField[], IFetchAnnouncementListParams>(
    "announcement/getlist",
    async ({_page,_limit,search}, thunkApi) => { // The payload argument is unused, so we can use '_'
      try {
        const result = await axiosClient.get<AnnouncementField[]>("/announcement",{
            params: {
                page: _page,
                limit: _limit,
                search
            },
        });
        return result.data;
      } catch (err:any) {
        // If your processAsyncThunkAxiosErrors function returns a Promise of AnnouncementField[], you can use it directly here.
        return processAsyncThunkAxiosErrors(err, thunkApi);
      }
    }
  );

  export const fetchAnnouncementsLoadMore = createAsyncThunk<AnnouncementField[], IFetchAnnouncementListParams>(
    "announcement/getlistloadmore",
    async ({_page,_limit,search}, thunkApi) => { // The payload argument is unused, so we can use '_'
      try {
        const result = await axiosClient.get<AnnouncementField[]>("/announcement",{
            params: {
                page: _page,
                limit: _limit,
                search
            },
        });
        return result.data;
      } catch (err:any) {
        // If your processAsyncThunkAxiosErrors function returns a Promise of AnnouncementField[], you can use it directly here.
        return processAsyncThunkAxiosErrors(err, thunkApi);
      }
    }
  );
export const selectOne = createAsyncThunk<AnnouncementField[], void>(  "announcement/selectOne", (payload:any) => payload);

export const createAnnouncement = createAsyncThunk<AnnouncementField, NewAnnouncementFromValues>(
    "announcement/create",
    async (values, thunkApi) => {

        try {
            const result = await axiosClient.post<AnnouncementField>(
                "/announcement",
                {
                    name: values.name
                },
            );
            return result.data;
        } catch (err:any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
export const UpdateAnnouncement = createAsyncThunk<AnnouncementField, UpdateAnnouncementFromValues>(
    "announcement/update",
    async (values, thunkApi) => {

        try {
            const result = await axiosClient.put<AnnouncementField>(
                "/announcement/" + values.id,
                {
                    name: values.name
                },
            );
            return result.data;
        } catch (err:any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const deleteAnnouncement = createAsyncThunk<AnnouncementField, NewAnnouncementFromValues>(
    "announcement/delete",
    async (id:any, thunkApi:any) => {

        try {
             await axiosClient.delete<AnnouncementField>(
                "/announcement/"+id);
            return id;
        } catch (err:any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const deleteContact = createAsyncThunk<
    AnnouncementField,
    { announcementId: number; contactId: number }
>(
    "announcement/deleteContact",
    async ({ announcementId, contactId }, thunkApi) => {
        try {
            const response = await axiosClient.delete<AnnouncementField>(
                `/announcement/${announcementId}/contact/${contactId}`
            );

            if (response.status === 200) {
                thunkApi.dispatch(
                    showSnackbarWithAutoHide({
                        msgText: "Contact has been deleted successfully.",
                    })
                );
                return response.data;
            } else {
                throw new Error("Failed to delete contact");
            }
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const fetchAnnouncementsContacts = createAsyncThunk<AnnouncementField[], number>(
    "announcement/getContactlist",
    async (announcementId, thunkApi) => {
        try {
            if(announcementId!==0){
                const result = await axiosClient.get<AnnouncementField[]>(`/announcement/announcement-contacts/${announcementId}`);
                return result.data;
            }else{
                return [];
            }
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
 

