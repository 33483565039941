import React from "react";
import MessagingThread from "../messages/MessagingThread";
import LayoutCard from "../../layouts/LayoutCard";

const MessagingThreadCard: React.FC = () => {
    return (
        <LayoutCard>
            <MessagingThread />
        </LayoutCard>
    );
};

export default MessagingThreadCard;
