import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MediaState } from "../../types/Common";
import { AssetCollection, ScriptAsset } from "../../types/Media";
import {
    createMediaCollection,
    createScriptAsset,
    fetchScriptAssetsByCollection,
    fetchUserAssetCollections,
    removeScriptAsset,
    updateAsset,
    uploadFile,
} from "../asyncActions/media";

const initialState: MediaState = {
    collections: {
        selectedCollection: 0,
        list: [],
        isLoading: true,
        error: undefined,
    },
    assets: {
        list: [],
        isLoading: true,
        error: undefined,
    },
};

const mediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {
        setSelectedCollection: (state, action: PayloadAction<number>) => {
            state.collections.selectedCollection = action.payload;
        },
    },
    extraReducers: {
        // Fetch collections
        [fetchUserAssetCollections.pending.toString()]: (state) => {
            state.collections.isLoading = true;
            state.collections.error = undefined;
        },
        [fetchUserAssetCollections.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.collections.isLoading = false;
            state.collections.error = action.payload;
        },
        [fetchUserAssetCollections.fulfilled.toString()]: (
            state,
            action: PayloadAction<AssetCollection[]>
        ) => {
            state.collections.isLoading = false;
            state.collections.list = action.payload;
            // if (action.payload.length !== 0)
            //     state.collections.selectedCollection = action.payload[0].id;
        },
        // Fetch script assets by collection
        [fetchScriptAssetsByCollection.pending.toString()]: (state) => {
            state.assets.isLoading = true;
            state.assets.error = undefined;
            state.assets.list = [];
        },
        [fetchScriptAssetsByCollection.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.assets.isLoading = false;
            state.assets.error = action.payload;
        },
        [fetchScriptAssetsByCollection.fulfilled.toString()]: (
            state,
            action: PayloadAction<ScriptAsset["items"][]>
        ) => {
            state.assets.isLoading = false;
            state.assets.list = action.payload;
        },
        [createMediaCollection.fulfilled.toString()]: (
            state,
            action: PayloadAction<AssetCollection>
        ) => {
            state.collections.list.push(action.payload);
        },
        // Script assets creation
        [createScriptAsset.pending.toString()]: (state) => {
            state.assets.isLoading = true;
            state.assets.error = undefined;
        },
        [createScriptAsset.fulfilled.toString()]: (
            state,
            action: PayloadAction<ScriptAsset["items"]>
        ) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const obj = { ...state.assets.list, ...action.payload };
        },
        // Remove script asset
        [removeScriptAsset.pending.toString()]: (state) => {
            state.assets.isLoading = true;
            state.assets.error = undefined;
        },
        [removeScriptAsset.fulfilled.toString()]: (
            state,
            action: PayloadAction<number> // Assuming the payload is the ID of the removed item (change if different)
        ) => {
            action.payload;

            // state.assets.list = state.assets.list.filter(
            //   (item) => item.id !== deletedItemId
            // );
        },
    },
});

export const mediaActions = {
    ...mediaSlice.actions,
    fetchUserAssetCollections,
    fetchScriptAssetsByCollection,
    createMediaCollection,
    uploadFile,
    createScriptAsset,
    removeScriptAsset,
    updateAsset,
};

const mediaReducer = mediaSlice.reducer;

export default mediaReducer;
