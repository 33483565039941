import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Dropdown, Nav, Navbar, OverlayTrigger } from "react-bootstrap";
import { getFileUrl } from "../../helpers/getFileUrl";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { LogoText } from "../icons";
import AutomationsPopover from "./AutomationsPopover";
import LinkWithPopover from "./LinkWithPopover";
import "./navBar.scss";
import PlusBtnPopover from "./PlusBtnPopover";
import ProfileBtnPopover from "./ProfileBtnPopover";
import SimpleLink from "./SimpleLink";
import clsx from "clsx";

const NavBar: React.FC = () => {
    const isAuth = useTypedSelector((state) => state.auth.isAuthenticated);
    const user = useTypedSelector((state) => state.auth.currentUser);

    const userInitials = `
        ${user?.nameFirst[0]}${user?.nameLast[0]}
    `.toUpperCase();

    const imageUrl =
        user && user.profilePic ? getFileUrl(user.profilePic) : null;

    return (
        <Navbar bg="light" expand="xl" className="py-0 px-lg-10 px-xl-10">
            <Navbar.Brand href="/">
                <LogoText className="text-primary h-50px w-100px" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" label="Expand" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto nav-bar">
                    <SimpleLink to="/party_hub" title="Event Hub" />
                    <SimpleLink to="/manage_parties" title="Manage Events" />
                    {/* <SimpleLink to="/direct_messages" title="Direct Messages" /> */}
                    <LinkWithPopover
                        commonPath="/automations"
                        title="Automations"
                        popover={AutomationsPopover}
                    />
                    <SimpleLink to="/calendar" title="Calendar" />
                    <SimpleLink to="/media" title="Media" />
                    <SimpleLink to="/contact" title="My Contacts" />
                </Nav>
                <div className="px-4 d-flex justify-content-between align-items-center my-2 my-xl-0 flex-row-reverse flex-sm-row">
                    <div className="d-flex align-items-center flex-row-reverse flex-lg-row">
                        {isAuth && (
                            <div className="d-flex flex-column align-items-start align-items-lg-end mr-4 ml-4 ml-lg-0">
                                <p className="mb-0 font-weight-bold text-black-50">
                                    Hello
                                </p>
                                <p className="mb-0 font-weight-bold">
                                    {user?.nameFirst} {user?.nameLast}
                                </p>
                            </div>
                        )}
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={PlusBtnPopover}
                        >
                            <Button className="rounded-lg shadow nav-bar__btn">
                                <span className="svg-icon d-flex justify-content-center align-items-center mx-1">
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </Button>
                        </OverlayTrigger>
                    </div>

                    {/* <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={ProfileBtnPopover}
                    >
                        <div className="rounded-circle overflow-hidden h-40px w-40px ml-sm-4 ml-0 cursor-pointer bg-light">
                            {user?.profilePic ? (
                                <img
                                    src={getFileUrl(user.profilePic)}
                                    alt=""
                                    className="h-40px w-40px rounded-circle d-flex  justify-content-center align-items-center text-center"
                                />
                            ) : (
                                <div className="bg-gray-500 h-100 w-100 d-flex justify-content-center align-items-center">
                                    <p className="user-select-none mb-0 font-weight-bold">
                                        {userInitials}
                                    </p>
                                </div>
                            )}
                        </div>
                    </OverlayTrigger> */}

                    <Dropdown>
                        <Dropdown.Toggle
                            variant="secondary"
                            style={
                                imageUrl
                                    ? {
                                          backgroundImage: `url(${imageUrl})`,
                                          backgroundSize: "cover"
                                      }
                                    : { backgroundColor: "#b5b5c3" }
                            }
                            id="dropdown-profile"
                            className="rounded-circle overflow-hidden h-40px w-40px ml-sm-4 ml-0 cursor-pointer"
                        >
                            {user && user.profilePic ? null : (
                                <div className="h-100 w-100 d-flex justify-content-center align-items-center ">
                                    <p className="user-select-none mb-0 font-weight-bold">
                                        {userInitials}
                                    </p>
                                </div>
                            )}
                        </Dropdown.Toggle>

                        <ProfileBtnPopover
                            userName={userInitials}
                            user={user}
                        />
                    </Dropdown>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
