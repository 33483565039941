import {
    faChartLine,
    faSearch,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import { ScriptType } from "../../types/Script";
import SearchField from "../common/SearchField";

interface ItemType {
    id: number;
    title: string;
    msgContext: string;
    scriptId: number;
}

const prepopulatedMessagesSections: {
    sectionTitle: string;
    sectionId: string;
    icon: JSX.Element;
    items: ItemType[];
    placeholderText?: string;
    showPlaceholder?: boolean;
}[] = [
    {
        sectionTitle: "Search Results",
        sectionId: "search",
        icon: <FontAwesomeIcon icon={faSearch} />,
        items: [],
    },
    {
        sectionTitle: "Favorite Messages",
        sectionId: "favorites",
        icon: <FontAwesomeIcon icon={faStar} />,
        items: [],
    },
    {
        sectionTitle: "Top Used",
        sectionId: "topUsed",
        icon: <FontAwesomeIcon icon={faChartLine} />,
        items: [],
    },
];

type IPrepopulatedMessages = {
    id?: number;
};

const PrepopulatedMessages: React.FC<IPrepopulatedMessages> = ({
    id: partyId,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const handleSearch = useCallback(
        debounce((value) => {
            dispatch(
                searchMsgTemplates({
                    searchText: value,
                    script: ScriptType.MESSAGE,
                })
            );
        }, 500),
        []
    );

    const dispatch = useTypedDispatch();
    const { favorites, list, topUsed, searchResults } = useTypedSelector(
        (state) => state.automations.messageTemplates
    );

    const {
        fetchFavoriteMsgTemplates,
        fetchTopUsedMsgTemplates,
        searchMsgTemplates,
    } = actions;
    useEffect(() => {
        dispatch(fetchFavoriteMsgTemplates({ size: 10 }));
    }, [list]);

    useEffect(() => {
        dispatch(fetchTopUsedMsgTemplates({ size: 10 }));
    }, [list]);

    useEffect(() => {
        handleSearch(searchValue);
    }, [searchValue]);

    const its = favorites?.map<ItemType>((m) => ({
        id: m.id,
        title: m?.msg_name || m?.name,
        msgContext: m?.msg_text || m?.messageText,
        scriptId: m.scriptId,
    }));
    prepopulatedMessagesSections[1].items = its || [];
    const searchResultsSection = searchResults?.map<ItemType>((m) => ({
        id: m.id,
        title: m?.msg_name || "",
        msgContext: m?.msg_text || "",
        scriptId: m?.scriptId,
    }));
    prepopulatedMessagesSections[0].items = searchResultsSection || [];
    prepopulatedMessagesSections[0].showPlaceholder =
        searchResults?.length === 0 && !!searchValue;
    const topUsedSection = topUsed?.map<ItemType>((m) => ({
        id: m.id,
        title: m?.msg_name || "",
        msgContext: m?.msg_text || "",
        scriptId: m?.scriptId,
    }));
    prepopulatedMessagesSections[2].items = topUsedSection || [];

    return (
        <div className="px-4">
            <SearchField
                value={searchValue}
                setValue={setSearchValue}
                inputPlaceholder="Search Messages"
                hideBtn={true}
            />
            {prepopulatedMessagesSections.map(
                (section) =>
                    (section.items?.length > 0 || section.showPlaceholder) && (
                        <div key={section.sectionTitle} className="my-2">
                            <p className="p4 mb-1">
                                <span className="text-primary">
                                    {section.icon}
                                </span>{" "}
                                {section.sectionTitle}
                            </p>

                            {section.showPlaceholder && (
                                <span
                                    hidden={!section.showPlaceholder}
                                    className="d-flex justify-content-center p4 user-select-none"
                                >
                                    No results
                                </span>
                            )}

                            <Droppable
                                droppableId={`prePopulatedMsgTemplateId.${section.sectionId}`}
                                type="scriptTempPre"
                            >
                                {(provided) => (
                                    <PerfectScrollbar>
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="d-flex flex-wrap max-h-150px max-w-150px"
                                        >
                                            {section.items.map(
                                                (item, index) => (
                                                    <Draggable
                                                        draggableId={`${section.sectionId}[${item.id}]-msg_name-${item.title}-msg_text-${item.msgContext}-partyId-${partyId}`}
                                                        index={index}
                                                        key={item.id}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={
                                                                    provided
                                                                        .draggableProps
                                                                        .style
                                                                }
                                                                draggable
                                                                className="rounded bg-gray-600 text-white px-3 py-2 m-1 cursor-move p5"
                                                                key={index}
                                                            >
                                                                {item.title}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    </PerfectScrollbar>
                                )}
                            </Droppable>
                        </div>
                    )
            )}
        </div>
    );
};
export default PrepopulatedMessages;
