import { Formik } from "formik";
import phone from "phone";
import * as React from "react";
import { ChangeEvent, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import transformToFormikErrors from "../../helpers/transformToFormikErrors";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import { timeZonneList } from "../../types/Common";
import { SellerProfileFormValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import CustomSelect from "./fields/CustomSelect";
// import Loading from "../common/Loading";
import { restoreUser } from "../../store/asyncActions/auth";

const phoneRegExpUS = /^\+1\d{10}$/;
const phoneRegExpIndia = /^\+91\d{10}$/;
const validationSchema = Yup.object().shape({
    nameLast: Yup.string().required("Last name is required"),
    nameFirst: Yup.string().required("First name is required"),
    nameAlias: Yup.string().required("Alias is required"),
    // profilePic: Yup.string(),
    phoneCell: Yup.string()
        .required("Cell Number is required")
        .test("is-valid-phone-number", "Cell number is invalid", (val) => {
            if (!val) return false;
            return phoneRegExpUS.test(val) || phoneRegExpIndia.test(val);
        })
        .nullable(),
    email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required"),
});

const SellerProfileForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    // const [selectedFile, setSelectedFile] = useState<
    //     FileWithPreview | undefined
    // >(undefined);
    const [utz, setUtz] = useState<number | string>(0);
    const user = useTypedSelector((state) => state.auth.currentUser);
    // const isLoading = useTypedSelector((state) => state.auth.isLoading);
    // const [formErrors, setFormErrors] = useState<undefined | ServerError>(
    //     undefined
    // );

    const { updateUserProfile } = authActions;
    // const { uploadFile } = mediaActions;
    const { showSnackbarWithAutoHide } = notificationActions;

    const utzSelector = useTypedSelector(
        (state) => state.auth.currentUser?.timezoneDefault
    );

    React.useEffect(() => {
        if (utzSelector) {
            const foundTimezone = timeZonneList.find(
                (timezone) => timezone.name === utzSelector
            );
            if (foundTimezone) {
                setUtz(foundTimezone.id);
            }
        }
    }, [utzSelector, timeZonneList]);

    const initialValues: SellerProfileFormValues = {
        nameLast: user?.nameLast || "",
        nameFirst: user?.nameFirst || "",
        nameAlias: user?.nameAlias || "",
        profilePic: user?.profilePic || "",
        phoneCell: user?.phoneCell || "",
        email: user?.email || "",
        timezoneDefault_loc: timeZonneList[0],
        timezoneDefault: user?.timezoneDefault || "",
    };
    // const handleProfilePicChange = async (profilePic: any) => {
    //     try {
    //         if (profilePic !== undefined) {
    //             const response = await fetch(profilePic); // Assuming `preview` contains the image URL
    //             const contentType: any = response.headers.get("content-type");
    //             const profilePicArrayBuffer = await response.arrayBuffer();
    //             const profilePicData = new Blob([profilePicArrayBuffer], {
    //                 type: contentType,
    //             });

    //             // Hit the API with the profilePicData payload
    //             const apiPayload = {
    //                 file: profilePicData,
    //                 type: "profile",
    //             };

    //             const result = await dispatch(
    //                 updateUserProfile(apiPayload)
    //             ).unwrap();
    //             if (result)
    //                 dispatch(
    //                     showSnackbarWithAutoHide({
    //                         msgText: "Updated!",
    //                     })
    //                 );
    //             dispatch(restoreUser());
    //         }
    //     } catch (error) {
    //         console.error("Error uploading profile picture:", error);
    //     }
    // };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    // const initialProfilePic = user?.profilePic;
                    // const uploadedProfilePic = values?.profilePic;
                    const values_tm =
                        values.timezoneDefault_loc.id === "0"
                            ? null
                            : values.timezoneDefault_loc.name;

                    // let profilePicData = null;
                    // if (
                    //     uploadedProfilePic !== initialProfilePic &&
                    //     values.profilePic !== null
                    // ) {
                    //     
                    //     const response = await fetch(values?.profilePic);
                    //     const contentType: any =
                    //         response.headers.get("content-type");
                    //     const profilePicArrayBuffer =
                    //         await response.arrayBuffer();
                    //     profilePicData = new Blob([profilePicArrayBuffer], {
                    //         type: contentType,
                    //     });
                    // }

                    const payload = {
                        nameFirst: values.nameFirst,
                        nameLast: values.nameLast,
                        nameAlias: values.nameAlias,
                        email: values.email,
                        phoneCell: values.phoneCell,
                        timezoneDefault: values_tm,
                        file: values.profilePic,
                        type: "profile",
                    };

                    await dispatch(updateUserProfile(payload)).unwrap();

                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Updated!",
                        })
                    );
                    dispatch(restoreUser());
                    setSubmitting(false);
                } catch (err: any) {
                    setSubmitting(false);
                    setErrors(transformToFormikErrors(err));
                }
            }}
        >
            {({
                handleSubmit,
                dirty,
                isSubmitting,
                isValid,
                setFieldValue,
                values,
                setValues,
            }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column justify-content-between h-600px"
                >
                    <div>
                        {/* <Form.Row>
                            <Form.Group>
                                <InputForImageUploading
                                    btnText="Upload New Profile Photo"
                                    initialValue={values.profilePic}
                                    valueSetter={(profilePic: any) => {
                                        setFieldValue("profilePic", profilePic);
                                        setSelectedFile(profilePic);
                                        handleProfilePicChange(profilePic); // Call the API when profile pic changes
                                    }}
                                    isLoading={isLoading}
                                    isLoadingLogo={false}
                                    fileSetter={setSelectedFile}
                                    hideButton={false}
                                    customStyles="h-100px w-100px img-circle-border rounded-circle "
                                />
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="Your First Name"
                                    type="text"
                                    label="First Name"
                                    name="nameFirst"
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="Your Last Name"
                                    type="text"
                                    label="Last Name"
                                    name="nameLast"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-12">
                                <CustomInput
                                    placeholder="Your Alias"
                                    type="text"
                                    label="Alias"
                                    name="nameAlias"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-12">
                                <CustomInput
                                    disabled
                                    placeholder="Your Email"
                                    type="email"
                                    label="Email"
                                    name="email"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-12">
                                <CustomInput
                                    placeholder="Your Cell Phone"
                                    type="tel"
                                    className="py-4"
                                    label="Cell Phone"
                                    name="phoneCell"
                                    required={true}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "phoneCell",
                                            phone(e.target.value).phoneNumber,
                                            true
                                        )
                                    }
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-12">
                                <CustomSelect
                                    value={utz}
                                    title="Select Default Timezone"
                                    onChange={(e) => {
                                        setUtz(e.target.value);
                                        setValues({
                                            ...values,
                                            timezoneDefault_loc:
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                timeZonneList.find(
                                                    (plan) =>
                                                        plan.id ===
                                                        e.target.value
                                                )!,
                                        });
                                    }}
                                >
                                    {timeZonneList.map(
                                        (plan) =>
                                            (plan.id !== "0" || utz == "0") && (
                                                <option
                                                    key={plan.id}
                                                    value={plan.id}
                                                    selected={plan.id === "0"}
                                                    disabled={plan.id === "0"}
                                                >
                                                    {plan.name}
                                                </option>
                                            )
                                    )}
                                </CustomSelect>
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div>
                        {/* <FormErrors errors={formErrors} /> */}
                        <div className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!dirty || !isValid || isSubmitting}
                                className="mx-4"
                            >
                                Update Information
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SellerProfileForm;
