import React from "react";
import { ServerError } from "../../types/Error";
import { Alert } from "react-bootstrap";

interface IProps {
    errors?: ServerError;
}

const FormErrors: React.FC<IProps> = ({ errors }) => {
    if (!errors) return <></>;
    if (typeof errors === "string")
        return <Alert variant="danger">{errors}</Alert>;
    return <></>;
};

export default FormErrors;
