import React from "react";
import BillingHistoryComponent from "./billing/history/BillingHistoryComponent";
import BillingPaymentComponent from "./billing/payment/BillingPaymentComponent";
import BillingSubscriptionComponent from "./billing/subscriptions/BillingSubscriptionComponent";

const Billing: React.FC = () => {
    return (
        <>
            <BillingSubscriptionComponent />

            <BillingPaymentComponent />

            <BillingHistoryComponent />
        </>
    );
};

export default Billing;
