import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/automationsSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { scriptRunnerActions } from "../../store/reducers/scriptRunnerSlice";
import { Script, ScriptType } from "../../types/Script";
import Loading from "../common/Loading";
import ManageScriptPopover from "../script/ManageScriptPopover";
import MsgTemplateModal from "../script/msg-templates/MsgTemplateModal";
import "../script/script.scss";
import ShowItem from "./ShowItem";

interface IProps {
    script: Script;
    isModifiable: boolean;
    scriptId?: number;
}

const RunTextFlow: React.FC<IProps> = ({ script }) => {
    const dispatch = useTypedDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const messageTemplates = useTypedSelector(
        (state) => state.scriptRunner.messageTemplates,
        () => false
    );
    const { fetchMsgTemplates } = actions;
    useEffect(() => {
        setIsLoading(true);
        dispatch(
            scriptRunnerActions.fetchMsgTemplates({ scriptId: script.id })
        );
        dispatch(fetchMsgTemplates({ scriptId: script.id }))
            .unwrap()
            .then(() => setIsLoading(false));
    }, [script]);

    const scriptTimeMinutes = useMemo(() => {
        const timeSum = messageTemplates.list.reduce(
            (acc, item) => acc + (item.quietWaitSeconds || 0),
            0
        );
        return Math.ceil(timeSum / 60);
    }, []);

    const openCreateModal = useCallback(() => {
        dispatch(
            modalActions.openCreateMsgTemplateModal({
                scriptId: script.id,
                type: script.scripttype.name as ScriptType,
            })
        );
    }, [script.id]);

    return (
        <div className="d-flex flex-column">
            <MsgTemplateModal />
            <div className="d-flex justify-content-center align-items-center position-relative">
                <h5
                    className="font-weight-bold text-center mb-0"
                    title={script.name}
                >
                    {script.name}
                </h5>
                <ManageScriptPopover script={script} />
            </div>
            {isLoading ? (
                <div className="skeleton w-25 mx-auto mb-6 mt-2 shadow-sm" />
            ) : (
                <p className="p5 text-uppercase text-center mb-0">
                    {messageTemplates.list.length} steps - {scriptTimeMinutes}{" "}
                    minutes
                </p>
            )}
            {isLoading ? (
                <Loading />
            ) : (
                <PerfectScrollbar className="mt-4 max-h-600px flex-grow-1">
                    <div>
                        {messageTemplates.list.map((item) => (
                            <div key={item.id}>
                                <ShowItem
                                    msgTemplate={item}
                                    scriptId={script.id}
                                />
                            </div>
                        ))}
                        <div className="d-flex flex-column align-items-center bg-gray-300 px-5 pb-10 pt-5 rounded-lg script__new-template-msg-container w-275px mx-auto">
                            <p className="p5 text-center mb-1">
                                Drag from quick texts or...
                            </p>

                            <Button
                                variant="primary"
                                className="mt-3"
                                onClick={openCreateModal}
                            >
                                Create a New Message
                            </Button>
                        </div>
                    </div>
                </PerfectScrollbar>
            )}
        </div>
    );
};

export default RunTextFlow;
