import { Field, FieldProps, Formik } from "formik";
import React, { useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Button, Form } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Yup from "yup";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import {
    dragDropActions,
    INewMessageToPartyInput,
} from "../../store/reducers/dragDropSlice";
import { actions } from "../../store/reducers/partySlice";
import { MessageFormValues } from "../../types/Forms";
import Loading from "../common/Loading";
import CustomInput from "../forms/fields/CustomInput";
import MediaGridViewSelect from "../media/MediaGridViewSelect";
import PrepopulatedMessages from "../prepopulatedMessages/PrepopulatedMessages";

const validationSchema = Yup.object().shape({
    msgText: Yup.string().required("Message text is required!").min(1),
});

interface IProps {
    id?: number;
    prePopulatedMessage?: string;
    onReset: () => void;
}

const NewMessageToParty: React.FC<IProps> = ({
    id,
    prePopulatedMessage,
    onReset,
}) => {
    const dispatch = useTypedDispatch();
    const ps = useRef<HTMLElement>(); 
    const [textMsg, setTextMsg] = React.useState('')
    const { currentMessageTemplate } = useTypedSelector(
        (state) => state.modals.msgTemplateModal
    );
    const { deleteMessageByIdNewMessageToPartyInput } = dragDropActions;
    const { sendMessageToTheSelectedParty } = actions;
    const msgText: INewMessageToPartyInput | undefined = useTypedSelector(
        (state) =>
            state.dragDrop.NewMessageToPartyInput.find(
                (message) => message.id == id
            )
    );
    const initialValues = {
        msgText: msgText?.msgText || "",
    };

    if (!id) return <Loading />;

    const scrollToTopIfNoOverflow = () => {
        if (ps.current) {
            ps.current.scrollTop = 0;
        }
    };
    return (
        <div>
            <span className="d-flex justify-content-between">
                <p className="p4 text-black-50 mb-1 my-auto">New Message to Event</p>{" "}
                <div>
                    <Button variant="light" size="sm" className="mr-2" type="button" onClick={() => setTextMsg("{{nameFirst}}")}>
                        First Name
                    </Button>
                    <Button variant="light" size="sm" type="button" onClick={() => setTextMsg("{{nameLast}}")} >
                        Last Name
                    </Button>
                </div>
            </span>
            <PerfectScrollbar
                containerRef={(el) => (ps.current = el)}
                options={{ suppressScrollX: true }}
                style={{ maxHeight: "650px" }}
                className="w-100"
            >
                <div className="border-bottom border-secondary pb-4 mb-4">
                    <Formik
                        onSubmit={(
                            values: MessageFormValues,
                            { setSubmitting, resetForm }
                        ) => {
                            setSubmitting(true);
                            const ids =
                                values.mediaItems &&
                                values.mediaItems.map((item: any) => item.id);

                            dispatch(
                                sendMessageToTheSelectedParty({
                                    messageText: values.msgText,
                                    msgThreadId: id,
                                    mediaUrlId: ids,
                                })
                            )
                                .unwrap()
                                .then(() => {
                                    resetForm();
                                    dispatch(
                                        deleteMessageByIdNewMessageToPartyInput(
                                            id
                                        )
                                    );
                                    onReset();
                                    setSubmitting(false);
                                })
                                .catch(() => setSubmitting(false));
                        }}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                            <Form
                                onSubmit={handleSubmit}
                                className="d-flex flex-column align-items-end"
                            >
                                <Droppable
                                    droppableId="NewMessageToPartyInput"
                                    type="scriptTempPre"
                                    isCombineEnabled={true}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="d-flex flex-column align-items-center  rounded-lg w-100 mx-auto"
                                        >
                                            <CustomInput
                                                placeholder={"Type here..."}
                                                type="text"
                                                className="w-100 bg-gray-300 placeholder-dark-75 h-150px"
                                                name="msgText"
                                                as={"textarea"}
                                                valueOverride={
                                                    prePopulatedMessage
                                                }
                                                externalValue={textMsg}
                                            />

                                            {snapshot.isDraggingOver && (
                                                <div className="d-flex flex-column align-items-center bg-gray-300 px-5 pb-10 pt-5 rounded-lg script__new-template-msg-container w-100 mx-auto">
                                                    <p className="p5 text-center mb-1">
                                                        Drop quick texts here.
                                                    </p>
                                                </div>
                                            )}

                                            <Form.Row className="d-flex justify-content-center w-100">
                                                <Form.Group>
                                                    <Form.Row>
                                                        <Field
                                                            className="custom-select"
                                                            name="mediaItems"
                                                            placeholder="Select Images"
                                                            isMulti={true}
                                                        >
                                                            {(
                                                                props: FieldProps
                                                            ) => (
                                                                <MediaGridViewSelect
                                                                    scrollToTopIfNoOverflow={
                                                                        scrollToTopIfNoOverflow
                                                                    }
                                                                    isSubmitted={
                                                                        !isSubmitting
                                                                    }
                                                                    currentAssets={
                                                                        currentMessageTemplate?.mediaItems
                                                                    }
                                                                    {...props}
                                                                ></MediaGridViewSelect>
                                                            )}
                                                        </Field>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Form.Row>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={!isValid || isSubmitting}
                                    className="rounded mt-2"
                                    title="Send Message"
                                >
                                    Send New Message
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>

                <PrepopulatedMessages id={id} />
            </PerfectScrollbar>
        </div>
    );
};

export default NewMessageToParty;
