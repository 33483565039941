import React from "react";
import MyProfileHeader from "../components/myProfile/MyProfileHeader";
import DefaultLayout from "../layouts/DefaultLayout";

import ChangePasswordForm from "../components/forms/ChangePasswordForm";

const ChangePasswordPage: React.FC = () => {
    return (
        <DefaultLayout>
            <MyProfileHeader />
            <div className="card mb-5 mb-xl-10 mt-4">
                <div className="card-header cursor-pointer">
                    <div className="card-title m-0">
                        {/* <div className="d-flex justify-content-between"> */}
                        <div className="col-md-6">
                            <ChangePasswordForm />
                        </div>

                        {/* </div> */}
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default ChangePasswordPage;
