import * as React from "react";

function SvgAudioicon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill="#3699FF" d="M0 0h24v24H0z" />
            <path
                d="M8 4v10.184A2.962 2.962 0 007 14a3 3 0 103 3V7h7v4.184A2.962 2.962 0 0016 11a3 3 0 103 3V4H8z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgAudioicon;
