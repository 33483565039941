import { faStar as fsStarEmpty } from "@fortawesome/free-regular-svg-icons";
import {
    faAngleDown,
    faAngleUp,
    faPlus,
    faShareFromSquare,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useCallback, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Button, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import {
    fetchFavoriteMsgTemplates,
    fetchMsgTemplatesDragnDrop,
    setisFav,
} from "../../store/asyncActions/automations";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { Script, ScriptType } from "../../types/Script";

interface IProps {
    items: Script[];
    isLoading?: boolean;
    type: ScriptType;
}

const ScriptsList: React.FC<IProps> = ({ items, isLoading, type }) => {
    const history = useHistory();

    const searchParams = useQueryParams();
    const dispatch = useTypedDispatch();
    const urlParamsKey = type === ScriptType.PARTY ? "workflow" : "message";
    const urlBase = "/automations/" + urlParamsKey + "s";
    const scriptIt = Number(searchParams.get(urlParamsKey));

    const { openCreateMsgTemplateModal, openEmailScriptInviteModal } =
        modalActions;
    const [openAccordionId, setOpenAccordionId] = useState<number | null>(null);
    const [isLoaderFav, setIsLoaderFav] = useState<boolean>(false);
    // const { isOpen } = useTypedSelector(
    //     (state) => state?.modals?.msgTemplateModal
    // );

    const { list: listDragnDrop } = useTypedSelector(
        (state) => state?.automations?.messageTemplatesDragnDrop
    );

    const openCreateModal = useCallback((id: any, type: any) => {
        dispatch(
            openCreateMsgTemplateModal({
                scriptId: id,
                type: type as ScriptType,
            })
        );
    }, []);

    const handleEmailScriptInviteModal = useCallback(
        (id: any, type: any) => {
            dispatch(
                openEmailScriptInviteModal({
                    scriptId: id,
                    type: type as ScriptType, 
                })
            );
        },
        []
    );

    if (isLoading)
        return (
            <div className="mt-2">
                {new Array(6).fill(0).map((item, index) => (
                    <div
                        key={index}
                        className={"mb-1 px-3 py-3 rounded d-flex flex-column"}
                    >
                        <div className="skeleton h-20px w-75" />
                        <div className="h-15px w-50 skeleton mt-3" />
                    </div>
                ))}
            </div>
        );

    if (items.length === 0)
        return (
            <p className="p3 text-center mt-3">No {urlParamsKey + "s"} yet</p>
        );

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             setLoader(true);
    //             const msg = await dispatch(
    //                 fetchMsgTemplates({ scriptId: itemId })
    //             ).unwrap();
    //             setMessages(msg);
    //             setLoader(false);
    //         } catch (error) {
    //             console.error("Error:", error);
    //             setLoader(false);
    //         }
    //     };

    //     fetchData();
    // }, [dispatch, isScriptModalOpen, setLoader, setMessages]);

    const toggleAccordion = async (itemId: number) => {
        setOpenAccordionId((prevId: any) =>
            prevId === itemId ? null : itemId
        );
        await dispatch(fetchMsgTemplatesDragnDrop({ scriptId: itemId }));
    };
    const handleFav = async (
        action: "fav" | "unfav",
        msgTemplateId: number,
        scriptId: number
    ) => {
        setIsLoaderFav(true);
        await dispatch(
            setisFav({
                msgTemplateId,
                isFavorite: action === "fav" ? true : false,
            })
        );
        await dispatch(
            fetchMsgTemplatesDragnDrop({
                scriptId: scriptId,
            })
        );
        await dispatch(fetchFavoriteMsgTemplates({ size: 10 }));

        setIsLoaderFav(false);
    };

    return (
        <Droppable
            droppableId="scriptName"
            isDropDisabled={true}
            type="scriptTemp"
        >
            {(prd) => (
                <div {...prd.droppableProps} ref={prd.innerRef}>
                    <div className="mt-2">
                        {items.map((item) => (
                            <div key={item.id}>
                                <div
                                    className={`mb-1 px-3 py-3 list-view__item rounded d-flex justify-content-between align-items-center cursor-pointer ${
                                        scriptIt === item.id
                                            ? "bg-gray-400 shadow-sm"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        history.push(
                                            `${urlBase}?${urlParamsKey}=${item.id}`
                                        );
                                    }}
                                >
                                    <div>
                                        <h5 className="mb-0 text-truncate max-w-225px">
                                            {item.name}
                                        </h5>
                                        <p className="p4 mb-0 text-black-50">
                                            {item.messageCount}{" "}
                                            {type === ScriptType.PARTY
                                                ? "Steps"
                                                : "Messages"}
                                        </p>
                                    </div>
                                    <span>
                                        <Button
                                            variant="link"
                                            className="p1 cursor-pointer mb-0"
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                                transition:
                                                    "background 0.3s ease",
                                                borderRadius: "50%",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openCreateModal(
                                                    item.id,
                                                    item.scripttype
                                                );
                                            }}
                                        >
                                            <span className="text-primary">
                                                <FontAwesomeIcon
                                                    size="sm"
                                                    icon={faPlus}
                                                />
                                            </span>
                                        </Button>
                                        <Button
                                            variant="link"
                                            className="p3 cursor-pointer mb-0"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEmailScriptInviteModal(
                                                    item.id,
                                                    item.scripttype
                                                );
                                            }}
                                        >
                                            <span className="text-primary">
                                                <FontAwesomeIcon
                                                    icon={faShareFromSquare}
                                                />
                                            </span>
                                        </Button>
                                        <Button
                                            variant="link"
                                            className="p1 cursor-pointer mb-0"
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                                transition:
                                                    "background 0.3s ease",
                                                borderRadius: "50%",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleAccordion(item.id);
                                            }}
                                        >
                                            <span className="text-primary">
                                                {openAccordionId === item.id ? (
                                                    <FontAwesomeIcon
                                                        icon={faAngleUp}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faAngleDown}
                                                    />
                                                )}
                                            </span>
                                        </Button>
                                    </span>
                                </div>

                                {openAccordionId === item.id && (
                                    <div>
                                        {/* {isLoadingDragnDrop ? (
                                            <Loading />
                                        ) : ( */}
                                        <>
                                            {listDragnDrop.length === 0 ? (
                                                <ListGroup.Item className="d-flex text-center justify-content-center w-100">
                                                    No Messages
                                                </ListGroup.Item>
                                            ) : (
                                                <ListGroup>
                                                    {listDragnDrop.map(
                                                        (
                                                            message: any,
                                                            index: any
                                                        ) => (
                                                            <Draggable
                                                                key={message.id}
                                                                draggableId={`Msg-Template-drag-${message.id.toString()}-ScriptId-drop-${scriptIt}-id=ScriptList-${
                                                                    item.id
                                                                }-DragnDropScript`}
                                                                index={index}
                                                            >
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided
                                                                                .draggableProps
                                                                                .style,
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "space-between",
                                                                        }}
                                                                    >
                                                                        {snapshot.isDragging ? (
                                                                            <div
                                                                                style={{
                                                                                    position:
                                                                                        "absolute",
                                                                                    top: "50%",
                                                                                    left: "50%",
                                                                                    transform:
                                                                                        "translate(-50%, -50%)",
                                                                                    padding:
                                                                                        "10px",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius:
                                                                                        "8px",
                                                                                    backgroundColor:
                                                                                        "white",
                                                                                    display:
                                                                                        "flex",
                                                                                    flexDirection:
                                                                                        "column",
                                                                                    alignItems:
                                                                                        "center",
                                                                                    boxShadow:
                                                                                        "0 0 10px rgba(0, 0, 0, 0.2)",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    message.name
                                                                                }
                                                                                &nbsp;
                                                                                <span
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "bold",
                                                                                    }}
                                                                                >
                                                                                    Move
                                                                                    to
                                                                                </span>
                                                                            </div>
                                                                        ) : (
                                                                            <ListGroup.Item className="d-flex justify-content-between w-100 custom-hover-effect">
                                                                                <p
                                                                                    style={{
                                                                                        marginLeft:
                                                                                            "8px",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        message.name
                                                                                    }
                                                                                </p>
                                                                                <span className="text-primary">
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            message.isFavorite
                                                                                                ? faStar
                                                                                                : fsStarEmpty
                                                                                        }
                                                                                        style={{
                                                                                            marginRight:
                                                                                                "8px",
                                                                                            cursor: isLoaderFav
                                                                                                ? "not-allowed"
                                                                                                : "pointer",
                                                                                            opacity:
                                                                                                isLoaderFav
                                                                                                    ? 0.5
                                                                                                    : 1,
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            handleFav(
                                                                                                message.isFavorite
                                                                                                    ? "unfav"
                                                                                                    : "fav",
                                                                                                message.id,
                                                                                                item.id
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </ListGroup.Item>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}
                                                </ListGroup>
                                            )}
                                            {/* <Button
                                                    style={{
                                                        margin: "0rem 0rem 1rem 14rem",
                                                    }}
                                                    onClick={() =>
                                                        openCreateModal(
                                                            item.id,
                                                            item.scripttype
                                                        )
                                                    }
                                                >
                                                    Create Message
                                                </Button> */}
                                        </>
                                        {/* )} */}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {prd.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default ScriptsList;
