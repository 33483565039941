import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";

interface IFetchSlugParams {
    slug__?: string;
}
 export const fetchStaticPage = createAsyncThunk<void, IFetchSlugParams>(
     "staticpage/pages",
     async ({ slug__ }, thunkApi) => {
         try {
             const result = await axiosClient.get<void>("/static-page/data/"+slug__);
             return result.data;
         } catch (err: any) {
             return processAsyncThunkAxiosErrors(err, thunkApi);
         }
     }
 );
