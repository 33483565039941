import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import * as React from "react";
import { ScriptAsset } from "../../types/Media";
import MediaGridItem, { MediaGridItemSkeleton } from "./MediaGridItem";

interface IProps {
    mediaItems: ScriptAsset["items"][];
    isLoading?: boolean;
    noCollection?: boolean;
    onItemClick?: (item: ScriptAsset["items"]) => void;
    onIconClick?: (item: ScriptAsset["items"]) => void;
    selectedIcon?: IconDefinition;
    iconColor?: string;
}

const MediaGrid: React.FC<IProps> = ({
    mediaItems,
    isLoading,
    onItemClick,
    onIconClick,
    selectedIcon,
    iconColor,
}) => {
    const records = mediaItems;
    if (isLoading)
        return (
            <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                {new Array(7).fill(0).map((_, index) => (
                    <MediaGridItemSkeleton key={index} />
                ))}
            </div>
        );

    // if (records.length === 0)
    //     return (
    //         <p className="text-center w-100 mt-5">
    //             Select media assets collection or create new one
    //         </p>
    //     );

    return (
        <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
            {records?.length === 0 && (
                <p className="text-center w-100 mt-5">No media</p>
            )}
            {records?.map((item) => (
                <MediaGridItem
                    key={item.id}
                    item={item}
                    onItemClick={onItemClick}
                    onIconClick={onIconClick}
                    selectedIcon={selectedIcon}
                    iconColor={iconColor}
                />
            ))}
        </div>
    );
};

export default MediaGrid;
