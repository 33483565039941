import * as React from "react";
import { Spinner } from "react-bootstrap";

const Loading: React.FC = () => {
    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
        </div>
    );
};

export default Loading;