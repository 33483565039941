import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { Message } from "../../types/Message";
import { MsgThreadWithLastMsgPagination } from "../../types/MsgThread";

interface IFetchSellerDirectMessageThreadsParams {
    _page?: number;
    _limit?: number;
}

export const fetchSellerDirectMessageThreads = createAsyncThunk<
    MsgThreadWithLastMsgPagination["items"],
    IFetchSellerDirectMessageThreadsParams
>(
    "direct-messages/fetchSellerDirectMessageThreads",
    async ({ _limit, _page }, thunkApi) => {
        try {
            const result = await axiosClient.get<MsgThreadWithLastMsgPagination>(
                "/msg-thread/direct-messages",
                {
                    params: {
                        page_size: _limit,
                        page_number: _page,
                    },
                }
            );
            return result?.data?.items;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IFetchMessagesForThreadParams {
    _page?: number;
    _limit?: number;
    threadId: number;
}

export const fetchMessagesForThread = createAsyncThunk<
    { threadId: number; list: Message[] },
    IFetchMessagesForThreadParams
>("direct-messages/fetchMessagesForThread", async ({ threadId }, thunkApi) => {
    try {
        const result = await axiosClient.get<Message[]>(
            `/message/by-msg-thread/${threadId}`
        );
        return { threadId, list: result.data };
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
