import React from "react";
import VerificationsModel from "./VerificationsModel";

const VerificationsWrapper: React.FC = () => {
    return (
        <>
            <VerificationsModel />
        </>
    );
};

export default VerificationsWrapper;
