import React from "react";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import { MsgThreadWithLastMsg } from "../../types/MsgThread";

interface IProps {
    messagesList: MsgThreadWithLastMsg[];
    isLoading?: boolean;
}

const MessagesList: React.FC<IProps> = ({ messagesList, isLoading }) => {
    const searchParams = useQueryParams();
    const threadId = Number(searchParams.get("thread"));
    const history = useHistory();
    if (isLoading)
        return (
            <>
                {new Array(5).fill(0).map((_, indx) => (
                    <div
                        key={indx}
                        className="d-flex mt-6 px-3 d-flex align-items-center"
                    >
                        <div className="skeleton shadow-sm small-image rounded-circle" />
                        <div className="w-100 h-100 flex-grow-1">
                            <div className="d-flex w-100 h-100 justify-content-between mr-4">
                                <div className="skeleton shadow-sm w-25" />
                                <div className="skeleton shadow-sm w-10" />
                            </div>
                            <div className="skeleton shadow-sm w-100 mt-2" />
                        </div>
                    </div>
                ))}
            </>
        );

    if (messagesList?.length === 0)
        return <p className="p3 text-center mt-3">No messages yet</p>;

    return (
        <div className="list-view">
            {messagesList?.map((item) => {
                const count = 6;
                //     = item.messages.reduce(
                //     (acc, val) => (!val.isRead ? acc + 1 : acc),
                //     0
                // );
                const itemNotificationsCount = count > 99 ? "99+" : `${count}`;
                return (
                    <div
                        key={item.id}
                        onClick={() =>
                            history.push(`/direct_messages?thread=${item.id}`)
                        }
                        className={`list-view__item d-flex my-1 px-4 py-3 d-flex align-items-center cursor-pointer rounded ${
                            threadId && threadId === item.id
                                ? "bg-gray-400 shadow-sm"
                                : ""
                        }`}
                    >
                        <div className="shadow-sm small-image bg-secondary rounded-circle d-flex align-items-center justify-content-center mt-2">
                            {count > 0 && (
                                <div className="p5 font-weight-bold msg-count border bg-primary rounded-circle text-white d-flex align-items-center justify-content-center">
                                    {itemNotificationsCount}
                                </div>
                            )}
                            EN
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <div className="d-flex justify-content-between mr-4 w-100 align-items-center">
                                <div className="p4 font-weight-bold text-truncate text-wrap text-break">
                                    {item.name}
                                </div>
                                <div className="p5 font-weight-bold text-wrap text-break">
                                    {/*{dayjs(item).format(*/}
                                    {/*    "h:mm A"*/}
                                    {/*)}*/}
                                </div>
                            </div>
                            <div className="text-truncate text-break mw-100 mh-50">
                                {item.welcomeMsg}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default MessagesList;
