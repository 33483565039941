import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationsState } from "../../types/Common";
import { showSnackbarWithAutoHide } from "../asyncActions/notifications";

const initialState: NotificationsState = {
    snackbar: {
        isOpen: false,
        msgText: "",
    },
};

interface IShowSnackbar {
    msgText: string;
}

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        showSnackbar: (state, action: PayloadAction<IShowSnackbar>) => {
            state.snackbar.msgText = action.payload.msgText;
            state.snackbar.isOpen = true;
        },
        hideSnackbar: (state) => {
            state.snackbar.msgText = "";
            state.snackbar.isOpen = false;
        },
    },
});

export const notificationActions = {
    ...notificationsSlice.actions,
    showSnackbarWithAutoHide,
};

const notificationsReducer = notificationsSlice.reducer;

export default notificationsReducer;
