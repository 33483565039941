export type AssetCollection = {
    id: number;
    sellerId: number;
    name: string;
    new: string;
};

export type ScriptAsset = {
    items: {
        id: number;
        sellerId: number;
        assetTypeId: number;
        assetCollectionId: number;
        name: string;
        size:number;
        type:string;
        assetUrl: string;
        assetTag: string;
        linkUrl: string;
        isActive: boolean;
        assetType: {id: number, name: string};
        assetCollection: string;
        isSelected: boolean;
        target_url: string;
    },
    links: [],
    meta: []
};

export enum AssetType {
    IMAGE = "image",
    VIDEO = "video",
    AUDIO = "audio",
    ANY = "any",
}

export enum AssetTypesSelect {
    "any",
    "image",
    "video",
    "audio",
}
