import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { fetchScriptAssetsByCollection } from "../../store/asyncActions/media";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { mediaActions } from "../../store/reducers/medaiSlice";
import { EditMediaFromValues } from "../../types/Forms";
import { AssetCollection, ScriptAsset } from "../../types/Media";
import Loading from "../common/Loading";
import CustomInput from "../forms/fields/CustomInput";
import FormErrors from "../forms/FormErrors";
import FileView from "./FileView";

interface IProps {
    show: boolean;
    handleClose: () => void;
    item?: ScriptAsset["items"];
    collectionsList: AssetCollection[];
}
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("File name is required")
        .max(150, "File name can't be that long"),
    target_url: Yup.string()
        //.required("address is required")
        .nullable() // Allow null or empty address
        .max(150, "Target url can't be that long"),
    assetCollectionId: Yup.string()
        // .required("address is required")
        .nullable(), // Allow null or empty address
});

const MediaEditModal: React.FC<IProps> = ({
    handleClose,
    show,
    item,
    collectionsList,
}) => {
    const dispatch = useTypedDispatch();
    const { updateAsset } = mediaActions;
    const currentCollection = useTypedSelector(
        (state) => state.media.collections.selectedCollection
    );
    const formErrors = useTypedSelector(
        (state) => state.contact.createContactForm.error
    );
    const selectedAssetCollectionId: any = item?.assetCollectionId;
    const [workflowSelect, setWorkflowSelect] = useState(0);

    // const handleWorkflowChange = (e: ChangeEvent<HTMLSelectElement>) => {
    //     const newValue = Number(e.target.value);
    //     setWorkflowSelect(newValue);
    // };
    const submitForm = async (values: any) => {
        const data = await dispatch(
            updateAsset({
                collection: {
                    ...values,
                    name: values.name,
                    collectionId: workflowSelect,
                },
                id: values.id,
            })
        )
            .unwrap()
            .then((data: any) => {
                if (!data?.error) {
                    dispatch(
                        fetchScriptAssetsByCollection({
                            collectionId: currentCollection,
                        })
                    );
                    handleClose();
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Updated!",
                        })
                    );
                }
            });

        return data;
    };

    const [initialValues, setInitialValues] =
        React.useState<EditMediaFromValues>({
            name: "",
            target_url: "",
            assetCollectionId: 0,
        });

    useEffect(() => {
        if (item) {
            setInitialValues(item);
            setWorkflowSelect(selectedAssetCollectionId);
        }
    }, [item, selectedAssetCollectionId]);
    const content = (
        <div className="d-flex flex-column">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values)
                        .then((data: any) => {
                            if (!data?.error) {
                                //resetForm();
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    handleSubmit,
                    dirty,
                    values,
                    isSubmitting,
                    isValid,
                    setValues,
                }) => {
                    return (
                        <Form
                            onSubmit={handleSubmit}
                            className="d-flex flex-column justify-content-between h-100"
                        >
                            <div
                                style={{ margin: "auto" }}
                                className="w-50 border-bottom overflow-hidden pb-6 mb-4 text-center"
                            >
                                {item ? <FileView file={item} /> : <Loading />}
                            </div>
                            <div>
                                <Form.Row>
                                    <Form.Group className="col-md">
                                        <CustomInput
                                            placeholder="Name..."
                                            type="text"
                                            label="Name"
                                            name="name"
                                            required={true}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group className="col-md">
                                        <CustomInput
                                            placeholder="Target URL..."
                                            type="text"
                                            label="Target URL"
                                            name="target_url"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group className="col-md">
                                        <Form.Group
                                            className="mb-3 w-100"
                                            controlId="assetCollectionId"
                                        >
                                            <Form.Control
                                                //{...field}
                                                as="select" // Render as a select element
                                                name="assetCollectionId"
                                                value={workflowSelect}
                                                onChange={(e) => {
                                                    const newValue = Number(
                                                        e.target.value
                                                    );
                                                    setWorkflowSelect(newValue);
                                                    setValues({
                                                        ...values,
                                                        assetCollectionId:
                                                            newValue,
                                                    });
                                                }}
                                                className={
                                                    "form-control h-auto py-5 px-6 mb-2 mt-2"
                                                }
                                                //isInvalid={!!meta.error && meta.touched && !hideError}
                                            >
                                                <option value={0}>
                                                    No selected textflow
                                                </option>
                                                {collectionsList.map(
                                                    (collection) => (
                                                        <option
                                                            key={collection?.id}
                                                            value={
                                                                collection?.id
                                                            }
                                                        >
                                                            {collection?.name}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Row>
                            </div>

                            <FormErrors errors={formErrors} />

                            <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={
                                        !dirty || !isValid || isSubmitting
                                    }
                                    className="mx-4"
                                >
                                    {item
                                        ? "Update Media"
                                        : "Create New Contact"}
                                </Button>
                            </Form.Row>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header
                closeButton
                className="d-flex justify-content-between align-items-center"
            >
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                ></button>
            </Modal.Header>
            <Modal.Body>{!item ? <Loading /> : content}</Modal.Body>
        </Modal>
    );
};

export default MediaEditModal;
