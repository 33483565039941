import {
    faEdit,
    faEllipsisH,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { notificationActions } from "../../store/reducers/notificationsSlice";
import { actions } from "../../store/reducers/partySlice";
import { MsgThread } from "../../types/MsgThread";

interface IProps {
    party: MsgThread;
    guests: any;
}

const ManagePartyPopover: React.FC<IProps> = ({ party }) => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const { openEditPartyModal } = modalActions;
    const { showSnackbarWithAutoHide } = notificationActions;
    const { deleteParty } = actions;

    const items = [
        {
            title: "Edit",
            action: () =>
                dispatch(
                    openEditPartyModal({
                        currentParty: party,
                    })
                ),
            icon: (
                <FontAwesomeIcon
                    icon={faEdit}
                    className="text-warning w-15px h-15px mr-2"
                />
            ),
        },

        {
            title: "Delete",
            action: () => {
                confirmAlert({
                    title: "Confirm to delete",
                    message:
                        "Are you sure you want to delete this item and all objects associated?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                dispatch(deleteParty({ id: party.id }))
                                    .unwrap()
                                    .then(() => {
                                        history.push(
                                            "/manage_parties/dashboard"
                                        );
                                    })
                                    .catch((err: any) => {
                                        dispatch(
                                            showSnackbarWithAutoHide({
                                                msgText: err,
                                            })
                                        );
                                    });
                            },
                        },
                        {
                            label: "No",
                            onClick: () => console.info("No."),
                        },
                    ],
                });
            },
            icon: (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger w-15px h-15px mr-2"
                />
            ),
        },
    ];

    const renderPopover = (props: any) => (
        <Popover id="manage-script-popover" {...props}>
            <Popover.Content className="p-3">
                <ul className="p-0 m-0">
                    {items.map((item) => (
                        <li
                            key={item.title}
                            onClick={() => item.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            overlay={renderPopover}
            placement="right"
        >
            {/*<Dots className="position-absolute right-0 top-50 mr-5 w-25px h-20px cursor-pointer" />*/}
            <FontAwesomeIcon
                icon={faEllipsisH}
                className="position-absolute right-0 top-50 mr-5 w-25px h-20px cursor-pointer"
            />
        </OverlayTrigger>
    );
};

export default ManagePartyPopover;
