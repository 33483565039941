import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { useTypedDispatch } from "../../../../store/hooks/useTypedDispatch";
import { authActions } from "../../../../store/reducers/authSlice";
import { notificationActions } from "../../../../store/reducers/notificationsSlice";
import { actions } from "../../../../store/reducers/subscriptionSlice";
import { Mymembership } from "../../../../types/Mymembership";
import { confirmAlert } from "react-confirm-alert";
type Props = {
    plans: Array<Mymembership>;
};

const SubscriptionPlanDetails: React.FC<Props> = ({ plans }) => {
    const dispatch = useTypedDispatch();
    const { restoreUser } = authActions;
    const { MembershipCancel } = actions;
    const sortedPlans = [...plans]
        .filter((plan: any) => plan.isMembershipActive === true)
        .sort(
            (a: any, b: any) =>
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
        );

    const handleCancel = async (memberShipId: number): Promise<void> => {
        await dispatch(MembershipCancel(memberShipId))
            .unwrap()
            .then(() => {
                dispatch(restoreUser());
            })
            .then(() => {
                dispatch(
                    notificationActions.showSnackbarWithAutoHide({
                        msgText: "Your subscription has been cancelled.",
                    })
                );
            });
    };

    const handleCancelSub = (planId: number) => {
        return (
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui container'>
                            <div className='row justify-content-center'>
                                <div className='col-md-8 col-lg-6'>
                                    <div className='bg-white p-10 rounded-xl shadow-lg'>
                                        <h1 className='alert-heading text h3'><strong>Are you sure you want to cancel your subscription?</strong></h1>
                                        <p className='mb-4'>By canceling, you will lose access to premium features, and your account will be downgraded to a free plan. Any unused time in your current billing cycle will not be refunded.</p>
                                        <p className='mb-4 alert-heading h5'><strong>Do you want to proceed with the cancellation?</strong></p>
                                        <div className='d-flex justify-content-between mt-10'>
                                            <button
                                                className='btn btn-danger'
                                                onClick={() => {
                                                    handleCancel(planId)
                                                    onClose();
                                                }}
                                            >
                                                Cancel Subscription
                                            </button>
                                            <button
                                                className='btn btn-secondary me-2'
                                                onClick={onClose}
                                            >
                                                Keep My Subscription
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            })
        );
    };


    return (
        <>
            {sortedPlans.map((plan: Mymembership) => (
                <div key={plan.id} className="row mb-lg-15">
                    <div className="col-lg-7">
                        <h4 className="mb-2 font-weight-bolder">
                            {`${plan?.planType?.name} Active until
                            ${dayjs(plan.endDate).format("MMM DD, YYYY")}`}
                        </h4>
                        <p className="fs-6 text-gray-600 font-weight-bold mb-6">
                            We will send you a notification upon Subscription
                            expiration
                        </p>
                        <div className="fs-5 mb-2">
                            <span className="text-gray-800 font-weight-bolder me-1">
                                ${plan?.planType?.price}
                            </span>
                            <span className="text-gray-600 font-weight-bold">
                                Per {plan?.planType?.recurrence}
                            </span>
                        </div>
                        <div className="fs-6 text-gray-600 font-weight-bold">
                            Extended Pro Package. Up to 100 Agents & 25 Projects
                        </div>
                    </div>
                    <div className="col-lg-5">
                        {/* <div className="d-flex text-muted font-weight-bolder fs-5 mb-3">
                            <span className="flex-grow-1 text-gray-800">
                                Features
                            </span>
                            <span className="text-gray-800">
                                {plan?.planType?.features?.length} of 100 Used
                            </span>
                        </div>
                        <div className="progress h-8px bg-light-primary mb-2">
                            <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{ width: "86%" }}
                                aria-valuenow={86}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            ></div>
                        </div>
                        <div className="fs-6 text-gray-600 font-weight-bold mb-10">
                            14 Users remaining until your plan requires update
                        </div> */}
                        <div className="d-flex justify-content-end pb-0 px-0">
                            <button
                                onClick={() => handleCancelSub(plan.id)}
                                className="btn btn-light btn-active-light-primary me-2"
                                id="kt_account_billing_cancel_subscription_btn"
                            >
                                Cancel Subscription
                            </button>
                            <Link to="/upgrade">
                                <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_upgrade_plan"
                                >
                                    Upgrade Plan
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default SubscriptionPlanDetails;
