import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
// import {  } from "react-bootstrap";
import dayjs from "dayjs";

import { UpdatePartyFormValues } from "../../types/Forms";
import { Button, Form } from "react-bootstrap";
import CustomInput from "./fields/CustomInput";
import { composeDates } from "../../helpers/composeDates";
import { actions } from "../../store/reducers/partySlice";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import FormErrors from "./FormErrors";
import { modalActions } from "../../store/reducers/modalsSlice";
import { isStartDateBeforeEndDate } from "../../helpers/isStartDateBeforeEndDate";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Event name is required")
        .max(150, "Event name can't be that long"),
    partyStartDate: Yup.date()
        .required("Start date is required")
        .min(
            dayjs().subtract(1, "day").toDate(),
            "You can't create events in the past"
        )
        .max(dayjs().add(2, "years").toDate(), "Too far into the future"),
    partyStartTime: Yup.string().required("Party time is required"),
    partyEndDate: Yup.date()
        .required("End date is required")
        .min(Yup.ref("partyStartDate"), "End date should be after start date")
        .max(dayjs().add(2, "years").toDate(), "Too far into the future"),
    partyEndTime: Yup.string()
        .required("Event time is required")
        .test(
            "is-not-in-the-past",
            "End time should be after start time",
            (val, testContext) =>
                isStartDateBeforeEndDate({
                    ...testContext.parent,
                    partyEndTime: val || "",
                })
        ),
});

const UpdatePartyForm: React.FC = () => {
    const dispatch = useTypedDispatch();
    // const { currentParty } = useTypedSelector(
    //     (state) => state.modals.partyModal
    // );
    const  currentParty  = useTypedSelector(
        (state) => state.party.parties.selected.party
    );
    const formErrors = useTypedSelector(
        (state) => state.party.createNewPartyForm.error
    );

    const { updateParty } = actions;
    const { closePartyModal } = modalActions;

    const initialValues: UpdatePartyFormValues = {
        name: currentParty?.name || "",
        partyStartDate:
            dayjs(currentParty?.timeSchedStart).format("YYYY-MM-DD") ||
            dayjs().format("YYYY-MM-DD"),
        partyStartTime:
            dayjs(currentParty?.timeSchedStart).format("HH:mm") || "",
        partyEndDate:
            dayjs(currentParty?.timeSchedEnd).format("YYYY-MM-DD") ||
            dayjs().format("YYYY-MM-DD"),
        partyEndTime: dayjs(currentParty?.timeSchedEnd).format("HH:mm") || "",
    };

    const submitForm = (values: UpdatePartyFormValues) => {
        const timeSchedStart = composeDates(
            values.partyStartDate,
            values.partyStartTime
        );

        const timeSchedEnd = composeDates(
            values.partyEndDate,
            values.partyEndTime
        );

        return dispatch(
            updateParty({
                id: currentParty?.id,
                name: values.name,
                timeSchedStart: timeSchedStart.toISOString(),
                timeSchedEnd: timeSchedEnd.toISOString(),
            })
        )
            .unwrap()
            .then(() => {
                dispatch(closePartyModal());
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                submitForm(values).finally(() => {
                    resetForm();
                    setSubmitting(false);
                });
            }}
        >
            {({ handleSubmit, dirty, isSubmitting, isValid }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column justify-content-between h-100"
                >
                    <div>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Unique Event Name..."
                                    type="text"
                                    label="Event Name"
                                    name="name"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder=""
                                    type="date"
                                    label="Event Start Date"
                                    name="partyStartDate"
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="10:00 PM"
                                    type="time"
                                    label="Start Time"
                                    className="timepicker"
                                    name="partyStartTime"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder=""
                                    type="date"
                                    label="Event End Date"
                                    name="partyEndDate"
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-6">
                                <CustomInput
                                    placeholder="10:00 PM"
                                    type="time"
                                    label="End Time"
                                    name="partyEndTime"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>

                    <FormErrors errors={formErrors} />

                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                        >
                            Update Party
                        </Button>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default UpdatePartyForm;
