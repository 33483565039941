import * as React from "react";
import { Col, Row } from "react-bootstrap";
import WorkflowEditorCard from "../../components/cardViews/WorkflowEditorCard";
import WorkflowsListCard from "../../components/cardViews/WorkflowsListCard";
import MsgTemplateModal from "../../components/script/msg-templates/MsgTemplateModal";
import ScriptPreview from "../../components/script/ScriptPreview";
import DefaultLayout from "../../layouts/DefaultLayout";
import PhoneCard from "../../layouts/PhoneCard";

const ManageWorkflows: React.FC = () => { 
    
    return ( 
            <DefaultLayout>
                <MsgTemplateModal />
                <Row xl={4}>
                    <Col xl={4} className="pt-15">
                        <WorkflowsListCard />
                    </Col>
                    <Col xl={5} className="pt-15">
                        <WorkflowEditorCard />
                    </Col>
                    <Col xl={3} className="pt-15">
                        <PhoneCard>
                            <ScriptPreview />
                        </PhoneCard>
                    </Col>
                </Row>
            </DefaultLayout> 
    );
};

export default ManageWorkflows;
