import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    default as axiosClient,
    default as axiosClients,
} from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { ContactState } from "../../types/Common";
import {
    NewAddInAnnouncementFromValues,
    NewContactFromValues,
    UpdateContactFromValues,
} from "../../types/Forms";
import { ContactMessage, Message } from "../../types/Message";
import { AddInAnnouncementField, ContactField } from "../../types/MsgThread";

interface IFetchContactListParams {
    _page?: number;
    _limit?: number;
    search?: string;
}

export const fetchContacts = createAsyncThunk<
    ContactField[],
    IFetchContactListParams
>("contact/getlist", async ({ _page, _limit, search }, thunkApi) => {
    // The payload argument is unused, so we can use '_'
    try {
        const result = await axiosClient.get<ContactField[]>("/contact", {
            params: {
                page: _page,
                limit: _limit,
                search,
            },
        });

        return result.data;
    } catch (err: any) {
        // If your processAsyncThunkAxiosErrors function returns a Promise of ContactField[], you can use it directly here.
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
export const fetchActiveContacts = createAsyncThunk<
    ContactField[],
    IFetchContactListParams
>("activeContacts/getlist", async ({ _page, _limit, search }, thunkApi) => {
    // The payload argument is unused, so we can use '_'
    try {
        const result = await axiosClient.get<ContactField[]>(
            "/contact/activeContacts",
            {
                params: {
                    page: _page,
                    limit: _limit,
                    search,
                },
            }
        );

        return result.data;
    } catch (err: any) {
        // If your processAsyncThunkAxiosErrors function returns a Promise of ContactField[], you can use it directly here.
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
export const fetchImportContacts = createAsyncThunk<any, number | undefined>(
    "contact/getgooglelist",
    async (id, thunkApi) => {
        try {
            const result = await axiosClient.get<ContactField[]>(
                "/contact/import-contacts/" + id
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
export const getGoogleContactCallbackResponse = createAsyncThunk<
    any,
    string | undefined
>("contact/getgooglecallback", async (code, thunkApi) => {
    try {
        const result = await axiosClient.get<ContactField[]>(
            "/contact/callback?code=" + code
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
export const selectOne = createAsyncThunk<ContactField, any>(
    "contact/selectOne",
    (payload: any) => payload
);

export const createContact = createAsyncThunk<
    ContactField,
    NewContactFromValues
>("contact/create", async (values, thunkApi) => {
    try {
        const result = await axiosClients.post<ContactField>("/contact", {
            name: values.name,
            address: values.address,
            phoneCell: values.phoneCell,
            email: values.email,
            profilePic: values.profilePic,
            status: values?.status,
        });
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
export const UpdateContact = createAsyncThunk<
    ContactField,
    UpdateContactFromValues
>("contact/update", async (values, thunkApi) => {
    try {
        const result = await axiosClients.put<ContactField>(
            "/contact/" + values.id,
            {
                name: values.name,
                address: values.address,
                phoneCell: values.phoneCell,
                email: values.email,
                profilePic: values.profilePic,
                status: values?.status,
            }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const deleteContact = createAsyncThunk<
    ContactField,
    NewContactFromValues
>("contact/delete", async (id: any, thunkApi: any) => {
    try {
        await axiosClients.delete<ContactField>("/contact/" + id);
        return id;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const createAddInAnnouncement = createAsyncThunk<
    AddInAnnouncementField,
    NewAddInAnnouncementFromValues
>("addInAnnoucement/create", async (values, thunkApi) => {
    try {
        const result = await axiosClients.post<AddInAnnouncementField>(
            "/announcement/add-to-announcement",
            {
                contact_id: values.contact_id,
                announcement_id: values.announcement_id,
                announcement_type: values.announcement_type,
            }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const sendMessageToTheSelectedContact = createAsyncThunk<
    ContactMessage,
    unknown
>("contact/sendMessageToTheSelectedContact", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<ContactMessage>(
            "/message/contact",
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IFetchMessagesForThreadParams {
    _page?: number;
    _limit?: number;
    selected: ContactState["selected"];
}

export const fetchContactMessageList = createAsyncThunk<
    IFetchMessagesForThreadParams,
    ContactState["selected"]
>("contact-messages/fetchMessages", async (selected, thunkApi) => {
    try {
        const result = await axiosClient.get<Message[]>(
            `/message/contact/${selected.entity?.id}`,
            {
                params: {
                    type: selected.type,
                },
            }
        );
        return { selected, list: result.data };
    } catch (err: any) {
        return thunkApi.rejectWithValue(
            processAsyncThunkAxiosErrors(err, thunkApi)
        );
    }
});

export const importCsvContact = createAsyncThunk<void, { file: any }>(
    "contact/ImportCSV",
    async (file: any, thunkApi: any) => {
        try {
            const data = new FormData();
            data.append("files", file);  
            const result = await axiosClient.post (
                "contact/import/csv",
                data
            ); 
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

 