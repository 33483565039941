import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import PartyDetailsCard from "../../components/cardViews/PartyDetailsCard";
import PartyMessageCard from "../../components/cardViews/PartyMessageCard";
import PartyTreeCard from "../../components/cardViews/PartyTreeCard";
import GuestProfileModal from "../../components/guest/GuestProfileModal";
import SaveGuestContactModal from "../../components/guest/SaveGuestContactModal";
import MessagesContainer from "../../components/messages/MessagesContainer";
import { SocketContext } from "../../layouts/AppWrapper";
import DefaultLayout from "../../layouts/DefaultLayout";
import PhoneCard from "../../layouts/PhoneCard";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions as automationActions } from "../../store/reducers/automationsSlice";
import { actions } from "../../store/reducers/partySlice";
import { ThreadState } from "../../types/MsgThread";

interface ManageSinglePartyParams {
    id: string;
}

const ManagePartiesSingleParty: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { id } = useParams<ManageSinglePartyParams>();
    const {
        fetchSelectedParty,
        fetchSelectedPartyGuests,
        fetchSelectedPartyGuestsPaginate,
        fetchSelectedPartyMessages,
        fetchSelectedPartyMessageAnalytics,
    } = actions;
    const { fetchScripts } = automationActions;
    const { isLoading, list } = useTypedSelector(
        (state) => state.party.parties.selected.messages
    );

    const { party: currentParty } = useTypedSelector(
        (state) => state.party.parties.selected
    );

    const { list: messageAnalytics } = useTypedSelector(
        (state) => state.party.messageAnalytics
    );
   
    const socket = useContext(SocketContext);
    const history = useHistory(); 
    const numberId = Number(id);
    const {page:guestPage, limit:guestLimit,search:guestSearch} = useTypedSelector(
        (state) => state?.party?.parties?.selected?.guests
    );
    useEffect(() => {
        if (numberId) {
            dispatch(
                fetchSelectedParty({
                    partyId: numberId,
                })
            );
            dispatch(
                fetchSelectedPartyMessageAnalytics({
                    id: numberId,
                })
            )
                .unwrap()
                .then(() => {
                    dispatch(
                        fetchSelectedPartyGuests({
                            partyId: numberId,_page: 1, _limit: 1000,search:""
                        })
                    ); 
                    dispatch(
                        fetchSelectedPartyGuestsPaginate({
                            partyId: numberId,_page: guestPage, _limit: guestLimit,search:guestSearch
                        })
                    );
                    dispatch(
                        fetchSelectedPartyMessages({
                            id: numberId,
                        })
                    );
                    dispatch(fetchScripts({_page: 1, _limit: 1000,search:""}));
                })
                .catch(() => history.replace("/manage_parties/dashboard"));
        }
    }, [numberId]);

    if (!numberId) history.replace("/manage_parties/dashboard");

    return (
        <DefaultLayout>
            <GuestProfileModal />
            <SaveGuestContactModal />
            <Row sm={2} xs={1} lg={1} xl={3}>
                <Col className="pt-15">
                    <PartyDetailsCard socket={socket} />
                </Col>

                {currentParty?.threadState?.name === ThreadState.COMPLETED ? (
                    <>
                        <Col className="pt-15" lg={9} xl={8}>
                            <PartyTreeCard
                                messageAnalytics={messageAnalytics}
                                isLoading={isLoading}
                                partyId={numberId}
                                socket={socket}
                            />
                        </Col>
                    </>
                ) : (
                    <>
                        <Col className="pt-15">
                            <PartyMessageCard />
                        </Col>

                        <Col className="pt-15">
                            <PhoneCard>
                                <MessagesContainer
                                    messages={list}
                                    isLoading={isLoading}
                                    partyId={numberId}
                                    socket={socket}
                                />
                            </PhoneCard>
                        </Col>
                    </>
                )}
            </Row>
        </DefaultLayout>
    );
};

export default ManagePartiesSingleParty;
