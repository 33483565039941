import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import CustomInput from "./fields/CustomInput";
import { RegisterFormValues } from "../../types/Forms";
import SteppedForm from "./steppedForm/SteppedForm";
import FormStep from "./steppedForm/FormStep";
import OAuth from "../auth/OAuth";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
//import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { isArray } from "lodash";

const initialValues: RegisterFormValues = {
    email: "",
    password: "",
    passwordRepeat: "",
    nameFirst: "",
    nameLast: "",
    nameAlias: "",
    phoneCell: "",
};
const phoneRegExpUS = /^\+1\d{10}$/;
const phoneRegExpIndia = /^\+91\d{10}$/;
const firstStepValidationSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required!")
        .email("Email is not valid!"),
    password: Yup.string()
        .required("Password is required!")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/, // Modify this regex as needed
            "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, and one number"
        ),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords should match")
        .required("Repeat password is required"),
});

const secondStepValidationSchema = Yup.object().shape({
    nameFirst: Yup.string().required("First Name is required"),
    nameLast: Yup.string().required("Last Name is required"),
    nameAlias: Yup.string().required("Name Alias is required"),
    phoneCell: Yup.string()
        .required("Cell Number is required")
        .test("is-valid-phone-number", "Cell number is invalid", (val) => {
            if (!val) return false;
            return phoneRegExpUS.test(val) || phoneRegExpIndia.test(val);
        })
        .nullable(),
});

const RegisterForm = () => {
    // const errors = useTypedSelector((state) => state.auth.errors);
    const dispatch = useTypedDispatch();
    //const errors = useTypedSelector((state) => state.auth.errors);
    //const history = useHistory();
    const { createUser } = authActions;
    const [error, setError] = useState<string>();
    const [errors, setErrors] = useState<
        Array<{ value: string; property: string; constrains: string }>
    >([]);

    return (
        <div className="login-form">
            <div className="mb-lg-4">
                <h1 className="h1 font-weight-bold">Welcome to Sparty</h1>
                <p className="text-muted font-weight-bold p2">
                    Already have an account?{" "}
                    <Link to={"/auth/login"}>
                        <span className="text-primary font-weight-bold">
                            Login
                        </span>
                    </Link>
                </p>
            </div>
            <div className="mb-6">
                <OAuth type="registration" />
            </div>
            <SteppedForm
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    setErrors([]);
                    setError("");
                    try {
                        const promiseResult = await dispatch(
                            createUser(values)
                        );
                        unwrapResult(promiseResult);

                        setSubmitting(false);
                    } catch (err) {
                        setSubmitting(false);
                        // Step-3 redirect for plan

                        if (isArray(err)) {
                            setErrors(err);
                        } else {
                            setError((err as Error).message);
                        }
                    }

                    setSubmitting(false);
                }}
            >
                <FormStep validationSchema={firstStepValidationSchema}>
                    <Form.Row>
                        <Form.Group className="w-100">
                            <CustomInput
                                placeholder="Email"
                                type="email"
                                label="Email"
                                name="email"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="w-100">
                            <CustomInput
                                placeholder="Password"
                                label="Password"
                                type="password"
                                name="password"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="w-100">
                            <CustomInput
                                placeholder="Retype password"
                                label="Retype password"
                                type="password"
                                name="passwordRepeat"
                                required={true}
                            />
                        </Form.Group>
                    </Form.Row>
                </FormStep>
                <FormStep validationSchema={secondStepValidationSchema}>
                    {error && (
                        <ul style={{ color: "red", padding: "12px" }}>
                            <li>{error}</li>
                        </ul>
                    )}
                    {errors && (
                        <ul style={{ color: "red", padding: "12px" }}>
                            {errors?.map((x) => (
                                <li key={x?.value}>
                                    {" "}
                                    {x?.property} : {x?.constrains}
                                </li>
                            ))}
                        </ul>
                    )}
                    <Form.Row>
                        <Form.Group className="col-md-6">
                            <CustomInput
                                placeholder="First Name"
                                type="text"
                                label="First Name"
                                name="nameFirst"
                            />
                        </Form.Group>
                        <Form.Group className="col-md-6">
                            <CustomInput
                                placeholder="Last Name"
                                label="Last Name"
                                type="text"
                                name="nameLast"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-12">
                            <CustomInput
                                placeholder="Name Alias"
                                label="Name Alias"
                                type="text"
                                name="nameAlias"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-12">
                            <CustomInput
                                placeholder="(888) 888-8888"
                                type="tel"
                                className="py-4"
                                maxLength={16}
                                label="Cell Phone"
                                name="phoneCell"
                            />
                        </Form.Group>
                    </Form.Row>
                </FormStep>
            </SteppedForm>
        </div>
    );
};

export default RegisterForm;
