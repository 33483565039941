import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { LoginUserResponse } from "../../types/Forms";
import { Mymembership } from "../../types/Mymembership";
import { Seller } from "../../types/Seller";

interface ILoginUserWithEmailAndPasswordParams {
    password: string;
    email: string;
}

export const loginUserWithEmailAndPassword = createAsyncThunk<
    void,
    ILoginUserWithEmailAndPasswordParams
>("auth/loginUserWithEmailAndPassword", async (params, thunkApi) => {
    try {
        const result = await axiosClient.post<LoginUserResponse>(
            "/auth/sign-in",
            {
                ...params,
            }
        );
        const { access_token } = result.data;
        const { dispatch } = thunkApi;
        dispatch(
            loginUsingToken({
                access_token,
            })
        );
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ICreateUserProfile {
    nameFirst?: string;
    nameLast?: string;
    nameAlias?: string;
    phoneCell?: string;
    profilePic?: string;
    companyLogo?: string;
    companyUrl?: string;
    // timezone: ;
    // personalGatewayId: null;
}

export const createUser = createAsyncThunk<
    { access_token: string },
    ICreateUserProfile
>("auth/register", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<{ access_token: string }>(
            "/auth/sign-up",
            data
        );
        const { dispatch } = thunkApi;
        const { access_token } = result.data;
        dispatch(
            loginUsingToken({
                access_token,
            })
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ILoginUsingTokenParams {
    access_token: string;
}

export const loginUsingToken = createAsyncThunk<Seller, ILoginUsingTokenParams>(
    "auth/loginUser",
    async ({ access_token }, thunkApi) => {
        try {
            localStorage.setItem("access_token", access_token);
            const sellerInfo = await axiosClient.get<Seller>("/seller/me", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            return sellerInfo.data;
        } catch (err: any) {
            // Check whether plan expires

            if (err?.response?.status == 402) {
                const user = err?.response?.data?.user;
                return user;
            }

            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const loginUsingTokenActive = createAsyncThunk<
    Seller,
    ILoginUsingTokenParams
>("auth/loginActiveUser", async ({ access_token }, thunkApi) => {
    try {
        localStorage.setItem("access_token", access_token);
        const sellerInfo = await axiosClient.get<Seller>(
            "/seller/user-status",
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
        return sellerInfo.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const restoreUser = createAsyncThunk<Seller>(
    "auth/restoreUser",
    async (_, { rejectWithValue }) => {
        const token = localStorage.getItem("access_token");
        if (!token) return rejectWithValue(0);
        try {
            const result = await axiosClient.get<Seller>("/seller/me", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return result.data;
        } catch (err: any) {
            if (err?.response?.status == 402) {
                const user = err?.response?.data?.user;
                return user;
            } else {
                localStorage.removeItem("access_token");
                return rejectWithValue("Server error");
            }
        }
    }
);

export const getMyMembership = createAsyncThunk<Mymembership>(
    "membership-plan/my",
    async (_, { rejectWithValue }) => {
        const token = localStorage.getItem("access_token");
        if (!token) return rejectWithValue(0);
        try {
            const result = await axiosClient.get<Mymembership>(
                "membership-plan/my",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return result.data;
        } catch (err: any) {
            localStorage.removeItem("access_token");
            return rejectWithValue("Server error");
        }
    }
);
type ProfileUpdatePayload = {
    nameFirst?: string;
    nameLast?: string;
    nameAlias?: string;
    phoneCell?: string;
    file?: Blob | string | null;
    companyUrl?: string | null;
    companyLogo?: string | null;
    email?: string;
    timezoneDefault?: string | null;
    type?: string;
};

export const updateUserProfile = createAsyncThunk<Seller, ProfileUpdatePayload>(
    "auth/updateUserProfile",
    async (payload, thunkApi) => {
        try {
            const data = new FormData();
            // debugger;
            if (payload.file) {
                data.append("file", payload.file);
            }
            if (payload.nameFirst) {
                data.append("nameFirst", payload.nameFirst);
            }
            if (payload.nameLast) {
                data.append("nameLast", payload.nameLast);
            }
            if (payload.phoneCell) {
                data.append("phoneCell", payload.phoneCell);
            }
            if (payload.nameAlias) {
                data.append("nameAlias", payload.nameAlias);
            }
            if (payload.timezoneDefault) {
                data.append("timezoneDefault", payload.timezoneDefault);
            }
            if (payload.companyUrl) {
                data.append("companyUrl", payload.companyUrl);
            }
            if (payload.type) {
                data.append("type", payload.type);
            }
            if (payload.companyLogo !== undefined) {
                data.append(
                    "companyLogo",
                    payload.companyLogo === null ? "" : payload.companyLogo
                );
            }
            // ... append other properties as needed
            const result = await axiosClient.patch<Seller>("/seller/me", data);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface ISubscribeToPlan {
    priceId: string;
}

export const subscribeToPlan = createAsyncThunk<
    { url: string },
    ISubscribeToPlan
>("auth/subscribeToPlan", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<{ url: string }>(
            "/payments/create-checkout-session",
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IChangePassword {
    email: string;
    oldPassword: string;
    newPassword: string;
}

export const changePassword = createAsyncThunk<void, IChangePassword>(
    "auth/changePassword",
    async (data, thunkApi) => {
        try {
            await axiosClient.patch("/auth/password", data);
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
export const logOutToken = createAsyncThunk<void, void>(
    "auth/logout",
    async (_, thunkApi) => {
        try {
            await axiosClient.post("/auth/log-out"); // Replace with your actual logout API endpoint
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IForgotPasswordParams {
    email: string;
}

export const forgotPassword_ = createAsyncThunk<void, IForgotPasswordParams>(
    "auth/forgotPassword",
    async (data, { rejectWithValue }) => {
        try {
            await axiosClient.post("/auth/forgot-password-email", data);
        } catch (err: any) {
            return rejectWithValue(err.message || "An error occurred");
        }
    }
);

interface IResetPasswordParams {
    newPassword: string;
    token: string;
}

export const resetPassword_ = createAsyncThunk<
    Seller,
    IResetPasswordParams & { token: string }
>("auth/resetPassword", async (data, thunkApi) => {
    try {
        const result = await axiosClient.patch<Seller>(
            "/auth/forgot-password/?token=" + data.token,
            {
                newPassword: data.newPassword,
                resetToken: data.token, // Include the 'token' parameter
            }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ISentOtp {
    isEmail: boolean;
}

export const sentOtp = createAsyncThunk<void, ISentOtp>(
    "auth/sentOTP",
    async ({ isEmail }, thunkApi) => {
        try {
            const response = await axiosClient.post("/auth/sent-otp", {
                isEmail,
            });
            return response.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IOTP {
    otp: string;
    isEmail: boolean | undefined;
}

export const verifyEmail = createAsyncThunk<void, IOTP>(
    "auth/verifyEmail",
    async ({ otp, isEmail }, thunkApi) => {
        try {
            const response = await axiosClient.post("/auth/otp-verify", {
                otp: parseInt(otp),
                isEmail,
            });
            return response.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
