import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { staticPageState } from "../../types/Common";
import { fetchStaticPage } from "../asyncActions/static-page";

const initialState: staticPageState = {
    staticPage: {
        data:undefined,
        isLoading: false,
        error: undefined,
    },
};

const staticPageSlice = createSlice({
    name: "staticpage",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchStaticPage.pending.toString()]: (state) => {
            state.staticPage.isLoading = true;
            state.staticPage.error = undefined;
        },
        [fetchStaticPage.fulfilled.toString()]: (
            state,
            action: PayloadAction<[]>
        ) => {
            state.staticPage.isLoading = false;
            state.staticPage.data = action.payload;
        },
        [fetchStaticPage.rejected.toString()]: (
            state,
            action: PayloadAction<string>
        ) => {
            state.staticPage.isLoading = false;
            state.staticPage.error = action.payload;
            state.staticPage.data = undefined;
        },
    },
});

export const actions = {
    ...staticPageSlice.actions,
    fetchStaticPage,
};

const staticPageReducer = staticPageSlice.reducer;

export default staticPageReducer;
