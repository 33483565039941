import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import React from "react";
import { Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { MsgThread, ThreadState } from "../../types/MsgThread";
dayjs.extend(localizedFormat);
interface IProps {
    partiesList: MsgThread[];
    isLoading?: boolean;
}

const PartyList: React.FC<IProps> = ({ partiesList, isLoading }) => {
    const history = useHistory();

    if (isLoading)
        return (
            <div className="list-view">
                {new Array(7).fill(0).map((_, index) => (
                    <div
                        key={index}
                        className="d-flex py-2 px-3 d-flex align-items-center"
                    >
                        <div className="skeleton shadow-sm small-image rounded-circle" />
                        <div className="d-flex w-100 h-100 flex-grow-1 justify-content-between align-items-center">
                            <div className="d-flex w-100 h-100 flex-column justify-content-center mr-4">
                                <div className="skeleton shadow-sm w-75" />
                                <div className="skeleton shadow-sm w-50 mt-2" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );

    if (partiesList?.length === 0)
        return <p className="p3 text-center mt-3">No events yet</p>;

    return (
        <div className="PrefectScrollbar_Custom">
            <PerfectScrollbar className="pr-3 max-h-550px">
                {partiesList?.map((party) => {
                    const partyNameWords = party.name.split(" ");
                    const nameInitials = `${partyNameWords[0][0]}${
                        partyNameWords[1]?.[0] || ""
                    }`.toUpperCase();
                    return (
                        <div
                            key={party.id}
                            onClick={() =>
                                history.push(
                                    `/manage_parties/party/${party.id}`
                                )
                            }
                            className="list-view__item min-h-65px d-flex px-4 py-2 my-1 d-flex align-items-center cursor-pointer rounded"
                        >
                            <div
                                className={`shadow-sm small-image bg-secondary rounded-circle d-flex align-items-center justify-content-center ${
                                    !party.isActive ? "mt-2" : ""
                                }`}
                            >
                                <p className="user-select-none mb-0 font-weight-bold">
                                    {nameInitials}
                                </p>
                            </div>
                            <div
                                className={
                                    "d-flex flex-grow-1 justify-content-between align-items-center"
                                }
                            >
                                <div className="d-flex flex-column justify-content-center mr-4">
                                    {party.threadState.name ===
                                        ThreadState.IN_PROGRESS && (
                                        <Badge
                                            pill
                                            variant="danger"
                                            className="align-self-start text-uppercase py-1 px-4"
                                        >
                                            Live
                                        </Badge>
                                    )}
                                    <div className="p4 text-truncate text-wrap">
                                        {party.name}
                                    </div>
                                    <div className="p5 font-weight-bold text-wrap text-break">
                                        {dayjs(party.timeSchedStart).format(
                                            "llll"
                                        )}
                                    </div>
                                </div>
                                {/*<Dots*/}
                                {/*    className="cursor-pointer"*/}
                                {/*    onClick={() => console.info(party.id)}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    );
                })}
            </PerfectScrollbar>
        </div>
    );
};

export default PartyList;
