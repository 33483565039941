import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { fetchScriptAssetsByCollection } from "../../store/asyncActions/media";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { mediaActions } from "../../store/reducers/medaiSlice";
import { AssetCollection, ScriptAsset } from "../../types/Media";
import Loading from "../common/Loading";
import FileView from "./FileView";
import MediaEditModal from "./MediaEditModal";

interface IProps {
    show: boolean;
    handleClose: () => void;
    item?: ScriptAsset["items"];
    collectionsList: AssetCollection[];
}

const MediaAssetViewModal: React.FC<IProps> = ({
    handleClose,
    show,
    item,
    collectionsList,
}) => {
    const dispatch = useTypedDispatch();
    const { removeScriptAsset } = mediaActions;
    const currentCollection = useTypedSelector(
        (state) => state.media.collections.selectedCollection
    );

    const [editModalOpen, setEditModalOpen] = useState(false);
    const removeItemConfirm = (item: ScriptAsset["items"]) => {
        handleClose();
        confirmAlert({
            title: "Confirm to delete",
            message:
                "Are you sure you want to delete this item and all objects associated?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(removeScriptAsset({ id: item.id }))
                            .unwrap()
                            .then(() => {
                                dispatch(
                                    fetchScriptAssetsByCollection({
                                        collectionId: currentCollection,
                                    })
                                );
                            })
                            .catch(() => {
                                dispatch(
                                    fetchScriptAssetsByCollection({
                                        collectionId: currentCollection,
                                    })
                                );
                            });
                    },
                },
                {
                    label: "No",
                    onClick: () => console.info("No."),
                },
            ],
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onClickEdit = useCallback((item: ScriptAsset["items"]) => {
        setEditModalOpen(true);
        handleClose();
    }, []);

    const closeModal = useCallback(() => {
        setEditModalOpen(false);
    }, []);

    const content = (
        <div className="d-flex flex-column">
            <div className="w-100 border-bottom overflow-hidden pb-6 mb-4">
                {item ? <FileView file={item} /> : <Loading />}
            </div>
            <div className="flex-grow-1">
                <p className="p4 text-wrap">
                    File name:{" "}
                    <span className="font-weight-boldest" title={item?.name}>
                        {item?.name}
                    </span>
                </p>
                <p className="p4 text-wrap">
                    Uploaded by:{" "}
                    <span className="font-weight-boldest" title={item?.name}>
                        you
                    </span>
                </p>
                <p className="p4">
                    Type of media:{" "}
                    <span className="font-weight-boldest">
                        {item?.assetType.name}
                    </span>
                </p>
            </div>
            <div className="d-flex justify-content-end">
                {!item ? (
                    <Loading />
                ) : (
                    <>
                        <div className="mr-1">
                            <Button
                                variant="danger"
                                onClick={() => removeItemConfirm(item)}
                            >
                                Delete file
                            </Button>
                        </div>
                        <div className="mr-1">
                            <Button
                                variant="primary"
                                onClick={() => onClickEdit(item)}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    className=" w-15px h-15px mr-2"
                                />
                                Edit
                            </Button>
                        </div>
                        <div className="mr-1">
                            <Button
                                variant="primary"
                                className="mr-1"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <>
            <MediaEditModal
                item={item}
                show={editModalOpen}
                handleClose={closeModal}
                collectionsList={collectionsList}
            />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header
                    closeButton
                    className="d-flex justify-content-end custom-modal-header"
                ></Modal.Header>
                <Modal.Body>{!item ? <Loading /> : content}</Modal.Body>
            </Modal>
        </>
    );
};

export default MediaAssetViewModal;
