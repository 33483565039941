import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/contactSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { NewContactFromValues } from "../../types/Forms";
import FormErrors from "../forms/FormErrors";

const SaveGuestContactModal: React.FC = () => {
    const dispatch = useTypedDispatch();

    const { closeSaveGuestContactModal } = modalActions;
    const show = useTypedSelector(
        (state) => state.modals.guestContactSaveModal.isOpen
    );
    const guest = useTypedSelector(
        (state) => state.modals.guestContactSaveModal.currentGuest
    );
    const { createContact } = actions;

    const submitForm = async () => {
        const payload: NewContactFromValues = {
            address: "",
            email: guest?.email ?? "",
            name: guest?.nameAlias ?? "",
            phoneCell: guest?.texters?.phoneCell ?? "",
            profilePic: "",
            status: true,
        };
        const data = await dispatch(createContact(payload))
            .unwrap()
            .then((data: any) => {
                if (!data?.error) {
                    //dispatch(fetchContacts());
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: "Guest Added as Contact",
                        })
                    );
                    dispatch(closeSaveGuestContactModal());
                }
            });

        return data;
    };
    const handleClose = () => {
        dispatch(closeSaveGuestContactModal());
    };
    const formErrors = useTypedSelector(
        (state) => state.contact.createContactForm.error
    );
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header
                closeButton
                className="d-flex justify-content-between align-items-center"
            >
                <Modal.Title>Save Guest as Contact</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                ></button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure wants to add this guest in your contact list!
                </p>
                <FormErrors errors={formErrors} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={submitForm}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SaveGuestContactModal;
