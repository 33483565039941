import { faStickyNote, faStream } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Popover } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/Overlay";
import { NavLink } from "react-router-dom";

const AutomationsPopover: React.FC<OverlayInjectedProps> = (props) => {
    return (
        <Popover id="popover-automations" {...props}>
            <Popover.Content>
                <Nav>
                    <Nav.Link
                        as={NavLink}
                        to="/automations/workflows"
                        className="d-flex align-items-center"
                    >
                        <FontAwesomeIcon
                            icon={faStream}
                            className="mr-2 w-15px h-15px text-secondary"
                        />
                        <div className="p3">Manage Textflows</div>
                    </Nav.Link>
                    {/* <Nav.Link
                        as={NavLink}
                        to="/automations/triggers"
                        className="d-flex align-items-center"
                    >
                        <div className="w-15px h-15px bg-gray-500 rounded-circle shadow mr-2" />
                        <div className="p3">Manage Triggers</div>
                    </Nav.Link> */}
                    <Nav.Link
                        as={NavLink}
                        to="/automations/messages"
                        className="d-flex align-items-center"
                    >
                        <FontAwesomeIcon
                            icon={faStickyNote}
                            className="mr-2 w-15px h-15px text-secondary"
                        />
                        <div className="p3">Manage Quick Texts</div>
                    </Nav.Link>
                </Nav>
            </Popover.Content>
        </Popover>
    );
};

export default AutomationsPopover;
