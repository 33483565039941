import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";

import {
    faEllipsisH,
    faFileCsv,
    faFileImport,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { modalActions } from "../../store/reducers/modalsSlice";
interface IProps {
    btnOnClick?: () => void;
}

const ManageContactActionsPopover: React.FC<IProps> = ({ btnOnClick }) => {
    const dispatch = useTypedDispatch();

    const handleBtnClick = btnOnClick
        ? btnOnClick
        : () => {
              return;
          };
    const items = [
        {
            title: "Add Contact",
            action: handleBtnClick,
            icon: (
                <FontAwesomeIcon
                    icon={faPlus}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Import Contacts",
            action: () => dispatch(modalActions.openImportContactModal()),
            icon: (
                <FontAwesomeIcon
                    icon={faFileImport}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Import Contacts via CSV",
            action: () => dispatch(modalActions.openImportCsvContactModal()),
            icon: (
                <FontAwesomeIcon
                    icon={faFileCsv}
                    className="text-primary w-15px h-15px mr-1 ml-1"
                />
            ),
        },
    ];
    const renderPopover = (props: any) => (
        <Popover id="manage-script-popover" {...props}>
            <Popover.Content className="p-3">
                <ul className="p-0 m-0">
                    {items?.map((item: any) => (
                        <li
                            key={item.title}
                            onClick={() => item?.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            overlay={renderPopover}
            placement="right"
        >
            <FontAwesomeIcon
                icon={faEllipsisH}
                className="w-25px h-30px cursor-pointer ml-2 more-actions rounded-lg shadow-sm btn btn-primary"
            />
        </OverlayTrigger>
    );
};

export default ManageContactActionsPopover;
