import React from "react";
import Log from "../components/myProfile/Log";
import MyProfileHeader from "../components/myProfile/MyProfileHeader";
import DefaultLayout from "../layouts/DefaultLayout";

const MyLog: React.FC = () => {
    return (
        <DefaultLayout>
            <MyProfileHeader />
            <Log />
        </DefaultLayout>
    );
};

export default MyLog;
