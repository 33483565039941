import * as React from "react";
import { Formik, FormikConfig, FormikValues } from "formik";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FormStepProps } from "./FormStep";

const SteppedForm: React.FC<FormikConfig<FormikValues>> = ({
    children,
    ...props
}) => {
    const childrenArray = React.Children.toArray(
        children
    ) as React.ReactElement<FormStepProps>[];
    const [step, setStep] = useState(0);
    const currentChild = childrenArray[step];

    const isLastStep = () => step === childrenArray.length - 1;

    return (
        <Formik
            {...props}
            validationSchema={currentChild.props.validationSchema}
            onSubmit={async (values, helpers) => {
                if (isLastStep()) {
                    await props.onSubmit(values, helpers);
                } else {
                    setStep((s) => s + 1);
                }
            }}
        >
            {({ handleSubmit, isSubmitting, dirty, isValid }) => (
                <Form onSubmit={handleSubmit}>
                    {currentChild}
                    <div className="d-flex justify-content-between w-100">
                        <div>
                            {step > 0 && (
                                <Button
                                    variant="secondary"
                                    onClick={() => setStep((s) => s - 1)}
                                >
                                    Back
                                </Button>
                            )}
                        </div>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="align-self-end"
                        >
                            {isLastStep() ? "Submit" : "Next Step"}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SteppedForm;
