import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { AssetCollection, AssetType, ScriptAsset } from "../../types/Media";

export const fetchUserAssetCollections = createAsyncThunk<AssetCollection[]>(
    "media/fetchUserAssetCollections",
    async (_, thunkApi) => {
        try {
            const result = await axiosClient.get<AssetCollection[]>(
                "/asset-collection/my"
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IFetchScriptAssetsByCollection {
    collectionId: number;
    include_inactive?: boolean;
}

export const fetchScriptAssetsByCollection = createAsyncThunk<
    ScriptAsset[],
    IFetchScriptAssetsByCollection
>(
    "media/fetchScriptAssetsByCollection",
    async ({ collectionId, ...params }, thunkApi) => {
        const url =
            collectionId === 0
                ? "/script-asset/by-collection"
                : `/script-asset/by-collection/${collectionId}`;
        try {
            const result = await axiosClient.get<ScriptAsset[]>(url, {
                params,
            });
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface ICreateMediaCollection {
    name: string;
}

export const createMediaCollection = createAsyncThunk<
    AssetCollection,
    ICreateMediaCollection
>("media/createMediaCollection", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<AssetCollection>(
            "/asset-collection",
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IUploadFile {
    files: any;
    collectionId: number;
}

export const uploadFile = createAsyncThunk<ScriptAsset["items"], IUploadFile>(
    "media/uploadFile",
    async ({ files, collectionId }, thunkApi) => {
        try {
            const data = new FormData();
            files.map((file: File) => {
                data.append("files", file);
            });
            data.append("collectionId", "" + collectionId);
            const result = await axiosClient.post<ScriptAsset["items"]>(
                "/storage/upload",
                data
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface ICreateScripAsset {
    collectionId: number;
    name: string;
    assetUrl: string;
    linkUrl: string;
    assetType: AssetType;
}

export const createScriptAsset = createAsyncThunk<
    ScriptAsset["items"],
    ICreateScripAsset
>("media/createScriptAsset", async ({ assetType, ...data }, thunkApi) => {
    try {
        const result = await axiosClient.post<ScriptAsset["items"]>(
            "/script-asset/",
            data,
            {
                params: {
                    asset_type: assetType,
                },
            }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IRemoveScripAsset {
    id: number;
}

export const removeScriptAsset = createAsyncThunk<number, IRemoveScripAsset>(
    "media/removeScriptAsset",
    async ({ id }, thunkApi) => {
        try {
            await axiosClient.delete(`/script-asset/${id}`);
            return id; // Return the ID of the removed item as the payload
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
export const updateAsset = createAsyncThunk<
    number,
    { id: number; collection: ScriptAsset["items"] }
>("media/updateScriptAsset", async ({ id, collection }, thunkApi) => {
    try {
        await axiosClient.patch(`/script-asset/${id}`, collection);
        return id; // Return the ID of the removed item as the payload
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
