import React, { useState } from "react";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { SelectedPartyTabActions } from "../store/reducers/selectedPartyTabSlice";

interface IItem {
    tabTitle: string;
    component: JSX.Element;
}
interface IProps {
    items: IItem[];
    title?: string;
    icon?: JSX.Element;
    cache?: boolean;
    _id?: string;
    onChange?: (payload: IItem) => void;
}

const TabbedCard: React.FC<IProps> = ({
    items,
    title,
    icon,
    onChange,
    _id,
}) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const { selectedTab } = useTypedSelector((state) => state.tabReducer);
    const dispatch = useTypedDispatch();
    const { changeSelectPartyTab } = SelectedPartyTabActions;

    const selectTab = (tab: IItem, indx: number) => { 
        if (_id === "your_parties_schedule") {
            dispatch(changeSelectPartyTab({ selectedTab: indx }));
        } else {
            setSelectedTabIndex(indx);
        }
        if (onChange) {
            onChange(tab);
        }
    };

    return (
        <>
            {title && (
                <div className="my-2 my-sm-4">
                    {icon && <div>{icon}</div>}
                    <h5 className="text-center text-sm-left">{title}</h5>
                </div>
            )}
            <div className="rounded-lg bg-light border parties-schedule h-lg-750px h-max-750px bg-white mb-2 shadow overflow-hidden">
                <div className="d-flex">
                    {items.map((tab, indx) => (
                        <div
                            key={tab.tabTitle}
                            onClick={() => selectTab(tab, indx)}
                            className={`flex-grow-1 d-flex justify-content-center align-items-center p-5 p4 font-weight-bold cursor-pointer text-uppercase ${
                                indx ===
                                (_id === "your_parties_schedule"
                                    ? selectedTab
                                    : selectedTabIndex)
                                    ? "text-white bg-primary"
                                    : ""
                            }`}
                        >
                            {tab.tabTitle}
                        </div>
                    ))}
                </div>
                {items.map((tab, indx) => (
                    <div
                        key={indx}
                        className={`px-3 px-sm-6 pb-6 pt-3 border-top ${
                            (_id === "your_parties_schedule"
                                ? selectedTab
                                : selectedTabIndex) == indx
                                ? ""
                                : "hidden"
                        }`}
                    >
                        {tab.component}
                    </div>
                ))}
                {/* <div className="px-3 px-sm-6 pb-6 pt-3 border-top">
                    {items[selectedTabIndex].component}
                </div> */}
            </div>
        </>
    );
};

export default TabbedCard;
