import * as React from "react";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Badge, Button } from "react-bootstrap";
import useQueryParams from "../../hooks/useQueryParams";
import LayoutCard from "../../layouts/LayoutCard";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { FormAction } from "../../types/Common";
import { Script } from "../../types/Script";
import PrepopulatedMsgTemplateForm from "../forms/PrepopulatedMsgTemplateForm";
import PrepopulatedMessages from "../prepopulatedMessages/PrepopulatedMessages";
import ManageScriptPopover from "../script/ManageScriptPopover";
import PerfectScrollbar from "react-perfect-scrollbar";

const PrepopulatedMessagesEditorCard: React.FC = () => {
    const prepopulatedSection = (
        <DragDropContext onDragEnd={(_) => console.log(_)}>
            <div className="w-250px border-left d-block d-lg-none d-xl-block ml-2">
                <p className="w-100 text-center p5 text-uppercase mb-1">
                    PREPOPULATED MESSAGES
                </p>
                <PrepopulatedMessages />
            </div>
        </DragDropContext>
    );
    const searchParams = useQueryParams();

    const { messageTemplates, scripts } = useTypedSelector(
        (state) => state.automations
    );
    const messageId = Number(searchParams.get("message"));
    const templateId = Number(searchParams.get("template"));
    const action = searchParams.get("action"); // waiting for create

    const [currentScript, setCurrentScript] = useState<Script>();
    useEffect(() => {
        if (messageId && scripts.list.items.length > 0) {
            setCurrentScript(
                scripts.list.items.filter((x) => x.id === messageId)[0]
            );
        } else {
            setCurrentScript(undefined);
        }
    }, [messageId, scripts]);

    if (messageTemplates.isLoading && !currentScript) {
        return (
            <LayoutCard title="Select Message">
                <div className="d-flex h-700px justify-content-center">
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <Badge pill variant="primary px-4 py-2 shadow-sm">
                            <span className="p4 user-select-none">
                                To edit the message, click on the plus icon.
                            </span>
                        </Badge>
                    </div>
                    {/* {prepopulatedSection} */}
                </div>
            </LayoutCard>
        );
    }

    if (templateId && currentScript && messageTemplates.currentMessage) {
        return (
            <LayoutCard title="Edit Message">
                <div className="d-flex h-700px justify-content-center">
                    <PerfectScrollbar
                        options={{ suppressScrollX: true }}
                        className="w-100"
                    >
                        <div className="d-flex justify-content-center align-items-center position-relative w-100">
                            <h5
                                className="font-weight-bold text-center mb-0"
                                title={currentScript.name}
                            >
                                {currentScript.name}
                            </h5>
                            <ManageScriptPopover script={currentScript} />
                        </div>
                        <PrepopulatedMsgTemplateForm
                            action={FormAction.UPDATE}
                            currentScriptId={messageId}
                            currentMessageTemplate={
                                messageTemplates.currentMessage
                            }
                        />
                    </PerfectScrollbar>
                    {/* {prepopulatedSection} */}
                </div>
            </LayoutCard>
        );
    }

    if (!templateId && currentScript && action === "create") {
        return (
            <LayoutCard title="New Message">
                <div className="d-flex h-700px justify-content-center">
                    <PerfectScrollbar
                        options={{ suppressScrollX: true }}
                        className="w-100"
                    >
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <h5
                                className="font-weight-bold text-center mb-0"
                                title={currentScript.name}
                            >
                                {currentScript.name}
                            </h5>
                            <ManageScriptPopover script={currentScript} />
                        </div>
                        <PrepopulatedMsgTemplateForm
                            action={FormAction.CREATE}
                            currentScriptId={currentScript.id}
                        />
                    </PerfectScrollbar>
                    {/* {prepopulatedSection} */}
                </div>
            </LayoutCard>
        );
    }

    if (currentScript) {
        return (
            <LayoutCard title="Select Message">
                <div className="d-flex h-700px justify-content-center">
                    <PerfectScrollbar
                        options={{ suppressScrollX: true }}
                        className="w-100"
                    >
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <h5
                                className="font-weight-bold text-center mb-0"
                                title={currentScript.name}
                            >
                                {currentScript.name}
                            </h5>
                            <ManageScriptPopover script={currentScript} />
                        </div>
                        <div className="d-flex h-650px justify-content-center align-items-center w-100">
                            <Badge pill variant="primary px-4 py-2 shadow-sm">
                                <span className="p4 user-select-none">
                                    To edit the message, click on the plus icon.                                </span>
                            </Badge>
                        </div>
                    </PerfectScrollbar>
                    {/* {prepopulatedSection} */}
                </div>
            </LayoutCard>
        );
    }
    return (
        <LayoutCard title="Select Message">
            <div className="d-flex h-700px justify-content-center">
                <div className="d-flex justify-content-center align-items-center w-100">
                    <Badge pill variant="primary px-4 py-2 shadow-sm">
                        <span className="p4 user-select-none">
                            To edit the message, click on the plus icon.
                        </span>
                    </Badge>
                </div>
                {/* {prepopulatedSection} */}
            </div>
        </LayoutCard>
    );
};

export default PrepopulatedMessagesEditorCard;
