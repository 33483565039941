import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import ContactForm from "../forms/ContactForm";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { FormAction } from "../../types/Common";
import UpdatePartyForm from "../forms/UpdatePartyForm";
import ImportContactForm from "../forms/ImportContactForm";
import ImportCsv from "../forms/ImportCsv";

const ContactFormModal = () => {
    const dispatch = useTypedDispatch();
    const { closeContactModal } = modalActions;
    const action = useTypedSelector((state) => state.modals.contactModal.action);
    const show = useTypedSelector((state) => state.modals.contactModal.isOpen);
    
    const handleClose = useCallback(() => {
        dispatch(closeContactModal());
    }, []);
  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="d-flex justify-content-end custom-modal-header">
        </Modal.Header>
            <Modal.Body>
                {action === FormAction.CREATE ? (
                    <ContactForm />
                ) : action === FormAction.IMPORT ? (
                    <ImportContactForm />
                ) :action === FormAction.IMPORTCSV ? (
                    <ImportCsv/>
                ):(
                    <UpdatePartyForm />
                )
            }
            </Modal.Body>
        </Modal>
  );
};

export default ContactFormModal;