import * as React from "react";
import { Col, Row } from "react-bootstrap";
import SellerCompanyInfoCard from "../components/cardViews/SellerCompanyInfoCard";
import SellerPasswordChangeCard from "../components/cardViews/SellerPasswordChangeCard";
import SellerProfileInfoCard from "../components/cardViews/SellerProfileInfoCard";
import DefaultLayout from "../layouts/DefaultLayout";

const Profile: React.FC = () => {
    return (
        <DefaultLayout>
            <Row sm={1} xs={1} lg={2} xl={3}>
                <Col xl={4}>
                    <SellerProfileInfoCard />
                </Col>
                <Col xl={4}>
                    <SellerCompanyInfoCard />
                </Col>
                <Col xl={4}>
                    <SellerPasswordChangeCard />
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default Profile;
