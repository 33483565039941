import React from "react";

const ShimmerLoader: React.FC = () => {
    return (
        <>
            {["100"].map((key) => {
                return (
                    <div key={key} className="col-xl-6 mb-2">
                        <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-10 bg-slate-200">
                            <p>No info to show</p>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default ShimmerLoader;
