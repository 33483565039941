import { DateClickArg } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import BigCalendarCard from "../components/cardViews/BigCalendarCard";
import SmallCalendarCard from "../components/cardViews/SmallCalendarCard";
import DefaultLayout from "../layouts/DefaultLayout";

const CalendarDashboard: React.FC = () => {
    const smallCalendarRef = useRef<FullCalendar>(null);
    const bigCalendarRef = useRef<FullCalendar>(null);

    const smallCalendarDateClick = (args: DateClickArg) => {
        const bigCalendarApi = bigCalendarRef.current?.getApi();
        bigCalendarApi?.gotoDate(args.date);
    };

    const bigCalendarDateClick = (args: DateClickArg) => {
        const smallCalendarApi = smallCalendarRef.current?.getApi();
        smallCalendarApi?.gotoDate(args.date);
        smallCalendarApi?.select(args.date);
    };

    return (
        <DefaultLayout>
            <Row>
                <Col xs={12} sm={12} xl={5} className="pt-15">
                    <SmallCalendarCard
                        calendarRef={smallCalendarRef}
                        dateClick={smallCalendarDateClick}
                    />
                </Col>
                <Col xl={7} className="pt-15 d-none d-lg-block">
                    <BigCalendarCard
                        calendarRef={bigCalendarRef}
                        dateClick={bigCalendarDateClick}
                    />
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default CalendarDashboard;
