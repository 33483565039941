import React ,{useEffect} from "react";
import LayoutCard from "../../layouts/LayoutCard";
import ScriptsListView from "../script/ScriptsListView";
import { ScriptType } from "../../types/Script";


const WorkflowsListCard = () => {
      return (
        <LayoutCard>
            <ScriptsListView type={ScriptType.PARTY} />
        </LayoutCard>
    );
};

export default WorkflowsListCard;

