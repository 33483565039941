import * as React from "react";
import LayoutCard from "../../layouts/LayoutCard";
import ChangePasswordForm from "../forms/ChangePasswordForm";

const SellerPasswordChangeCard: React.FC = () => {
    return (
        <LayoutCard title="Change Your Password">
            <ChangePasswordForm />
        </LayoutCard>
    );
};

export default SellerPasswordChangeCard;
