import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ModalWapper from "../../pages/auth/model-pages/ModalWapper";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { authActions } from "../../store/reducers/authSlice";
import Loading from "../common/Loading";
import Snackbar from "../notifications/Snackbar";

const OnlyUnauthorizedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const isAuthenticated = useTypedSelector(
        (state) => state.auth.isAuthenticated
    );
    const dispatch = useTypedDispatch();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const { logOutToken } = authActions;
 
    if (rest.path === "/auth/login" && token) {
        try {
            dispatch(logOutToken()).then(() => {
                window.location.href = `/auth/login?token_v2=${token}`;
            });
            return <Loading />;
        } catch (error) {
            return <Redirect to={"/auth/login"} />;
        }
    }

   


    return (
        <Route
            {...rest}
            render={() =>
                !isAuthenticated ? (
                    <>
                        <Snackbar />
                        <ModalWapper />
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                        {children}
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                )
            }
        />
    );
};

export default OnlyUnauthorizedRoute;
