import React from "react";
import { Accordion, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/announcementSlice";
import { actions as cActions } from "../../store/reducers/contactSlice";
import Loading from "../common/Loading";
import "./contact.scss";
import ManageAnnouncementPopover from "./ManageAnnouncementPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";

interface IProps {
    announcement: any;
    isLoading?: boolean;
    name?: string;
}
const AnnouncementList: React.FC<IProps> = ({
    name,
    announcement,
    isLoading,
}) => {
    const dispatch = useTypedDispatch();
    const { fetchAnnouncementsContacts, selectOne } = actions;
    const [selectedAnnouncementId, setSelectedAnnouncementId] = React.useState(null);
    const { list: announcementContactlist, isLoading: checkLoading } =
        useTypedSelector((state) => state.announcement.announcementContactList);
    // Assuming you are using this function to handle a click event on an accordion item
    const handleAccordionToggle = async (announcement: any) => {
        setSelectedAnnouncementId(announcement.id === selectedAnnouncementId ? null : announcement.id);
        dispatch(
            cActions.setSelected({
                type: "announcement",
                entity: announcement,
            })
        );
        dispatch(selectOne(announcement));
        dispatch(fetchAnnouncementsContacts(announcement?.id || 0))
            .unwrap()
            .then((data: any) => {
                return data;
            });
    };

    const handleContactDelete = (announcementId: number, contactId: number) => {
        confirmAlert({
            title: "Confirm to delete",
            message:
                "Are you sure you want to delete this item?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(
                            actions.deleteContact({ announcementId, contactId })
                        )
                    },
                },
                {
                    label: "No",
                    onClick: () => console.info("No."),
                },
            ],
        })
    }
    if (isLoading && (!announcement || announcement.length === 0))
        return (
            <div>
                {new Array(5).fill(0).map((_, index) => (
                    <div
                        key={index}
                        className="d-flex align-items-center mb-1 px-2 py-1 list-view__item rounded"
                    >
                        <div className="rounded-circle shadow-sm skeleton bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4" />
                        <div className="flex-grow-1 d-flex flex-column">
                            <div className="skeleton w-50 mb-3 shadow-sm" />
                            <div className="skeleton w-20 shadow-sm" />
                        </div>
                    </div>
                ))}
            </div>
        );

    if (announcement.length === 0)
        return (
            <div>
                <p className="p3 text-center mt-3">
                    {name ? name : "No Announcements"}
                </p>
            </div>
        );
    return (
        <div>
            <Accordion defaultActiveKey="1" className="announcementTree"  >
                {announcement.map((announcement: any) => (
                    <div
                        key={announcement.id}
                        className={`d-flex align-items-center p-1 mt-1 list-view__item rounded ${selectedAnnouncementId === announcement.id ? 'bg-secondary' : ''
                            }`}
                    >
                        <div className="card p-0">
                            <div className="accordion-header">
                                <Accordion.Toggle
                                    as={Button}
                                    variant="as"
                                    onClick={() => handleAccordionToggle(announcement)}
                                    eventKey={announcement.id}
                                    className="d-flex justify-content-between w-100 p-1 my-auto"
                                >
                                    <div className="my-auto rounded-circle shadow-sm bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4 user-select-none">
                                        <p className="mb-0">
                                            {announcement.name[0].toUpperCase()}
                                            {announcement.name[0].toUpperCase()}
                                        </p>
                                    </div>
                                    <div className="my-auto flex-grow-1">
                                        <h6 className="mb-0 announcemntTitle">
                                            {announcement.name}
                                        </h6>
                                    </div>
                                    <div className="my-auto popoverAction">
                                        <ManageAnnouncementPopover
                                            announcement={announcement}
                                        />
                                    </div>
                                </Accordion.Toggle>
                            </div>
                            <Accordion.Collapse eventKey={announcement.id}>
                                <div className="p-2">
                                    <h6 className="contactTitle">
                                        <b>Contacts</b>
                                    </h6>
                                    {checkLoading ? (
                                        <Loading />
                                    ) : announcementContactlist.length === 0 ? (
                                        <p>No contact found.</p>
                                    ) : (
                                        announcementContactlist.map((contact) => (
                                            <div key={contact.id} className="flex-grow-1 d-flex justify-content-between box-item p-2 mt-1 border rounded">
                                                <span className="flex-grow-1">
                                                    <h6 className="mb-0">{contact?.name}</h6>
                                                    <p className="mb-0">{contact?.phoneCell}</p>
                                                </span>
                                                <Button className="my-auto cursor-pointer" onClick={() => handleContactDelete(announcement.id, contact.id)} variant="link" >
                                                    <FontAwesomeIcon
                                                        className="text-danger mr-2 fs-4"
                                                        icon={faTrash}
                                                    />
                                                </Button>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </Accordion.Collapse>

                        </div>
                    </div>
                ))}
            </Accordion>
        </div>

    );
};

export default AnnouncementList;
