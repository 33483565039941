import * as React from "react";
import { Col, Row } from "react-bootstrap";
import MediaUploadCard from "../components/cardViews/MediaUploadCard";
import MediaCollectionsModal from "../components/media/MediaCollectionsModal";
import MediaGridView from "../components/media/MediaGridView";
import DefaultLayout from "../layouts/DefaultLayout";

const MediaDashboard: React.FC = () => {
    return (
        <DefaultLayout>
            <MediaCollectionsModal />
            <Row>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="pt-15">
                    <div className="position-sticky top-0">
                        <MediaUploadCard />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="pt-15">
                    <MediaGridView />
                </Col>
            </Row>
        </DefaultLayout>
    );
};

export default MediaDashboard;
