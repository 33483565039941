import * as React from "react";
import { ContactLists } from "../../types/ContactList";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { modalActions } from "../../store/reducers/modalsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faEllipsisH,
    // faEnvelope,
    faTrashAlt,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../store/reducers/contactSlice";
import { confirmAlert } from "react-confirm-alert";
import {
    fetchActiveContacts,
    fetchContacts,
} from "../../store/asyncActions/contact";
interface IProps {
    contact: ContactLists;
    _page?: number;
    _limit?: number;
    search?: string;
}

const ManageContactPopover: React.FC<IProps> = ({
    contact,
    _page,
    _limit,
    search,
}: any) => {
    const dispatch = useTypedDispatch();
    const items = [
        {
            title: "Edit Contact",
            action: () =>
                dispatch(modalActions.openCreateContactModal(contact)),
            icon: (
                <FontAwesomeIcon
                    icon={faUser}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Move to Announcement",
            action: () =>
                dispatch(
                    modalActions.openMoveAnncouncementContactModal(contact?.id)
                ),
            icon: (
                <FontAwesomeIcon
                    icon={faUser}
                    className="text-primary w-15px h-15px mr-2"
                />
            ),
        },
        {
            title: "Delete Contact",
            action: () =>
                confirmAlert({
                    title: "Confirm to delete",
                    message:
                        "Are you sure you want to delete this item and all objects associated?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                dispatch(actions.deleteContact(contact.id))
                                    .unwrap()
                                    .then(() => {
                                        dispatch(
                                            fetchContacts({
                                                _page,
                                                _limit,
                                                search,
                                            })
                                        );
                                        dispatch(
                                            fetchActiveContacts({
                                                _page: 1,
                                                _limit: 5000,
                                                search: "",
                                            })
                                        );
                                        // history.push(
                                        //     "/manage_parties/dashboard"
                                        // );
                                    })
                                    .catch(() => {
                                        // dispatch(
                                        //     showSnackbarWithAutoHide({
                                        //         msgText: err,
                                        //     })
                                        // );
                                    });
                            },
                        },
                        {
                            label: "No",
                            onClick: () => console.info("No."),
                        },
                    ],
                }),
            icon: (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="text-danger w-15px h-15px mr-2"
                />
            ),
        },
        // {
        //     title: "Add In Announcement",
        //     action: () => dispatch(modalActions.openAddInAnncouncementModal(contact)),
        //     icon: (
        //         <FontAwesomeIcon
        //             icon={faUser}
        //             className="text-primary w-15px h-15px mr-2"
        //         />
        //     ),
        // },
    ];
    const renderPopover = (props: any) => (
        <Popover id="manage-script-popover" {...props}>
            <Popover.Content className="p-3">
                <ul className="p-0 m-0">
                    {items.map((item) => (
                        <li
                            key={item.title}
                            onClick={() => item.action()}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            {item.icon}
                            <p className="p3 mb-0">{item.title}</p>
                        </li>
                    ))}
                </ul>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            rootClose
            trigger="click"
            overlay={renderPopover}
            placement="right"
        >
            <FontAwesomeIcon
                icon={faEllipsisH}
                className="w-25px h-20px cursor-pointer"
            />
        </OverlayTrigger>
    );
};

export default ManageContactPopover;
