import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../helpers/axiosClients";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { ScriptAsset } from "../../types/Media";
import { MsgTemplate, Script, ScriptType } from "../../types/Script";
import { showSnackbarWithAutoHide } from "./notifications";

const sortMsgTemplates = (a: MsgTemplate, b: MsgTemplate) => {
    const firstIndex = a.scriptIndex;
    const secondIndex = b.scriptIndex;
    return firstIndex > secondIndex ? 1 : firstIndex < secondIndex ? -1 : 0;
};

interface IFetchScriptSParams {
    _page?: number;
    _limit?: number;
    search?: string;
}

export const fetchScripts = createAsyncThunk<Script[], IFetchScriptSParams>(
    "automations/fetchScripts",
    async ({ _page, _limit, search }, thunkApi) => {
        try {
            const result = await axiosClient.get<Script[]>("/script/my", {
                params: {
                    page: _page,
                    limit: _limit,
                    search,
                },
            });
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const fetchScriptsPaginate = createAsyncThunk<
    Script[],
    IFetchScriptSParams
>(
    "automations/fetchScriptsPaginate",
    async ({ _page, _limit, search }, thunkApi) => {
        try {
            const result = await axiosClient.get<Script[]>("/script/my", {
                params: {
                    page: _page,
                    limit: _limit,
                    search,
                },
            });
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface ICreateScriptParams {
    name: string;
    scriptType: ScriptType;
}

export const createScript = createAsyncThunk<Script, ICreateScriptParams>(
    "automations/createScript",
    async ({ scriptType, name }, thunkApi) => {
        try {
            const result = await axiosClient.post<Script>(
                "/script",
                {
                    name,
                },
                {
                    params: {
                        script_type: scriptType,
                    },
                }
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface ISendAutomationMessageParams {
    msgThreadId: number;
    msgTemplateId: number;
}
export const sendAutomationMessage = createAsyncThunk<
    Script,
    ISendAutomationMessageParams
>(
    "automation/send-message",
    async ({ msgThreadId, msgTemplateId }, thunkApi) => {
        try {
            const result = await axiosClient.post<Script>(
                "/automation/send-message",
                {
                    msgThreadId,
                    msgTemplateId,
                }
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);
interface IEditScriptParams {
    scriptId?: number;
    name: string;
    scriptType: ScriptType;
    starred?: boolean;
}

export const updateScript = createAsyncThunk<Script, IEditScriptParams>(
    "automations/editScript",
    async ({ scriptId, ...data }, thunkApi) => {
        try {
            const result = await axiosClient.put<Script>(
                `/script/${scriptId}`,
                data,
                {
                    params: {
                        id: scriptId,
                    },
                }
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IDeactivateScriptParams {
    id: number;
}

export const deactivateScript = createAsyncThunk<
    number,
    IDeactivateScriptParams
>("automations/deactivateScript", async ({ id }, thunkApi) => {
    try {
        const result = await axiosClient.delete(`/script/${id}`);
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IGetMsgTemplatesParams {
    scriptId: number;
}
interface isFavorite {
    msgTemplateId: number;
    isFavorite: boolean;
}

export const fetchMsgTemplates = createAsyncThunk<
    MsgTemplate[],
    IGetMsgTemplatesParams
>("automations/fetchMsgTemplates", async ({ scriptId }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgTemplate[]>(
            `/msg-template/by-script/${scriptId}/`
        );
        return result.data.sort(sortMsgTemplates);
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const fetchMsgTemplatesDragnDrop = createAsyncThunk<
    MsgTemplate[],
    IGetMsgTemplatesParams
>("automations/fetchMsgTemplatesDragnDrop", async ({ scriptId }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgTemplate[]>(
            `/msg-template/by-script/${scriptId}/`
        );
        return result.data.sort(sortMsgTemplates);
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const setisFav = createAsyncThunk<MsgTemplate[], isFavorite>(
    "automations/setisFav",
    async ({ msgTemplateId, isFavorite: Fav }, thunkApi) => {
        try {
            const result = await axiosClient.put<MsgTemplate[]>(
                `/msg-template/${msgTemplateId}/favorite`,
                { isFavorite: Fav }
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface ISizableParams {
    size: number;
}
export const fetchFavoriteMsgTemplates = createAsyncThunk<
    MsgTemplate[],
    ISizableParams
>("automations/fetchFavoriteMsgTemplates", async ({ size }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgTemplate[]>(
            "/msg-template/favorites",
            { params: { size: size } }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const fetchTopUsedMsgTemplates = createAsyncThunk<
    MsgTemplate[],
    ISizableParams
>("automations/fetchTopUsedMsgTemplates", async ({ size }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgTemplate[]>(
            "/top-used/recently-used",
            { params: { size: size } }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IMsgTemplateSearchParams {
    searchText: string;
    script: ScriptType;
}
export const searchMsgTemplates = createAsyncThunk<
    MsgTemplate[],
    IMsgTemplateSearchParams
>("automations/searchMsgTemplates", async ({ searchText }, thunkApi) => {
    try {
        if (!searchText) {
            return [];
        }
        const result = await axiosClient.get<MsgTemplate[]>(
            "/top-used/recently-used",
            { params: { search: searchText } }
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ICreateMsgTemplateParams {
    scriptId: number;
    name: string;
    quietWaitSeconds: number;
    maxWaitSeconds: number;
    messageText: string;
    mediaItems: ScriptAsset["items"];
}

export const createMsgTemplate = createAsyncThunk<
    MsgTemplate,
    ICreateMsgTemplateParams
>("automations/createMsgTemplate", async ({ ...data }, thunkApi) => {
    try {
        const result = await axiosClient.post<MsgTemplate>(
            "/msg-template/",
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IUpdateMsgTemplateParams {
    id?: number;
    name: string;
    quietWaitSeconds: number;
    maxWaitSeconds: number;
    messageText: string;
    mediaItems: ScriptAsset["items"];
}

export const updateMsgTemplate = createAsyncThunk<
    MsgTemplate,
    IUpdateMsgTemplateParams
>("automations/updateMsgTemplate", async ({ id, ...data }, thunkApi) => {
    try {
        const result = await axiosClient.put<MsgTemplate>(
            `/msg-template/${id}`,
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ISetFavoriteMsgTemplateParams {
    id: number;
    isFavorite: boolean;
}

export const setFavoriteMsgTemplate = createAsyncThunk<
    MsgTemplate,
    ISetFavoriteMsgTemplateParams
>(
    "automations/toggleFavoriteMsgTemplate",
    async ({ id, ...data }, thunkApi) => {
        try {
            const result = await axiosClient.put<MsgTemplate>(
                `/msg-template/${id}/favorite`,
                data
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export interface ISwapMessageTemplatesParams {
    sourceId: number;
    data: any;
    sourceIndex: number;
    destinationScriptIndex: number;
    destinationIndex: number;
}

export const swapMessageTemplates = createAsyncThunk<
    void,
    ISwapMessageTemplatesParams
>("automations/swapMessageTemplates", async ({ sourceId, data }, thunkApi) => {
    try {
        await axiosClient.put<MsgTemplate>(
            `/msg-template/change-order/${sourceId}`,
            data
        );
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export interface ICopyTemplatesParams {
    sourceId: number;
    scriptId: number;
    sourceIndex: number;
    destinationIndex?: number;
}

export interface ICopyTemplatesParamsScriptList {
    MsgTemplateDrag: number;
    ScriptIdDrop: number;
    dropIndex: number;
}

export const copyMessageTemplates = createAsyncThunk<
    void,
    ICopyTemplatesParams
>(
    "automations/copyTemplates",
    async ({ sourceId, destinationIndex, scriptId }, thunkApi) => {
        try {
            await axiosClient.post<MsgTemplate>(
                `/msg-template/copy/${sourceId}/${scriptId}`,
                {},
                { params: { insert_at: destinationIndex } }
            );
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const copyMessageTemplatesForScriptList = createAsyncThunk<
    void,
    ICopyTemplatesParamsScriptList
>(
    "automations/copyTemplates/ScriptList",
    async ({ MsgTemplateDrag, ScriptIdDrop, dropIndex }, thunkApi) => {
        try {
            await axiosClient.post<MsgTemplate>(
                "/msg-template/change-order-and-add-new",
                {
                    ScriptId: ScriptIdDrop,
                    dragIndex: null,
                    msgTemplateId: MsgTemplateDrag,
                    dropIndex: dropIndex,
                }
            );
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export interface IDeleteMsgTemplateParams {
    msgTemplateId: number;
}

export const deleteMsgTemplate = createAsyncThunk<
    number,
    IDeleteMsgTemplateParams
>("automations/deleteMsgTemplate", async ({ msgTemplateId }, thunkApi) => {
    try {
        await axiosClient.delete(`/msg-template/${msgTemplateId}`);
        return msgTemplateId;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export interface IEmailScriptInviteForm {
    scriptId: number | undefined;
    email: string;
}

export const sendEmailScriptInvite = createAsyncThunk<
    void,
    IEmailScriptInviteForm
>(
    "automations/sendEmailScriptInvite",
    async ({ email, scriptId }, thunkApi) => {
        try {
            const resData = await axiosClient.post(
                `/msg-template/script/send-invite-email`,
                {
                    email,
                    scriptId,
                }
            );
            if (resData.data.success === true) {
                thunkApi.dispatch(
                    showSnackbarWithAutoHide({
                        msgText: resData.data.message,
                    })
                );
            } else {
                thunkApi.dispatch(
                    showSnackbarWithAutoHide({
                        msgText: resData.data.message,
                    })
                );
            }
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const copyScriptByInvite = createAsyncThunk<
    void,
    { history: any; token: string }
>("automations/copyScriptByInvite", async ({ history, token }, thunkApi) => {
    try {
        const resData = await axiosClient.post(`/msg-template/copy/text-flow`, {
            token,
        });
        if (resData.data.success === true) {
            thunkApi.dispatch(
                showSnackbarWithAutoHide({
                    msgText: resData.data.message,
                })
            );
            history.push("/");
        } else {
            thunkApi.dispatch(
                showSnackbarWithAutoHide({
                    msgText: resData.data.message,
                })
            );
            history.push("/");
        }
    } catch (err: any) {
        history.push("/");
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
