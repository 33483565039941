import * as React from "react";
import { FileWithPreview } from "../../types/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileVideo } from "@fortawesome/free-solid-svg-icons";
import { determineMediaFileType } from "../../helpers/determineMediaFileType";
import { AssetType } from "../../types/Media";

interface IProps {
    file: FileWithPreview;
}

const FilePreview: React.FC<IProps> = ({ file }) => {
    const fileType = determineMediaFileType(file.type);
    switch (fileType) {
        case AssetType.VIDEO:
            return (
                <div
                    title={file.name}
                    className="d-flex flex-column w-200px h-100px align-items-center d-flex justify-content-center align-items-center overflow-hidden"
                >
                    <FontAwesomeIcon
                        icon={faFileVideo}
                        className="h-50px w-50px"
                    />
                    <p className="mb-0 text-truncate text-wrap max-w-150px">
                        {file.name}
                    </p>
                </div>
            );

        case AssetType.AUDIO:
            return (
                <div
                    title={file.name}
                    className="d-flex flex-column align-items-center w-100"
                >
                    <p className="text-truncate mb-0 mt-2 mx-3">{file.name}</p>
                    <audio src={file.preview} controls className="d-block" />
                </div>
            );

        case AssetType.IMAGE:
            return (
                <img
                    title={file.name}
                    src={file.preview}
                    className="d-block w-100px h-100px preview-image"
                    alt={file.name}
                />
            );

        default:
            return <></>;
    }
};

export default FilePreview;
