import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import useTypedSearchField from "../../hooks/useTypedSearchField";
import CustomPagination from "../../layouts/CustomPagination";
import { fetchAnnouncements } from "../../store/asyncActions/announcement";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { modalActions } from "../../store/reducers/modalsSlice";
import { AnnouncementField } from "../../types/MsgThread";
import AnnouncementList from "../contactList/AnnouncementList";

const Announcement: React.FC = () => {
    const dispatch = useTypedDispatch();
    const { list, isLoading, page, limit } = useTypedSelector(
        (state) => state.announcement.announcementList
    );
    const totalItems = (list as any)?.meta?.totalItems;
    // const [isShowLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(page);
    const [searchValue, setSearchValue] = useState<string>("");
    const [dataPerPage] = useState(limit);
    const [apiList, setApiList] = useState<AnnouncementField[]>([]);

    useEffect(() => {
        const fetchData = () => {
            dispatch(
                fetchAnnouncements({
                    _page: currentPage,
                    _limit: dataPerPage,
                    search: searchValue,
                })
            );
        };
        fetchData();
    }, [currentPage, dataPerPage]);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    useEffect(() => {
        if ((list as any)?.items) {
            setApiList((list as any)?.items);
        }
    }, [list]);

    // const [items, setItems] = useState([]); // State to hold your data

    const [announcementList, setAnnouncementList] = useState<
        AnnouncementField[]
    >([]);
    // const handleScroll = (e: any) => {
    //     const bottom =
    //         e.target.scrollHeight - e.target.scrollTop ===
    //         e.target.clientHeight;
    //     // setIsLoading(true);

    //     if (bottom && !isLoading) {
    //         // Set isLoading to true to prevent multiple simultaneous requests

    //         // Simulate a data fetch (replace this with your actual fetch logic)
    //         setTimeout(() => {
    //             dispatch(
    //                 fetchAnnouncements({ _page: 2, _limit: limit, search })
    //             );
    //             // Set isLoading back to false
    //             // setIsLoading(false);
    //         }, 800); // Simulated delay for demonstration
    //     }
    // };
    const SearchField = useTypedSearchField<AnnouncementField>({
        btnOnClick: () =>
            dispatch(modalActions.openCreateAnncouncementModal(undefined)),
        inputPlaceholder: "Search Announcements...",
        btnType: "bulk",
        list: apiList,
        itemValueSelector: (item) => item.name.toLowerCase(),
        searchValueTransform: (value) => value.toLowerCase(),
        searching: "serverSide",
        searchOnChange: (value) => {
            dispatch(
                fetchAnnouncements({
                    _page: 1,
                    _limit: dataPerPage,
                    search: value,
                })
            );
            setSearchValue(value);
            return value;
        }, // Set the value here
        setList: setAnnouncementList,
    });

    return (
        <>
            {SearchField}
            <PerfectScrollbar className="pr-3 mt-2 max-h-550px">
                <AnnouncementList
                    announcement={announcementList}
                    isLoading={isLoading}
                />
            </PerfectScrollbar>
            <div className="centerPagination">
                {totalItems > dataPerPage && (
                    <CustomPagination
                        dataPerPage={dataPerPage}
                        totalData={totalItems}
                        paginate={(page) => paginate(page)} // Pass the paginate function here
                        currentPage={currentPage}
                    />
                )}
            </div>
        </>
    );
};

export default Announcement;
