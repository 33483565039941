import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import {
    fetchActiveContacts,
    fetchContacts,
} from "../../store/asyncActions/contact";
import { showSnackbarWithAutoHide } from "../../store/asyncActions/notifications";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import {
    actions,
    resetCreateContactFormError,
} from "../../store/reducers/contactSlice";
import { modalActions } from "../../store/reducers/modalsSlice";
import { NewContactFromValues } from "../../types/Forms";
import CustomInput from "./fields/CustomInput";
import FormErrors from "./FormErrors";

const phoneRegExpUS = /^\+1\d{10}$/;
const phoneRegExpIndia = /^\+91\d{10}$/;
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("contact name is required")
        .max(150, "contact name can't be that long"),
    address: Yup.string()
        //.required("address is required")
        .nullable() // Allow null or empty address
        .max(150, "address can't be that long"),
    phoneCell: Yup.string()
        .required("Cell Number is required")
        .test("is-valid-phone-number", "Cell number is invalid", (val) => {
            if (!val) return false;
            return phoneRegExpUS.test(val) || phoneRegExpIndia.test(val);
        })
        .nullable(),
    email: Yup.string()
        // .required("Host email is required")
        .nullable() // Allow null or empty address
        .email("Email is not valid"),
    //profilePic: Yup.string(),
});

const ContactForm: React.FC = () => {
    const [initialValues, setInitialValues] = useState<NewContactFromValues>({
        name: "",
        address: "",
        phoneCell: "",
        email: "",
        profilePic: "",
        status: true,
    });

    const dispatch = useTypedDispatch();
    const formErrors = useTypedSelector(
        (state) => state.contact.createContactForm.error
    );

    const contact = useTypedSelector(
        (state) => state.modals.contactModal.currentContact
    );
    const { page, limit, search } = useTypedSelector(
        (state) => state.contact.contactList
    );
    useEffect(() => {
        if (contact) {
            setInitialValues(contact);
        }
        dispatch(resetCreateContactFormError());
    }, [contact]);

    const { createContact, UpdateContact } = actions;
    const { closeContactModal } = modalActions;
    const submitForm = async (values: NewContactFromValues) => {
        const data = await dispatch(
            contact
                ? UpdateContact({ ...values, id: contact?.id })
                : createContact(values)
        )
            .unwrap()
            .then((data: any) => {
                if (!data?.error) {
                    contact
                        ? dispatch(
                              fetchContacts({
                                  _page: page,
                                  _limit: limit,
                                  search,
                              })
                          )
                        : dispatch(
                              fetchContacts({ _page: 1, _limit: limit, search })
                          );
                    dispatch(closeContactModal());
                    dispatch(
                        fetchActiveContacts({
                            _page: 1,
                            _limit: 50,
                            search: "",
                        })
                    );
                    dispatch(
                        showSnackbarWithAutoHide({
                            msgText: contact
                                ? "Contact Updated!"
                                : "Contact Added!",
                        })
                    );
                }
            });

        return data;
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values)
                    .then((data: any) => {
                        if (!data?.error) {
                            //resetForm();
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({
                handleSubmit,
                dirty,
                isSubmitting,
                isValid,
                setFieldValue,
            }) => (
                <Form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column justify-content-between h-100"
                >
                    <div>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Unique Contact Name..."
                                    type="text"
                                    label="Contact Name"
                                    name="name"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Address..."
                                    type="text"
                                    label="Address"
                                    name="address"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Mobile Number..."
                                    type="tel"
                                    className="py-4"
                                    label="Mobile Number"
                                    name="phoneCell"
                                    required={true}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <CustomInput
                                    placeholder="Email Address"
                                    type="text"
                                    label="Email"
                                    name="email"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className="col-md">
                                <label htmlFor="status">Status</label>
                                <Field
                                    as="select"
                                    name="status"
                                    className="form-control"
                                    onChange={(e: any) => {
                                        const selectedValue = JSON.parse(
                                            e.target.value
                                        ); // Convert the value to a boolean
                                        setFieldValue("status", selectedValue); // Manually set the field value
                                    }}
                                >
                                    <option
                                        value="true"
                                        selected={contact?.status === true}
                                    >
                                        Active
                                    </option>
                                    <option
                                        value="false"
                                        selected={contact?.status === false}
                                    >
                                        Inactive
                                    </option>
                                </Field>
                            </Form.Group>
                        </Form.Row>
                    </div>

                    <FormErrors errors={formErrors} />

                    <Form.Row className="flex-grow-1 d-flex justify-content-center align-items-end mb-5">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!dirty || !isValid || isSubmitting}
                            className="mx-4"
                        >
                            {contact ? "Update Contact" : "Create New Contact"}
                        </Button>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;
