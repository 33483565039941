import * as React from "react";

function SvgVideoicon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path fill="#3699FF" d="M0 0h24v24H0z" />
            <path
                d="M20 12a8 8 0 11-16.001 0A8 8 0 0120 12zm-9.21-2.907A.5.5 0 0010 9.5v5a.5.5 0 00.79.407l3.5-2.5a.501.501 0 000-.814l-3.5-2.5z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgVideoicon;
