import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddCardStep from "../../pages/AddCardStep";
import { ForgotPassword } from "../../pages/auth/ForgotPassword";
import Login from "../../pages/auth/Login";
import Register from "../../pages/auth/Register";
import { ResetPassword } from "../../pages/auth/ResetPasswordToken";
import ManagePrepopulatedMessages from "../../pages/automations/ManagePrepopulatedMessages";
import ManageWorkflows from "../../pages/automations/ManageWorkflows";
import Billing from "../../pages/Billing";
import CalendarDashboard from "../../pages/CalendarDashboard";
import ChangePasswordPage from "../../pages/ChangePassword";
import ContactDashboard from "../../pages/ContactDashboard";
import DirectMessages from "../../pages/DirectMessages";
import GoogleContactsCallback from "../../pages/GoogleContactsCallback";
import JoinParty from "../../pages/JoinParty";
import AllLegalPage from "../../pages/legalInformation/AllLegalPage";
import ManagePartiesDashboard from "../../pages/manageParties/Dashboard";
import ManagePartiesSingleParty from "../../pages/manageParties/SingleParty";
import MediaDashboard from "../../pages/MediaDashboard";
import MyBilling from "../../pages/MyBilling";
import MyCompany from "../../pages/MyCompany";
import MyLog from "../../pages/MyLog";
import MyPlans from "../../pages/MyPlans";
import MyProfile from "../../pages/MyProfile";
import PartyDashboard from "../../pages/PartyDashboard";
import Profile from "../../pages/Profile";
import UpgradePlan from "../../pages/UpgradePlan";
import InactiveSellerRoute from "./InactiveSellerRoute";
import OnlyUnauthorizedRoute from "./OnlyUnauthorizedRoute";
import ProtectedRoute from "./ProtectedRoute";
import TextFlowCopy from "../../pages/TextFlowCopy";

const Routes: React.FC = () => {
    return (
        <>
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/party_hub" />
                </Route>
                <ProtectedRoute path="/party_hub" exact>
                    <PartyDashboard />
                </ProtectedRoute>
                <ProtectedRoute path="/manage_parties" exact>
                    <Redirect to={"/manage_parties/dashboard"} />
                </ProtectedRoute>
                <ProtectedRoute path="/manage_parties/dashboard" exact>
                    <ManagePartiesDashboard />
                </ProtectedRoute>
                <ProtectedRoute path="/manage_parties/party/:id" exact>
                    <ManagePartiesSingleParty />
                </ProtectedRoute>
                <ProtectedRoute path="/calendar" exact>
                    <CalendarDashboard />
                </ProtectedRoute>
                <ProtectedRoute path="/direct_messages" exact>
                    <DirectMessages />
                </ProtectedRoute>
                <ProtectedRoute path="/automations/workflows" exact>
                    <ManageWorkflows />
                </ProtectedRoute>
                <ProtectedRoute path="/automations/messages" exact>
                    <ManagePrepopulatedMessages />
                </ProtectedRoute>
                <ProtectedRoute path="/media" exact>
                    <MediaDashboard />
                </ProtectedRoute>
                <ProtectedRoute path="/textflow/invite" exact>
                    <TextFlowCopy />
                </ProtectedRoute>
                <ProtectedRoute path="/contact" exact>
                    <ContactDashboard />
                </ProtectedRoute>
                <ProtectedRoute path="/profile" exact>
                    <Profile />
                </ProtectedRoute>
                <InactiveSellerRoute path="/checkout" exact>
                    <Billing />
                </InactiveSellerRoute>
                <InactiveSellerRoute path="/billing" exact>
                    <Billing />
                </InactiveSellerRoute>
                <ProtectedRoute path="/plans" exact>
                    <MyPlans />
                </ProtectedRoute>
                <ProtectedRoute path="/upgrade" exact>
                    <UpgradePlan />
                </ProtectedRoute>
                <OnlyUnauthorizedRoute path="/auth/login" exact>
                    <Login />
                </OnlyUnauthorizedRoute>
                <OnlyUnauthorizedRoute path="/auth/forgot-password" exact>
                    <ForgotPassword />
                </OnlyUnauthorizedRoute>
                <OnlyUnauthorizedRoute path="/auth/resetpassword/" exact>
                    <ResetPassword />
                </OnlyUnauthorizedRoute>
                <OnlyUnauthorizedRoute path="/auth/register" exact>
                    <Register />
                </OnlyUnauthorizedRoute>
                <Route path="/legal/:pageType" exact>
                    <AllLegalPage />
                </Route>

                <OnlyUnauthorizedRoute path="/join" exact>
                    <JoinParty />
                </OnlyUnauthorizedRoute>
                <ProtectedRoute path="/my-profile/overview" exact>
                    <MyProfile />
                </ProtectedRoute>
                <ProtectedRoute path="/my-profile/billing" exact>
                    <MyBilling />
                </ProtectedRoute>
                <ProtectedRoute path="/my-profile/company" exact>
                    <MyCompany />
                </ProtectedRoute>

                <ProtectedRoute path="/my-profile/change-password" exact>
                    <ChangePasswordPage />
                </ProtectedRoute>
                <ProtectedRoute path="/my-profile/log" exact>
                    <MyLog />
                </ProtectedRoute>
                <InactiveSellerRoute path="/add-card-step/plan/:planId" exact>
                    <AddCardStep />
                </InactiveSellerRoute>
                <ProtectedRoute path="/contactCallback" exact>
                    <GoogleContactsCallback />
                </ProtectedRoute>
            </Switch>
        </>
    );
};

export default Routes;
